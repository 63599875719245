import React from 'react'
import { includeComma } from '../../../../services/global/globalFunction'
import walletIcon from '../../../../assets/img/money-wallet.png'

const CreditComponent = ({ creditData }) => {
  const { job_credits, credits_available } = creditData
  return (
    <div className="flex gap-3 items-center">
      <img src={walletIcon} className="w-14 md:w-20 object-contain" alt="" />

      <div className="text-left">
        <h1 className=" text-xl font-medium text-gray-500 ">
          <span className="text-sm">Credits : </span>
          {includeComma(credits_available)}
        </h1>
        <h3 className="text-lg text-gray-500">
          <span className="text-sm">Job Credits : </span>
          {includeComma(job_credits)}
        </h3>
      </div>
    </div>
  )
}

export default CreditComponent
