import React from 'react'
import { Link } from 'react-router-dom'
import { PRICING_PAGE } from '../../../navigation/routes'

const NoCreditComponent = () => {
  return (
    <div className="w-full  max-w-xl mx-auto flex flex-col gap-6 text-sm  items-center justify-center text-center py-10 px-6 lg:px-20 rounded-3xl bg-gray-100 ">
      <lottie-player
        src="https://assets2.lottiefiles.com/packages/lf20_yZpLO2.json"
        background="transparent"
        speed="1"
        style={{ height: '250px', objectFit: 'contain' }}
        loop
        autoplay
      ></lottie-player>
      <h3 className="font-bold text-xl">Not Enough Credits</h3>
      <p className="w-full max-w-2xl leading-6">
        Seems like you've used off all your credits . Don't worry , we have more
        credits in the stock
      </p>
      <Link
        to={PRICING_PAGE}
        className="px-4 py-3 rounded-lg bg-gray-200 text-gray-500 font-bold text-xs"
      >
        Get More Credits
      </Link>
    </div>
  )
}

export default NoCreditComponent
