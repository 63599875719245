import React, { Fragment, useState } from 'react'
import { MdClose } from 'react-icons/md'
import AddedApplicants from './AddedApplicants'
import NewApplicantForm from './NewApplicantForm'

const AddApplicantModal = ({
  close,
  jobID,
  activeStep,
  setActiveStep,
  isModal = false
}) => {
  const [addedApplicants, setAddedApplicants] = useState([])

  const handleButtonClick = () => {
    setActiveStep(activeStep + 1)
    close()
  }

  return (
    <Fragment>
      <div
        className={`p-3 sm:p-6 md:p-10 lg:p-16 py-10 lg:py-16 w-full flex flex-col gap-10 ${
          isModal && 'rounded-2xl scrollbar-hidden '
        } max-h-[95vh]  bg-white overflow-y-scroll relative `}
      >
        {isModal && (
          <button
            onClick={close}
            className="absolute top-6 right-6 text-xl font-bold"
          >
            <MdClose />
          </button>
        )}
        {addedApplicants.length > 0 && (
          <AddedApplicants data={addedApplicants} />
        )}

        <NewApplicantForm
          setAddedApplicants={setAddedApplicants}
          addedApplicants={addedApplicants}
          jobID={jobID}
        />

        {addedApplicants.length > 0 && (
          <button
            onClick={() => (isModal ? close() : handleButtonClick())}
            className="p-3 px-5 self-end bg-blue-500 text-white rounded-xl w-40 text-sm font-semibold "
          >
            {isModal ? 'Finish' : 'Continue'}
          </button>
        )}
      </div>
    </Fragment>
  )
}

export default AddApplicantModal
