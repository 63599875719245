import React, { useState } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ReactHtmlParser from 'react-html-parser'
import { AiOutlineAntDesign, AiOutlineClockCircle } from 'react-icons/ai'
import { GiDesk, GiMoneyStack } from 'react-icons/gi'
import { IoMedalOutline, IoPeopleOutline } from 'react-icons/io5'
import {
  MdCopyAll,
  MdLocationPin,
  MdOutlineWorkOutline,
  MdReport,
  MdVerified,
  MdWorkOutline
} from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import IconButton from '../../ui-component/buttons/IconButton'
import ConfirmationModal from '../../ui-component/modal/ConfirmationModal'
import { ArrowTooltip } from '../../ui-component/tooltip/Tooltip'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import ViewAssignmentModal from '../job-listing/ViewAssignmentModal'
import RippleLoader from '../loader/RippleLoader'

const SingleApplicantJobCard = ({ singleJobData, fetchAllJobs }) => {
  const {
    company_logo,
    create_date,
    description,
    applicant_tasks: assignment,
    id,
    job_id,
    job_name,
    job_type,
    key_skills,
    organization,
    pay_range,
    weekly_hours,
    work_hours,
    years_of_experience,
    country,
    verified
  } = singleJobData
  const navigate = useNavigate()
  const [showAssignment, setShowAssignment] = useState(false)
  const [showWithdrawlModal, setShowWithdrawlModal] = useState(false)
  const [isReadMore, setIsReadmore] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const { githubUser } = useSelector((state) => state.auth)

  const handleJobWithdraw = async () => {
    console.log({
      job_id,
      github_id: githubUser?.username
    })
    try {
      await axiosInstance.delete(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/withdrawapplication`,
        {
          data: {
            job_id,
            github_id: githubUser?.username
          }
        }
      )

      showToast('success', 'Application Withdrawn Successfully')
      fetchAllJobs()
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleJobverificationStatus = async (text) => {
    try {
      await axiosInstance.put(
        `https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/updatejobverified`,
        {
          status: text,
          id
        }
      )
      showToast('success', `Application Status ${text}`)

      fetchAllJobs()
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div className="rounded-2xl flex flex-col text-sm p-4 gap-1 shadow-outer bg-white  relative">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 backdrop-filter backdrop-blur-sm bg-opacity-20 grid place-items-center ">
          <RippleLoader />
        </div>
      )}

      <div className="flex flex-col gap-6 relative">
        <div className="flex justify-between gap-6 items-start border-b-2 border-gray-300 border-opacity-40 pt-2 pb-6 ">
          <div className="flex flex-col gap-2">
            <div className="flex gap-3 items-center">
              {company_logo && (
                <img
                  className="w-16 h-16 object-contain "
                  src={company_logo}
                  alt={''}
                />
              )}
              <p className="text-sm font-semibold ">{organization}</p>
            </div>
            <p className="text-blue-500 flex gap-2 items-center text-sm">
              {' '}
              <AiOutlineAntDesign />
              <span>{job_name}</span>
            </p>
            <div className="flex gap-2 text-xs items-center text-blue-500">
              <MdOutlineWorkOutline />
              <p>
                <span className="font-semibold">Job ID : </span>
                {job_id}
              </p>
              <CopyToClipboard
                text={job_id}
                onCopy={() =>
                  showToast('success', 'Copied Job ID to Clipboard')
                }
              >
                <ArrowTooltip title="Copy Job-Id">
                  <button className="text-lg">
                    <MdCopyAll />
                  </button>
                </ArrowTooltip>
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            {assignment && assignment.length > 0 && (
              <IconButton
                toolTipTitle={'View Assignment'}
                text={'View Assignment'}
                icon={<IoPeopleOutline />}
                onClick={() => setShowAssignment(true)}
                type="primary"
                outline
              />
            )}
            <IconButton
              toolTipTitle={'Withdraw Application'}
              text={'Withdraw '}
              icon={<IoPeopleOutline />}
              onClick={() => setShowWithdrawlModal(true)}
              type="error"
              outline
            />
            {verified === 'PENDING' && (
              <>
                <IconButton
                  toolTipTitle={'Verify Application'}
                  text={'Verify '}
                  icon={<MdVerified />}
                  onClick={() => handleJobverificationStatus('VERIFIED')}
                  type="success"
                  outline
                />
                <IconButton
                  toolTipTitle={'Reject Application'}
                  text={'Reject '}
                  icon={<MdReport />}
                  onClick={() => handleJobverificationStatus('REJECTED')}
                  type="warning"
                  outline
                />
              </>
            )}
          </div>
        </div>
        <div className="flex ">
          {key_skills && key_skills.length > 0 && (
            <div className="flex-1">
              <h2 className="font-semibold mb-2"> Skills required : </h2>

              <div>
                {key_skills && (
                  <div className="flex gap-2 items-center flex-wrap">
                    {key_skills.split(',').map((skills) => (
                      <p
                        key={uuid()}
                        className="bg-gray-100 p-2 px-3 text-gray-700 rounded-full shadow text-xs "
                      >
                        {' '}
                        {skills}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className=" flex flex-col gap-2">
            <p className="text-sm flex  gap-2 items-center ">
              <IoMedalOutline className="text-xl" />
              {years_of_experience} {years_of_experience > 1 ? 'years' : 'year'}{' '}
              of experience{' '}
            </p>

            {pay_range && (
              <p className="text-sm flex  gap-2 items-center ">
                <GiMoneyStack className="text-xl" />
                {pay_range}
              </p>
            )}
            {job_type && (
              <p className="text-sm flex  gap-1 items-center ">
                <MdWorkOutline className="text-xl" />
                {job_type}
              </p>
            )}
            {country && (
              <p className="text-sm flex  gap-1 capitalize items-center ">
                <MdLocationPin className="text-xl" />
                {country}
              </p>
            )}
            {work_hours && (
              <p className="text-sm flex  gap-1 items-center ">
                <GiDesk className="text-xl" />
                {work_hours}
              </p>
            )}
            {weekly_hours && (
              <p className="text-sm flex  gap-1 items-center ">
                <AiOutlineClockCircle className="text-xl" />
                {weekly_hours} hours
              </p>
            )}
          </div>
        </div>
      </div>

      <div className="my-3 ">
        {description && (
          <>
            <p>
              <span className="font-semibold mb-1">Description</span>
            </p>
            <p className={`${isReadMore ? 'truncate' : ''} `}>
              {' '}
              {ReactHtmlParser(
                !isReadMore ? description : description.slice(0, 200)
              )}
            </p>
            {description.toString().split(' ').length > 15 && (
              <button
                onClick={() => setIsReadmore(!isReadMore)}
                className="text-blue-400 text-sm font-semibold"
              >
                {!isReadMore ? 'Read less' : 'Read More'}
              </button>
            )}
          </>
        )}
      </div>

      <div className="flex justify-between items-end gap-4 w-full ">
        <p className="text-xs w-full text-right ">
          <span className="font-semibold">Posted on </span>
          {create_date
            ? create_date.split(' ')[0].split('-').reverse().join('/')
            : ''}
        </p>
      </div>
      {showAssignment && (
        <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-3xl p-4  m-auto z-50">
            <ClickWrapper func={() => setShowAssignment(false)}>
              <ViewAssignmentModal
                close={() => setShowAssignment(false)}
                applicantId={id}
                jobid={job_id}
                applicantGithub={githubUser.username}
                assignment={assignment}
                recruiter={false}
                refetchData={fetchAllJobs}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
      {showWithdrawlModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-md p-6 m-auto z-50">
            <ClickWrapper func={() => setShowWithdrawlModal(false)}>
              <ConfirmationModal
                heading={'Withdraw Application'}
                title={
                  'Are you sure you want to withdraw application from this Job ?'
                }
                positiveText={'Withdraw'}
                negativeText={'Cancel'}
                handlePositive={handleJobWithdraw}
                handleNegative={() => setShowWithdrawlModal(false)}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default SingleApplicantJobCard
