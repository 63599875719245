import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import axiosInstance from '../../../services/axios/axios'
import RippleLoader from '../loader/RippleLoader'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../error/ErrorComponent'
import { useDispatch, useSelector } from 'react-redux'
import creditAction from '../../../redux/actions/creditAction'
import AgencyApplicantsTable from './AgencyApplicantsTable'
import EndPoints from '../../../services/api/api'
import { agencyStatus } from '../single-job-listing/data'

const AgencyApplicants = () => {
  const [loading, setLoading] = useState(true)
  const [singleJobData, setSingleJobData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [status, setStatus] = useState(agencyStatus[0])
  const [search, setSearch] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [activePartner, setActivePartner] = useState([])
  const [company, setCompany] = useState('')
  const { shortlistEndpoint } = EndPoints.dashboard
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const fetchSingleJobApplicants = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Dev/agencyAddedUsers/get?agency_id=${user.user_id}&page=${page}&limit=${limit}&status=${status.value}&company=${company}&search=${search}`
      )

      setSingleJobData(data.message)
      setTotalPage(data.total_pages)

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }

  const fetchAllPartner = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Stage/partnerAgency/agency/get?agency_id=${user.user_id}`
      )
      if (data.message) {
        const partners = data.message

        setActivePartner(
          partners.filter((partner) => partner.status === 'APPROVED')
        )
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const applySearchFilter = async () => {
    setLoadingSearch(true)
    setPage(1)
    fetchSingleJobApplicants()
  }

  useEffect(() => {
    fetchAllPartner()
  }, [])

  useEffect(() => {
    fetchSingleJobApplicants()
  }, [page, limit, status, company])

  useEffect(() => {
    if (search.length > 0) {
      setLoadingSearch(true)
    } else setLoadingSearch(false)

    const jobSearchTimeout = setTimeout(() => {
      applySearchFilter()
    }, 2000)
    return () => {
      clearTimeout(jobSearchTimeout)
    }
  }, [search])

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10">
      {loading ? (
        <RippleLoader />
      ) : (
        <div>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <AgencyApplicantsTable
              data={singleJobData}
              fetchAllApplicants={fetchSingleJobApplicants}
              fetchCreditData={() =>
                dispatch(creditAction.fetchUserCredits(user.user_id))
              }
              page={page}
              limit={limit}
              totalPage={totalPage}
              status={status}
              search={search}
              company={company}
              setCompany={setCompany}
              setStatus={setStatus}
              setSearch={setSearch}
              setPage={setPage}
              applicantStatus={agencyStatus}
              activePartner={activePartner}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default AgencyApplicants
