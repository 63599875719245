import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { BiMailSend, BiTime } from 'react-icons/bi'
import { GiMoneyStack } from 'react-icons/gi'
import { IoMedalOutline } from 'react-icons/io5'
import { MdInfo, MdLocationPin } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import Button from '../../ui-component/buttons/Button'
import IconButton from '../../ui-component/buttons/IconButton'

export const parseDuration = (duration) => {
  switch (duration) {
    case '1':
      return '1 Month or Less'
    case '3':
      return '3 Months'
    case '6':
      return '6 Months'
    default:
      return 'Open Discussion'
  }
}

const SingleProjectCard = ({ project }) => {
  const {
    agency,
    company_logo,
    country,
    create_date,
    description,
    email,
    guidance,
    duration,
    id,
    key_skills,
    organization,
    payment,
    project_category,
    project_id,
    project_title,
    status,
    updated_at,
    user_id,
    years_of_experience
  } = project
  const [isReadMore, setIsReadmore] = useState(true)
  const navigate = useNavigate()

  const projectDuration = parseDuration(duration)
  return (
    <div className="p-4 rounded-xl flex flex-col gap-3 shadow-outer">
      <div className="flex gap-2 items-start">
        <img
          className="w-16 h-16 rounded-lg object-cover"
          src={
            company_logo
              ? company_logo
              : 'https://p.kindpng.com/picc/s/24-248253_user-profile-default-image-png-clipart-png-download.png'
          }
          alt=""
        />

        <div className="flex flex-col">
          <h1 className="text-2xl font-semibold">{project_title}</h1>
          <p className="text-blue-500 text-sm font-semibold">{organization} </p>
          <p className="text-blue-500 text-xs flex items-center gap-1">
            {' '}
            <BiMailSend /> {email}{' '}
          </p>
        </div>
      </div>
      <div className="flex flex-wrap gap-4 items-center">
        {duration && (
          <div className="flex gap-1 items-center">
            <BiTime />
            <p className="text-sm">{projectDuration}</p>
          </div>
        )}

        {years_of_experience && (
          <div className="flex gap-1 items-center">
            <IoMedalOutline />
            <p className="text-sm">
              {years_of_experience} {years_of_experience > 1 ? 'years' : 'year'}{' '}
              of experience
            </p>
          </div>
        )}
        {country && (
          <div className="flex gap-1 items-center">
            <MdLocationPin />
            <p className="text-sm">{country}</p>
          </div>
        )}
        {payment && (
          <div className="flex gap-1 items-center">
            <GiMoneyStack />
            <p className="text-sm capitalize ">{payment.toLowerCase()}</p>
          </div>
        )}
      </div>
      <p className="text-sm">
        <span className="font-semibold">Category :</span> {project_category}
      </p>
      <p className="text-sm">
        {description && (
          <>
            <p>
              <span className="font-semibold mb-1">Description</span>
            </p>
            <p className={`${isReadMore ? 'truncate' : ''} `}>
              {' '}
              {ReactHtmlParser(
                !isReadMore ? description : description.slice(0, 200)
              )}
            </p>
            {description.toString().split(' ').length > 15 && (
              <button
                onClick={() => setIsReadmore(!isReadMore)}
                className="text-blue-400 text-sm font-semibold"
              >
                {!isReadMore ? 'Read less' : 'Read More'}
              </button>
            )}
          </>
        )}
      </p>

      <div className="flex gap-2 items-center flex-wrap">
        {key_skills && key_skills.length > 0 && (
          <div className="flex-1">
            <h2 className="font-semibold text-sm mb-2"> Skills required : </h2>

            <div>
              {key_skills && (
                <div className="flex gap-2 items-center flex-wrap">
                  {key_skills.split('|').map((skills) => (
                    <p
                      key={uuid()}
                      className="bg-gray-100 p-2 px-3 text-gray-700 rounded-full shadow text-xs "
                    >
                      {' '}
                      {skills}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <div className="flex justify-end gap-3 py-2 mt-auto">
        <IconButton
          toolTipTitle={`More Info`}
          text={'More info'}
          onClick={() => {
            navigate(
              `/${organization
                .split(' ')
                .join('-')}/projects/${project_id}/apply`
            )
          }}
          type="primary"
          outline
          icon={<MdInfo />}
        />
        <Button
          toolTipTitle={`Apply`}
          text={'Apply'}
          onClick={() => {
            navigate(
              `/${organization
                .split(' ')
                .join('-')}/projects/${project_id}/apply`
            )
          }}
          type="primary"
        />
      </div>
      <div className="flex justify-between items-end gap-4 w-full mt-auto ">
        <p className="text-xs w-full text-right ">
          <span className="font-semibold">Posted on </span>
          {create_date
            ? create_date.split(' ')[0].split('-').reverse().join('/')
            : ''}
        </p>
      </div>
    </div>
  )
}

export default SingleProjectCard
