import React, { Fragment } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { includeComma } from '../../../../services/global/globalFunction'
import LanguageLogo from '../../language-logo/LanguageLogo'

const SingleContributorTable = ({ data, index }) => {
  const {
    additions,
    branch_name,
    changedFiles,
    commit_date,
    commit_id,
    commit_url,
    deletions,
    files_changed,
    github_id,
    id,
    language_name,
    languages,
    last_commit,
    message,
    profile_pic,
    repo_name,
    repo_owner,
    total_commits,
    user_id,
    user_name
  } = data
  const navigate = useNavigate()

  return (
    <tr className="text-xs xl:text-sm font-semibold font-600 text-gray-600   ">
      <td className="text-center">{index + 1} .</td>
      <td className="">
        <div className="flex gap-1 sm:gap-2 items-center w-full">
          <img
            className="w-12 h-12 object-contain rounded-md"
            src={profile_pic}
            alt={github_id}
          />
          <Link
            to={`/dashboard/${github_id}`}
            className="px-4 flex flex-col gap-1 cursor-pointer"
          >
            <p className=" lg:text-sm font-semibold">{github_id}</p>
            <p className="  text-xs font-normal">{user_name}</p>
          </Link>
        </div>
      </td>

      <td className="text-center">
        <div className=" flex gap-3 flex-wrap p-2 px-4 ">
          {languages.map((language) => (
            <LanguageLogo
              key={uuid()}
              language={language.name}
              logo={language.language_logo}
            />
          ))}
        </div>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{total_commits}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{includeComma(changedFiles)} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 flex gap-3 items-center justify-center ">
          <span className="text-green-600"> + {includeComma(additions)}</span>
          <span className="text-red-600">- {includeComma(deletions)}</span>
        </p>
      </td>
      <td className="text-center">
        {last_commit.split(' ')[0].split('-').reverse().join('/')}
      </td>
    </tr>
  )
}

export default SingleContributorTable
