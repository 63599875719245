import React from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import TechProfileLogo from '../navbar/TechProfileLogo'

const WelcomeModal = ({ close }) => {
  return (
    <div className=" flex flex-col p-6 rounded-3xl shadow-outer gap-6 bg-gradient-to-br from-sky-900 to-sky-700 text-sky-100">
      <div className="flex justify-between  items-start ">
        <div className="flex gap-2 items-center ">
          <TechProfileLogo />
          <h1 className=" text-2xl font-bold  tracking-wide ">
            {' '}
            <span className="text-blue-500">T</span>ech
            <span className="text-blue-500">P</span>
            rofile
          </h1>
        </div>
        <button onClick={close} className=" text-3xl font-bold text-white">
          <IoMdCloseCircleOutline />
        </button>
      </div>

      <div className=" flex flex-col justify-center items-center gap-8 p-10 border-2 border-opacity-10 border-sky-200 border-dashed rounded-xl  ">
        <p className=" text-base sm:text-xl">
          First Resume was written in 1482 by Leonardo DaVinci and not much has
          changed since 1482. TechProfile want to change and build a developer
          resume based on his code and skills.
        </p>
        <h1 className="text-3xl md:text-4xl flex flex-wrap gap-2 font-bold items-center justify-center  text-center md:text-left w-full  sm:leading-relaxed ">
          <span className="text-blue-500">We</span>
          <span className="text-orange-500">Believe</span>
        </h1>
        <p className="text-base sm:text-xl">
          Software engineers and developers should be evaluated based on their
          actual work, contribution, and impact, instead of CVs, online
          assessments, and creative writing.
        </p>
      </div>
    </div>
  )
}

export default WelcomeModal
