import dayjs from 'dayjs'
import axiosInstance from '../axios/axios'

export const includeComma = (number) => {
  if (number === null) {
    return
  }
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const getIpDetails = async () => {
  try {
    const { data } = await axiosInstance.get(
      `https://api.ipgeolocation.io/ipgeo?apiKey=d582e444f8e44b3699019941488d0ae4`
    )
    if (data) {
      const { ip, country_name, city } = data
      return { ip, ipcountry: country_name, ipcity: city }
    }
  } catch (err) {
    return { ip: '', ipcountry: '', ipcity: '' }
  }
}

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export const parseCreditHistory = (arr) => {
  let obj = {}
  arr.reduce((_obj, item) => {
    const month = `${months[dayjs(item.activity_date).month()]} , ${dayjs(
      item.activity_date
    ).year()}`
    if (month in obj) {
      obj[month].push(item)
    } else Object.assign(obj, { [month]: [{ ...item }] })
  }, {})

  return obj
}
