//Dev api endpoints

//dashboard api

const DEV_DASHBOARD_API =
  'https://zqyi4123a5.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_GET_USER_LANGUAGE =
  'https://xjv88n4z2i.execute-api.ap-south-1.amazonaws.com/dev/languageusedbyuser'

//public dashboard endpoints

const DEV_PUBLIC_DASHBOARD_API =
  'https://o1z5c17hh4.execute-api.ap-south-1.amazonaws.com/Dev/public'

const DEV_FETCH_PUBLIC_DASHBOARD_EXIST =
  'https://zqyi4123a5.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_SHORTLIST_ENDPOINT =
  'https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com'

// auth endpoints

const DEV_AUTH_SIGNUP =
  'https://rsl02vlt8g.execute-api.ap-south-1.amazonaws.com/dev/register'
const DEV_AUTH_SIGNIN =
  'https://rsl02vlt8g.execute-api.ap-south-1.amazonaws.com/dev/login'
const DEV_AUTH_CHECK_EMAIL_AVAILABILITY =
  'https://rsl02vlt8g.execute-api.ap-south-1.amazonaws.com/dev/checkemail'

const DEV_AUTH_SEND_OTP =
  'https://4agqpuk8q2.execute-api.ap-south-1.amazonaws.com/dev/sendotp'
const DEV_AUTH_VALIDATE_OTP =
  'https://4agqpuk8q2.execute-api.ap-south-1.amazonaws.com/dev/validateotp'
const DEV_AUTH_CHANGE_PASSWORD =
  'https://4agqpuk8q2.execute-api.ap-south-1.amazonaws.com/dev/changepassword'

//repository endpoints

const DEV_GET_COMMIT_DETAILS =
  'https://g6iqggjpq2.execute-api.ap-south-1.amazonaws.com/dev/getcommitdetails'
const DEV_GET_FILE_CHANGE_GRAPH =
  'https://g6iqggjpq2.execute-api.ap-south-1.amazonaws.com/dev'

//create profile endpoints

const DEV_GET_ALL_SKILLS =
  'https://xjv88n4z2i.execute-api.ap-south-1.amazonaws.com/dev/searchlanguage'
const DEV_CREATE_PROFILE =
  'https://zpdt3klwq4.execute-api.ap-south-1.amazonaws.com/dev/githubform'
const DEV_CHECK_JOBID =
  'https://7g6izqwdr4.execute-api.ap-south-1.amazonaws.com/dev/'
const DEV_VERIFY_GITHUB =
  'https://v2cslhzp4d.execute-api.ap-south-1.amazonaws.com/dev'

//create job api

const DEV_CREATE_JOB =
  'https://zpdt3klwq4.execute-api.ap-south-1.amazonaws.com/dev/postjobdetails'

const DEV_UPLOAD_LOGO =
  'https://u94r7oyttb.execute-api.ap-south-1.amazonaws.com/dev/presignedurl'

const DEV_EDIT_JOB =
  'https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev'

//job listing api

const DEV_JOB_LISTING =
  'https://7g6izqwdr4.execute-api.ap-south-1.amazonaws.com/dev'

//user search api

const DEV_SEARCH_USER =
  'https://hl4rofkxnc.execute-api.ap-south-1.amazonaws.com/dev'
const DEV_ADVANCE_SEARCH =
  'https://zqyi4123a5.execute-api.ap-south-1.amazonaws.com/dev/getsavedsearch'
const DEV_GET_RESULTS =
  'https://8t7ohxd0j2.execute-api.ap-south-1.amazonaws.com/dev'

//single job listing endpoints

const DEV_CREATE_ASSIGNMENT =
  'https://g0bq5p0h77.execute-api.ap-south-1.amazonaws.com/dev'

const DEV_RAISE_ISSUE =
  'https://scewbkt2n4.execute-api.ap-south-1.amazonaws.com/dev'

const DEV_SEND_MAIL =
  'https://iyj0lpwwd1.execute-api.ap-south-1.amazonaws.com/dev'

const DEV_SINGLE_JOB_OPERATIONS =
  'https://7g6izqwdr4.execute-api.ap-south-1.amazonaws.com/dev'

//sinngle applicant endpoints

const DEV_SINGLE_APPLICANT_STATUS_CHANGE =
  'https://7g6izqwdr4.execute-api.ap-south-1.amazonaws.com/dev'

//create user endpoints

const DEV_GET_CREATED_USER =
  'https://rsl02vlt8g.execute-api.ap-south-1.amazonaws.com/dev'

//leaderboard endpoints

const DEV_GET_LEADERBOARD =
  'https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev'

//college leaderboard page

const DEV_GET_COLLEGE_LEADERBOARD =
  'https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev'

//all jobs page endpoints

const DEV_GET_ALL_JOB_LIST =
  'https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getalljobs'

//Prod api endpoints

//dashboard api

const PROD_DASHBOARD_API = ''
const PROD_GET_USER_LANGUAGE = ''

//public dashboard API

const PROD_PUBLIC_DASHBOARD_API = ''
const PROD_FETCH_PUBLIC_DASHBOARD_EXIST = ''
const PROD_SHORTLIST_ENDPOINT = ''

// auth endpoints

const PROD_AUTH_SIGNUP = ''
const PROD_AUTH_SIGNIN = ''
const PROD_AUTH_CHECK_EMAIL_AVAILABILITY = ''
const PROD_AUTH_SEND_OTP = ''
const PROD_AUTH_VALIDATE_OTP = ''
const PROD_AUTH_CHANGE_PASSWORD = ''

//repository endpoints

const PROD_GET_COMMIT_DETAILS = ''
const PROD_GET_FILE_CHANGE_GRAPH = ''

//create-profile endpoint

const PROD_GET_ALL_SKILLS = ''
const PROD_CREATE_PROFILE = ''
const PROD_CHECK_JOBID = ''
const PROD_VERIFY_GITHUB = ''

//create job api

const PROD_CREATE_JOB = ''
const PROD_UPLOAD_LOGO = ''
const PROD_EDIT_JOB = ''

//job listing api

const PROD_JOB_LISTING = ''

//search user

const PROD_SEARCH_USER = ''
const PROD_ADVANCE_SEARCH = ''
const PROD_GET_RESULTS = ''

//single job listing endpoints

const PROD_CREATE_ASSIGNMENT = ''
const PROD_RAISE_ISSUE = ''
const PROD_SEND_MAIL = ''
const PROD_SINGLE_JOB_OPERATIONS = ''

//create user endpoints

const PROD_GET_CREATED_USER = ''

//leaderboard endpoint

const PROD_GET_LEADERBOARD = ''

//college leaderbpard page

const PROD_GET_COLLEGE_LEADERBOARD = ''

//all job listing page
const PROD_GET_ALL_JOB_LIST = ''

///single applicant endpoints

const PROD_SINGLE_APPLICANT_STATUS_CHANGE = ''

let UAT = true

const EndPoints = {
  auth: {
    authSignUp: UAT ? DEV_AUTH_SIGNUP : PROD_AUTH_SIGNUP,
    authSignIn: UAT ? DEV_AUTH_SIGNIN : PROD_AUTH_SIGNIN,
    authCheckEmailAvailability: UAT
      ? DEV_AUTH_CHECK_EMAIL_AVAILABILITY
      : PROD_AUTH_CHECK_EMAIL_AVAILABILITY,
    authSendOtp: UAT ? DEV_AUTH_SEND_OTP : PROD_AUTH_SEND_OTP,
    authValidateOtp: UAT ? DEV_AUTH_VALIDATE_OTP : PROD_AUTH_VALIDATE_OTP,
    authChangePassword: UAT
      ? DEV_AUTH_CHANGE_PASSWORD
      : PROD_AUTH_CHANGE_PASSWORD
  },
  dashboard: {
    dashboardEndpoint: UAT ? DEV_DASHBOARD_API : PROD_DASHBOARD_API,
    getUserLanguages: UAT ? DEV_GET_USER_LANGUAGE : PROD_GET_USER_LANGUAGE,
    shortlistEndpoint: UAT ? DEV_SHORTLIST_ENDPOINT : PROD_SHORTLIST_ENDPOINT
  },
  publicDashboard: {
    publicDashboardEndpoint: UAT
      ? DEV_PUBLIC_DASHBOARD_API
      : PROD_PUBLIC_DASHBOARD_API,
    checkUserExist: UAT
      ? DEV_FETCH_PUBLIC_DASHBOARD_EXIST
      : PROD_FETCH_PUBLIC_DASHBOARD_EXIST
  },
  repository: {
    getRepoCommits: UAT ? DEV_GET_COMMIT_DETAILS : PROD_GET_COMMIT_DETAILS,
    getFilechangeGraph: UAT
      ? DEV_GET_FILE_CHANGE_GRAPH
      : PROD_GET_FILE_CHANGE_GRAPH
  },
  createProfile: {
    getAllSkills: UAT ? DEV_GET_ALL_SKILLS : PROD_GET_ALL_SKILLS,
    createProfile: UAT ? DEV_CREATE_PROFILE : PROD_CREATE_PROFILE,
    checkJobExist: UAT ? DEV_CHECK_JOBID : PROD_CHECK_JOBID,
    verifyGithubId: UAT ? DEV_VERIFY_GITHUB : PROD_VERIFY_GITHUB
  },
  createJob: {
    postJob: UAT ? DEV_CREATE_JOB : PROD_CREATE_JOB,
    uploadJobLogo: UAT ? DEV_UPLOAD_LOGO : PROD_UPLOAD_LOGO,
    editJob: UAT ? DEV_EDIT_JOB : PROD_EDIT_JOB
  },
  jobListing: {
    getJobListing: UAT ? DEV_JOB_LISTING : PROD_JOB_LISTING
  },
  homepage: {},
  searchPage: {
    searchUserEndpoint: UAT ? DEV_SEARCH_USER : PROD_SEARCH_USER,
    searchAdvancedUser: UAT ? DEV_ADVANCE_SEARCH : PROD_ADVANCE_SEARCH,
    getSearchResult: UAT ? DEV_GET_RESULTS : PROD_GET_RESULTS
  },
  singleJobListing: {
    createAssignment: UAT ? DEV_CREATE_ASSIGNMENT : PROD_CREATE_ASSIGNMENT,
    raiseIssue: UAT ? DEV_RAISE_ISSUE : PROD_RAISE_ISSUE,
    sendMail: UAT ? DEV_SEND_MAIL : PROD_SEND_MAIL,
    singleJobOperation: UAT
      ? DEV_SINGLE_JOB_OPERATIONS
      : PROD_SINGLE_JOB_OPERATIONS
  },

  singleApplicant: {
    jobStatusChange: UAT
      ? DEV_SINGLE_APPLICANT_STATUS_CHANGE
      : PROD_SINGLE_APPLICANT_STATUS_CHANGE
  },
  createUser: {
    showCreatedUser: UAT ? DEV_GET_CREATED_USER : PROD_GET_CREATED_USER
  },
  leaderboard: {
    getLeaderBoard: UAT ? DEV_GET_LEADERBOARD : PROD_GET_LEADERBOARD
  },
  collegeLeaderboard: {
    getCollegeLeaderboard: UAT
      ? DEV_GET_COLLEGE_LEADERBOARD
      : PROD_GET_COLLEGE_LEADERBOARD
  },
  allJobsPage: {
    getAllJobs: UAT ? DEV_GET_ALL_JOB_LIST : PROD_GET_ALL_JOB_LIST
  }
}

export default EndPoints
