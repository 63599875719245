import React from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import { useState } from 'react'
import { RiImageEditLine } from 'react-icons/ri'
import { useEffect } from 'react'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import TextInput from '../../create-profile/text-input/TextInput'

const ProfileEditComponent = () => {
  const { user } = useSelector((state) => state.auth)
  const [name, setName] = useState(user.name ?? '')
  const [fileName, setFileName] = useState(
    user.profile_image
      ? user.profile_image?.split('/')[
          user.profile_image?.split('/').length - 1
        ]
      : ''
  )
  const [imageFile, setImageFile] = useState()
  const [previewImage, setPreviewImage] = useState(
    user.profile_image
      ? user.profile_image
      : 'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png'
  )
  const [isImageChanged, setIsImageChanged] = useState(false)
  const { uploadJobLogo } = EndPoints.createJob

  const fileChange = (e) => {
    if (!e.target.files[0]) {
      alert('please Select a valid file')
      return
    }
    const newFile = e.target.files[0]
    setImageFile(newFile)
    if (fileName === '') {
      setFileName(`${user.name ?? ''}-${uuid()}`)
    }
    setIsImageChanged(true)
  }

  const uploadImage = async () => {
    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': imageFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'githubcompanieslogo'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      const res = await axiosInstance.put(uploadUrl, imageFile, options)
      console.log(res)
      setImageFile(undefined)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleUserDetailsChange = async () => {
    try {
      if (isImageChanged) {
        await uploadImage()
      }
      const putData = !isImageChanged
        ? { name, user_id: user.user_id }
        : { name, user_id: user.user_id, profile_image: fileName }
      await axiosInstance.put(
        'https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/updateusersadmin',
        putData
      )
      const registeredUser = localStorage.getItem('TECH_RESUME_USER')
      localStorage.setItem(
        'TECH_RESUME_USER',
        JSON.stringify({
          ...JSON.parse(registeredUser),
          name,
          profile_image: `https://githubcompanieslogo.s3.ap-south-1.amazonaws.com/${fileName}`
        })
      )
      window.location.reload()
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (!imageFile) {
      return
    }
    const objectUrl = URL.createObjectURL(imageFile)
    setPreviewImage(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imageFile])

  return (
    <div className="flex flex-col-reverse items-center md:flex-row md:items-start gap-10 justify-between">
      <div className="flex flex-col gap-6 px-2 lg:px-6 w-full md:w-auto md:flex-1 max-w-md ">
        <TextInput
          value={name}
          setValue={(val) => setName(val)}
          label={'Organization'}
          placeholder={'Enter Organization name'}
          required
        />

        <div className="flex flex-col w-full gap-2 text-xs md:text-sm opacity-60 ">
          <p className={`font-semibold  `}>Email</p>
          <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 disabled:text-gray-300 disabled:cursor-default">
            {user?.email}
          </div>
        </div>

        <button
          className=" text-sm  bg-blue-500 w-40 rounded-lg self-end p-4 text-white hover:bg-blue-400 py-2 shadow "
          onClick={handleUserDetailsChange}
        >
          Save
        </button>
      </div>
      <div>
        <div className="relative">
          <img
            src={`${previewImage}?rand=${new Date()}`}
            alt=""
            className="w-40 h-40 rounded-full border object-cover"
          />
          <label className="absolute bottom-1 right-4 cursor-pointer">
            <RiImageEditLine className=" text-3xl p-1 bg-white shadow rounded-full border " />
            <input
              className="hidden"
              type="file"
              onChange={fileChange}
              accept="image/*"
            />
          </label>
        </div>
      </div>
    </div>
  )
}

export default ProfileEditComponent
