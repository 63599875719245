import React from 'react'
import uuid from 'react-uuid'
import LanguageLogo from '../language-logo/LanguageLogo'
import LanguageBar from '../radial-bar/LanguageBar'

const CollegeLanguage = ({ language }) => {
  return (
    <div className="shadow-custom w-full flex flex-col gap-6 rounded-md bg-white p-3 sm:p-6 ">
      <div className="flex justify-between items-center">
        <h3 className="font-semibold truncate mr-2 ">
          Languages used by students
        </h3>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
        {language.map((language) => (
          <div key={uuid()} className="flex gap-4 items-center">
            <LanguageLogo
              language={language.language}
              logo={language.language_logo || null}
            />
            <div className="flex-1">
              <LanguageBar
                value={language.percentage}
                language={language.language}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CollegeLanguage
