import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import CollegeLeaderboardTable from '../../components/college-leaderboard/CollegeLeaderboardTable'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'

const getLeaderboardData = () => {
  const data = sessionStorage.getItem('COLLEGE_LEADERBOARD_DATA')
  if (data === null) {
    return null
  }
  return JSON.parse(data)
}

const CollegeLeaderboard = () => {
  const [loading, setLoading] = useState(true)
  const [collegeLeaderboard, setCollegeLeaderboard] = useState([])
  const [page, setPage] = useState(
    getLeaderboardData()?.page ? getLeaderboardData()?.page : 1
  )
  const [limit, setLimit] = useState(
    getLeaderboardData()?.limit ? getLeaderboardData()?.limit : 10
  )
  const [totalPage, setTotalPage] = useState(1)
  const { getCollegeLeaderboard } = EndPoints.collegeLeaderboard

  const fetchLeaderboard = async () => {
    //setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${getCollegeLeaderboard}/getallcolleges?page=${page}&limit=${limit}`
      )
      console.log(data)
      setCollegeLeaderboard(data.message)
      setTotalPage(data.total_pages)
      const storedData = {
        page,
        limit
      }
      sessionStorage.setItem(
        'COLLEGE_LEADERBOARD_DATA',
        JSON.stringify(storedData)
      )
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchLeaderboard()
  }, [page, limit])
  return (
    <div className="w-full p-4 sm:p-6 md:p-10 min-h-screen ">
      {loading ? (
        <RippleLoader />
      ) : (
        <>
          {' '}
          <div className="flex flex-col md:px-4 md:flex-row justify-between gap-3 md:items-center font-semibold mb-4">
            <h1 className="text-3xl ">Leaderboard</h1>
          </div>
          <div className=" ">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <CollegeLeaderboardTable
                data={collegeLeaderboard}
                page={page}
                onPageChange={(page) => setPage(page)}
                count={totalPage}
                limit={limit}
              />
            </ErrorBoundary>
          </div>
          {/* <div className="md:hidden ">
						<ErrorBoundary FallbackComponent={ErrorComponent}>
							<ResponsiveCollegeLeaderboardTable
								data={collegeLeaderboard}
								page={page}
								onPageChange={(page) => setPage(page)}
								count={totalPage}
								limit={limit}
							/>
						</ErrorBoundary>
					</div> */}
        </>
      )}
    </div>
  )
}

export default CollegeLeaderboard
