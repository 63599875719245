import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, Link } from 'react-router-dom'
import { JOB_LISTING_PAGE, SIGNUP_PAGE } from '../../../navigation/routes'
import authAction from '../../../redux/actions/authAction'
import { getIpDetails } from '../../../services/global/globalFunction'
import Loader from '../loader/Loader'
import RippleLoader from '../loader/RippleLoader'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'

const Signin = ({ toggleTab, closeTab }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const alert = useAlert()
  const [signInDetails, setSignInDetails] = useState({
    email: '',
    password: ''
  })
  const [loginErrors, setLoginErrors] = useState({
    email: '',
    password: ''
  })

  const handleSignIn = async (e) => {
    e.preventDefault()
    console.log(signInDetails)

    if (
      signInDetails.email.length === 0 ||
      signInDetails.password.length === 0
    ) {
      if (signInDetails.email.length === 0) {
        setLoginErrors({
          ...loginErrors,
          email: 'Enter valid Email'
        })
      } else if (signInDetails.password.length === 0) {
        setLoginErrors({
          ...loginErrors,
          password: 'Enter valid password'
        })
      }
      return
    }
    const ipData = await getIpDetails()

    dispatch(
      authAction.login(signInDetails, setLoading, closeTab, notifyLogin, ipData)
    )
    //navigate(JOB_LISTING_PAGE);
  }

  const notifyLogin = (text) => {
    alert.show(text)
  }

  return (
    <div className="flex flex-col items-center w-full pb-3 relative scrollbar-hidden text-white overflow-y-scroll h-full ">
      {loading && (
        <div className="absolute grid place-items-center w-full h-full top-0 left-0 z-10 bg-white bg-opacity-20 backdrop-filter blur-sm">
          <RippleLoader />
        </div>
      )}

      <div className="flex flex-col w-full px-2">
        <h1 className="text-4xl md:text-5xl leading-snug font-bold ">
          <span className="text-blue-500">Login</span> to your{' '}
          <span className="text-orange-500">Account</span>{' '}
        </h1>

        <p className="text-sm font-500 text-gray-100 my-6">
          Don’t have an account? Create one
          <Link
            to={SIGNUP_PAGE}
            className="text-blue-200 text-xs p-2 px-4  ml-2 font-semibold border-2 border-blue-200 border-opacity-40 rounded-full hover:text-blue-700"
          >
            Sign Up
          </Link>
        </p>
      </div>

      <form
        onSubmit={handleSignIn}
        className="w-full flex flex-col  p-6 rounded-lg bg-white bg-opacity-10   "
      >
        <EmailField
          value={signInDetails.email}
          checkEmail={false}
          errorMessage={signInDetails.email.length === 0 && loginErrors.email}
          onChange={(e) =>
            setSignInDetails({
              ...signInDetails,
              email: e.target.value
            })
          }
        />

        <PasswordField
          labelText={'Password'}
          value={signInDetails.password}
          errorMessage={
            signInDetails.password.length === 0 && loginErrors.password
          }
          onChange={(e) =>
            setSignInDetails({
              ...signInDetails,
              password: e.target.value
            })
          }
        />

        <div className="flex justify-end  items-center mb-3">
          <p
            onClick={() => toggleTab(1)}
            className="text-blue-200  font-semibold hover:text-blue-100 text-sm cursor-pointer"
          >
            Forgot Password ?
          </p>
        </div>

        <SubmitButton text={'Sign in'} />
      </form>
    </div>
  )
}

export default Signin
