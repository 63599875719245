import React from 'react'
import ReactApexChart from 'react-apexcharts'

const CommitChart = ({ data: commitData, commitFetching }) => {
  const random_hex_color_code = (length) => {
    let arr = []
    for (var i = 0; i < length; i++) {
      arr.push(() => {
        var color = '#'
        for (var i = 0; i < 6; i++) {
          color += Math.floor(Math.random() * 10)
        }
        return color
      })
    }

    return arr
  }
  var optimalColumnWidthPercent =
    20 + 60 / (1 + 30 * Math.exp(-Object.values(commitData).length / 3))
  const series = [
    {
      name: 'Number of commits',
      data: commitData.map((commit) => commit.total_commits)
    }
  ]
  const options = {
    chart: {
      type: 'bar',
      foreColor: '#000000',
      toolbar: {
        show: false
      }
    },
    colors: random_hex_color_code(commitData.length),
    plotOptions: {
      bar: {
        columnWidth: optimalColumnWidthPercent,
        borderRadius: 10,
        horizontal: false,
        dataLabels: {
          position: 'top'
        }
      }
    },
    grid: {
      show: true
    },
    dataLabels: {
      enabled: true,

      offsetX: -20,
      style: {
        fontSize: '12px'
      }
    },
    tooltip: {
      theme: 'dark'
    },

    xaxis: {
      categories: commitData.map((commit) => commit.github_id),
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true
      }
    }
  }
  return (
    <div className=" bg-white shadow-custom p-4 md:p-8 rounded-lg">
      <div className="flex justify-between items-center">
        <h3 className="font-semibold">Commits from Contributors </h3>
        {commitFetching && <p>Fetching commit data ...</p>}
      </div>
      <div id="chart">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={
            commitData.map((commit) => commit.github_id).length <= 5 ? 250 : 380
          }
        />
      </div>
    </div>
  )
}

export default CommitChart
