import React, { useState } from 'react'
import { GiSandsOfTime } from 'react-icons/gi'
import { GrAchievement } from 'react-icons/gr'
import { SiWeightsandbiases } from 'react-icons/si'
import EmailInput from '../../components/create-profile/email-input/EmailInput'
import TextInput from '../../components/create-profile/text-input/TextInput'
import FaqAccordion from '../../components/homepage/FaqAccordion'

const certificateCardData = [
  {
    subHeading: 'Enhanced Recruiting Skills',
    text: 'The certification program provides participants with the knowledge and skills needed to recruit technical professionals more effectively. Participants learn best practices for sourcing, screening, and assessing candidates, enabling them to identify the best candidates for each role. This enhances the overall efficiency of the recruitment process, resulting in better hires and reduced turnover.',
    icon: <GrAchievement className="text-5xl text-sky-900" />
  },

  {
    subHeading: 'Increased Professional Credibility',
    text: 'Achieving a certification in technical recruitment demonstrates to potential clients and employers that you have the knowledge and expertise required to recruit top technical talent. It shows that you are committed to your profession and are continuously developing your skills to provide better services.',
    icon: <SiWeightsandbiases className="text-5xl text-white" />
  },
  {
    subHeading: 'Career Advancement Opportunities',
    text: 'Being a Certified Technical Recruitment Consultant opens up more career advancement opportunities for individuals. They can apply for senior-level recruitment roles, manage recruitment teams or even start their recruitment agency. This certification shows that you are a competent and skilled recruiter and can be an asset to any organization.',
    icon: <GiSandsOfTime className="text-5xl text-sky-900" />
  }
]

const courseArr = [
  {
    question: '1. Introduction to Technical Recruitment ',
    answer: `Introduction to the course and learning objectives
			The role of a technical recruitment consultant
			Overview of the technical recruitment process
			Understanding the different types of technical roles and the skills required`
  },
  {
    question: '2. Sourcing and Screening Candidates',
    answer: `Identifying potential candidates through various sourcing methods
			Screening and shortlisting candidates based on job requirements
			Conducting initial interviews and assessments
			Using technology tools to streamline the recruitment process`
  },
  {
    question: '3. Assessing and Selecting Candidates',
    answer: `Conducting technical assessments and evaluations
			Understanding different types of technical assessments (coding challenges, logic puzzles, etc.)
			Evaluating candidates based on technical and soft skills
			Making a successful job offer`
  },
  {
    question: '4. Legal and Ethical Considerations in Technical Recruitment',
    answer: `Understanding legal requirements for recruitment
			Avoiding discrimination in recruitment
			Maintaining candidate confidentiality
			Understanding GDPR and data protection regulations`
  },
  {
    question: '5. Trends and Best Practices in Technical Recruitment',
    answer: `Keeping up with the latest trends in technical recruitment
			Understanding the importance of employer branding
			Using social media and online platforms for recruitment
			Developing effective recruitment strategies`
  }
]

const SingleCard = ({ subHeading, text, idx, icon }) => {
  return (
    <div className="flex flex-col justify-end">
      <div
        className={`shadow-xl ${
          idx === 1
            ? 'bg-sky-600 text-white min-h-[350px] '
            : 'bg-white min-h-[300px] '
        }  border flex flex-col gap-5 p-4 sm:px-6 md:px-4 lg:px-6 py-10 rounded-2xl items-center text-center`}
      >
        {icon}
        <h2 className="font-semibold text-xl">{subHeading}</h2>

        <p>{text}</p>
      </div>
    </div>
  )
}

const CertificationPage = () => {
  const [profileValue, setProfileValue] = useState({
    email: '',
    name: ''
  })

  const handleCertificationRegister = async (e) => {
    e.preventDefault()
  }

  return (
    <div className="w-screen min-h-screen overflow-hidden relative">
      <div className="bg-sky-600 pb-20 w-[150vw] -mt-4 -mr-2 rotate-[8deg] ">
        <div className="bg-sky-700 pb-20 w-[150vw] -rotate-[3deg] ">
          <div className="flex -rotate-[5deg] -translate-x-4 justify-between items-center w-screen p-4 sm:p-10 md:px-20 min-h-[80vh] text-white bg-gradient-to-t from-sky-900 to-sky-800    ">
            <div className="p-4  justify-center ">
              <div className="flex flex-col  gap-4 md:gap-8 max-w-2xl">
                <h1 className="text-3xl md:text-5xl font-bold leading-10 ">
                  <span className="text-blue-500">Certified </span>Technical
                  <span className="text-orange-500"> Recruitment</span>{' '}
                  Consultant
                </h1>

                <p className="text-sm md:text-xl font-normal leading-relaxed text-gray-100 ">
                  This course is designed to provide participants with the
                  knowledge and skills required to become a successful technical
                  recruitment consultant.
                </p>
                <p className="text-sm md:text-xl font-normal leading-relaxed text-gray-100 ">
                  Participants will learn the fundamentals of technical
                  recruitment, including sourcing, screening, and assessing
                  candidates, as well as the latest trends and best practices in
                  the industry.
                </p>
                <p className="text-sm md:text-xl font-normal leading-relaxed text-gray-100 ">
                  The course will also cover the legal and ethical
                  considerations of technical recruitment.
                </p>
              </div>
            </div>
            <div className="hidden md:block">
              <lottie-player
                src="https://assets2.lottiefiles.com/packages/lf20_wfyrbf2p.json"
                background="transparent"
                speed="1"
                style={{ width: '75%', objectFit: 'contain' }}
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center gap-20 w-full px-4 sm:px-6 md:px-10 max-w-7xl m-auto relative py-10">
        <h1 className="text-3xl md:text-4xl font-bold text-center  leading-relaxed ">
          <span className="text-blue-500">Benefits</span> of
          <span className="text-orange-500"> Certification</span>
        </h1>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 pb-20">
          {certificateCardData.map((data, idx) => (
            <SingleCard key={idx} idx={idx} {...data} />
          ))}
        </div>
      </div>

      <div className="bg-sky-600 py-20 mt-40 w-[250vw] md:w-[200vw] -translate-x-1/4 rotate-[8deg] ">
        <div className="bg-sky-700 py-20 w-[250vw] md:w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg] -translate-x-[13%] md:translate-x-0  text-white w-screen flex bg-gradient-to-l from-sky-900 to-sky-800 flex-col gap-10 md:gap-20 p-6 md:p-10 py-20 lg:p-20 items-center">
            <div className="flex flex-col gap-12 w-full max-w-7xl mx-auto ">
              <h1 className="text-3xl md:text-5xl leading-snug text-center max-w-4xl mx-auto  font-bold  ">
                How
                <span className="text-blue-500"> Certified </span>Technical
                <span className="text-orange-500"> Recruitment</span> Consultant
                will help you
              </h1>
              <div className="flex flex-col gap-10 py-20  w-full max-w-4xl text-lg md:text-xl">
                <h1 className="text-3xl md:text-4xl  leading-snug font-bold  ">
                  <span className="text-orange-500">Master </span>
                  the Art of{' '}
                  <span className="text-blue-500">Tech Sourcing</span> with Our
                  Certified Technical Recruitment Consultant Program
                </h1>
                <p className=" font-medium leading-loose  text-base md:text-lg  ">
                  The Certified Technical Recruitment Consultant program is the
                  perfect way to learn the art of tech sourcing. This
                  certification program provides a comprehensive training to
                  help participants master the skills needed to become a
                  successful technical recruitment consultant. Participants will
                  learn the best practices and techniques for sourcing,
                  screening, and assessing candidates, which can help them
                  identify the best candidates for each role. With this
                  certification, participants can enhance their professional
                  credibility and increase their career advancement
                  opportunities.
                </p>
              </div>
              <div className="flex flex-col items-end justify-end gap-10  w-full  text-lg md:text-xl">
                <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>

                <div className=" py-20 flex flex-col gap-10 ">
                  <h1 className="text-3xl md:text-4xl leading-snug font-bold  max-w-4xl ">
                    <span className="text-orange-500">Develop </span>
                    Your
                    <span className="text-blue-500"> Technical</span> with Our
                    Sourcing Skills and Take Your Recruiting Career to the Next
                    Level!
                  </h1>
                  <p className=" font-medium leading-loose  text-base md:text-lg  max-w-4xl ">
                    Are you looking to take your recruiting career to the next
                    level? Our Certified Technical Recruitment Consultant
                    program can help you develop your technical sourcing skills.
                    This program provides a comprehensive training to help you
                    become an expert in tech sourcing. You'll learn the latest
                    best practices and techniques for sourcing, screening, and
                    assessing candidates, enabling you to identify the best
                    candidates for each role. With this certification, you can
                    demonstrate your expertise to potential clients and
                    employers, and increase your career advancement
                    opportunities.
                  </p>
                </div>

                <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>
              </div>
              <div className="flex flex-col gap-10 py-20  w-full max-w-4xl text-lg md:text-xl">
                <h1 className="text-3xl md:text-4xl leading-snug font-bold  ">
                  <span className="text-orange-500">Boost </span>
                  Your
                  <span className="text-blue-500"> Recruitment</span> Success
                  Rate with Advanced Technical Sourcing Techniques from Our
                  Certification Program
                </h1>
                <p className=" font-medium leading-loose  text-base md:text-lg  ">
                  Are you struggling to identify the best candidates for
                  technical roles? Our Certified Technical Recruitment
                  Consultant program can help. This program provides advanced
                  technical sourcing techniques that can help you boost your
                  recruitment success rate. You'll learn the latest best
                  practices and techniques for sourcing, screening, and
                  assessing candidates, enabling you to identify the best
                  candidates for each role. With this certification, you can
                  demonstrate your expertise to potential clients and employers,
                  and increase your career advancement opportunities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-20 max-w-7xl mx-auto py-20 px-4 sm:px-6 md:px-10">
        <h1 className="text-3xl md:text-4xl font-bold text-center  leading-relaxed ">
          <span className="text-blue-500">Course</span>{' '}
          <span className="text-orange-500">Content</span>
        </h1>

        <div className="flex flex-col gap-10  w-full max-w-4xl text-lg md:text-xl">
          <h1 className="text-3xl md:text-4xl leading-snug font-bold  ">
            <span className="text-orange-500">Learning </span>

            <span className="text-blue-500"> Objectives</span>
          </h1>
          <p className=" font-medium leading-relaxed text-base md:text-lg  ">
            Understand the role of a technical recruitment consultant Learn the
            process of sourcing, screening, and assessing candidates Understand
            the legal and ethical considerations of technical recruitment Learn
            the latest trends and best practices in technical recruitment
          </p>
        </div>

        <h1 className="text-3xl md:text-4xl font-bold text-center  leading-relaxed ">
          <span className="text-blue-500">Course</span>{' '}
          <span className="text-orange-500">Outline</span>
        </h1>
        <div>
          <FaqAccordion data={courseArr} />
        </div>
      </div>

      <div className="bg-sky-600 pt-20 mt-40 w-[200vw] -mb-2 -translate-x-1/4 rotate-[8deg] ">
        <div className="bg-sky-700 pt-20 w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg]  text-white w-screen flex bg-gradient-to-l from-sky-900 to-sky-800 flex-col gap-10 md:gap-20 p-4 md:p-10 py-20 lg:p-20 items-center">
            <div className="flex flex-col gap-20 w-full max-w-7xl mx-auto ">
              <div className="text-center  flex flex-col gap-6 ">
                <h1 className="text-4xl md:text-5xl leading-snug text-center max-w-4xl mx-auto  font-bold  ">
                  <span className="text-blue-500"> Register </span> for
                  <span className="text-orange-500"> Certification </span>
                </h1>

                <p>Our Team will reach out for the next batch.</p>
              </div>

              <form
                onSubmit={handleCertificationRegister}
                className="p-6 md:p-10 lg:p-16 rounded-3xl bg-white bg-opacity-20 backdrop-filter backdrop-blur-sm flex flex-col gap-6 md:gap-10 w-full max-w-xl mx-auto "
              >
                <TextInput
                  value={profileValue.name}
                  setValue={(val) =>
                    setProfileValue({ ...profileValue, name: val })
                  }
                  label={'Name'}
                  placeholder={'Enter your name'}
                  required
                />
                <EmailInput
                  value={profileValue.email}
                  setValue={(val) =>
                    setProfileValue({ ...profileValue, email: val })
                  }
                  label={'Email'}
                  placeholder={'Enter your Email'}
                  required
                />

                <button className="grid place-items-center bg-blue-500 text-white p-3 rounded-xl text-sm hover:bg-blue-600 text-center ">
                  Register
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CertificationPage
