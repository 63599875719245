import React, { useState } from 'react'
import { VscEye, VscEyeClosed } from 'react-icons/vsc'

const PasswordField = ({
  value,
  onChange,
  labelText,
  errorMessage,
  required = true
}) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <label className="flex flex-col text-sm w-full font-600 my-2 z-0">
      <p className="font-semibold tracking-wide ">{labelText}* </p>

      <div className="relative w-full">
        <input
          className="p-3 rounded-xl border-2 text-gray-700 w-full my-2 outline-none font-600 border-gray-400 border-opacity-50 placeholder-gray-400 "
          type={showPassword ? 'text' : 'password'}
          name="password"
          value={value}
          onChange={onChange}
          required={required}
          placeholder="Min. 8 character"
        />
        <div
          className="absolute right-4 top-1/2 transform -translate-y-1/2 text-lg font-600 cursor-pointer text-gray-500 hover:text-gray-800 "
          onClick={() => setShowPassword(!showPassword)}
        >
          {!showPassword ? <VscEye /> : <VscEyeClosed />}
        </div>
      </div>

      {errorMessage && (
        <p className="text-red-500 text-xs ml-4">{errorMessage}</p>
      )}
    </label>
  )
}

export default PasswordField
