import React, { useState } from 'react'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ConfirmationModal from '../../ui-component/modal/ConfirmationModal'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import EditJobModal from '../create-job/EditJobModal'
import EditProjectModal from '../project/EditProjectModal'

const SingleAdminJobTable = ({ data, idx, fetchAllJobs, limit, page }) => {
  const {
    id,
    agency,
    company_logo,
    country,
    create_date,
    job_type,
    key_skills,
    organization,
    pay_range,
    status,
    updated_at,
    type,
    user_id,
    description,
    weekly_hours,
    work_hours,
    work_type,
    years_of_experience
  } = data

  const [isLoading, setLoading] = useState(false)
  const { singleJobOperation } = EndPoints.singleJobListing
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showEditJobModal, setShowEditJobModal] = useState(false)
  const [showEditProjectModal, setShowEditProjectModal] = useState(false)
  const [showStatusConfirmationModal, setShowStatusConfirmationModal] =
    useState(false)

  const handleDeleteJob = async () => {
    const putData = {
      job_id: data.job_id,
      status: 'DELETE'
    }
    setLoading(true)
    try {
      await axiosInstance.put(`${singleJobOperation}/updatejobstatus`, putData)

      fetchAllJobs()
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }
  const handleDeleteProject = async () => {
    const putData = {
      id,
      status: 'DELETE'
    }
    setLoading(true)
    try {
      await axiosInstance.put(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/UpdateProject`,
        putData
      )

      fetchAllJobs()
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }
  return (
    <tr className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   ">
      <td className="text-center min-w-[50px]">
        {(page - 1) * limit + idx + 1}
      </td>
      <td className=" p-3  w-[300px]">
        <div className="flex gap-2  items-center">
          {company_logo && company_logo !== '' && (
            <img
              className="w-8 h-8 object-contain rounded-sm"
              src={company_logo}
              alt=""
            />
          )}
          <h2 className="">
            {'job_name' in data ? data.job_name : data.project_title}
          </h2>
        </div>
      </td>
      <td className="text-center">
        <p className=" p-2  ">{organization} </p>
      </td>
      <td className="text-center">
        <p className=" p-2  ">
          {'job_email' in data ? data.job_email : data.email}{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2  ">
          {'job_category' in data ? data.job_category : data.project_category}{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2  ">{country} </p>
      </td>
      <td className="text-center">
        <p className=" p-2  ">
          {create_date.split(' ')[0].split('-').reverse().join('/')}{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2  capitalize  ">
          {status === 'OPEN_UNPUBLISHED'
            ? 'processing job'
            : type.toLowerCase()}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2  ">
          {'job_id' in data ? data.job_id : data.project_id}{' '}
        </p>
      </td>
      <td className="text-center">
        <div className=" p-2  flex gap-3  justify-center items-center">
          <button
            onClick={() =>
              type === 'JOB'
                ? setShowEditJobModal(true)
                : setShowEditProjectModal(true)
            }
            className="bg-blue-500 hover:bg-blue-400 shadow-md text-white rounded-full px-3 py-2 w-16"
          >
            Edit
          </button>
          <button
            onClick={() => setShowDeleteModal(true)}
            className="bg-red-500 hover:bg-red-400 shadow-md text-white rounded-full px-3 py-2 w-16 "
          >
            Delete
          </button>
        </div>
        {showDeleteModal && (
          <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-md p-6 m-auto z-50">
              <ClickWrapper func={() => setShowDeleteModal(false)}>
                <ConfirmationModal
                  heading={`Delete ${type === 'JOB' ? 'Job' : 'Project'}`}
                  title={`Are you sure you want to delete the ${
                    type === 'JOB' ? 'Job' : 'Project'
                  } ?`}
                  positiveText={'Delete'}
                  negativeText={'Cancel'}
                  handlePositive={
                    type === 'JOB' ? handleDeleteJob : handleDeleteProject
                  }
                  handleNegative={() => setShowDeleteModal(false)}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showEditJobModal && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-4xl p-4 m-auto z-50">
              <ClickWrapper func={() => {}}>
                <EditJobModal
                  refetchData={fetchAllJobs}
                  close={() => setShowEditJobModal(false)}
                  id={id}
                  jobData={{
                    job_name: data.job_name,
                    key_skills,
                    status,
                    organization,
                    job_email: data.job_email,
                    years_of_experience,
                    description,
                    job_type,
                    weekly_hours,
                    work_hours,
                    pay_range,
                    company_logo,
                    country,
                    job_category: data.job_category
                  }}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showEditProjectModal && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-4xl p-4 m-auto z-50">
              <ClickWrapper func={() => {}}>
                <EditProjectModal
                  refetchData={fetchAllJobs}
                  close={() => setShowEditProjectModal(false)}
                  id={id}
                  projectData={{
                    project_title: data.project_title,
                    key_skills,
                    status,
                    organization,
                    email: data.email,
                    years_of_experience,
                    description,
                    duration: data.duration,
                    payment: data.payment,
                    guidance: data.guidance,
                    country,
                    project_category: data.project_category
                  }}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SingleAdminJobTable
