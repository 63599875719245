import React from 'react'
import uuid from 'react-uuid'
import TechProfileLogo from '../../../navbar/TechProfileLogo'

const Profile = ({ applicantData }) => {
  return (
    <div className="p-6  ">
      <div className=" pb-10 lg:p-10 bg-white flex flex-col lg:flex-row gap-10 relative ">
        <div className="flex absolute top-0 right-0 justify-end items-center md:px-6">
          <TechProfileLogo />
          <h1 className="font-bold  tracking-wide ">
            <span className="text-blue-500">T</span>ech
            <span className="text-blue-500">P</span>
            rofile
          </h1>
        </div>
        <img
          className="w-40 h-40 rounded-xl object-contain"
          src={applicantData.avatar_url}
          alt=""
        />
        <div className="flex flex-col gap-6">
          <h1 className="text-3xl font-bold">{applicantData?.name}</h1>
          <div className="flex flex-wrap  gap-4 lg:gap-8 ">
            <div className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Email</p>
              <p>{applicantData?.email_id}</p>
            </div>
            <div className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Phone </p>
              <p>{applicantData?.phone_no}</p>
            </div>
            <div className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Year Passout</p>
              <p>{applicantData?.year_of_passout}</p>
            </div>
            <div className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Institution</p>
              <p>{applicantData?.college}</p>
            </div>

            <div className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Kaggle ID</p>
              <p>{applicantData?.kaggle_id}</p>
            </div>
            <div className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Hackerrank ID</p>
              <p>{applicantData?.hacker_rank_id}</p>
            </div>
          </div>

          {applicantData?.key_skill.length > 0 && (
            <div className="flex gap-2 items-center">
              <h3 className="font-semibold text-sm">Skills : </h3>
              <div className=" flex gap-2 items-center  flex-wrap">
                {applicantData?.key_skill.map((skill) => (
                  <p
                    className="p-2 px-3 bg-gray-100 rounded-full shadow text-xs"
                    key={uuid()}
                  >
                    {skill.language_name}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>
    </div>
  )
}

export default Profile
