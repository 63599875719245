import React, { useState } from 'react'
import { RiArrowDropDownLine, RiArrowDropUpLine } from 'react-icons/ri'
import { Link, useLocation } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleResponsiveHeader = ({ menu, setShowMenu }) => {
  const [showsubMenu, setShowsubMenu] = useState(false)
  const { pathname } = useLocation()
  return (
    <div className="flex flex-col w-full items-center capitalize ">
      <div
        key={uuid()}
        className={`${
          pathname === menu.target
            ? 'text-blue-400 bg-gray-400 bg-opacity-10 font-semibold '
            : ''
        } w-full flex gap-2 justify-center hover:bg-gray-400 max-w-xs hover:bg-opacity-10 hover:font-semibold items-center text-center font-600  transition-all duration-300 ease-in-out p-3   rounded-lg `}
      >
        <Link
          onClick={() => {
            setShowsubMenu(false)
            setShowMenu(false)
          }}
          to={menu.target}
        >
          {menu.title}
        </Link>
        {menu.child.length > 0 && (
          <button
            className="text-3xl "
            onClick={() => {
              setShowsubMenu(!showsubMenu)
            }}
          >
            {showsubMenu ? <RiArrowDropUpLine /> : <RiArrowDropDownLine />}
          </button>
        )}
      </div>
      {menu.child.length > 0 && showsubMenu && (
        <div
          className={`flex flex-col rounded-lg overflow-hidden bg-gray-400 bg-opacity-10 w-full max-w-xs m-auto text-center  `}
        >
          {menu.child.map((submenu) => (
            <Link
              onClick={() => {
                setShowsubMenu(false)
                setShowMenu(false)
              }}
              key={uuid()}
              to={submenu.target}
              className={` ${
                pathname === submenu.target
                  ? 'text-blue-400  bg-opacity-10  '
                  : ''
              } text-xs lg:text-base font-600 p-3 hover:font-semibold  hover:text-blue-400 transition-all duration-300 ease-in-out`}
            >
              {submenu.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default SingleResponsiveHeader
