import React from 'react'

const TextInput = ({
  onChange,
  value,
  labelText,
  type = '',
  placeholder,
  errorMessage,
  required = false
}) => {
  return (
    <label className="flex flex-col text-sm flex-1 font-600 my-2 z-0 ">
      <p className="font-semibold tracking-wide">
        {labelText}
        {required && '*'}
      </p>

      <input
        className={`p-3 rounded-xl border-2 ${
          type === 'code' && 'tracking-widest'
        }  outline-none text-gray-700 my-2 font-600 border-gray-400 border-opacity-50 placeholder-gray-400`}
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{ width: '100%' }}
        required={required}
      />
      {errorMessage && (
        <p className="text-red-500 text-xs ml-4">{errorMessage}</p>
      )}
    </label>
  )
}

export default TextInput
