import { Box, Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../services/axios/axios'
import ApplicantJobListing from '../../components/applicant-jobs/ApplicantJobListing'
import ApplicantProjectListing from '../../components/applicant-jobs/ApplicantProjectListing'
import RippleLoader from '../../components/loader/RippleLoader'

const ApplicantJobPage = () => {
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState('1')

  const [allJobData, setAllJobData] = useState([])
  const [allProjectData, setAllProjectData] = useState([])
  const { username } = useParams()
  const { githubUser } = useSelector((state) => state.auth)
  const navigate = useNavigate()

  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const fetchAllJobs = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://7g6izqwdr4.execute-api.ap-south-1.amazonaws.com/dev/getapplicantapplications?GITHUB_ID=${username}`
      )
      console.log(JSON.parse(data.body))
      setAllJobData(JSON.parse(data.body))
    } catch (err) {
      navigate('*')
      console.log(err.message)
    }
  }

  const fetchAllProjects = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://7g6izqwdr4.execute-api.ap-south-1.amazonaws.com/dev/getapplicantapplications?GITHUB_ID=${username}&type=PROJECT`
      )
      console.log(JSON.parse(data.body))
      setAllProjectData(JSON.parse(data.body))

      setLoading(false)
    } catch (err) {
      navigate('*')
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (githubUser.username !== username) {
      navigate('*')
    }
    fetchAllJobs()
    fetchAllProjects()
  }, [])

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10 ">
      {loading ? (
        <RippleLoader />
      ) : (
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Your Jobs" value="1" />
              <Tab label="Your Projects" value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <ApplicantJobListing
              allJobData={allJobData}
              fetchAllJobs={fetchAllJobs}
            />
          </TabPanel>
          <TabPanel value="2">
            <ApplicantProjectListing
              allProjectData={allProjectData}
              fetchAllProjects={fetchAllProjects}
            />
          </TabPanel>
        </TabContext>
      )}
    </div>
  )
}

export default ApplicantJobPage
