//auth actions
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const LOGOUT = 'LOGOUT'

//github actions
export const GITHUB_LOGIN = 'GITHUB_LOGIN'

//credit action
export const UPDATE_CREDITS = 'UPDATE_CREDITS'
export const CREDITS_LOADING = 'CREDITS_LOADING'
