import React from 'react'
import { GiRank3 } from 'react-icons/gi'
import { HiLocationMarker } from 'react-icons/hi'
import { MdOutlineDescription } from 'react-icons/md'
import { VscOrganization } from 'react-icons/vsc'
import { includeComma } from '../../../../services/global/globalFunction'
import { AiOutlineUserAdd, AiOutlineCalendar } from 'react-icons/ai'
import { RiUserFollowLine } from 'react-icons/ri'

const InfoCard = ({ data, rankData }) => {
  const { bio, company, location, followers, git_create_date, following } = data
  const { ranking } = rankData
  const parseDate = (date) => {
    const month = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec'
    ]

    return (
      date.split('-')[2] +
      ' ' +
      month[Number(date.split('-')[1]) - 1] +
      ' ' +
      date.split('-')[0]
    )
  }
  return (
    <div className=" flex-1 shadow-custom rounded-md bg-white text-xs md:text-sm flex flex-col xl:flex-row xl:justify-around gap-10  p-4 sm:p-6  w-full text-left  ">
      <div className="flex xl:flex-col items-center xl:items-start gap-3 py-3 justify-between flex-[.3]  text-sm md:text-base">
        <div className="flex flex-col xl:flex-row items-center xl:items-start gap-2 flex-wrap ">
          <div className="flex items-center">
            <RiUserFollowLine />
            <p className="ml-2">Followers</p>
          </div>

          <h3 className="font-semibold text-gray-600 ">
            {includeComma(followers)}
          </h3>
        </div>
        <div className="flex flex-col xl:flex-row items-center xl:items-start gap-2 flex-wrap ">
          <div className="flex items-center">
            <AiOutlineUserAdd />
            <p className="ml-2">Following</p>
          </div>

          <h3 className="font-semibold text-gray-600 ">
            {includeComma(following)}
          </h3>
        </div>
        <div className="flex flex-col xl:flex-row items-center xl:items-start gap-2 flex-wrap ">
          <div className="flex items-center">
            <AiOutlineCalendar />
            <p className="ml-2 flex items-center">
              Joined<span className="hidden md:block ml-1">Date</span>
            </p>
          </div>

          <h3 className="font-semibold text-gray-600 ">
            {parseDate(git_create_date)}
          </h3>
        </div>
      </div>
      <div className="flex flex-col gap-3 justify-around flex-[.6] ">
        {ranking !== null && ranking.length > 0 && (
          <div className="flex items-center gap-2 text-lg">
            <GiRank3 />

            <p className="text-sm md:text-xl font-bold object-contain ">
              {ranking}
            </p>
          </div>
        )}

        {bio !== null && bio.length > 0 && (
          <div className="flex gap-2 text-lg">
            <MdOutlineDescription />
            <p className="text-xs">{bio}</p>
          </div>
        )}
        {location !== null && (
          <div className="flex items-start gap-2 text-lg">
            <HiLocationMarker />
            <p className="text-xs">{location}</p>
          </div>
        )}

        {company !== null && (
          <div className="flex items-start gap-2 text-lg">
            <VscOrganization />
            <p className="text-xs">{company}</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoCard
