import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import SkillScore from '../../../single-job-listing/SkillScore'

const ProfileSummary = ({ data }) => {
  const { summary } = data

  return (
    <div className="p-6 flex flex-col gap-10 text-sm ">
      {/* <div className="flex flex-col lg:flex-row  gap-6">
				<div className="p-6 flex-1 flex flex-col gap-2 shadow-custom rounded-xl">
					<h2 className="font-semibold ">Profile Summary:</h2>
					<p>
						John Doe is a B.Tech in Computer Science and Engineering student at
						XYZ Institute of Science and Technology with a GPA of 9.5. He has
						worked as a Research Intern at Sample Research India, Research
						Associate at Cornell University, and Summer Intern at IIT Kanpur.
					</p>
				</div>
				<div className="p-6 flex-1 flex flex-col sm:flex-row  gap-10 justify-evenly shadow-custom rounded-xl">
					<div style={{ width: '100%', maxWidth: '150px' }}>
						<CircularProgressbar
							value={17.5}
							text={`17.5%`}
							strokeWidth={10}
							styles={buildStyles({
								textColor: 'black',
								pathColor: '#0091FE',
								trailColor: '#efefef',
								textSize: '18px',
								fontWeight: 'bold',
							})}
						/>
					</div>
					<div className="grid grid-cols-2 gap-6">
						<SkillScore label={'Technical Skills'} score={32.6} />
						<SkillScore label={'Management Skills'} score={32.6} />
						<SkillScore label={'Soft Skills'} score={32.6} />
						<SkillScore label={'Hard Skills'} score={32.6} />
						<SkillScore label={'Soft Skills'} score={32.6} />
						<SkillScore label={'Profile Skills'} score={32.6} />
					</div>
				</div>
			</div> */}

      {summary && (
        <div className="flex flex-col gap-2 p-6 shadow-custom rounded-xl">
          <h2 className="font-semibold ">Resume Summary:</h2>
          <p>
            John Doe is a highly motivated B.Tech Engineering student in
            Computer Science at XYZ Institute of Science and Technology,
            boasting a GPA of 9.5. With research as his passion and a strong
            academic background, John has gained valuable experience as a
            Research Intern at Sample Research India, Research Associate at
            Cornell University, and Summer Intern at Indian Institute of
            Technology, Kanpur. At Sample Research India, John contributed to
            cutting-edge projects, showcasing his ability to analyze complex
            problems and propose innovative solutions. His solid grasp of
            research methodologies enabled him to efficiently collect and
            analyze data, supporting team decision-making. As a Research
            Associate at Cornell University, John collaborated with renowned
            experts, effectively contributing to multiple research projects. His
            communication, teamwork, critical thinking, and attention to detail
            led to accurate data collection, analysis, and significant
            contributions to publications and findings. During his Summer
            Internship at Indian Institute of Technology, Kanpur, John gained
            hands-on experience, designing and implementing software solutions.
            His strong programming skills and adaptability to new technologies
            were evident. John's exceptional GPA and research experience
            highlight his analytical and problem-solving abilities. With a
            passion for innovation and a solid foundation in computer science,
            he is poised to make valuable contributions to engineering.
          </p>
        </div>
      )}
    </div>
  )
}

export default ProfileSummary
