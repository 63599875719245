import React from 'react'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import { includeComma } from '../../../../services/global/globalFunction'
import LanguageLogo from '../../language-logo/LanguageLogo'

const SingleContributors = ({ data }) => {
  const {
    additions,
    branch_name,
    changedFiles,
    commit_date,
    commit_id,
    commit_url,
    deletions,
    files_changed,
    github_id,
    id,
    language_name,
    languages,
    last_commit,
    message,
    profile_pic,
    repo_name,
    repo_owner,
    total_commits,
    user_id,
    user_name
  } = data
  return (
    <div className="flex flex-col  rounded-md text-sm md:text-lg bg-white p-4 md:p-8 shadow-custom">
      <div className="flex gap-3">
        <img
          className="w-20 h-20 object-contain rounded-full"
          src={profile_pic}
          alt={github_id}
        />
        <div className="flex flex-col gap-2">
          <Link
            to={`/dashboard/${github_id}`}
            className="text-xl font-bold cursor-pointer"
          >
            {github_id}
          </Link>
          <h3>{user_name}</h3>

          <div className="flex gap-2">
            <h3>{changedFiles} files</h3>
            <h3 className="flex gap-2 ">
              (
              <span className="text-green-600">
                {' '}
                + {includeComma(additions)}
              </span>
              <span> , </span>
              <span className="text-red-600">- {includeComma(deletions)}</span>)
            </h3>
          </div>

          <h3>
            Last Commit :{' '}
            {last_commit.split(' ')[0].split('-').reverse().join('/')}
          </h3>
        </div>
      </div>
      <div className=" flex gap-3 flex-wrap py-4 ">
        {languages.map((language) => (
          <LanguageLogo
            key={uuid()}
            language={language.name}
            logo={language.language_logo}
          />
        ))}
      </div>
    </div>
  )
}

export default SingleContributors
