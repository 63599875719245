import { useSelector } from 'react-redux'

const useCreditHook = () => {
  const { loggedIn } = useSelector((state) => state.auth)
  const { userCredits, creditValues } = useSelector((state) => state.credits)
  const { credits_available, job_credits } = userCredits

  const {
    JOB_CREATE,
    APPLICATION_PROCESS,
    SEARCH_CANDIDATE,
    PROFILE_GENERATE,
    MESSAGE,
    CREATE_ASSIGNMENT
  } = creditValues
  const createJobEligibility = loggedIn
    ? Boolean(job_credits >= JOB_CREATE.value)
    : false
  const applicationProcessEligibility = loggedIn
    ? Boolean(credits_available >= APPLICATION_PROCESS.value)
    : false
  const searchCandidateEligibility = loggedIn
    ? Boolean(credits_available >= SEARCH_CANDIDATE.value)
    : false
  const generateProfileEligibility = loggedIn
    ? Boolean(credits_available >= PROFILE_GENERATE.value)
    : false
  const messageEligibility = loggedIn
    ? Boolean(credits_available >= MESSAGE.value)
    : false
  const createAssignmentEligibility = loggedIn
    ? Boolean(credits_available >= CREATE_ASSIGNMENT.value)
    : false
  return {
    createJobEligibility,
    applicationProcessEligibility,
    searchCandidateEligibility,
    generateProfileEligibility,
    messageEligibility,
    createAssignmentEligibility
  }
}

export default useCreditHook
