import React from 'react'
import uuid from 'react-uuid'

const YearSelect = ({ arr, value, onChange, isString }) => {
  return (
    <div className="px-3 py-1 border border-gray-300 rounded-sm">
      <select
        value={value}
        onChange={onChange}
        className="font-semibold text-sm cursor-pointer bg-transparent outline-none"
      >
        {arr.length > 0 &&
          arr.map((option) => (
            <option key={uuid()}>
              {isString ? option : new Date().getFullYear() - option}
            </option>
          ))}
      </select>
    </div>
  )
}

export default YearSelect
