import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import ReactHtmlParser from 'react-html-parser'
import { AiOutlineAntDesign } from 'react-icons/ai'
import { BiTime } from 'react-icons/bi'
import { GiMoneyStack } from 'react-icons/gi'
import { IoMedalOutline } from 'react-icons/io5'
import { MdCopyAll, MdLocationPin, MdOutlineWorkOutline } from 'react-icons/md'
import uuid from 'react-uuid'
import { showToast } from '../../../services/global/Toaster'
import { ArrowTooltip } from '../../ui-component/tooltip/Tooltip'
import RippleLoader from '../loader/RippleLoader'
import { parseDuration } from '../project/SingleProjectCard'

const SingleApplicantProjectCard = ({ singleProjectData }) => {
  const {
    agency,
    company_logo,
    country,
    create_date,
    description,
    duration,
    email,
    guidance,
    id,
    key_skills,
    organization,
    payment,
    project_category,
    project_id,
    project_title,
    status,
    updated_at,
    user_id,
    years_of_experience
  } = singleProjectData

  const [isReadMore, setIsReadmore] = useState(true)
  const [isLoading, setLoading] = useState(false)

  return (
    <div className="rounded-2xl flex flex-col text-sm p-4 gap-1 shadow-outer bg-white  relative">
      {isLoading && (
        <div className="absolute inset-0 bg-gray-200 backdrop-filter backdrop-blur-sm bg-opacity-20 grid place-items-center ">
          <RippleLoader />
        </div>
      )}

      <div className="flex flex-col gap-6 relative">
        <div className="flex justify-between gap-6 items-start border-b-2 border-gray-300 border-opacity-40 pt-2 pb-6 ">
          <div className="flex flex-col gap-2">
            <div className="flex gap-3 items-center">
              {company_logo && (
                <img
                  className="w-16 h-16 object-contain "
                  src={company_logo}
                  alt={''}
                />
              )}
              <p className="text-sm font-semibold ">{organization}</p>
            </div>
            <p className="text-blue-500 flex gap-2 items-center text-sm">
              {' '}
              <AiOutlineAntDesign />
              <span>{project_title}</span>
            </p>
            <div className="flex gap-2 text-xs items-center text-blue-500">
              <MdOutlineWorkOutline />
              <p>
                <span className="font-semibold">Job ID : </span>
                {project_id}
              </p>
              <CopyToClipboard
                text={project_id}
                onCopy={() =>
                  showToast('success', 'Copied Job ID to Clipboard')
                }
              >
                <ArrowTooltip title="Copy Job-Id">
                  <button className="text-lg">
                    <MdCopyAll />
                  </button>
                </ArrowTooltip>
              </CopyToClipboard>
            </div>
          </div>
          <div className="flex flex-col gap-2"></div>
        </div>
        <div className="flex ">
          {key_skills && key_skills.length > 0 && (
            <div className="flex-1">
              <h2 className="font-semibold mb-2"> Skills required : </h2>

              <div>
                {key_skills && (
                  <div className="flex gap-2 items-center flex-wrap">
                    {key_skills.split('|').map((skills) => (
                      <p
                        key={uuid()}
                        className="bg-gray-100 p-2 px-3 text-gray-700 rounded-full shadow text-xs "
                      >
                        {' '}
                        {skills}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
          <div className=" flex flex-col gap-2">
            {years_of_experience && (
              <div className="flex gap-1 items-center">
                <IoMedalOutline />
                <p className="text-sm">
                  {years_of_experience}{' '}
                  {years_of_experience > 1 ? 'years' : 'year'} of experience
                </p>
              </div>
            )}

            {payment && (
              <p className="text-sm flex  gap-2 items-center ">
                <GiMoneyStack className="text-xl" />
                {payment}
              </p>
            )}
            {country && (
              <div className="flex gap-1 items-center">
                <MdLocationPin />
                <p className="text-sm">{country}</p>
              </div>
            )}

            {duration && (
              <div className="flex gap-1 items-center">
                <BiTime />
                <p className="text-sm">{parseDuration(duration)}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="my-3 ">
        {description && (
          <>
            <p>
              <span className="font-semibold mb-1">Description</span>
            </p>
            <p className={`${isReadMore ? 'truncate' : ''} `}>
              {' '}
              {ReactHtmlParser(
                !isReadMore ? description : description.slice(0, 200)
              )}
            </p>
            {description.toString().split(' ').length > 15 && (
              <button
                onClick={() => setIsReadmore(!isReadMore)}
                className="text-blue-400 text-sm font-semibold"
              >
                {!isReadMore ? 'Read less' : 'Read More'}
              </button>
            )}
          </>
        )}
      </div>

      <div className="flex justify-between items-end gap-4 w-full ">
        <p className="text-xs w-full text-right ">
          <span className="font-semibold">Posted on </span>
          {create_date
            ? create_date.split(' ')[0].split('-').reverse().join('/')
            : ''}
        </p>
      </div>
    </div>
  )
}

export default SingleApplicantProjectCard
