import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import creditAction from '../../../redux/actions/creditAction'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import SearchUserForm from '../../components/search-user/general/SearchUserForm'
import SearchUserTable from '../../components/search-user/general/SearchUserTable'

const SearchPage = () => {
  const { searchUserEndpoint } = EndPoints.searchPage
  const [searchedUser, setSearchedUser] = useState([])
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)

  const fetchSearchUser = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${searchUserEndpoint}/getsearch?user_id=${user.user_id}`
      )
      setSearchedUser(JSON.parse(JSON.parse(data.body).message))
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSearchUser()
  }, [])

  return (
    <div className="w-full p-4 sm:p-6 md:p-10 min-h-screen ">
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="flex flex-col gap-4 md:gap-6">
          <SearchUserForm searchedUsers={searchedUser} />

          <div>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <SearchUserTable
                data={searchedUser}
                fetchCreditData={() =>
                  dispatch(creditAction.fetchUserCredits(user.user_id))
                }
              />
            </ErrorBoundary>
            {/* <ErrorBoundary FallbackComponent={ErrorComponent}>
						<ResponsiveSingleJobTable data={singleJobData} />
					</ErrorBoundary> */}
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchPage
