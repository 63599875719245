import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { MdDownload } from 'react-icons/md'
import { ArrowTooltip } from '../../../../ui-component/tooltip/Tooltip'
import ErrorComponent from '../../../error/ErrorComponent'
import LanguageChart from './component/LanguageChart'
import Profile from './component/Profile'
import ProfileSummary from './component/ProfileSummary'
import ProjectCharts from './component/ProjectCharts'
import RepoTable from './component/RepoTable'
import UserLanguage from './component/UserLanguage'

const ProfilePreview = ({
  close,
  download,
  candidateDetails,
  dashboardData,
  componentRef,
  hasCreatedProfile
}) => {
  return (
    <div className="flex flex-col md:items-center gap-4 bg-white  rounded-xl shadow-lg m-auto relative p-4 sm:w-full ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-3xl font-bold text-blue-500"
      >
        <IoMdCloseCircleOutline />
      </button>
      <h2 className="hidden md:block text-xl md:text-center font-semibold mb-2">
        Profile Preview
      </h2>

      <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>
      <div className="overflow-hidden md:overflow-scroll w-full h-[1vh] sm:h-[65vh] p-2 border-2 rounded-lg border-opacity-30">
        <div ref={componentRef} className="flex flex-col   ">
          {hasCreatedProfile && (
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <Profile applicantData={candidateDetails} />
            </ErrorBoundary>
          )}
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <ProfileSummary data={dashboardData[0]} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <UserLanguage language={dashboardData[2]} />
          </ErrorBoundary>
          <div className="flex w-full">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <LanguageChart data={dashboardData[2]} />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <ProjectCharts data={dashboardData[1]} />
            </ErrorBoundary>
          </div>

          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <RepoTable data={dashboardData[1]} userData={dashboardData[0]} />
          </ErrorBoundary>
        </div>
      </div>

      <ArrowTooltip title="Download">
        <button
          onClick={download}
          className="w-full px-6 py-2 max-w-max  flex justify-center items-center gap-3  text-white bg-blue-400 rounded-full"
        >
          <MdDownload />
          <p className="uppercase flex gap-1 font-semibold">
            Download <span className="lg:hidden xl:block">Profile</span>
          </p>
        </button>
      </ArrowTooltip>
    </div>
  )
}

export default ProfilePreview
