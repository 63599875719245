import React from 'react'
import { AiOutlineProfile } from 'react-icons/ai'
import { HiOutlineNewspaper } from 'react-icons/hi'
import { BsPerson } from 'react-icons/bs'
import { Link } from 'react-router-dom'
import {
  RESUME_INTRODUCTION_PAGE,
  RESUME_JOB_PAGE,
  RESUME_SUMMARY_PAGE
} from '../../../navigation/routes'
import GrowthImage from '../../../assets/img/GrowthIllustration.png'

const ResumeInsights = () => {
  return (
    <div className="w-full min-h-screen">
      <div className="flex justify-between min-h-[90vh] px-10 bg-gradient-to-br from-blue-100 to-orange-100 items-center gap-8">
        <div className="flex flex-col gap-4 py-20  sm:px-10 lg:min-w-[600px] lg:w-1/2 ">
          <h1
            style={{ lineHeight: '1.5' }}
            className="text-3xl md:text-4xl max-w-xl  font-bold "
          >
            Elevate Your Job Search with Our{' '}
            <span className="text-blue-500"> AI-Powered </span>{' '}
            <span className="text-orange-500 ml-2">Tools</span>
          </h1>

          <p className="text-sm md:text-xl font-normal leading-loose">
            Looking for a way to take your job search to the next level? Our
            AI-powered tools can help. We offer a range of free services
            designed to help job seekers stand out in a crowded job market.
          </p>
          <p className="text-sm md:text-base font-normal leading-loose">
            Don't settle for a mediocre job search. Try our AI-powered tools
            today and elevate your job search to new heights!
          </p>
          <p className="text-sm md:text-base font-normal leading-loose">
            Our free resume to job description scorer helps you evaluate how
            well your resume matches the requirements of a job posting, so you
            can focus your efforts on the jobs that are the best fit for your
            skills and experience.
          </p>
          <p className="text-sm md:text-base font-normal leading-loose">
            Don't settle for a mediocre job search. Try our AI-powered tools
            today and elevate your job search to new heights!
          </p>
          <Link
            to={RESUME_SUMMARY_PAGE}
            className="p-3 py-2 max-w-[250px] mt-6 font-semibold text-sm rounded-full w-full grid place-items-center bg-blue-500 text-white "
          >
            Try it for Free !
          </Link>
        </div>
        <div className="hidden lg:block">
          <lottie-player
            src="https://assets10.lottiefiles.com/packages/lf20_1eLAOamprA.json"
            background="transparent"
            speed="1"
            style={{ width: '75%', objectFit: 'contain', maxWidth: '600px' }}
            loop
            autoplay
          ></lottie-player>
        </div>
      </div>

      <div className="p-10 py-28 flex flex-col gap-10 items-center">
        <h1
          style={{ lineHeight: '1.5' }}
          className="text-3xl md:text-4xl text-center   font-bold "
        >
          Our<span className="text-blue-500"> AI-Powered </span>
          <span className="text-orange-500 ml-2">Tools</span> Includes
        </h1>

        <div className="grid-cols-1 grid md:grid-cols-3 gap-8 w-full max-w-6xl mx-auto ">
          <div className="p-4 sm:p-6 md:p-4 lg:p-6 rounded-xl shadow-lg bg-gradient-to-b from-blue-100 to-orange-50 text-base flex flex-col gap-4 text-center items-center">
            <AiOutlineProfile className="text-5xl" />
            <h2 className="text-2xl font-semibold text-center">
              Free Resume Summary Generator{' '}
            </h2>
            <p>
              Crafting a powerful resume summary has never been easier or more
              efficient. With just a few clicks, our tool will generate a
              concise and compelling summary that will capture the attention of
              any hiring manager.
            </p>
            <Link
              to={RESUME_SUMMARY_PAGE}
              className="p-3 py-2 max-w-[250px] mt-6 font-semibold text-sm rounded-full w-full grid place-items-center bg-blue-500 text-white "
            >
              Generate Resume Summary
            </Link>
          </div>
          <div className="p-4 sm:p-6 md:p-4 lg:p-6  rounded-xl shadow-lg bg-gradient-to-b from-blue-100 to-orange-50 text-base flex flex-col gap-4 text-center  items-center">
            <BsPerson className="text-5xl" />
            <h2 className="text-2xl font-semibold text-center">
              Free Tell Me About Yourself
            </h2>
            <p>
              The easiest way to craft a winning response to one of the most
              common job interview questions!
            </p>
            <Link
              to={RESUME_INTRODUCTION_PAGE}
              className="p-3 py-2 mt-auto max-w-[250px]  font-semibold text-sm rounded-full w-full grid place-items-center bg-blue-500 text-white "
            >
              Tell me about Myself
            </Link>
          </div>
          <div className="p-4 sm:p-6 md:p-4 lg:p-6  rounded-xl shadow-lg bg-gradient-to-b from-blue-100 to-orange-50 text-base flex flex-col gap-4 text-center  items-center">
            <HiOutlineNewspaper className="text-5xl" />
            <h2 className="text-2xl font-semibold text-center">
              Cover Letter Service
            </h2>
            <p>
              AI-Powered Cover Letter Writing Service Our AI-powered cover
              letter writing service takes the hassle out of job hunting by
              providing job seekers with a professionally written cover letter
              that highlights their skills and qualifications.
            </p>
            <Link
              to={RESUME_JOB_PAGE}
              className="p-3 py-2 max-w-[250px] mt-6 font-semibold text-sm rounded-full w-full grid place-items-center bg-blue-500 text-white "
            >
              Generate Report
            </Link>
          </div>
        </div>
      </div>
      <div className="p-20 bg-gradient-to-br from-blue-100 to-orange-100">
        <div className=" grid grid-cols-1 md:grid-cols-2 gap-20 max-w-7xl mx-auto w-full">
          <div className="flex flex-col gap-6">
            <h1
              style={{ lineHeight: '1.5' }}
              className="text-3xl md:text-4xl  font-bold "
            >
              Highlights key qualifications
            </h1>

            <p>
              A resume summary allows you to quickly and succinctly highlight
              your key qualifications and experiences that make you an ideal
              candidate for the position. This helps hiring managers quickly
              understand your value and qualifications without having to read
              through your entire resume.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <h1
              style={{ lineHeight: '1.5' }}
              className="text-3xl md:text-4xl  font-bold "
            >
              Shows relevance to the position
            </h1>

            <p>
              By tailoring your resume summary to the specific job you're
              applying for, you can show the hiring manager that you have the
              necessary skills and experience to excel in the role. This can
              help you stand out from other applicants and increase your chances
              of being selected for an interview.
            </p>
          </div>
          <div className="flex flex-col gap-6">
            <h1
              style={{ lineHeight: '1.5' }}
              className="text-3xl md:text-4xl  font-bold "
            >
              Saves Time
            </h1>

            <p>
              Hiring managers often receive hundreds of resumes for each open
              position, so it's important to make sure that your resume stands
              out. A resume summary allows you to quickly convey your
              qualifications and relevance to the position, which can save the
              hiring manager time and increase your chances of being selected
              for an interview.
            </p>
            <Link
              to={RESUME_SUMMARY_PAGE}
              className="p-3 py-2 max-w-[250px] mt-6 font-semibold text-sm rounded-full w-full grid place-items-center bg-blue-500 text-white "
            >
              Get Resume Summary
            </Link>
          </div>
          <div>
            <img src={GrowthImage} alt="" className="w-3/4 object-contain" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResumeInsights
