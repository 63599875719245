import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import authAction from '../../../redux/actions/authAction'
import { Avatar } from '@mui/material'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import { Link, useLocation } from 'react-router-dom'
import { RECRUITER_PROFILE_PAGE } from '../../../navigation/routes'

const ProfileComponent = ({ userType }) => {
  const [showUserMenu, setShowUserMenu] = useState(false)
  const dispatch = useDispatch()
  const { user, githubUser } = useSelector((state) => state.auth)
  const { pathname } = useLocation()

  const redirectURL =
    userType === 'github'
      ? `/profile/${githubUser.username}`
      : RECRUITER_PROFILE_PAGE
  return (
    <div className="flex items-center mx-2 relative">
      {showUserMenu && (
        <div className="absolute  right-0 top-full bg-white shadow-md border z-50 my-2 w-40  p-2 rounded-lg">
          <ClickWrapper func={() => setShowUserMenu(false)}>
            <div className="flex flex-col">
              <Link
                onClick={() => setShowUserMenu(false)}
                to={redirectURL}
                className={`${
                  pathname === redirectURL
                    ? 'text-blue-400 bg-gray-400 bg-opacity-10 font-semibold '
                    : ''
                } w-full  hover:bg-gray-400 max-w-xs hover:bg-opacity-10 hover:font-semibold   font-600  transition-all duration-300 ease-in-out p-3   rounded-lg `}
              >
                Your Profile
              </Link>
              <button
                onClick={() => {
                  dispatch(authAction.logout())
                }}
                className="flex items-center gap-3 shadow bg-red-500 rounded-lg p-2 px-4 text-white "
              >
                Logout
              </button>
            </div>
          </ClickWrapper>
        </div>
      )}

      <Avatar
        className="cursor-pointer shadow-lg "
        onClick={() => setShowUserMenu(!showUserMenu)}
        src={`${
          userType === 'github' ? githubUser.photoUrl : user?.profile_image
        }?rand=${new Date()}`}
        alt={userType === 'github' ? githubUser?.username : user?.name}
        style={{ width: 40, height: 40 }}
      />
    </div>
  )
}

export default ProfileComponent
