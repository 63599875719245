import React, { Fragment, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import TableSearch from '../../../ui-component/inputs/TableSearch'
import { advancedTableHeader } from '../TableData'
import SingleAdvanceUser from './SingleAdvanceUser'

const AdvanceUserTable = ({ data, fetchCreditData, refetchUserData }) => {
  const [filterApplicantData, setfilterApplicantData] = useState(data)
  const [applicantText, setApplicantText] = useState('')

  const filterChange = (text) => {
    const filteredData = data.filter(
      (user) =>
        user.language.toUpperCase().includes(text.toUpperCase()) ||
        user.github_id.toUpperCase().includes(text.toUpperCase())
    )

    setfilterApplicantData(filteredData)
  }

  const onTextChange = (e) => {
    setApplicantText(e.target.value)
    filterChange(e.target.value)
  }

  useEffect(() => {
    setfilterApplicantData(data)
  }, [data])
  return (
    <div className="flex flex-col gap-4 mt-10 ">
      <TableSearch
        value={applicantText}
        onChange={onTextChange}
        placeholder="Search Searched Users"
      />
      <div className="overflow-x-scroll w-full">
        <table className={`p-4 w-full rounded-lg  shadow-custom bg-white`}>
          <tbody className="text-xs xl:text-sm rounded-lg ">
            <tr className="bg-blue-400 bg-opacity-25 p-2 ">
              {advancedTableHeader.map((head) => (
                <th key={uuid()} className="text-center p-2 py-3 ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {filterApplicantData.length !== 0 &&
              filterApplicantData.map((applicantData, idx) => (
                <SingleAdvanceUser
                  key={uuid()}
                  refetchUserData={refetchUserData}
                  fetchCreditData={fetchCreditData}
                  data={applicantData}
                  idx={idx}
                  lastIdx={idx >= filterApplicantData.length - 4}
                  searchedUsers={data}
                />
              ))}
          </tbody>
        </table>
        {filterApplicantData.length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
              No Applicants Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default AdvanceUserTable
