export const applicantHeaderData = [
  {
    title: 'S No.',
    width: '30%'
  },
  {
    title: 'Github',
    width: '10%'
  },
  {
    title: 'Name ',
    width: '30%'
  },
  {
    title: 'Email',
    width: '25%'
  },
  {
    title: 'Phone Number',
    width: '10%'
  },
  {
    title: 'Added Date',
    width: '10%'
  },
  {
    title: 'Last Updated',
    width: '10%'
  },
  {
    title: 'Actions',
    width: '10%'
  }
]
