import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import ReactHtmlParser from 'react-html-parser'
import { AiFillFileWord } from 'react-icons/ai'
import { MdPictureAsPdf, MdSummarize, MdUpload } from 'react-icons/md'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import ResumeLoader from '../../components/resume/ResumeLoader'
import IconButton from '../../ui-component/buttons/IconButton'

const ResumeJobPage = () => {
  const [showSummary, setShowSummary] = useState(false)
  const [resumeFile, setResumeFile] = useState()
  const [fileName, setFileName] = useState('')
  const { uploadJobLogo } = EndPoints.createJob
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState('')
  const [jobData, setJobData] = useState({
    email: '',
    jobDescription: ''
  })
  const [showJobDescription, setShowJobDescription] = useState(false)

  const maxSize = 2097152
  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragReject
  } = useDropzone({
    // Disable click and keydown behavior
    accept: {
      file: ['.doc', '.docx', '.pdf']
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true
  })

  const generateSummary = async () => {
    setLoading(true)
    console.log('files', acceptedFiles)

    if (!fileName) {
      showToast('error', 'Please Upload your Resume')

      return
    }

    if (jobData.jobDescription.length < 150) return
    try {
      await uploadResume()
      const { data } = await axiosInstance.post(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/jobDescScore`,
        {
          resume_name: fileName,
          job_desc: jobData.jobDescription,
          email: jobData.email
        }
      )

      const parsedData = data.message.split('\n').join('<br>')
      setSummary(parsedData)
      setShowSummary(true)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
      setLoading(false)
      showToast('error', 'Server is Overloaded . Please try after 15 mins')
    }
  }

  const uploadResume = async () => {
    console.log(resumeFile)
    if (!resumeFile) return

    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': resumeFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'github-cv'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      const res = await axiosInstance.put(uploadUrl, resumeFile, options)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (acceptedFiles.length === 0) return

    setResumeFile(acceptedFiles[0])
    setFileName(`${uuid()}-${acceptedFiles[0].name.split(' ').join('')}`.trim())
  }, [acceptedFiles])
  return (
    <div className="w-full min-h-screen p-6 sm:p-8 md:p-10 relative">
      {loading && <ResumeLoader />}
      <div className="max-w-6xl mx-auto pb-10 flex gap-10">
        <div className="  flex flex-col gap-4  ">
          <h1
            style={{ lineHeight: '1.5' }}
            className="text-3xl md:text-4xl  font-bold "
          >
            <span className="text-blue-500">Cover</span>
            <span className="text-orange-500 ml-2">Letter</span> writing service
          </h1>
          <p>
            AI-Powered Cover Letter Writing Service Our AI-powered cover letter
            writing service takes the hassle out of job hunting by providing job
            seekers with a professionally written cover letter that highlights
            their skills and qualifications. Using the latest Natural Language
            Processing technology, our system analyzes job descriptions and
            creates personalized cover letters that are tailored to the specific
            needs of each job application.
          </p>
          <h2 className="text-xl font-bold ">Benefits :</h2>
          <p>
            Save time and effort: Our AI-powered service takes care of the
            tedious task of cover letter writing, freeing up your time to focus
            on other aspects of your job search. Stand out from the crowd: Our
            cover letters are optimized to grab the attention of hiring managers
            and showcase your unique skills and qualifications. Improve your
            chances of success: A well-written cover letter can be the
            difference between getting hired and being overlooked. Our
            AI-powered service ensures that your cover letter is crafted to
            maximize your chances of success.
          </p>
        </div>
      </div>

      {!showSummary ? (
        <div className="w-full flex flex-col items-center gap-8 bg-white shadow-xl p-6 rounded-3xl  border-2 border-dashed max-w-6xl mx-auto  ">
          <div className="flex flex-col w-full lg:flex-row-reverse justify-between gap-10 px-6 sm:px-10 items-center">
            <div className="w-full flex flex-col gap-6 items-center flex-1">
              <div className=" w-full  flex flex-col gap-6 items-center p-3">
                <div
                  id="drag_drop"
                  {...getRootProps({
                    className: 'flex flex-col gap-6  items-center w-full'
                  })}
                >
                  <input {...getInputProps()} />
                  <div className="grid place-items-center border-dashed border-2 rounded-2xl  shadow p-6 h-80 w-full">
                    <div className="flex flex-col gap-2 items-center text-center font-semibold text-sm  text-gray-300">
                      <MdUpload className="text-3xl" />
                      <p className=" tracking-wider">Upload</p>
                      <p className="text-sm">OR</p>
                      <p className=" tracking-wider">Drag n Drop Your Resume</p>
                      <div className="flex gap-4 items-center text-3xl text-gray-500 ">
                        <AiFillFileWord /> <p className="text-sm">or</p>{' '}
                        <MdPictureAsPdf />
                      </div>
                    </div>
                    {isDragActive && !isDragReject && "Drop it like it's hot!"}
                    {acceptedFiles[0] && acceptedFiles[0].size > maxSize && (
                      <p className="text-red-500 mt-2">
                        File is too large. Compress and Try Again . Max File
                        Size 2MB
                      </p>
                    )}
                    <IconButton
                      toolTipTitle={'Upload Resume'}
                      text={'Upload Resume'}
                      icon={<MdUpload />}
                      onClick={open}
                      type="primary"
                    />
                  </div>
                </div>
                {acceptedFiles.length > 0 && (
                  <aside className="w-full flex gap-4 items-center text-sm ">
                    <h4>File</h4>
                    <ul>
                      {acceptedFiles.map((file) => (
                        <li key={file.path}>
                          {file.path} - {(file.size / (1024 * 1024)).toFixed(2)}{' '}
                          Mb
                        </li>
                      ))}
                    </ul>
                  </aside>
                )}
              </div>
            </div>

            <div className="flex flex-col gap-4  bg-white h-full w-full text-sm p-3 flex-1">
              <label className="flex flex-col gap-4  ">
                <p className="font-bold">Enter your Email</p>
                <input
                  type="email"
                  value={jobData.email}
                  onChange={(e) =>
                    setJobData({ ...jobData, email: e.target.value })
                  }
                  required
                  placeholder="Enter your email ."
                  className="h-full border-2   rounded-xl bg-white p-3  text-grey-500 outline-none resize-none "
                />
              </label>

              <button
                onClick={() => setShowJobDescription(!showJobDescription)}
                className={` max-w-max  `}
              >
                {showJobDescription ? '- Remove' : '+ Add'} Job Description
              </button>

              {showJobDescription && (
                <label className="flex flex-col gap-4 h-full">
                  <p className="font-bold">Enter Job Description</p>
                  <textarea
                    value={jobData.jobDescription}
                    onChange={(e) =>
                      setJobData({ ...jobData, jobDescription: e.target.value })
                    }
                    required
                    placeholder="Type the Job description for which the candidate is applying ."
                    className="h-full min-h-[200px]   border-2 rounded-2xl bg-white p-3 py-6 text-grey-500 outline-none resize-none "
                  />
                  {jobData.jobDescription.length < 150 && (
                    <p className="text-red-500 font-semibold">
                      {jobData.jobDescription.length} / 150 characters minimum
                    </p>
                  )}
                </label>
              )}
            </div>
          </div>

          <IconButton
            toolTipTitle={'Generate Resume Report'}
            text={'Generate Report'}
            icon={<MdSummarize />}
            onClick={() => generateSummary()}
            disabled={
              (showJobDescription && jobData.jobDescription.length < 150) ||
              jobData.email === '' ||
              fileName === ''
            }
          />
        </div>
      ) : (
        <div className="w-full max-w-4xl mx-auto p-10 rounded-2xl bg-blue-50 shadow-lg flex flex-col   ">
          <h1 className="text-3xl font-semibold text-center ">Cover Letter</h1>

          <p className="-mt-10 cover-letter-paragraph   ">
            {ReactHtmlParser(summary)}
          </p>
        </div>
      )}
    </div>
  )
}

export default ResumeJobPage
