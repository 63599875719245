import React from 'react'
import { useState } from 'react'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import EditUserModal from './EditUserModal'

const SingleSuperAdminUser = ({ data, limit, index, page, fetchAllUsers }) => {
  const {
    credits_available,
    user_id,
    email,
    github_id,
    github_token,
    linked_to,
    name,
    sonar_cloud_token,
    sonar_user_key,
    last_login,
    parentEmail,
    parentName,
    parentCredits,
    status,
    total_credit,
    type
  } = data
  const [showEditUserModal, setShowEditUserModal] = useState(false)
  return (
    <tr className="text-xs xl:text-sm  font-semibold font-600 relative text-gray-600   ">
      <td className="text-center">
        <p className=" p-2 px-4 ">{(page - 1) * limit + index + 1}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{name} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{email} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">
          {last_login ? new Date(last_login).toLocaleString() : ''}{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">
          {type === 'SUPER_ADMIN' ? 'SUPER ADMIN' : type}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{status}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{parentName}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{parentEmail}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{parentCredits}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{total_credit} </p>
      </td>

      <td className="text-center">
        <p className=" p-2 px-4 ">{credits_available} </p>
      </td>
      <td className="text-center">
        <button
          onClick={() => setShowEditUserModal(true)}
          className="bg-blue-500 hover:bg-blue-400 shadow-md text-white rounded-full px-3 py-2 w-16"
        >
          Edit
        </button>
        {showEditUserModal && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-4xl p-4 m-auto z-50">
              <ClickWrapper func={() => setShowEditUserModal(false)}>
                <EditUserModal
                  refetchData={fetchAllUsers}
                  close={() => setShowEditUserModal(false)}
                  id={user_id}
                  userData={data}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SingleSuperAdminUser
