export const collegeLeaderboardHeader = [
  {
    title: 'Rank.',
    width: '10%'
  },
  {
    title: 'College Name',
    width: '10%'
  },

  {
    title: 'Technical Skills',
    width: '10%'
  },

  {
    title: 'Total Students',
    width: '10%'
  },
  {
    title: 'Average Rating',
    width: '10%'
  }
]
