import React from 'react'
import { useState } from 'react'
import ApplicantJobListingWrapper from './ApplicantJobListingWrapper'

const ApplicantJobListing = ({ allJobData, fetchAllJobs }) => {
  const [tab, setTab] = useState(1)
  return (
    <div className="">
      <div className="flex w-full max-w-7xl mx-auto gap-6 items-center text xs  sm:text-sm mb-10 ">
        <button
          className={` p-3 grid w-40 place-items-center ${
            tab === 1 &&
            'bg-blue-50 shadow-md  rounded-full border  font-semibold  '
          } `}
          onClick={() => setTab(1)}
        >
          Open Job
        </button>
        <button
          className={` p-3 grid w-40 place-items-center ${
            tab === 2 &&
            'bg-orange-50  shadow-md rounded-full border font-semibold  '
          }  `}
          onClick={() => setTab(2)}
        >
          Closed Job
        </button>
      </div>
      {tab === 1 && (
        <ApplicantJobListingWrapper
          allJobData={allJobData.filter((job) => job.job_status === 'OPEN')}
          fetchAllJobs={fetchAllJobs}
        />
      )}
      {tab === 2 && (
        <ApplicantJobListingWrapper
          allJobData={allJobData.filter((job) => job.job_status === 'CLOSE')}
          fetchAllJobs={fetchAllJobs}
        />
      )}
    </div>
  )
}

export default ApplicantJobListing
