import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { BsGearWideConnected } from 'react-icons/bs'
import { FaHandshake } from 'react-icons/fa'
import { GiSandsOfTime } from 'react-icons/gi'
import { GrAchievement } from 'react-icons/gr'
import { HiOutlineSpeakerphone } from 'react-icons/hi'
import { RiUserSearchLine } from 'react-icons/ri'
import { SiWeightsandbiases } from 'react-icons/si'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import RecruiterHeader from '../../../assets/img/recruiter-header.png'
import { SIGNUP_PAGE } from '../../../navigation/routes'
import ClickWrapper from '../../components/click-wrapper/ClickWrapper'
import { companyLogos } from '../../components/home/data'
import FaqAccordion from '../../components/homepage/FaqAccordion'
import VideoModal from '../../components/homepage/VideoModal'
import { settings } from '../home/HomePage'

const recruiterCardData = [
  {
    subHeading: 'Find Qualified Developers',
    text: 'Pedigree is a poor proxy for skills. Broaden your candidate pool and qualify individuals based on their programming skills.',
    icon: <GrAchievement className="text-5xl text-sky-900" />
  },

  {
    subHeading: 'Reduce Hiring Bias',
    text: 'Screening based on resumes invites unconscious biases, whether it’s about gender, race, or pedigree. Build diverse teams with objective programming skills screens.',
    icon: <SiWeightsandbiases className="text-5xl text-white" />
  },
  {
    subHeading: 'Save Engineering Time Spent on Recruiting',
    text: 'Reduce hundreds of hours of engineering and recruiting time by streamlining the technical screening. TechProfile do the technical screening.',
    icon: <GiSandsOfTime className="text-5xl text-sky-900" />
  }
]

const FaqArr = [
  {
    question: 'What is TechProfile.org?',
    answer:
      "TechProfile.org is an online platform that connects companies with qualified tech professionals. We use proprietary algorithms and industry benchmarked machine learning models to assess candidates' resumes and public code from GitHub to provide a comprehensive data set for hiring managers to make informed decisions."
  },
  {
    question: 'How does TechProfile.org work?',
    answer:
      'Companies can sign up for TechProfile.org and post their job openings. Our algorithms analyze the job requirements and match them with the most qualified candidates in our database. Companies can also use our job posting feature to reach a broader audience of tech professionals.'
  },
  {
    question: 'What kind of tech professionals does TechProfile.org work with?',
    answer:
      'TechProfile.org works with a wide range of tech professionals, including software engineers, web developers, data scientists, product managers, and more.'
  },
  {
    question: 'How does TechProfile.org assess candidates?',
    answer:
      "TechProfile.org uses a data-driven approach to candidate assessment, utilizing proprietary algorithms and industry benchmarked machine learning models to score each candidate. We analyze each applicant's resume and public code from GitHub to provide a comprehensive data set for hiring managers to make informed decisions. We also use static code analysis to provide deep insights into each candidate's coding abilities."
  },
  {
    question:
      'Can companies communicate with candidates directly through TechProfile.org?',
    answer:
      'Yes, TechProfile.org provides a direct communication channel between companies and candidates, allowing for streamlined collaboration on the recruitment process. Companies can use the platform to send messages, schedule interviews, and collaborate with their team members on the recruitment process.			'
  },
  {
    question: 'Is TechProfile.org free to use for companies?',
    answer:
      'No, TechProfile.org offers subscription plans for companies based on their hiring needs. However, companies can sign up for a free trial to test the platform before committing to a subscription.'
  },
  {
    question:
      "What happens if I don't find the right candidate on TechProfile.org?",
    answer:
      "If you don't find the right candidate on TechProfile.org, we offer a satisfaction guarantee. If you are not satisfied with the quality of candidates on our platform, we will work with you to find a solution that meets your needs."
  },
  {
    question:
      "Is my company's information and candidate data secure on TechProfile.org?",
    answer:
      "Yes, we take the security and privacy of our users' data very seriously. We use industry-standard encryption and security protocols to protect our platform and ensure that your data is secure."
  }
]

const recruiterSliderSetting = {
  ...settings,
  slidesToShow: 3,
  responsive: [
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1
      }
    }
  ]
}

const SingleCard = ({ subHeading, text, idx, icon }) => {
  return (
    <div className="flex flex-col justify-end">
      <div
        className={`shadow-xl ${
          idx === 1
            ? 'bg-sky-600 text-white min-h-[350px] '
            : 'bg-white min-h-[300px] '
        }  border flex flex-col gap-5 p-4 sm:px-6 md:px-4 lg:px-6 py-10 rounded-2xl items-center text-center`}
      >
        {icon}
        <h2 className="text-xl font-semibold">{subHeading}</h2>

        <p>{text}</p>
      </div>
    </div>
  )
}

const RecruiterPage = () => {
  const [showVideoModal, setShowVideoModal] = useState(false)
  return (
    <div className="relative w-screen min-h-screen overflow-hidden ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Hire Developers - Automate sourcing and assessment</title>
      </Helmet>

      <div className="bg-sky-600 pb-20 w-[150vw] rotate-[8deg] ">
        <div className="bg-sky-700 pb-20 w-[150vw] -rotate-[3deg] ">
          <div className="flex -rotate-[5deg] -translate-x-4 justify-between items-center w-screen p-4 sm:p-10 md:px-20 min-h-[95vh] text-white bg-gradient-to-t from-sky-900 to-sky-800    ">
            <div className="justify-center p-4 ">
              <div className="flex flex-col max-w-2xl gap-4 md:gap-8">
                <h1 className="text-4xl font-bold leading-10 md:text-5xl ">
                  <span className="text-blue-500">Source ,</span>Qualify and
                  Evaluate <span className="text-orange-500">Developers</span>
                </h1>
                <h1 className="text-3xl font-medium md:text-4xl">
                  Built For Developers !
                </h1>

                <h1 className="text-2xl md:text-3xl ">
                  Data Driven Tech Talent Sourcing Platform
                </h1>

                <div className="flex flex-col gap-3 mt-4 mb-10 ">
                  <p className="text-sm font-normal md:text-xl">
                    Publish job, source candidates, data driven evaluation and
                    recommendation, send assignment and auto code quality
                    evaluation.
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3 md:flex-row ">
                <div className="w-full min-w-max max-w-[350px] grid place-items-center border-2 border-white  rounded-full  overflow-hidden">
                  <Link
                    to={SIGNUP_PAGE}
                    className="flex items-center w-full px-2 py-3 text-sm text-white uppercase transition-all duration-300 ease-in-out bg-transparent outline-none hover:text-sky-900 hover:bg-white font-500 "
                  >
                    <p className="flex-1 text-base font-semibold text-center uppercase ">
                      Sign Up
                    </p>
                  </Link>
                </div>
                <div className="max-w-[200px] grid place-items-center   rounded-full  overflow-hidden">
                  <button
                    onClick={() => setShowVideoModal(true)}
                    className="flex items-center w-full text-sm text-white uppercase transition-all duration-300 ease-in-out bg-blue-400 outline-none sm:px-8 hover:bg-blue-500 font-500 "
                  >
                    <p className="flex-1 px-2 py-3 text-base font-semibold text-center uppercase ">
                      Learn More
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex w-1/2 max-w-[700px] justify-end flex-1  ">
              <img
                src={RecruiterHeader}
                alt="tech profile"
                className="object-contain w-full "
              />
            </div>
            {showVideoModal && (
              <div className="fixed top-0 left-0 right-0 z-40 grid w-screen h-screen backdrop-filter backdrop-blur-sm place-items-center">
                <div className="z-50 ">
                  <ClickWrapper func={() => setShowVideoModal(false)}>
                    <VideoModal
                      videoUrl={'https://youtu.be/ZOUMIk6lpSU'}
                      close={() => setShowVideoModal(false)}
                    />
                  </ClickWrapper>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-6 my-10 md:px-10">
        <div className="relative flex flex-col items-center w-full gap-20 py-10 m-auto max-w-7xl">
          <h1 className="text-4xl font-bold leading-relaxed text-center md:text-5xl ">
            <span className="text-blue-500">Revolutionize</span> Your
            <span className="text-orange-500">Tech Hiring</span>
          </h1>

          <div className="grid grid-cols-1 gap-6 pb-20 md:grid-cols-3">
            {recruiterCardData.map((data, idx) => (
              <SingleCard key={idx} idx={idx} {...data} />
            ))}
          </div>
        </div>
        <div className="px-6 my-10 md:px-10">
          <div className="relative flex flex-col items-center w-full max-w-5xl gap-20 py-10 m-auto">
            <h1 className="text-3xl font-bold leading-relaxed text-center md:text-4xl ">
              <span className="text-blue-500">Top</span>{' '}
              <span className="text-orange-500">Companies</span> with us
            </h1>

            <div className="w-full">
              <Slider {...recruiterSliderSetting}>
                {companyLogos.map((item, index) => (
                  <div className="grid px-4 place-items-center" key={index}>
                    <img className="object-contain w-full " src={item} alt="" />
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-sky-600 py-20 mt-40 w-[200vw] -translate-x-1/4 rotate-[8deg] ">
        <div className="bg-sky-700 py-20 w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg] -translate-x-1 text-white w-screen flex bg-gradient-to-l from-sky-900 to-sky-800 flex-col gap-10 md:gap-20 p-10 lg:p-20 items-center">
            <div className="flex flex-col-reverse items-center gap-10 text-center md:flex-row md:text-left ">
              <div className="flex flex-col gap-10 sm:w-[80%] md:w-1/2 text-lg md:text-xl">
                <h1 className="text-4xl font-bold leading-snug md:text-5xl ">
                  What we do at{' '}
                  <span className="text-orange-500">Techprofile</span>
                </h1>
                <p className="font-medium leading-loose text-left ">
                  We believe that a developer's skills should be constantly
                  assessed in order to identify weaknesses and areas that need
                  improvement. We also believe that this process should be as
                  efficient and painless as possible. That's why we've developed
                  a real-time skills assessment software that does all the heavy
                  lifting for you. All you need is the GitHub profile of the
                  developer you want to assess. Our software will then go
                  through their public code and analyze their skills through
                  many software development metrics.
                </p>
                <div className="grid overflow-hidden border-2 border-white rounded-full sm:w-80 place-items-center">
                  <Link
                    to={SIGNUP_PAGE}
                    className="flex items-center w-full px-2 py-3 text-sm text-white uppercase transition-all duration-300 ease-in-out bg-transparent outline-none hover:text-sky-900 hover:bg-white font-500 "
                  >
                    <p className="flex-1 text-base font-semibold text-center uppercase ">
                      Get Started
                    </p>
                  </Link>
                </div>
              </div>
              <div className="flex-1 hidden lg:flex">
                <lottie-player
                  src="https://assets10.lottiefiles.com/packages/lf20_h5wdcgrm.json"
                  background="transparent"
                  speed="1"
                  style={{
                    width: '70%',
                    height: '70%',
                    objectFit: 'contain',
                    margin: 'auto'
                  }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 md:px-10">
        <div className="w-full py-20 m-auto max-w-7xl ">
          <div className="relative grid grid-cols-1 gap-10 md:grid-cols-2 py-28">
            <div className="flex flex-col items-center gap-4 p-10 px-10 text-center text-white shadow-xl sm:px-20 md:px-10 lg:px-20 bg-gradient-to-b from-sky-800 to-sky-600 rounded-2xl ">
              <HiOutlineSpeakerphone className="text-6xl text-white" />

              <h1 className="text-3xl font-semibold ">
                Post Job Opportunities
              </h1>

              <p className="text-sm md:text-base">
                If you're looking for a broader pool of candidates, you can also
                post job opportunities on TechProfile. You can create a job
                posting that includes all the necessary details about the
                position, including the job description, required skills, and
                compensation package. Your job posting will be visible to all
                the tech professionals on the platform who meet your criteria.
              </p>
            </div>
            <div className="flex flex-col items-center gap-4 p-10 px-10 text-center border shadow-xl sm:px-20 md:px-10 lg:px-20 bg-gradient-to-t from-blue-100 to-white text-sky-800 rounded-2xl ">
              <FaHandshake className="text-6xl text-sky-800" />

              <h1 className="text-3xl font-semibold ">
                Manage your candidates
              </h1>

              <p className="text-sm md:text-base">
                As you start receiving applications and messages from
                candidates, you can use TechProfile to manage your recruitment
                process. You can track the status of each candidate, send them
                messages, and schedule interviews. You can also collaborate with
                your team members to evaluate candidates and make hiring
                decisions.
              </p>
            </div>
            <div className="flex flex-col items-center gap-4 p-10 px-10 text-center border shadow-xl sm:px-20 md:px-10 lg:px-20 bg-gradient-to-t from-blue-100 to-white text-sky-800 rounded-2xl ">
              <RiUserSearchLine className="text-6xl text-sky-800" />

              <h1 className="text-3xl font-semibold ">Search for talent</h1>

              <p className="text-sm md:text-base">
                Once your profile is complete, you can start searching for
                talent on TechProfile. You can filter candidates based on their
                skills, experience, location, and other relevant criteria. You
                can also browse through their profiles to get a sense of their
                expertise and accomplishments.
              </p>
            </div>
            <div className="flex flex-col items-center gap-4 p-10 px-10 text-center text-white shadow-xl sm:px-20 md:px-10 lg:px-20 bg-gradient-to-b from-sky-800 to-sky-600 rounded-2xl ">
              <BsGearWideConnected className="text-6xl text-white" />

              <h1 className="text-3xl font-semibold ">
                Connect with candidates
              </h1>

              <p className="text-sm md:text-base">
                If you find a candidate that you're interested in, you can
                connect with them directly through the platform. You can send
                them a message to introduce yourself and discuss the job
                opportunity in more detail. You can also invite them to apply
                for your job posting directly through the platform.
              </p>
            </div>
            <div className="h-[2px] absolute inset-x-0 top-full bg-gradient-to-r from-blue-500 to-orange-500  "></div>
          </div>

          <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-4 py-28">
            <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
              <h1 className="text-4xl ">65 %</h1>
              <h3 className="text-2xl">Effort saved in hiring</h3>
            </div>
            <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-sky-600 text-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
              <h1 className="text-4xl ">80 %</h1>
              <h3 className="text-2xl">Time reduced to hire candidates</h3>
            </div>
            <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
              <h1 className="text-4xl ">6319</h1>
              <h3 className="text-2xl">Total resumes generated</h3>
            </div>
            <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-sky-600 text-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
              <h1 className="text-4xl ">1525 </h1>
              <h3 className="text-2xl">Total candidates selected</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-sky-600 py-20 mt-10 w-[200vw] -translate-x-1/4 translate-y-2 rotate-[8deg] ">
        <div className="bg-sky-700 py-20 w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg] -translate-x-1 text-white w-screen flex bg-gradient-to-b from-sky-900 to-sky-700 flex-col  py-20 items-center">
            <div className="relative flex flex-col gap-16 py-20 lg:flex-row ">
              <div className="flex flex-col flex-1 gap-10">
                <div className="flex items-end w-full gap-4">
                  <h2 className="w-20 text-sm font-bold text-right  md:text-2xl md:w-40">
                    Traditional Hiring
                  </h2>
                  <div className="flex-1 w-full p-16 shadow-lg  bg-gradient-to-r from-orange-100 to-orange-500 rounded-r-xl"></div>
                </div>
                <div className="flex items-end w-full gap-4">
                  <h2 className="w-20 text-sm font-bold text-right  md:text-2xl md:w-40">
                    TechProfile Hiring
                  </h2>
                  <div className="flex-1 p-16 bg-gradient-to-r from-blue-100 to-blue-500 shadow-lg rounded-r-xl max-w-[35%]  "></div>
                  <h2 className="w-20 text-sm font-bold text-right  md:text-2xl md:w-60">
                    Techprofile reduces time to hire by 65%
                  </h2>
                </div>
              </div>
              <div className="flex flex-col flex-1 max-w-xl gap-10 px-6 ">
                <h1 className="text-3xl font-bold text-left md:text-4xl sm:leading-relaxed ">
                  <span className="text-blue-500">Time required</span> for
                  hiring reduced by over{' '}
                  <span className="text-orange-500">65%</span>
                </h1>
                <p className="font-medium leading-loose text-left ">
                  Traditional hiring processes need a lot of time dedication.
                  Techprofile reduces the time required for hiring by over 60%
                  as compared to regular hiring processes
                </p>
              </div>
              <div className="h-[2px] absolute inset-x-0 top-full bg-gradient-to-r from-blue-300 to-orange-300  "></div>
            </div>
            <div className="flex flex-col items-center gap-16 py-20 lg:flex-row ">
              <div className="flex flex-col flex-1 max-w-xl gap-10 px-6 ">
                <h1 className="text-3xl font-bold text-left md:text-4xl sm:leading-relaxed ">
                  <span className="text-blue-500">Efforts required</span> for
                  hiring reduced by <span className="text-orange-500">80%</span>
                </h1>
                <p className="font-medium leading-loose text-left ">
                  Techprofile reduces the hiring efforts by over 80% as compared
                  to traditional hiring processes which are both time-intensive
                  and task-intensive
                </p>
              </div>
              <div className="flex items-end justify-center flex-1 gap-10">
                <div className="flex flex-col items-center gap-4">
                  <div className=" p-16 bg-gradient-to-t from-orange-100 to-orange-500 shadow-lg rounded-t-xl h-[350px]  "></div>
                  <h2 className="w-20 text-sm font-bold text-center  md:text-2xl md:w-32">
                    Traditional Hiring
                  </h2>
                </div>
                <div className="flex flex-col items-center gap-4">
                  <h2 className="w-20 text-sm font-bold text-center  md:text-2xl md:w-32">
                    Techprofile reduces hiring effort by 80%
                  </h2>
                  <div className="flex-1 p-16 shadow-lg bg-gradient-to-t from-blue-100 to-blue-500 rounded-t-xl "></div>
                  <h2 className="w-20 text-sm font-bold text-center  md:text-2xl md:w-32">
                    TechProfile Hiring
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 my-10 md:px-10">
        <div className="relative flex flex-col items-center w-full gap-20 py-20 m-auto max-w-7xl">
          <h1 className="text-3xl font-bold leading-relaxed text-center md:text-4xl ">
            <span className="text-blue-500">Frequently</span>
            <span className="ml-1 text-orange-500">Asked</span> Questions
          </h1>
          <div>
            <FaqAccordion data={FaqArr} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecruiterPage
