import React from 'react'

const ErrorComponent = () => {
  return (
    <div role="alert" className="p-2 sm:px-6 md:px-10 text-center">
      <p>Something went wrong :(</p>
    </div>
  )
}

export default ErrorComponent
