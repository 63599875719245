import { makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import ForgetPassword from './ForgetPassword'
import clsx from 'clsx'
import SignIn from './SignIn'
import SignUp from './SignUp'

const useStyles = makeStyles((theme) => ({
  hiddenScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}))

const AuthComponent = ({ closeTab }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const classes = useStyles()
  function handleTabChange(value) {
    setSelectedTab(value)
  }
  return (
    <div
      className={clsx(
        classes.hiddenScrollBar,
        'w-full max-w-xl sm:min-w-[400px] h-full scrollbar-hidden '
      )}
    >
      <div className=" w-full pt-20">
        {selectedTab === 0 && (
          <SignIn closeTab={closeTab} toggleTab={handleTabChange} />
        )}
        {selectedTab === 1 && <ForgetPassword toggleTab={handleTabChange} />}
      </div>
    </div>
  )
}

export default AuthComponent
