import React from 'react'
import { useState } from 'react'
import axiosInstance from '../../../services/axios/axios'
import { useEffect } from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io'

const JobTemplateModal = ({ close, jobID, currentTab = 1 }) => {
  const [tab, setTab] = useState(currentTab)
  const [jobTemplateData, setJobtemplateData] = useState([])
  const [loading, setLoading] = useState(true)

  const fetchJobTemplatedetails = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Stage/jobExtraDetails/get?job_id=${jobID}`
      )
      setJobtemplateData(data[0])
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchJobTemplatedetails()
  }, [])
  return (
    <div className="w-full h-[600px] bg-white relative rounded-2xl shadow-lg m-auto p-4 md:p-10 lg:p-12 flex flex-col gap-4 ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-3xl font-bold text-blue-500"
      >
        <IoMdCloseCircleOutline />
      </button>
      <div className="flex w-full max-w-xs justify-between items-center text-sm pt-10 md:pt-0 ">
        <button
          className={`flex-1 p-3 rounded-full grid place-items-center ${
            tab === 1 && 'bg-blue-200  shadow-md  '
          } `}
          onClick={() => setTab(1)}
        >
          Interview Questions
        </button>
        <button
          className={`flex-1 p-3 rounded-full grid place-items-center ${
            tab === 2 && 'bg-orange-100 shadow-md  '
          }  `}
          onClick={() => setTab(2)}
        >
          Email Template
        </button>
      </div>
      <div className="">
        {tab === 1 && (
          <div className="w-full  flex flex-col gap-4 ">
            <h2 className="text-xl text-center font-semibold mb-2">
              Interview Questions
            </h2>

            <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>
            <div className="h-[370px] overflow-y-scroll">
              {jobTemplateData?.interview_quesitons ? (
                <p>
                  {jobTemplateData?.interview_quesitons
                    .split(/\n/)
                    .map((line) => (
                      <React.Fragment key={line}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              ) : (
                <p className="text-center">
                  No resources found . This might take time to process . please
                  bare with us.
                </p>
              )}
            </div>
          </div>
        )}

        {tab === 2 && (
          <div className=" w-full  flex flex-col gap-4">
            <h2 className="text-xl text-center font-semibold mb-2">
              Email Template
            </h2>

            <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>

            <div className="h-[370px] overflow-y-scroll">
              {jobTemplateData?.candidate_cold_email ? (
                <p>
                  {jobTemplateData?.candidate_cold_email
                    .split(/\n/)
                    .map((line) => (
                      <React.Fragment key={line}>
                        {line}
                        <br />
                      </React.Fragment>
                    ))}
                </p>
              ) : (
                <p className="text-center">
                  No resources found . This might take time to process . please
                  bare with us.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default JobTemplateModal
