import { toast } from 'react-toastify'

export const showToast = (type, message = '') => {
  switch (type.toLowerCase()) {
    case 'success':
      toast.success(message)
      break
    case 'error':
      toast.error(message)
      break
    case 'info':
      toast.info(message)
      break
    case 'warning':
      toast.warning(message)
      break
    default:
      toast.default(message)
  }
}
