import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { BiImageAdd } from 'react-icons/bi'
import { MdClose } from 'react-icons/md'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import EmailInput from '../create-profile/email-input/EmailInput'
import MultiSelect from '../create-profile/multi-select/MultiSelect'
import NumberInput from '../create-profile/num-input/NumberInput'
import TextInput from '../create-profile/text-input/TextInput'
import { leaderboardCountries } from '../leaderboard/tableData'
import RippleLoader from '../loader/RippleLoader'
import { jobCategoryData } from './data'

const EditJobModal = ({ jobData, refetchData, close, jobId, id }) => {
  const [imageUploading, setImageUploading] = useState(false)
  const [imageFile, setImageFile] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const [previewImage, setPreviewImage] = useState(
    jobData.company_logo ? jobData.company_logo : ''
  )

  const { getAllSkills } = EndPoints.createProfile

  const [fileName, setFileName] = useState(
    jobData.company_logo
      ? jobData.company_logo.split('/')[
          jobData.company_logo.split('/').length - 1
        ]
      : ''
  )
  const [allSkills, setAllSkills] = useState([])
  const { user } = useSelector((state) => state.auth)

  const { editJob, uploadJobLogo } = EndPoints.createJob
  const [profileValue, setProfileValue] = useState({
    user_id: user.user_id,
    job_name: jobData.job_name ? jobData.job_name : '',
    key_skills:
      jobData.key_skills && jobData.key_skills.length > 0
        ? jobData.key_skills
            .split(',')
            .map((skill) => ({ language_name: skill, language_logo: '' }))
        : [],
    status: jobData.status ? jobData.status : '',
    organization: jobData.organization ? jobData.organization : '',
    job_email: jobData.job_email ? jobData.job_email : '',
    years_of_experience: jobData.years_of_experience
      ? jobData.years_of_experience
      : '',
    description: jobData.description ? jobData.description : '',
    job_type: jobData.job_type ? jobData.job_type : '',
    weekly_hours: jobData.weekly_hours ? jobData.weekly_hours : '',
    work_hours: jobData.work_hours ? jobData.work_hours : '',
    pay_range: jobData.pay_range ? jobData.pay_range : '',
    country: jobData.country ? jobData.country : '',
    job_category: jobData.job_category ? jobData.job_category : '',
    management_skills_weight: jobData.management_skills_weight ?? 20,
    soft_skills_weight: jobData.soft_skills_weight ?? 30,
    technical_skills_weight: jobData.technical_skills_weight ?? 50
  })

  const fetchAllSkills = async () => {
    try {
      const { data } = await axiosInstance.get(`${getAllSkills}`)
      //console.log(data);
      setAllSkills(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }

  const uploadImage = async () => {
    console.log(imageFile, fileName)
    if (!imageFile) return

    setImageUploading(true)
    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': imageFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'githubcompanieslogo'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      await axiosInstance.put(uploadUrl, imageFile, options)

      setImageFile(undefined)
    } catch (err) {
      console.log(err.message)
    }
    setImageUploading(false)
  }

  const fileChange = (e) => {
    if (!e.target.files[0]) {
      alert('please Select a valid file')
      return
    }
    const newFile = e.target.files[0]
    setImageFile(newFile)
    if (fileName === '') {
      setFileName(`${user.name ?? ''}-${uuid()}`)
    }
  }

  const handleEditJob = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const putData = {
      ...profileValue,
      logo_name: fileName,
      id,
      key_skills: profileValue.key_skills
        .map((skills) => skills.language_name)
        .join(',')
    }
    console.log(putData)
    try {
      await uploadImage()
      const { data } = await axiosInstance.put(
        `${editJob}/updatejobdetails`,
        putData
      )
      console.log(data)
      showToast('success', JSON.parse(data.message).message)

      //navigate(`${JOB_LISTING_PAGE}`);
      await refetchData()
      setIsLoading(false)
      close()
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }

  useEffect(() => {
    if (!imageFile) {
      return
    }
    const objectUrl = URL.createObjectURL(imageFile)
    setPreviewImage(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imageFile])

  useEffect(() => {
    fetchAllSkills()
  }, [])

  return (
    <div className="p-3 sm:p-6 md:p-10 w-full  m-auto h-[95vh] bg-white overflow-y-scroll relative ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-xl font-bold"
      >
        <MdClose />
      </button>

      <Fragment>
        {isLoading && (
          <div className="bg-opacity-20 bg-white fixed inset-0 grid place-items-center">
            <RippleLoader />
          </div>
        )}

        <form onSubmit={handleEditJob}>
          <div className="w-full max-w-3xl m-auto flex flex-col gap-6 md:gap-10">
            <div className="flex flex-col gap-4">
              <h1 className="text-2xl md:text-3xl font-semibold  border-b-2 border-gray-200 pb-4 ">
                Edit Job
              </h1>
            </div>
            <div className="flex flex-col gap-6 ">
              <div className="w-full flex flex-col gap-4 justify-center items-center">
                {imageFile !== null && previewImage != null ? (
                  <img
                    className="w-20 h-20 object-contain"
                    src={`${previewImage}?rand=${new Date()}`}
                    alt={''}
                  />
                ) : (
                  <img
                    className="w-20 h-20 object-contain"
                    src={
                      'https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg'
                    }
                    alt={''}
                  />
                )}
              </div>

              {!imageUploading && (
                <div className="flex flex-col gap-2 items-center">
                  <label className="grid place-items-center text-2xl cursor-pointer">
                    <BiImageAdd />
                    <p className="text-xs">
                      {jobData?.company_logo ? 'Update' : 'Upload'} Company Logo
                    </p>
                    <input
                      className="hidden"
                      type="file"
                      onChange={fileChange}
                      accept="image/*"
                    />
                  </label>
                </div>
              )}
            </div>

            <div className="flex flex-col  sm:grid sm:grid-cols-2 gap-6  ">
              <TextInput
                value={profileValue.job_name}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, job_name: val })
                }
                label={'Title'}
                placeholder={'Enter Job Title'}
              />
              <TextInput
                value={profileValue.organization}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, organization: val })
                }
                label={'Organization'}
                placeholder={'Enter Organization name'}
              />
              <NumberInput
                value={profileValue.years_of_experience}
                setValue={(val) =>
                  setProfileValue({
                    ...profileValue,
                    years_of_experience: val
                  })
                }
                label={'Years of Experience'}
                placeholder={'Years of Experience'}
              />

              <EmailInput
                value={profileValue.job_email}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, job_email: val })
                }
                label={'Job Email'}
                placeholder={'Enter Job Email'}
              />
              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Country</p>
                <Select
                  className="basic-single  bg-transparent text-sm"
                  onChange={(val) =>
                    setProfileValue({ ...profileValue, country: val.value })
                  }
                  value={{
                    value: profileValue.country,
                    label: profileValue.country
                  }}
                  classNamePrefix="Country"
                  defaultValue={
                    leaderboardCountries.map((country) => ({
                      value: country,
                      label: country
                    }))[0]
                  }
                  isClearable={true}
                  isSearchable={true}
                  name="country"
                  placeholder="Select Job Country"
                  styles={reactSelectStyleUtils}
                  options={leaderboardCountries.map((country) => ({
                    value: country,
                    label: country
                  }))}
                />
              </label>
              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Job Category</p>
                <Select
                  className="basic-single  bg-transparent text-sm"
                  onChange={(val) =>
                    setProfileValue({
                      ...profileValue,
                      job_category: val.value
                    })
                  }
                  value={{
                    value: profileValue.job_category,
                    label: profileValue.job_category
                  }}
                  classNamePrefix="Category"
                  defaultValue={
                    jobCategoryData.map((category) => ({
                      value: category,
                      label: category
                    }))[0]
                  }
                  isClearable={true}
                  isSearchable={true}
                  name="jobCategory"
                  placeholder="Select Job Category"
                  styles={reactSelectStyleUtils}
                  options={jobCategoryData.map((category) => ({
                    value: category,
                    label: category
                  }))}
                />
              </label>
              <TextInput
                value={profileValue.pay_range}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, pay_range: val })
                }
                label={'Pay Range'}
                placeholder={'Enter Pay Range'}
              />
              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Status</p>
                <select
                  value={profileValue.status}
                  onChange={(e) =>
                    setProfileValue({
                      ...profileValue,
                      status: e.target.value
                    })
                  }
                  className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'OPEN'}>
                    Open
                  </option>
                  <option className="" value={'CLOSE'}>
                    Closed
                  </option>
                </select>
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Job Type</p>
                <select
                  value={profileValue.job_type}
                  onChange={(e) =>
                    setProfileValue({
                      ...profileValue,
                      job_type: e.target.value
                    })
                  }
                  className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'permanent'}>
                    Permanent
                  </option>
                  <option className="" value={'contract'}>
                    Contract
                  </option>
                  <option className="" value={'intern'}>
                    Intern
                  </option>
                </select>
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Weekly Hours</p>
                <select
                  value={profileValue.weekly_hours}
                  onChange={(e) =>
                    setProfileValue({
                      ...profileValue,
                      weekly_hours: e.target.value
                    })
                  }
                  className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'10'}>
                    10 hrs
                  </option>
                  <option className="" value={'20'}>
                    20 hrs
                  </option>
                  <option className="" value={'40'}>
                    40 hrs
                  </option>
                </select>
              </label>
              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Working Hours</p>
                <select
                  value={profileValue.work_hours}
                  onChange={(e) =>
                    setProfileValue({
                      ...profileValue,
                      work_hours: e.target.value
                    })
                  }
                  className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'fixed'}>
                    Fixed
                  </option>
                  <option className="" value={'flexible'}>
                    Flexible
                  </option>
                </select>
              </label>

              <MultiSelect
                label={'Key Skills'}
                placeholder={'Add Skill'}
                value={profileValue.key_skills}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, key_skills: val })
                }
                keySkills={allSkills}
              />

              <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 ">
                <p className=" font-semibold  ">Description</p>
                <div className="bg-white h-40">
                  <ReactQuill
                    className=" h-32 "
                    placeholder="Enter Job Description"
                    value={profileValue.description}
                    onChange={(value) => {
                      setProfileValue({
                        ...profileValue,
                        description: value
                      })
                    }}
                  />
                </div>
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 ">
                <p className=" font-semibold  ">Description Preview</p>
                <div className="bg-white border-2 min-h-[10rem] outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 ">
                  {ReactHtmlParser(profileValue.description.toString())}
                  {/* {profileValue.description} */}
                </div>
              </label>
            </div>

            <div className=" md:w-1/2  flex flex-col justify-between gap-3">
              <SubmitButton text={'Update'} />
            </div>
          </div>
        </form>
      </Fragment>
    </div>
  )
}

export default EditJobModal
