import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import LeaderboardTable from '../../components/leaderboard/LeaderboardTable'
import ResponsiveLeaderboardTable from '../../components/leaderboard/ResponsiveLeaderboardTable'
import {
  leaderboardCountries,
  leaderboardTechnologies
} from '../../components/leaderboard/tableData'
import RippleLoader from '../../components/loader/RippleLoader'

const getLeaderboardData = () => {
  const data = sessionStorage.getItem('LEADERBOARD_DATA')
  if (data === null) {
    return null
  }
  return JSON.parse(data)
}

const LeaderboardPage = () => {
  const [loading, setLoading] = useState(true)
  const { getLeaderBoard } = EndPoints.leaderboard
  const [leaderboardData, setLeaderboardData] = useState([])
  const [page, setPage] = useState(
    getLeaderboardData()?.page ? getLeaderboardData()?.page : 1
  )
  const [limit, setLimit] = useState(
    getLeaderboardData()?.limit ? getLeaderboardData()?.limit : 10
  )
  const [totalPage, setTotalPage] = useState(1)
  const [selectedCountry, setSelectedCountry] = useState(
    getLeaderboardData()?.selectedCountry
      ? getLeaderboardData()?.selectedCountry
      : 'ALL'
  )

  const [language, setLanguage] = useState(
    getLeaderboardData()?.language ? getLeaderboardData()?.language : 'ALL'
  )

  const fetchLeaderboard = async () => {
    //setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${getLeaderBoard}/getleaderBoard?page=${page}&limit=${limit}&country=${selectedCountry}&language=${language}&city=${'ALL'}`
      )
      console.log(data)
      setLeaderboardData(data.message)
      setTotalPage(data.total_pages)
      const storedData = {
        page,
        limit,
        selectedCountry,
        language
      }
      sessionStorage.setItem('LEADERBOARD_DATA', JSON.stringify(storedData))
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchLeaderboard()
  }, [page, selectedCountry, language, limit])

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })
  }, [page])
  return (
    <div className="w-full  min-h-screen ">
      {loading ? (
        <RippleLoader />
      ) : (
        <>
          {' '}
          <div className="bg-gradient-to-br from-blue-700 to-blue-300 p-6  md:max-h-[250px]  md:mb-[200px] ">
            <div className="flex flex-col md:px-4 md:flex-row justify-between gap-3 md:items-center font-semibold mb-4">
              <h1 className="text-3xl text-white">Leaderboard</h1>
              <div className="flex  gap-3 md:gap-6 mb-4 md:mb-0 items-center self-end">
                <label className="flex flex-col flex-1 gap-1">
                  <p className="text-sm font-semibold text-white">Language</p>

                  <Select
                    className="basic-single md:w-60 bg-transparent text-sm"
                    onChange={(val) => setLanguage(val.value)}
                    value={{ value: language, label: language }}
                    classNamePrefix="Technology"
                    defaultValue={
                      leaderboardTechnologies.map((technology) => ({
                        value: technology,
                        label: technology
                      }))[0]
                    }
                    isClearable={true}
                    isSearchable={true}
                    name="technology"
                    options={leaderboardTechnologies.map((technology) => ({
                      value: technology,
                      label: technology
                    }))}
                  />
                </label>
                <label className="flex flex-col flex-1 gap-1">
                  <p className="text-sm font-semibold text-white">Country</p>
                  <Select
                    className="basic-single md:w-60 bg-transparent text-sm"
                    onChange={(val) => setSelectedCountry(val.value)}
                    value={{ value: selectedCountry, label: selectedCountry }}
                    classNamePrefix="Country"
                    defaultValue={
                      leaderboardCountries.map((country) => ({
                        value: country,
                        label: country
                      }))[0]
                    }
                    isClearable={true}
                    isSearchable={true}
                    name="country"
                    options={leaderboardCountries.map((country) => ({
                      value: country,
                      label: country
                    }))}
                  />
                </label>
              </div>
            </div>

            <div className="flex gap-4 sm:gap-6 justify-evenly items-end">
              {leaderboardData[1] && (
                <Link
                  to={`/${leaderboardData[1].github_id}`}
                  className="flex flex-col text-center items-center gap-1 sm:gap-3 bg-white p-3 sm:p-4 sm:px-8 rounded-md shadow-md relative"
                >
                  <p className="absolute top-1 right-1 px-2 py-1 rounded-tr-md bg-blue-500 text-white text-sm font-semibold ">
                    {(page - 1) * limit + 2}
                  </p>
                  <img
                    className="w-12 h-12 sm:w-20 sm:h-20 md:w-40 md:h-40 rounded-full object-contain"
                    src={leaderboardData[1].avatar_url}
                    alt={leaderboardData[1].github_id}
                  />
                  <h1 className="text-xs sm:text-base font-semibold">
                    {leaderboardData[1].name}
                  </h1>
                  <p className="text-xs sm:text-sm">
                    TP Rate : {leaderboardData[1].profile_score}
                  </p>
                </Link>
              )}

              {leaderboardData[0] && (
                <Link
                  to={`/${leaderboardData[0].github_id}`}
                  className="flex flex-col text-center items-center gap-1 sm:gap-3 bg-white p-3 sm:p-4 sm:px-8 rounded-md shadow-md relative"
                >
                  <p className="absolute  top-1 right-1 px-2 py-1 rounded-tr-md  bg-blue-500 text-white text-sm font-semibold ">
                    {(page - 1) * limit + 1}
                  </p>
                  <img
                    className="w-20 h-20 sm:w-40 sm:h-40 md:w-60 md:h-60 rounded-full object-contain"
                    src={leaderboardData[0].avatar_url}
                    alt={leaderboardData[0].github_id}
                  />
                  <h1 className="text-xs sm:text-base font-semibold">
                    {leaderboardData[0].name}
                  </h1>
                  <p className="text-xs sm:text-sm">
                    TP Rate : {leaderboardData[0].profile_score}
                  </p>
                </Link>
              )}

              {leaderboardData[2] && (
                <Link
                  to={`/${leaderboardData[2].github_id}`}
                  className="flex flex-col text-center items-center gap-1 sm:gap-3 bg-white p-3 sm:p-4 sm:px-8 rounded-md shadow-md relative"
                >
                  <p className="absolute  top-1 right-1 px-2 py-1 rounded-tr-md  bg-blue-500 text-white text-sm font-semibold ">
                    {(page - 1) * limit + 3}
                  </p>
                  <img
                    className="w-12 h-12 sm:w-20 sm:h-20 md:w-40 md:h-40 rounded-full object-contain"
                    src={leaderboardData[2].avatar_url}
                    alt={leaderboardData[2].github_id}
                  />
                  <h1 className="text-xs sm:text-base font-semibold">
                    {leaderboardData[2].name}
                  </h1>
                  <p className="text-xs sm:text-sm">
                    TP Rate : {leaderboardData[2].profile_score}
                  </p>
                </Link>
              )}
            </div>
          </div>
          <div className="hidden md:block p-4 sm:p-6 md:p-10">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <LeaderboardTable
                data={leaderboardData}
                page={page}
                onPageChange={(page) => setPage(page)}
                count={totalPage}
                limit={limit}
              />
            </ErrorBoundary>
          </div>
          <div className="md:hidden p-3 sm:p-6 md:p-10">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <ResponsiveLeaderboardTable
                data={leaderboardData}
                page={page}
                onPageChange={(page) => setPage(page)}
                count={totalPage}
                limit={limit}
              />
            </ErrorBoundary>
          </div>
        </>
      )}
    </div>
  )
}

export default LeaderboardPage
