import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useParams } from 'react-router-dom'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import YearSelect from '../../select/YearSelect'
import { includeComma } from '../../../../services/global/globalFunction'

const FileChangeChart = ({
  data: fileData,
  isRepoLevel,
  Contributorsdata,
  repoName,
  commitFetching
}) => {
  const { username } = useParams()

  const getSum = (fileData) => {
    if (!fileData) return 0
    let sum = 0
    ;[...Object.values(fileData)].forEach((data) => (sum += parseInt(data)))
    return sum
  }

  const series = [
    {
      name: 'Lines of code changes',
      data: [...Object.values(fileData)].reverse()
    }
  ]

  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#F37726', '#3E8FF5'],
        shadeIntensity: 0.5,
        type: 'horizontal',
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 100, 100, 100]
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      categories: [...Object.keys(fileData)]
        .reverse()
        .map((data) => [...data.split('-')].reverse().join('/'))
    },
    tooltip: {
      x: {
        format: 'dd/MM/yy HH:mm'
      },
      theme: 'dark'
    }
  }
  const [action, setAction] = useState('all')
  const [duration, setDuration] = useState('1 Year')
  const [totalChange, setTotalChange] = useState(getSum(fileData))
  const [contributor, setContributor] = useState(
    Contributorsdata && Contributorsdata.length > 0
      ? Contributorsdata[0]?.github_id
      : ''
  )
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOption, setChartOption] = useState(options)
  const { getFilechangeGraph } = EndPoints.repository

  const updateFileChangeDetails = async (
    duration,
    contributor,
    action,
    repoName
  ) => {
    if (!repoName) return

    try {
      const { data } = await axiosInstance.get(
        `${getFilechangeGraph}/getfilesmodificationgraph?limit=${
          duration === '1 Week'
            ? 7
            : duration === '1 Month'
            ? 30
            : duration === '1 Year'
            ? 365
            : 7
        }&owner=${
          Contributorsdata[0].repo_owner
        }&repo_name=${repoName}&contrinbutor=${contributor}&filter=${
          action === 'all' ? 'ALL' : action
        }`
      )
      const parsedData = JSON.parse(data.body)
      console.log(parsedData)
      setTotalChange(getSum(parsedData))
      setChartSeries([
        {
          name: 'Lines of code changes',
          data: [...Object.values(parsedData)].reverse()
        }
      ])

      setChartOption({
        ...chartOption,
        xaxis: {
          ...chartOption.xaxis,
          categories: [...Object.keys(parsedData)]
            .reverse()
            .map((data) => [...data.split('-')].reverse().join('/'))
        }
      })
    } catch (err) {
      console.log(err.message)
    }
  }

  const updateFileChangeDuration = async (duration, action) => {
    try {
      const { data } = await axiosInstance.get(
        `${getFilechangeGraph}/getfilesmodificationgraph?limit=${
          duration === '1 Week'
            ? 7
            : duration === '1 Month'
            ? 30
            : duration === '1 Year'
            ? 365
            : 7
        }&owner=${username}&filter=${action === 'all' ? 'ALL' : action}`
      )
      const parsedData = JSON.parse(data.body)
      console.log(parsedData)
      setTotalChange(getSum(parsedData))
      setChartSeries([
        {
          name: 'Lines of code changes',
          data: [...Object.values(parsedData)].reverse()
        }
      ])

      setChartOption({
        ...chartOption,
        xaxis: {
          ...chartOption.xaxis,
          categories: [...Object.keys(parsedData)]
            .reverse()
            .map((data) => [...data.split('-')].reverse().join('/'))
        }
      })
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (isRepoLevel) {
      updateFileChangeDetails(duration, contributor, action, repoName)
    } else {
      updateFileChangeDuration(duration, action)
    }
  }, [duration, contributor, action])

  // useEffect(() => {
  // 	if (!isRepoLevel) {
  // 		updateFileChangeDuration(duration, action);
  // 	}
  // }, [duration, action]);

  return (
    <div className="">
      <div className="p-4 sm:p-6 bg-white shadow-custom rounded-xl ">
        <div className="flex flex-col md:flex-row md:justify-between gap-3  md:items-center">
          <div className="flex gap-4 items-center">
            <h3 className="font-semibold"> Lines of Code Changes : </h3>
            <p className="text-xl font-bold">{includeComma(totalChange)}</p>
          </div>

          {commitFetching ? (
            <p>Fetching Commit Data ...</p>
          ) : (
            <div className="flex gap-3 items-center">
              <YearSelect
                arr={['all', 'additions', 'deletions']}
                value={action}
                isString={true}
                onChange={(e) => setAction(e.target.value)}
              />
              {isRepoLevel && (
                <>
                  <YearSelect
                    arr={
                      Contributorsdata.length > 0
                        ? Contributorsdata.map((data) => data.github_id)
                        : []
                    }
                    value={contributor}
                    onChange={(e) => setContributor(e.target.value)}
                    isString={true}
                  />
                </>
              )}
              <YearSelect
                arr={['1 Week', '1 Month', '1 Year']}
                value={duration}
                isString={true}
                onChange={(e) => setDuration(e.target.value)}
              />
            </div>
          )}
        </div>

        <ReactApexChart
          options={chartOption}
          series={chartSeries}
          type="line"
          height={250}
        />
      </div>
    </div>
  )
}

export default FileChangeChart
