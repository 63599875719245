import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoMdClose } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import uuid from 'react-uuid'
import { APPLICANT_SIGNIN_PAGE, LOGIN_PAGE } from '../../../navigation/routes'
import authAction from '../../../redux/actions/authAction'
import { githubCandidateNavData, unauthNavData } from './NavData'
import SingleHeaderLink from './SingleHeaderLink'
import SingleResponsiveHeader from './SingleResponsiveHeader'
import ProfileComponent from '../auth/ProfileComponent'
import NotificationComponent from '../notification/NotificationComponent'

const UnauthenticatedNavbar = ({ isTransparent, unreadNotif }) => {
  const { pathname } = useLocation()
  const [showNavMenu, setShowNavMenu] = useState(false)
  const [showsubMenu, setShowsubMenu] = useState(false)
  const { user, githubUser } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const navData =
    !githubUser && !user
      ? unauthNavData
      : githubCandidateNavData(githubUser?.username)
  return (
    <div className="flex flex-row-reverse gap-2 items-center">
      <div className=" flex md:gap-3 items-center text-xs sm:text-sm">
        {showNavMenu && (
          <div
            className={` md:hidden flex flex-col border-b-2 border-gray-300 border-opacity-40  md:flex-row absolute top-full w-full left-0 z-30 ${
              isTransparent ? 'bg-sky-600' : 'bg-white'
            }  p-6 justify-center items-center text-xs sm:text-sm`}
          >
            {navData.map((navData) => (
              <SingleResponsiveHeader
                key={uuid()}
                menu={navData}
                setShowMenu={setShowNavMenu}
              />
            ))}

            {!githubUser && !user && (
              <div className="flex flex-col w-full ">
                <Link
                  onClick={() => setShowsubMenu(!showsubMenu)}
                  to={'#'}
                  className="w-full bg-blue-500 max-w-xs m-auto text-center p-2 px-6 text-white rounded-lg relative "
                >
                  Login
                  <div className="absolute right-4 text-xl top-1/2 transform -translate-y-1/2">
                    {showsubMenu ? <FiChevronUp /> : <FiChevronDown />}
                  </div>
                </Link>
                {showsubMenu && (
                  <div className="flex flex-col w-full items-center cursor-pointer capitalize my-4">
                    <Link
                      to={APPLICANT_SIGNIN_PAGE}
                      onClick={() => setShowNavMenu(false)}
                      className={`${
                        pathname === APPLICANT_SIGNIN_PAGE
                          ? 'text-blue-400 bg-gray-400 bg-opacity-10 font-semibold '
                          : ''
                      } w-full flex gap-2 justify-center hover:bg-gray-400 max-w-xs hover:bg-opacity-10 hover:font-semibold items-center text-center font-600  transition-all duration-300 ease-in-out p-3   rounded-lg `}
                    >
                      For Developers
                    </Link>
                    <Link
                      to={LOGIN_PAGE}
                      onClick={() => setShowNavMenu(false)}
                      className={`${
                        pathname === LOGIN_PAGE
                          ? 'text-blue-400 bg-gray-400 bg-opacity-10 font-semibold '
                          : ''
                      } w-full flex gap-2 justify-center hover:bg-gray-400 max-w-xs hover:bg-opacity-10 hover:font-semibold items-center text-center font-600  transition-all duration-300 ease-in-out p-3   rounded-lg `}
                    >
                      For Companies
                    </Link>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
        <div className="flex gap-3 items-center">
          {githubUser && (
            <Tooltip title="Notification">
              <NotificationComponent
                unreadNotif={unreadNotif}
                userType={'github'}
              />
            </Tooltip>
          )}
          {githubUser && (
            <Tooltip title="Profile">
              <ProfileComponent userType={'github'} />
            </Tooltip>
          )}

          <Tooltip title="Menu">
            <button
              className={`md:hidden text-xl rounded-full w-8 h-8  ${
                isTransparent ? 'text-white' : 'text-gray-800'
              } grid place-items-center`}
              onClick={() => setShowNavMenu(!showNavMenu)}
            >
              {!showNavMenu ? <GiHamburgerMenu /> : <IoMdClose />}
            </button>
          </Tooltip>
        </div>
      </div>

      <div
        className={` hidden md:flex ${
          isTransparent ? 'bg-transparent' : 'bg-white'
        }  md:gap-3 justify-center items-center text-xs sm:text-sm`}
      >
        {navData.map((navData) => (
          <SingleHeaderLink
            isTransparent={isTransparent}
            key={uuid()}
            menu={navData}
          />
        ))}

        {!githubUser && !user && (
          <div id="single__header__link" className="relative">
            <Link
              to={'#'}
              className="px-6 p-2  bg-blue-500 shadow text-white rounded-full"
            >
              Login
            </Link>
            <div
              id="single__link__child"
              className={`absolute w-40 top-6 transform -translate-x-1/2 left-1/2 text-gray-800  flex flex-col rounded-lg overflow-hidden p-2 z-50  bg-white shadow-md `}
            >
              <Link
                key={uuid()}
                to={APPLICANT_SIGNIN_PAGE}
                className={` ${
                  pathname === APPLICANT_SIGNIN_PAGE
                    ? 'text-blue-400 font-semibold  '
                    : ''
                }  text-sm pb-1 font-600 p-2 px-4  hover:text-blue-400 hover:bg-gray-100 transition-all duration-300 ease-in-out`}
              >
                For Developers
              </Link>
              <Link
                key={uuid()}
                to={LOGIN_PAGE}
                className={` ${
                  pathname === LOGIN_PAGE ? 'text-blue-400 font-semibold  ' : ''
                }  text-sm pb-1 font-600 p-2 px-4  hover:text-blue-400 hover:bg-gray-100 transition-all duration-300 ease-in-out`}
              >
                For Companies
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default UnauthenticatedNavbar
