import { Tooltip } from '@mui/material'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { MdCopyAll, MdDelete } from 'react-icons/md'
import { showToast } from '../../../services/global/Toaster'
import IconButton from '../../ui-component/buttons/IconButton'

const SingleCreatedUser = ({ data, idx }) => {
  const { name, create_time, email, status } = data

  return (
    <tr className="text-xs xl:text-sm  font-semibold font-600 relative text-gray-600   ">
      <td>
        <p className="text-center">{idx + 1}</p>
      </td>
      <td>
        <p className="text-center">{name ? name : ''}</p>
      </td>

      <td className="text-center">
        <p className=" p-2 px-4 ">{email} </p>
      </td>
      <td className="text-center">
        <div className="flex gap-3 items-center p-2 px-4 justify-center">
          <p className=" text-lg ">•••••••••• </p>
          <CopyToClipboard
            text={'password'}
            onCopy={() => showToast('success', 'Password Copied')}
          >
            <Tooltip title="Copy Password">
              <button className="text-2xl">
                <MdCopyAll />
              </button>
            </Tooltip>
          </CopyToClipboard>
        </div>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">
          {create_time
            ? create_time.split(' ')[0].split('-').reverse().join('/')
            : ''}{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{status} </p>
      </td>
      <td>
        <div className="grid place-items-center">
          <IconButton
            toolTipTitle={'Delete User'}
            text={'Delete'}
            icon={<MdDelete />}
            outline
            type="error"
          />
        </div>
      </td>
    </tr>
  )
}

export default SingleCreatedUser
