import React from 'react'
import { AiOutlineStar } from 'react-icons/ai'
import { CgGitFork } from 'react-icons/cg'
import { IoPersonAddOutline } from 'react-icons/io5'
import { BiGitCommit } from 'react-icons/bi'
import { includeComma } from '../../../../services/global/globalFunction'
import LanguageLogo from '../../language-logo/LanguageLogo'
import uuid from 'react-uuid'

const RepoDetails = ({ data, no_of_contributors, print }) => {
  //console.log(data[0]);
  const {
    repo_name,
    stars,
    github_id,
    description,
    total_commits,
    lang,
    forks,
    size
  } = data[0]
  return (
    <div className="flex flex-col lg:flex-row gap-4 sm:gap-6">
      <div className="relative bg-white shadow-md rounded-xl p-4 md:p-8 flex flex-1 flex-col md:flex-row gap-3 ">
        <div className=" flex flex-col gap-2 text-sm md:text-base p-3">
          <div className="flex items-start gap-3 ">
            <img
              className="w-16 h-16 object-cover rounded-full"
              //src={'https://avatars.githubusercontent.com/u/3771963?v=4'}
              src={data[1].avatar.avatar_url}
              alt=""
            />
            <div>
              <h1 className="text-3xl font-bold">{repo_name}</h1>
              <h3>Owner : {github_id}</h3>
            </div>
          </div>

          <div className="flex gap-3 flex-wrap my-2">
            {lang.map((language) => (
              <LanguageLogo
                key={uuid()}
                language={language.language}
                logo={language.language_logo.language_logo}
              />
            ))}
          </div>

          <h3>Size : {includeComma(size.toFixed(1))} KB </h3>
          <h3>Description : {description} </h3>
        </div>
        <div className="absolute top-4 right-4 md:top-10 md:right-10">
          <button
            onClick={print}
            className="px-4 py-2 bg-blue-500 text-sm md:text-base rounded-sm text-white font-semibold hover:bg-transparent border border-blue-500 hover:text-gray-600 transition-all duration-300 ease-in-out "
          >
            Download
          </button>
        </div>
      </div>
      <div className="grid grid-cols-2  gap-4 md:gap-6 ">
        <div className="flex  items-center gap-2 bg-white rounded-md shadow-md p-4 md:p-6 ">
          <div className="text-3xl text-yellow-400">
            <AiOutlineStar />
          </div>
          <div className="flex text-xs md:text-base flex-col gap-2 justify-center w-full items-center">
            <p>Total Stars</p>

            <h3 className="text-2xl md:text-4xl font-semibold">{stars}</h3>
          </div>
        </div>

        <div className="flex  items-center gap-2 bg-white rounded-md shadow-md p-4 md:p-6 ">
          <div className="text-4xl text-green-400">
            <BiGitCommit />
          </div>
          <div className="flex text-xs md:text-base flex-col gap-2 justify-center w-full items-center">
            <p>Total Commits</p>

            <h3 className="text-2xl md:text-4xl font-semibold">
              {total_commits}
            </h3>
          </div>
        </div>

        <div className="flex  items-center gap-2 bg-white rounded-md shadow-md p-4 md:p-6 ">
          <div className="text-2xl text-blue-400">
            <IoPersonAddOutline />
          </div>
          <div className="flex text-xs md:text-base flex-col gap-2 justify-center w-full items-center">
            <p>Total Contributions</p>

            <h3 className="text-2xl md:text-4xl font-semibold">
              {no_of_contributors}
            </h3>
          </div>
        </div>

        <div className="flex  items-center gap-2 bg-white rounded-md shadow-md p-4 md:p-6 ">
          <div className="text-3xl text-gray-400">
            <CgGitFork />
          </div>
          <div className="flex text-xs md:text-base flex-col gap-2 justify-center w-full items-center">
            <p>Total Forks</p>

            <h3 className="text-2xl md:text-4xl font-semibold">{forks}</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RepoDetails
