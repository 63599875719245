import * as React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Typography from '@mui/material/Typography'
import { MdExpandMore } from 'react-icons/md'

export default function ControlledAccordions({ data }) {
  const [expanded, setExpanded] = React.useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div className="w-full max-w-3xl mx-auto flex flex-col gap-3">
      {data.map((faq, idx) => (
        <div className="shadow-md rounded-lg " key={idx}>
          <Accordion
            expanded={expanded === `panel${idx + 1}`}
            onChange={handleChange(`panel${idx + 1}`)}
            sx={{
              border: 0,
              borderRadius: '0.5rem',
              outline: 0,
              boxShadow: 'none'
            }}
          >
            <AccordionSummary
              expandIcon={<MdExpandMore className="text-3xl font-extrabold" />}
              aria-controls="panel1bh-content"
              id={`panel${idx + 1}bh-header`}
              sx={{
                backgroundColor: '#f0f9ff',
                padding: '0.5rem 1rem',
                borderRadius: '0.5rem'
              }}
            >
              <Typography sx={{ fontWeight: '550' }}>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{ padding: '1.2rem 1rem', border: 0, outline: 0 }}
            >
              <Typography sx={{ fontSize: '14px' }}>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        </div>
      ))}
    </div>
  )
}
