import React from 'react'
import ReactApexChart from 'react-apexcharts'

const LanguageChart = ({ data }) => {
  const series = Object.keys(data).map((keys) => data[keys].in_percentage)
  const categories = Object.keys(data).map((keys) => keys)

  const options = {
    chart: {
      type: 'donut'
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: categories,
    dataLabels: {
      enabled: true,
      textAnchor: 'middle'
    },
    fill: {
      type: 'solid'
    },
    legend: {
      formatter: function (val, opts) {
        return (
          val + ' - ' + opts.w.globals.series[opts.seriesIndex].toFixed(2) + '%'
        )
      },
      position: 'right'
    },
    responsive: [
      {
        breakpoint: 556,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }

  return (
    <div className="p-4 sm:p-6 flex-1">
      <div className="p-3 sm:p-6 bg-white shadow-custom rounded-xl ">
        <div className="flex justify-between items-center mb-2">
          <h3 className="font-semibold truncate mr-2 ">
            Languages used throughout all the projects
          </h3>
        </div>

        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="pie"
            height={300}
            className="w-full h-full grid place-items-center"
          />
        </div>
      </div>
    </div>
  )
}

export default LanguageChart
