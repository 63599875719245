export const jobTypeData = [
  {
    label: 'Permanent',
    value: 'permanent'
  },
  {
    label: 'Contract',
    value: 'contract'
  },
  {
    label: 'Intern',
    value: 'intern'
  }
]
export const EmploymentTypeData = [
  {
    label: 'Part Time',
    value: 'permanent'
  },
  {
    label: 'Full Time',
    value: 'contract'
  }
]
export const workTypeData = [
  {
    label: 'Onsite',
    value: 'onsite'
  },
  {
    label: 'Remote',
    value: 'remote'
  },
  {
    label: 'Hybrid',
    value: 'hybrid'
  }
]
