import { Step, StepLabel, Stepper } from '@material-ui/core'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import RippleLoader from '../../components/loader/RippleLoader'
import AddApplicantModal from '../../components/self-jobs/AddApplicantModal'
import ApplicantAdditionTable from '../../components/self-jobs/ApplicantAdditionTable'
import CreateSelfJobModal from '../../components/self-jobs/CreateSelfJobModal'

const ProcessCandidatePage = () => {
  const { state } = useLocation()
  const [createdJobId, setCreatedJobId] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [showCreateJob, setShowCreateJob] = useState(true)
  const [currentJobData, setCurrentJobData] = useState({})
  const [singleJobData, setSingleJobData] = useState([])
  const { checkJobExist } = EndPoints.createProfile
  const { getJobListing } = EndPoints.jobListing
  console.log(state)

  // stepper implementation
  const steps = ['Create Job', 'Add Applicants', 'Manage Applicants']
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const fetchJobData = async (jobID) => {
    try {
      const { data } = await axiosInstance.get(
        `${checkJobExist}/getjobdetails?job_id=${jobID}`
      )
      // if (JSON.parse(data.body.message).length === 0) {
      // 	setshowMessage(true);
      // }
      setCurrentJobData(JSON.parse(data.body.message)[0])
      setIsLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchSingleJobData = async (jobID) => {
    try {
      const res = await Promise.all([
        axiosInstance.get(`${getJobListing}/getjobapplicants?job_id=${jobID}`)
      ])
      const parsedData = res.map((res) => JSON.parse(res.data.body).message)

      setSingleJobData(parsedData[0])
    } catch (err) {
      console.log(err.message)
    }
  }

  const refetchData = (jobID) => {
    fetchJobData(jobID)
    fetchSingleJobData(jobID)
  }

  useEffect(() => {
    if (state === null) {
      setIsLoading(false)
      return
    }
    if (state?.jobID) {
      fetchJobData(state?.jobID)
      fetchSingleJobData(state?.jobID)
      setShowCreateJob(false)
    }
  }, [])

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10 ">
      {isLoading ? (
        <RippleLoader />
      ) : (
        <div className="flex flex-col  max-w-6xl mx-auto">
          <div className="flex flex-col gap-4">
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {}
                const labelProps = {}

                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
            <div className="h-[2px] bg-gradient-to-r from-blue-400 to-orange-400 rounded-full w-full"></div>
          </div>

          {activeStep === 0 && (
            <CreateSelfJobModal
              refetchData={refetchData}
              setShowCreateJob={setShowCreateJob}
              showCreateJob={showCreateJob}
              setCreatedJobId={setCreatedJobId}
              currentJobData={currentJobData}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          )}

          {activeStep === 1 && (
            <AddApplicantModal
              close={() => {
                refetchData(state === null ? createdJobId : state?.jobID)
              }}
              jobID={state === null ? createdJobId : state?.jobID}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          )}

          {activeStep === 2 && (
            <ApplicantAdditionTable
              applicantsData={singleJobData}
              refetchData={refetchData}
              jobID={state === null ? createdJobId : state?.jobID}
              setActiveStep={setActiveStep}
              activeStep={activeStep}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default ProcessCandidatePage
