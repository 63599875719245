import React from 'react'
import { Fragment } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { AiOutlineProfile } from 'react-icons/ai'
import { BsChevronRight, BsGithub } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import {
  ALL_JOBS_PAGE,
  CREATE_USER_PROFILE_PAGE
} from '../../../../navigation/routes'
import { ArrowTooltip } from '../../../ui-component/tooltip/Tooltip'
import RippleLoader from '../../loader/RippleLoader'
import SingleInterviewCard from '../../interviews/SingleInterviewCard'

const CandidateDetails = ({
  applicantData,
  jobData,
  interviewData,
  showCreateProfileMessage,
  CandidateLoading
}) => {
  const { username } = useParams()
  const { githubUser } = useSelector((state) => state.auth)

  return (
    <div>
      {CandidateLoading ? (
        <RippleLoader />
      ) : (
        <div className="p-4 sm:p-6 flex flex-col gap-6">
          {githubUser && githubUser?.username === username && (
            <div className="flex flex-col gap-4">
              <h3 className="font-semibold px-4 md:px-6 ">
                Upcoming Interviews
              </h3>
              {interviewData.length === 0 ? (
                <>
                  <div className=" flex flex-col gap-4 items-center text-center text-gray-400 ">
                    <AiOutlineProfile className="text-6xl" />

                    <h5>It seems you don't have any upcoming interviews.</h5>
                  </div>
                </>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 flex-1 gap-6">
                    {interviewData.map((interview) => (
                      <SingleInterviewCard
                        key={uuid()}
                        interview={interview}
                        type="candidate"
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          {githubUser && githubUser?.username === username && (
            <div className="flex flex-col gap-4">
              <h3 className="font-semibold px-4 md:px-6 ">Applied Jobs</h3>
              {jobData.length === 0 ? (
                <div className=" flex flex-col gap-4 items-center text-center text-gray-400 ">
                  <AiOutlineProfile className="text-6xl" />

                  <h5>It seems you haven't applied to any jobs yet.</h5>
                  <Link
                    to={ALL_JOBS_PAGE}
                    className=" p-2 text-sm  bg-blue-500  hover:bg-blue-400 text-white rounded-md ml-2"
                  >
                    Apply Now
                  </Link>
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 flex-1 gap-6">
                    {jobData
                      .filter((job) => job.job_status === 'OPEN')
                      .slice(0, 3)
                      .map((job) => {
                        const {
                          company_logo,

                          create_date,

                          job_name,

                          organization
                        } = job

                        return (
                          <div
                            key={uuid()}
                            className="flex flex-col gap-6 relative bg-white shadow-custom p-4 rounded-md"
                          >
                            <div className="flex justify-between gap-6 items-start ">
                              <div className="flex flex-col gap-2 w-full">
                                <div className="flex gap-4 items-center">
                                  {company_logo && (
                                    <img
                                      className="w-20 h-20  rounded-xl object-contain "
                                      src={company_logo}
                                      alt={''}
                                    />
                                  )}
                                  <div className="flex flex-col gap-2">
                                    <p className="text-blue-500 flex text-base lg:text-lg font-semibold items-center ">
                                      {' '}
                                      <span>{job_name}</span>
                                    </p>
                                    <p className="text-sm font-semibold ">
                                      {organization}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex justify-between items-end gap-4 w-full ">
                                  <p className="text-xs w-full text-right ">
                                    <span className="font-semibold">
                                      Posted on{' '}
                                    </span>
                                    {create_date
                                      ? create_date
                                          .split(' ')[0]
                                          .split('-')
                                          .reverse()
                                          .join('/')
                                      : ''}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })}
                  </div>
                  <div className="grid place-items-center">
                    {' '}
                    <Link
                      className="text-sm mt-2 text-blue-500 font-semibold"
                      to={`/${username}/jobs`}
                    >
                      View All
                    </Link>
                  </div>
                </div>
              )}
            </div>
          )}
          <div
            className={`flex flex-col lg:flex-row gap-4 ${
              githubUser?.username === username && 'mt-6'
            }`}
          >
            <div className="flex flex-col flex-1 bg-white shadow-custom rounded-md p-4 md:p-6">
              <div className=" flex flex-col items-start">
                <h3 className="text-center font-semibold mb-4 ">
                  Personal Details
                </h3>
                {showCreateProfileMessage ? (
                  <>
                    {githubUser?.username === username ? (
                      <div className="w-full h-full grid place-items-center text-gray-400">
                        <div className=" flex flex-col gap-4 items-center text-center ">
                          <AiOutlineProfile className="text-6xl" />

                          <h5 className="max-w-md m-auto">
                            It seems you haven't created your profile with us .
                            We highly recommend you to create your profile
                            before applying for jobs.
                          </h5>
                          <Link
                            to={CREATE_USER_PROFILE_PAGE}
                            className=" p-2 text-sm  bg-blue-500  hover:bg-blue-400 text-white rounded-md ml-2"
                          >
                            Create Profile
                          </Link>
                        </div>
                      </div>
                    ) : (
                      <div className="w-full h-full grid place-items-center text-gray-400">
                        <div className=" flex flex-col gap-4 items-center text-center ">
                          <AiOutlineProfile className="text-6xl" />

                          <h5 className="max-w-md m-auto">
                            Candidate haven't updated his / her profile yet .
                          </h5>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {' '}
                    <div className="flex gap-4 mb-4">
                      <div className=" grid place-items-center">
                        <img
                          className="w-20 h-20 rounded-full object-contain"
                          src={applicantData.avatar_url}
                          alt=""
                        />
                      </div>
                      <div className=" flex gap-2 justify-center items-center flex-wrap">
                        {applicantData?.key_skill.map((skill) => (
                          <p
                            className="p-2 px-3 bg-gray-100 rounded-full shadow text-xs"
                            key={uuid()}
                          >
                            {skill.language_name}
                          </p>
                        ))}
                      </div>
                    </div>
                    <div className="grid grid-cols-2 w-full  md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4  gap-4 ">
                      <div className="flex flex-col gap-2 text-xs md:text-sm ">
                        <p className=" font-semibold  ">Name</p>
                        <p>{applicantData?.name}</p>
                      </div>
                      <div className="flex flex-col gap-2 text-xs md:text-sm ">
                        <p className=" font-semibold  ">Email</p>
                        <p>{applicantData?.email_id}</p>
                      </div>
                      <div className="flex flex-col gap-2 text-xs md:text-sm ">
                        <p className=" font-semibold  ">Phone </p>
                        <p>{applicantData?.phone_no}</p>
                      </div>
                      <div className="flex flex-col gap-2 text-xs md:text-sm ">
                        <p className=" font-semibold  ">Year Passout</p>
                        <p>{applicantData?.year_of_passout}</p>
                      </div>
                      <div className="flex flex-col gap-2 text-xs md:text-sm ">
                        <p className=" font-semibold  ">Institution</p>
                        <p>{applicantData?.college}</p>
                      </div>

                      <div className="flex flex-col gap-2 text-xs md:text-sm ">
                        <p className=" font-semibold  ">Kaggle ID</p>
                        <p>{applicantData?.kaggle_id}</p>
                      </div>
                      <div className="flex flex-col gap-2 text-xs md:text-sm ">
                        <p className=" font-semibold  ">Hackerrank ID</p>
                        <p>{applicantData?.hacker_rank_id}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {githubUser && githubUser?.username === username && (
              <div className="flex flex-col flex-1 ">
                <h3 className="px-4 md:px-6 font-semibold mb-4 ">
                  Assignments
                </h3>
                {jobData.length === 0 ? (
                  <div className=" flex flex-col gap-4 items-center text-center text-gray-400 ">
                    <AiOutlineProfile className="text-6xl" />

                    <h5>No Assignments.</h5>
                  </div>
                ) : (
                  <div className="flex flex-col gap-2">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-2 flex-1  gap-4">
                      {jobData
                        .filter((job) => job.applicant_tasks.length > 0)
                        .slice(0, 4)
                        .map((job) => {
                          const {
                            applicant_tasks: assignment,
                            company_logo,
                            job_name,

                            organization
                          } = job

                          return (
                            assignment &&
                            assignment[0] && (
                              <div
                                key={uuid()}
                                className="flex flex-col gap-6 relative bg-white shadow-custom p-4 rounded-md"
                              >
                                <div className="flex justify-between gap-6 items-start ">
                                  <div className="flex flex-col gap-2 w-full">
                                    <div className="flex gap-2 items-start">
                                      <img
                                        src={company_logo}
                                        className="w-10 h-10 rounded-full object-contain"
                                        alt=""
                                      />
                                      <div className="flex flex-col ">
                                        <p className="text-blue-500 flex text-xs font-semibold items-center ">
                                          {' '}
                                          <span>{job_name}</span>
                                        </p>
                                        <p className="text-sm font-semibold ">
                                          {organization}
                                        </p>
                                      </div>{' '}
                                    </div>

                                    <div className="flex flex-col gap-1 text-xs">
                                      <h3 className="truncate w-full ">
                                        {ReactHtmlParser(
                                          assignment[0].task_title
                                        )}
                                      </h3>
                                    </div>
                                    <div className="flex justify-between items-end gap-4">
                                      <ArrowTooltip
                                        title={'Link to assignment repository'}
                                      >
                                        <a
                                          className=" p-3 py-2 text-sm border-2 border-blue-500 border-opacity-40 text-blue-500 flex gap-2 items-center justify-center bg-white hover:bg-blue-50  rounded-full w-40 text-center cursor-pointer"
                                          href={`https://github.com/${assignment[0].github_owner}/${assignment[0].repo_name}`}
                                          target="blank"
                                        >
                                          <BsGithub />
                                          Task Repository
                                        </a>
                                      </ArrowTooltip>
                                      {assignment[0].task_close_date && (
                                        <div className="flex flex-col gap-1 text-xs">
                                          <h3 className=" font-bold text-right w-full">
                                            Last Date :{' '}
                                            <span className="font-semibold">
                                              {assignment[0].task_close_date
                                                .split(' ')[0]
                                                .split('-')
                                                .reverse()
                                                .join('-')}
                                            </span>{' '}
                                          </h3>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          )
                        })}
                    </div>
                    <div className="grid place-items-center">
                      {' '}
                      <Link
                        className="text-sm mt-3 text-blue-500 font-semibold"
                        to={`/${username}/jobs`}
                      >
                        View All
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default CandidateDetails
