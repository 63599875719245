import React from 'react'
import { BsFillCalendar3EventFill } from 'react-icons/bs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'

const SingleInterviewCard = ({ type = 'recruiter', interview }) => {
  const {
    accepted_slot,
    agency_id,
    candidate_id,
    candidate_name,
    company,
    company_id,
    id,
    interview1,
    interview2,
    interview3,
    job_id,
    location,
    message,
    slot_time,
    status,
    status_reason,
    time_zone,
    type: interviewType
  } = interview

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)

  const scheduledDateArr =
    dayjs(accepted_slot).tz(time_zone).format('LLLL').split(' ') ?? []

  return (
    <div className="p-6 rounded-xl shadow-custom text-gray-500 flex flex-col gap-1 ">
      <div className="flex gap-3 items-center">
        <BsFillCalendar3EventFill className="text-gray-800" />
        <h1 className="text-xl font-semibold text-gray-800">
          {`${scheduledDateArr[0]} ${scheduledDateArr[1]} ${scheduledDateArr[2]} ${scheduledDateArr[3]}`}
        </h1>
      </div>
      <h3 className="text-base font-semibold text-gray-800 ">
        {`${scheduledDateArr[4]} ${scheduledDateArr[5]} `}
      </h3>
      {type === 'candidate' ? (
        <p className="flex gap-2 text-sm">
          <span className="font-semibold">Company :</span> {company}
        </p>
      ) : (
        <p className="flex gap-2 text-sm">
          <span className="font-semibold">Candidate :</span> {candidate_name}
        </p>
      )}

      <p className="flex gap-2 text-sm">
        <span className="font-semibold">Interview :</span> Front End Developer
      </p>
      <p className="flex gap-2 text-sm capitalize">
        <span className="font-semibold">Mode :</span>
        {interviewType.toLowerCase()}
      </p>
      <p className="flex gap-2 text-sm">
        <span className="font-semibold">Location :</span>
        {interviewType === 'ONLINE' ? (
          <a
            className="text-blue-500 underline    "
            href="https://meet.google.com"
            target="blank"
          >
            {location}
          </a>
        ) : (
          <p className="font-semibold">{location}</p>
        )}
      </p>
    </div>
  )
}

export default SingleInterviewCard
