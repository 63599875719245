import React, { Fragment, useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import uuid from 'react-uuid'
import IconButton from '../../ui-component/buttons/IconButton'
import TableSearch from '../../ui-component/inputs/TableSearch'
import SingleCreatedUser from './SingleCreatedUser'
import { createUserTableHeader } from './data'

const CreateUserTable = ({
  data,
  fetchCreatedUsers,
  setShowCreateUserModal
}) => {
  const [filterApplicantData, setfilterApplicantData] = useState(data)
  const [applicantText, setApplicantText] = useState('')

  const filterChange = (text) => {
    const filteredData = data.filter(
      (user) => user.email.toUpperCase().includes(text.toUpperCase())
      // ||
      // user.github_id.toUpperCase().includes(text.toUpperCase()),
    )

    setfilterApplicantData(filteredData)
  }

  const onTextChange = (e) => {
    setApplicantText(e.target.value)
    filterChange(e.target.value)
  }

  useEffect(() => {
    setfilterApplicantData(data)
  }, [data])
  return (
    <div className="flex flex-col gap-4   ">
      <div className="flex flex-col-reverse sm:flex-row justify-between gap-3 items-end sm:items-center mb-6 md:mb-10 ">
        <TableSearch
          value={applicantText}
          onChange={onTextChange}
          placeholder="Search Created Users"
        />
        <IconButton
          onClick={() => setShowCreateUserModal(true)}
          type="primary"
          text={'Create User'}
          icon={<MdAdd />}
          toolTipTitle={'Create User'}
        />
      </div>

      <div className="overflow-x-scroll w-full">
        <table
          className={`p-4 w-full rounded-md overflow-hidden shadow-custom bg-white`}
        >
          <tbody className="text-xs xl:text-sm ">
            <tr className="bg-blue-400 bg-opacity-25 p-2 ">
              {createUserTableHeader.map((head) => (
                <th key={uuid()} className="text-center p-2 py-3 ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {filterApplicantData.length !== 0 &&
              filterApplicantData.map((userData, idx) => (
                <SingleCreatedUser key={uuid()} data={userData} idx={idx} />
              ))}
          </tbody>
        </table>
        {filterApplicantData.length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
              No User Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default CreateUserTable
