import React, { useState } from 'react'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import VideoModal from '../homepage/VideoModal'
import { useSelector } from 'react-redux'
import { MdVideoSettings } from 'react-icons/md'

const StickyVideo = () => {
  const { user, githubUser } = useSelector((state) => state.auth)
  const [showVideoModal, setShowVideoModal] = useState(false)
  return (
    <div className="fixed bottom-6 right-6 md:bottom-10 md:right-10  cursor-pointer ">
      <div
        onClick={() => setShowVideoModal(true)}
        className="shadow w-16 h-16 p-2 rounded-full bg-orange-500 border-2 text-white flex flex-col items-center justify-center"
      >
        <MdVideoSettings className="text-2xl" />
        <p className="text-xs font-semibold word-break text-center  ">More</p>
      </div>

      {showVideoModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-screen z-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="z-50   ">
            <ClickWrapper func={() => setShowVideoModal(false)}>
              <VideoModal
                close={() => setShowVideoModal(false)}
                videoUrl={
                  user
                    ? 'https://youtu.be/lejA9ZrapA8'
                    : githubUser
                    ? 'https://youtu.be/qwNbdGxByrM'
                    : ''
                }
              />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default StickyVideo
