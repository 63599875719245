import React from 'react'
import uuid from 'react-uuid'

const CollegeHeader = [
  {
    title: 'Year'
  },
  {
    title: 'Total Passouts'
  }
]

const CollegeTable = ({ data }) => {
  return (
    <div className="p-4 sm:p-6 bg-white shadow-custom rounded-lg">
      <table className={`p-4 w-full rounded-lg  `}>
        <tbody className="text-xs xl:text-sm rounded-lg ">
          <tr className="bg-blue-400 bg-opacity-20 p-2 ">
            {CollegeHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>

          {data.length !== 0 &&
            data.map((candidate, idx) => (
              <tr
                key={idx}
                className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   "
              >
                <td className="text-center">
                  <p className=" p-2  ">{candidate.year_of_passout}</p>
                </td>

                <td className="text-center">
                  <p className=" p-2  ">{candidate.total}</p>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default CollegeTable
