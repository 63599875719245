import React, { useState } from 'react'
import axiosInstance from '../../../services/axios/axios'
import { ArrowTooltip } from '../../ui-component/tooltip/Tooltip'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import ViewMessageModal from '../shortlist/ViewMessageModal'

const SingleAdminContactTable = ({
  data,
  idx,
  fetchAllContact,
  limit,
  page
}) => {
  const { contact_date, email, id, message, phone, reason, status } = data
  const [showMessage, setShowMessage] = useState(false)
  const handleStatusChange = async () => {
    const putData = {
      id,
      status: 'RESOLVED'
    }
    try {
      await axiosInstance.put(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/contactus/put`,
        putData
      )
      fetchAllContact()
    } catch (err) {
      console.log(err.message)
    }
  }
  return (
    <tr className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   ">
      <td className="text-center min-w-[50px]">
        {(page - 1) * limit + idx + 1}
      </td>

      <td className="text-center">
        <p className=" p-2 px-4 ">{email} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{phone} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">
          {contact_date ? new Date(contact_date).toLocaleString() : ''}{' '}
        </p>
      </td>
      <td className="text-center">
        <ArrowTooltip title={'View Message'}>
          <button
            onClick={() => setShowMessage(true)}
            className="border-2 bg-white text-blue-500 border-blue-500 disabled:bg-gray-400 hover:bg-blue-200 shadow-md rounded-full px-3 py-1 "
          >
            View Message
          </button>
        </ArrowTooltip>
        {showMessage && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-3xl p-4 m-auto z-50">
              <ClickWrapper func={() => setShowMessage(false)}>
                <ViewMessageModal
                  close={() => setShowMessage(false)}
                  message={message}
                  title={reason}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{reason}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 capitalize ">{status.toLowerCase()} </p>
      </td>
      <td className="text-center">
        <button
          onClick={handleStatusChange}
          disabled={status === 'RESOLVED'}
          className="bg-blue-500 disabled:bg-gray-400 hover:bg-blue-400 shadow-md text-white rounded-full px-3 py-2 w-20"
        >
          {status === 'RESOLVED' ? 'Resolved' : 'Resolve'}
        </button>
      </td>
    </tr>
  )
}

export default SingleAdminContactTable
