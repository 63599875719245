import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { FaUniversity, FaUserSlash, FaUsers } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import RatingProgress from '../../components/dashboard/header/RatingProgress'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import CollegeCommitChart from '../../components/single-college/CollegeCommitChart'
import CollegeLanguage from '../../components/single-college/CollegeLanguage'
import CollegeTable from '../../components/single-college/CollegeTable'

const SingleCollegeStats = () => {
  const { collegeName } = useParams()
  const [loading, setLoading] = useState(true)
  const [collegeData, setCollegeData] = useState({})
  const { getCollegeLeaderboard } = EndPoints.collegeLeaderboard

  const fetchCollegeData = async () => {
    //setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `${getCollegeLeaderboard}/getcollegedata?college=${collegeName}`
      )
      console.log(data)
      setCollegeData(data.message)

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchCollegeData()
  }, [])

  return (
    <div className="w-full p-4 sm:p-6  min-h-screen ">
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="">
          {collegeData === null ? (
            <div className="grid place-items-center p-6 bg-white rounded-lg shadow-custom max-w-5xl m-auto">
              <FaUserSlash className="text-7xl" />
              <h2 className="text-lg font-semibold">
                Not Enough Data to display for this college
              </h2>
              <p className="text-sm">
                We'll collect more info and make it available to you in future
              </p>
            </div>
          ) : (
            <div className="flex flex-col gap-4 md:gap-6">
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                <div className="p-4 rounded-md shadow-custom flex flex-col items-center  text-center gap-3 bg-white">
                  <FaUniversity className="text-6xl" />
                  <h4>College</h4>
                  <h1 className="text-3xl font-semibold ">
                    {collegeData?.college_name}
                  </h1>
                </div>
                <div className="flex  gap-4 sm:gap-6">
                  <div className="p-4 flex-1 rounded-md shadow-custom flex flex-col items-center text-center gap-3 bg-white">
                    <FaUsers className="text-6xl" />
                    <h4>Total Students</h4>
                    <h1 className="text-4xl font-semibold ">
                      {collegeData?.total_students}
                    </h1>
                  </div>
                  <div className="p-3 flex-1 rounded-md shadow-custom bg-white flex flex-col justify-center text-center gap-3">
                    <h4>Average Rating</h4>
                    <RatingProgress points={collegeData?.average_rating} />
                  </div>
                </div>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <CollegeTable
                    data={JSON.parse(collegeData?.batch_count) || []}
                  />
                </ErrorBoundary>
              </div>

              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <CollegeLanguage
                  language={JSON.parse(collegeData?.top_languages) || []}
                />
              </ErrorBoundary>
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <CollegeCommitChart
                  data={JSON.parse(collegeData?.yearly_commits) || []}
                />
              </ErrorBoundary>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default SingleCollegeStats
