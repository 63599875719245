import React, { useState } from 'react'
import SingleCreditTransaction from './SingleCreditTransaction'
import axiosInstance from '../../../../services/axios/axios'
import EndPoints from '../../../../services/api/api'
import { useEffect } from 'react'
import RippleLoader from '../../loader/RippleLoader'
import { parseCreditHistory } from '../../../../services/global/globalFunction'

const CreditHistory = () => {
  const [loading, setLoading] = useState(true)
  const [creditHistory, setCreditHistory] = useState({})
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const { shortlistEndpoint } = EndPoints.dashboard

  const fetchCreditHistory = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Stage/creditHistory/get?user_id=16&limit=${limit}&page=${page}`
      )

      const res = data.message
      if (res) {
        setCreditHistory(parseCreditHistory(res))
      }
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (limit <= 90) {
      fetchCreditHistory()
    }
  }, [page, limit])

  return (
    <div className="px-2 md:px-8">
      {loading ? (
        <RippleLoader />
      ) : (
        <div className=" flex flex-col w-full gap-4 max-w-3xl mx-auto">
          <h3 className="text-sm ">Last 90 Credit Transactions</h3>
          {Object.keys(creditHistory).map((creditMonth, idx) => (
            <div
              key={idx}
              className="flex flex-col border-x rounded-lg shadow overflow-hidden"
            >
              <div className="p-3 bg-sky-100">
                <h3>{creditMonth}</h3>
              </div>
              {creditHistory[creditMonth].map((credit, idx) => (
                <SingleCreditTransaction key={idx} data={credit} />
              ))}
            </div>
          ))}

          {limit < 90 && (
            <button
              className="text-blue-500"
              onClick={() => setLimit(limit + 10)}
            >
              Load More
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default CreditHistory
