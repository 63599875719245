import React from 'react'
import { Fragment } from 'react'
import { useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import TextInput from '../create-profile/text-input/TextInput'

const DeleteUserModal = ({ close, handleDeleteProfile }) => {
  const [deleteInput, setDeleteInput] = useState('')
  const [showDeleteInput, setShowDeleteInput] = useState(false)
  const { githubUser } = useSelector((state) => state.auth)
  return (
    <Fragment>
      {showDeleteInput ? (
        <div className="flex flex-col items-center gap-6 rounded-2xl bg-white p-6">
          <h2 className="text-sm">
            You are about to <strong>permanently delete</strong> admissions and
            all its contents. You will not be able to recover this account and
            its data. This operation cannot be undone.
          </h2>
          <div className="w-full">
            <TextInput
              value={deleteInput}
              setValue={(val) => setDeleteInput(val)}
              label={'Enter your github ID to confirm'}
              placeholder={'github ID'}
            />
          </div>

          <div className="flex  items-center w-full justify-between rounded-lg overflow-hidden">
            <button
              className="p-2 text-sm  w-1/2 grid place-items-center  bg-gray-200 hover:bg-gray-300 text-black "
              onClick={close}
            >
              Cancel
            </button>
            <button
              disabled={deleteInput !== githubUser.username}
              className="p-2 text-sm  w-1/2 grid place-items-center  bg-red-500 hover:bg-red-600 text-white disabled:bg-gray-400 disabled:text-black  "
              onClick={handleDeleteProfile}
            >
              Delete
            </button>
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-center gap-6 rounded-2xl bg-white p-6">
          <AiOutlineCloseCircle className="text-red-500 text-6xl " />
          <h1 className="text-3xl"> Delete Account ?</h1>
          <p>Are you sure you want to delete your account permanently</p>

          <div className="flex  items-center w-full justify-between rounded-lg overflow-hidden">
            <button
              className="p-2  w-1/2 grid place-items-center text-sm  bg-gray-300 hover:bg-gray-400 text-black "
              onClick={close}
            >
              Cancel
            </button>
            <button
              className="p-2   w-1/2 grid place-items-center text-sm  bg-red-500 hover:bg-red-600 text-white "
              onClick={() => setShowDeleteInput(true)}
            >
              Delete
            </button>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default DeleteUserModal
