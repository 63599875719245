import React from 'react'
import uuid from 'react-uuid'
import ReactHtmlParser from 'react-html-parser'
import { useNavigate } from 'react-router-dom'

const SingleRecruiterProjectTable = ({ data, idx }) => {
  const {
    company_logo,
    create_date,
    description,
    project_id,
    project_title,
    key_skills,
    total_applicant
  } = data
  const navigate = useNavigate()
  return (
    <tr
      onClick={() => navigate(`/project-listing/${project_id}`)}
      className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   "
    >
      <td className="text-center">{idx + 1}</td>
      <td className=" p-3 px-4 min-w-[300px] max-w-[400px]">
        <div className="flex gap-2 mb-2">
          {company_logo && company_logo !== '' && (
            <img
              className="w-8 h-8 object-contain rounded-sm"
              src={company_logo}
              alt=""
            />
          )}
          <h2 className="text-base   font-bold">{project_title}</h2>
        </div>
        <p className="font-bold">Description:</p>{' '}
        <p className=" font-normal flex items-end  line-clamp-1 ">
          {ReactHtmlParser(description.slice(0, 60))}
          <span className="text-blue-500">...more</span>
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4  ">
          <div className="flex justify-center items-center gap-3">
            {key_skills &&
              key_skills.split('|').map((lang) => (
                <p
                  key={uuid()}
                  className="bg-gray-100 shadow p-2 px-4 rounded-full text-xs text-gray-900 "
                >
                  {' '}
                  {lang}
                </p>
              ))}
          </div>{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">
          {create_date.split(' ')[0].split('-').reverse().join('/')}{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{project_id} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{total_applicant} </p>
      </td>
    </tr>
  )
}

export default SingleRecruiterProjectTable
