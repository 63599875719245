import React from 'react'

const PrivacyPolicyPage = () => {
  return (
    <div className="min-h-screen p-6 sm:p-8 md:p-10   flex flex-col gap-10 leading-loose max-w-7xl mx-auto ">
      <p className="text-3xl font-semibold flex flex-col gap-1 ">
        Privacy Policy - TechProfile
        <span className="text-base"> Last modified: Jan 26, 2023</span>
      </p>

      <p>
        At TechProfile.org, we take your privacy seriously. We are committed to
        protecting the personal information that you provide to us when you use
        our website. This Privacy Policy explains how we collect, use, and
        protect your personal information.
      </p>

      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-semibold ">Information We Collect</h3>

        <p>
          When you sign up for an account on TechProfile.org, we collect
          personal information such as your name, email address, and password.
          If you choose to create a profile, we may also collect additional
          information such as your education, work experience, and skills. We
          may also collect information about how you use our website, such as
          your IP address, browser type, and the pages you visit.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-semibold ">How We Use Your Information</h3>

        <p>
          We use the information that we collect to provide our services to you.
          For example, we use your personal information to create and manage
          your account, to connect you with other users, and to match you with
          job opportunities. We may also use your information to improve our
          website and to send you promotional emails about our services.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-semibold ">
          How We Protect Your Information
        </h3>

        <p>
          We take the security of your personal information seriously. We use
          industry-standard security measures to protect your information from
          unauthorized access, disclosure, and modification. We also require our
          employees and service providers to comply with strict confidentiality
          obligations.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-semibold ">
          Disclosure of Your Information
        </h3>

        <p>
          We may disclose your personal information to third parties in certain
          circumstances. For example, we may share your information with our
          partners who help us provide our services to you. We may also share
          your information if we are required to do so by law or if we believe
          in good faith that such disclosure is necessary to protect our rights
          or the safety of our users.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-semibold ">Your Choices</h3>

        <p>
          You have certain choices about how we use your personal information.
          You can choose not to provide us with certain information, although
          this may limit your ability to use some of our services. You can also
          opt-out of receiving promotional emails from us by following the
          instructions in the email.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-semibold ">
          Changes to this Privacy Policy
        </h3>

        <p>
          We may update this Privacy Policy from time to time to reflect changes
          in our practices or in applicable law. We will post the updated
          Privacy Policy on our website and notify you of any material changes.
        </p>
      </div>
      <div className="flex flex-col gap-2">
        <h3 className="text-2xl font-semibold ">Contact Us</h3>

        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at support@techprofile.org.
        </p>
      </div>
    </div>
  )
}

export default PrivacyPolicyPage
