import React, { useState } from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import useCreditHook from '../../../hooks/useCreditHook'
import creditAction from '../../../redux/actions/creditAction'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import DateInput from '../create-profile/date-input/DateInput'
import MessageInput from '../create-profile/message-input/MessageInput'
import TextInput from '../create-profile/text-input/TextInput'
import NoCreditComponent from '../credits/NoCreditComponent'
import RippleLoader from '../loader/RippleLoader'

const assignmentLanguages = [
  {
    title: 'None',
    value: ''
  },
  {
    title: 'Java',
    value: 'MAVEN'
  },
  {
    title: 'JavaScript',
    value: 'JAVASCRIPT'
  },
  {
    title: 'Python',
    value: 'PYTHON'
  },
  {
    title: 'Typescript',
    value: 'TYPESCRIPT'
  }
]

const AssignmentModal = ({
  close,
  applicantId,
  jobid,
  applicantGithub,
  refreshData
}) => {
  const [assignmentDetails, setAssignmentDetails] = useState({
    title: '',
    message: '',
    lastDate: '',
    taskLang: ''
  })
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { createAssignment } = EndPoints.singleJobListing
  const { createAssignmentEligibility } = useCreditHook()
  const dispatch = useDispatch()

  const handleSendAssignment = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const postData = {
      APPLICANT_ID: applicantId,
      TASK: assignmentDetails.message,
      TASK_LANG:
        assignmentDetails.taskLang === ''
          ? 'OTHERS'
          : assignmentDetails.taskLang,
      TASK_CLOSE_DATE: assignmentDetails.lastDate,
      TASK_TITLE: assignmentDetails.title,
      APPLICANT_GITHUB: applicantGithub,
      JOB_ID: jobid,
      USER_ID: user.user_id
    }

    console.log(postData)
    try {
      await axiosInstance.post(
        `${createAssignment}/githubconfigandsonarcloud`,
        postData
      )
      //console.log(data);
      showToast('success', 'Created Assignment Successfully')
      setIsLoading(false)
      refreshData()
      dispatch(creditAction.fetchUserCredits(user.user_id))
      close()
    } catch (err) {
      console.log(err.message)
    }
  }

  if (!createAssignmentEligibility) return <NoCreditComponent />

  return (
    <form
      onSubmit={handleSendAssignment}
      className="w-full p-3 md:p-12 bg-white rounded-2xl shadow-lg m-auto  relative flex flex-col gap-6"
    >
      <button
        onClick={close}
        className="absolute top-6 right-6 text-3xl font-bold text-blue-500"
      >
        <IoMdCloseCircleOutline />
      </button>
      {isLoading && (
        <div className="absolute inset-0 grid place-items-center bg-white bg-opacity-40">
          <RippleLoader />
        </div>
      )}

      <TextInput
        label={'Title'}
        required
        placeholder={'Enter Title'}
        value={assignmentDetails.title}
        setValue={(val) =>
          setAssignmentDetails({ ...assignmentDetails, title: val })
        }
      />
      <DateInput
        label={'Last Date'}
        value={assignmentDetails.lastDate}
        setValue={(val) =>
          setAssignmentDetails({ ...assignmentDetails, lastDate: val })
        }
      />
      <label className="flex flex-col gap-2 text-xs md:text-sm  ">
        <p className=" font-semibold  ">Task Language</p>
        <div className="p-3  w-full border-2 rounded-xl">
          <select
            onChange={(e) =>
              setAssignmentDetails({
                ...assignmentDetails,
                taskLang: e.target.value
              })
            }
            className="outline-none cursor-pointer w-full  border-none text-xs"
            value={assignmentDetails.taskLang}
          >
            {assignmentLanguages.map((language) => (
              <option key={uuid()} value={language.value}>
                {language.title}
              </option>
            ))}
          </select>
        </div>
      </label>
      <MessageInput
        label={'Task'}
        placeholder={'Write Assignment Task Description'}
        value={assignmentDetails.message}
        setValue={(val) =>
          setAssignmentDetails({ ...assignmentDetails, message: val })
        }
      />
      <div className="grid place-items-center">
        <SubmitButton
          text={'Send Assignment '}
          disabled={
            assignmentDetails.title === '' || assignmentDetails.message === ''
          }
        />
      </div>
    </form>
  )
}

export default AssignmentModal
