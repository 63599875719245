import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import { githubTableHeader } from './data'

const GithubUsersTable = ({ githubUsers }) => {
  return (
    <div className="overflow-x-scroll w-full mt-10  max-w-4xl mx-auto  ">
      <table className={`p-4 w-full rounded-2xl  shadow-custom bg-white`}>
        <tbody className="text-xs xl:text-sm  ">
          <tr className="bg-blue-400 bg-opacity-20 p-2 ">
            {githubTableHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>

          {githubUsers.length !== 0 &&
            githubUsers.map((user, idx) => {
              const { github_id, email, created_date } = user

              return (
                <tr
                  key={idx}
                  className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   "
                >
                  <td className="text-center">
                    <p className=" p-2 px-4 ">{idx + 1}</p>
                  </td>

                  <td className="text-center">
                    <p className=" p-2 px-4 ">{github_id}</p>
                  </td>
                  <td className="text-center">
                    <p className=" p-2 px-4 ">{email} </p>
                  </td>
                  <td className="text-center">
                    <p className=" p-2 px-4 min[] ">
                      {new Date(created_date).toDateString()}{' '}
                    </p>
                  </td>
                </tr>
              )
            })}
        </tbody>
      </table>

      {githubUsers.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
            No Applicants
          </p>
        </Fragment>
      )}
    </div>
  )
}

export default GithubUsersTable
