import React, { useState } from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import useCreditHook from '../../../hooks/useCreditHook'
import creditAction from '../../../redux/actions/creditAction'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import MessageInput from '../create-profile/message-input/MessageInput'
import TextInput from '../create-profile/text-input/TextInput'
import NoCreditComponent from '../credits/NoCreditComponent'
import RippleLoader from '../loader/RippleLoader'

const RaiseIssueModal = ({ close, githubid, refetchData }) => {
  const [emailDetails, setEmailDetails] = useState({
    title: '',
    message: ''
  })
  const { user } = useSelector((state) => state.auth)
  const { raiseIssue } = EndPoints.singleJobListing
  const [isLoading, setIsLoading] = useState(false)
  const { messageEligibility } = useCreditHook()
  const dispatch = useDispatch()

  const handleRaiseIssue = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const postData = {
      issue_title: emailDetails.title,
      issue_body: `@${githubid} ${emailDetails.message.replace(
        /<[^>]+>/g,
        ''
      )}`,
      owner: 'TechResume',
      repo: 'TechResume-user',
      github_id: githubid,
      user_id: user.user_id,
      credit_transaction: true
    }
    try {
      await axiosInstance.post(`${raiseIssue}/createissue`, postData)
      //console.log(data);
      showToast('success', 'Connection Request Sent')

      setIsLoading(false)
      refetchData()
      dispatch(creditAction.fetchUserCredits(user.user_id))
      close()
    } catch (err) {
      console.log(err.message)
    }
  }

  if (!messageEligibility) return <NoCreditComponent />

  return (
    <form
      onSubmit={handleRaiseIssue}
      className="w-full bg-white rounded-2xl shadow-lg m-auto relative p-4 md:p-10 lg:p-12 min-h-[500px]   "
    >
      <button
        onClick={close}
        className="absolute top-6 right-6 text-3xl font-bold text-blue-500"
      >
        <IoMdCloseCircleOutline />
      </button>
      <div className="flex flex-col gap-4  ">
        {isLoading && (
          <div className="absolute inset-0 grid place-items-center bg-white bg-opacity-40">
            <RippleLoader />
          </div>
        )}

        <TextInput
          label={'TItle'}
          required
          placeholder={'Enter Title'}
          value={emailDetails.title}
          setValue={(val) => setEmailDetails({ ...emailDetails, title: val })}
        />
        <MessageInput
          label={'Message'}
          placeholder={'Write Message'}
          value={emailDetails.message}
          setValue={(val) => setEmailDetails({ ...emailDetails, message: val })}
        />
        <div className="grid place-items-center">
          <SubmitButton
            text={'Connect'}
            disabled={emailDetails.title === '' || emailDetails.message === ''}
          />
        </div>
      </div>
    </form>
  )
}

export default RaiseIssueModal
