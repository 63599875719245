import React from 'react'

const TermsPage = () => {
  return (
    <div className="min-h-screen p-6 sm:p-8 md:p-10   flex flex-col gap-6 leading-loose max-w-7xl mx-auto ">
      <p className="text-3xl font-semibold flex flex-col gap-1 ">
        Terms of service - TechProfile
        <span className="text-base"> Last modified: Jan 26, 2023</span>
      </p>

      <p>
        Welcome to TechProfile. This Terms of Service Agreement (&ldquo;Terms of
        Service&rdquo; or &ldquo;Agreement&rdquo;) are a legal agreement between
        you and TechProfile, Inc (&ldquo;TechProfile&rdquo;, &ldquo;us&rdquo;,
        &ldquo;we&rdquo;), governing your access to and use of the services and
        website found at https://amazinghiring.com, including successor domain
        names or sites (the &ldquo;Site&rdquo;), the servers used by the Site,
        and any and all information, text, graphics, photos, or other materials
        uploaded, downloaded, or appearing on the Services (as defined below).
      </p>

      <p>
        BY ACCESSING AND USING THE SERVICES IN ANY MANNER, YOU ARE
        &quot;ACCEPTING&quot; AND AGREEING TO BE BOUND BY THESE TERMS OF SERVICE
        TO THE EXCLUSION OF ALL OTHER TERMS. IF YOU DO NOT UNCONDITIONALLY
        ACCEPT THESE TERMS IN THEIR ENTIRETY, YOU SHALL NOT (AND SHALL HAVE NO
        RIGHT TO) ACCESS OR USE THE SERVICES. IF THE TERMS OF THIS AGREEMENT ARE
        CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS.
        THESE TERMS SHOULD BE READ IN CONJUNCTION WITH AMAZING HIRING&rsquo;S
        PRIVACY POLICY. IF YOU DO NOT AGREE TO THESE TERMS, YOU MAY NOT ACCESS
        THE SERVICES.
      </p>

      <p>
        Wherever used in these Terms of Service, &ldquo;you&rdquo;,
        &ldquo;your&rdquo; or similar terms means the person or legal entity
        accessing or using the Services. If you are accessing and using the
        Services on behalf of a company (such as your employer) or other legal
        entity, you represent and warrant that you have the authority to bind
        that company or other legal entity to these Terms of Service.
      </p>

      <p>
        TechProfile reserves the right, at any time, to update and change any or
        all of these Terms of Service, in its sole discretion, including but not
        limited to the fees and charges associated with the use of the Services.
        If Amazing Hiring does so, it will post the modified Terms of Service on
        the Site and will update the &quot;Last Modified&quot; date above.
        Continued use of the Services following the effective date of changes to
        these Terms or Service shall constitute your consent to such changes.
        You are responsible for regularly reviewing the most current version of
        the Terms of Service, which are currently available at:
        https://techprofile.org/terms-of-service/.
      </p>

      <p>
        <strong>1. DEFINITIONS</strong> &ldquo;Authorization Form&rdquo; means a
        document issued by TechProfile and executed or otherwise agreed upon by
        authorized representatives of the Parties in the form of a software as a
        service agreement or other document specifying, among other things, a
        description of the Services, the fees for your Paid Subscription (as
        defined below), the number of Seats purchased, the subscription term,
        and any other details specifically related to the Services.
      </p>

      <p>
        &ldquo;Authorized Users&rdquo; means individuals who are directly
        accessing the Services via an online sign-up process, or individual
        users authorized by you to use the Services and who you have supplied
        user identifications and passwords to. Authorized Users may include your
        employees, consultants, contractors, agents, or your other designees,
        but shall not include any employee or agent of any Amazing Hiring
        competitor.
      </p>

      <p>
        &ldquo;Seat&rdquo; means a single subscription associated with a single
        log-in to the Site, assigned to one Authorized User.
      </p>

      <p>
        &ldquo;Services&rdquo; means the Amazing Hiring services made available
        via Amazing Hiring&rsquo;s proprietary software delivered through the
        Site, our Chrome extension, and mobile applications.
      </p>

      <p>
        &ldquo;Supported Platform(s)&rdquo; means social networking sites,
        blogs, and third-party sites and platforms.
      </p>

      <p>
        &ldquo;User Content&rdquo; means all information and data that you make
        available to Amazing Hiring to enhance the Services (e.g., your internal
        resume database).
      </p>

      <p>
        <strong>2. SERVICES Description of Services.</strong> TechProfile
        enables you to identify the best technically skilled professionals from
        publicly available candidate profiles and your User Content, if
        applicable, for the specifics of each of your technical listings using a
        proprietary sorting algorithm, making your search for technical talent
        more efficient.
      </p>

      <p>Shall be replaced with a bit extended version:</p>

      <p>
        {' '}
        Availability of Services. You agree to use the Services subject to the
        terms and conditions of this Agreement, our Privacy Policy and
        applicable Authorization Form, solely for your internal business
        purposes. Amazing Hiring shall use commercially reasonable efforts to
        make the Services available to you without interruption, 24 hours a day,
        seven days a week, except for any planned or unscheduled maintenance.
      </p>

      <p>
        Trial Version. We may offer you access and use of the Services on a
        trial basis for a term set forth in the Authorization Form.
      </p>

      <p>
        Support. Customer support will be available to paying customers via
        email within 48 hours, Monday through Friday, except public holidays.
        TechProfile shall have no obligation to provide customer support
        services to any customer enrolled in a free trial.
      </p>

      <p>
        Updates and Functionalities. TechProfile may update the Services from
        time to time without prior notice to you, and such updates may result in
        changes in the appearance and/or functionality of the Services
        (including the addition, modification, or removal of functionality,
        features, or content).
      </p>

      <p>
        Supported Platforms. You acknowledge that the Services may incorporate
        information from Supported Platforms, and that the Services provided are
        highly dependent on the availability of such Supported Platforms. If at
        any time any Supported Platform should cease to make its data available
        to TechProfile on reasonable terms, TechProfile may cease to incorporate
        such data in the Services without entitling you to a refund, credit, or
        other compensation. Amazing Hiring makes no warranty as to the
        reliability or accuracy of any data provided through the Services.
      </p>

      <p>
        Use of the Services. As a condition of using the Services, you agree not
        to use the Services for any purpose that is prohibited by this
        Agreement. You are solely responsible for all of your activity in
        connection with the Services and that of your Authorized Users, and you
        shall abide by all local, state, national, and international laws and
        regulations and any applicable regulatory codes. You agree that you
        SHALL NOT:
      </p>

      <p>
        USE THE SERVICES OR ANY PROFESSIONAL PROFILES GENERATED THROUGH THE
        SERVICE TO DETERMINE SUCH PROFESSIONAL&rsquo;S ELIGIBILITY FOR: (A)
        CREDIT OR INSURANCE FOR PERSONAL, FAMILY, OR HOUSEHOLD PURPOSES; (B)
        EMPLOYMENT; (C) A GOVERNMENT LICENSE OR BENEFIT; OR FOR (D) ANY OTHER
        PURPOSES THAT WOULD REQUIRE FCRA COMPLIANCE; use the Services for any
        purpose other than solely for your internal business purposes; resell,
        rent, lease, loan, sublicense, distribute, or otherwise transfer rights
        to the Services; allow more than one individual Authorized User to use a
        Seat, or sell, trade, or otherwise transfer your Seats to another party;
        collect content or information, or otherwise access the Services using
        automated means (such as bots or scrapers) without our prior permission;
        modify, reverse engineer, decompile or disassemble the Services; upload
        to, or transmit from, the Services any data, file, software, or link
        that contains or redirects to a virus, Trojan horse, worm, or other
        harmful component; copy, adapt, alter, modify, translate, or create
        derivative works of the Services without written authorization of the
        Company; permit other individuals to use the Services, including but not
        limited to shared use via a network connection, except under the terms
        of this Agreement; circumvent or disable any technological features or
        measures in the Service for protection of intellectual property rights;
        use or access the Services to compile data in a manner that is used or
        usable by a competitive product or service; use of your Amazing Hiring
        account to engage in any illegal conduct; attempt to gain unauthorized
        access to the Services or its related systems or networks; or authorize,
        permit, or encourage any third party to do any of the above. you as an
        individual user are entitled to maximum 1000 unique candidates contact
        access credits per month. If you need to exceed this limit, please,
        contact the sales team. TechProfile is an aggregator of job candidates
        profiles from publicly available sources. Prior contacting a job
        candidate the Customer shall check the original source and be aware if
        the information about the job caandidate is uptodate. The Customer, when
        approaching the job candidate, shall refer to the original source.
        TechProfile may, in its discretion, suspend or terminate your access to
        the Services for failure to abide by the terms hereof without
        reimbursement of fee paid for the serivce but not used due to the breach
        of this Agreement.
      </p>

      <p>
        <strong>3. ACCOUNT SECURITY</strong>
        You are solely responsible for activity that occurs on your account and
        it is your responsibility to maintain the confidentiality of your
        username and password. You are also responsible for immediately
        notifying us of any unauthorized use of your account, or breach of your
        account information or password. We will not be liable for any loss that
        you may incur as a result of someone else using your username or
        password, either with or without your knowledge. To the extent allowable
        by law, you shall be liable for any expenses and reasonable
        attorneys&rsquo; fees for your failure to safeguard user and password
        information and/or promptly notify us about unauthorized use of your
        account or breach of your account information or password.
      </p>

      <p>
        <strong>4. USER CONTENT</strong>
        By transmitting or submitting any User Content while using the Services,
        you affirm, represent and warrant that such transmission or submission
        is (a) not in violation of any applicable laws, contractual restrictions
        or other third party rights, and that you have permission from any third
        party whose personal information or intellectual property is comprised
        in the User Content; and (b) free of viruses, adware, spyware, worms or
        other malicious code.
      </p>

      <p>
        <strong>5. CUSTOMER FEEDBACK</strong>
        You may from time to time provide suggestions, comments or other
        feedback to TechProfile with respect to the Services
        (&ldquo;Feedback&rdquo;). Feedback, even if designated as confidential
        by you, shall not create any confidentiality obligation for Amazing
        Hiring notwithstanding anything else. You shall, and hereby do, grant to
        Techprofile a non-exclusive, worldwide, perpetual, irrevocable,
        transferable, sub licensable, royalty-free, fully paid-up license to use
        and exploit the Feedback for any purpose.
      </p>

      <p>
        <strong>6. SUBSCRIPTION</strong>
        Subscription. You may access and use the Site and/or Services either on
        a trial basis, if applicable, or based on a paid subscription
        (&ldquo;Paid Subscription&rdquo;), in each case on the terms set forth
        in your Authorization Form.
      </p>

      <p>
        <strong>7. TERM AND TERMINATION</strong>
        Paid Subscription. If you signed-up for a Paid Subscription, this
        Agreement shall commence on the day you first access the Services and
        shall continue until your account is cancelled as set forth herein.
      </p>

      <p>
        Auto-Renewal and Cancellation. Your Paid Subscription will automatically
        renew at the end of its then current term, for another term of the same
        length, unless you cancel it prior to the commencement of such new term.
        You may elect to cancel your Paid Subscription effective as of the end
        of the then current term on the Site in your &ldquo;Account
        Settings&rdquo; or by contacting us at: sales@amazinghiring.com.
      </p>

      <p>
        Termination. If you violate the letter or spirit of these Terms of
        Service, abuse the Services, or otherwise create risk or possible legal
        exposure to Amazing Hiring, we can terminate or suspend the Services in
        our sole discretion.
      </p>

      <p>
        Effects of Termination. Upon termination of this Agreement for any
        reason, (i) you will immediately cease all use of the Services; (ii) you
        will have no further access to your accounts provided by Amazing Hiring;
        and (iii) you will pay Amazing Hiring all unpaid amounts owing to
        Amazing Hiring.
      </p>

      <p>
        Survival. Any provision of this Agreement which, either by its terms or
        to give effect to its meaning, must survive, and such other provisions
        which expressly, or by their nature, are intended to survive termination
        shall survive the expiration or termination of this Agreement.
      </p>

      <p>
        <stronog>8. WARRANTY</stronog>
        DISCLAIMER EXCEPT AS EXPRESSLY PROVIDED HEREIN, TO THE GREATEST EXTENT
        PERMITTED BY APPLICABLE LAW, AMAZING HIRING EXPRESSLY EXCLUDES AND
        DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS, IMPLIED,
        STATUTORY, OR OTHERWISE. WITHOUT LIMITING THE GENERALITY OF THE
        FOREGOING, AMAZING HIRING SPECIFICALLY DISCLAIMS ALL EXPRESS OR IMPLIED
        WARRANTIES OF DESIGN, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
        TITLE, QUALITY, AND NONINFRINGEMENT, THAT ITS SERVICES WILL MEET YOUR
        REQUIREMENTS, OR THAT ITS SERVICES WILL ALWAYS BE AVAILABLE, ACCESSIBLE,
        UNINTERRUPTED, TIMELY, SECURE, CURRENT, ACCURATE, COMPLETE, OR
        ERROR-FREE. IN ADDITION, AMAZING HIRING DOES NOT WARRANT ANY CONNECTION
        TO OR TRANSMISSION FROM THE INTERNET. NO ADVICE OR INFORMATION, WHETHER
        ORAL OR WRITTEN, OBTAINED FROM AMAZING HIRING OR ELSEWHERE WILL CREATE
        ANY WARRANTY OR CONDITION NOT EXPRESSLY STATED IN THIS AGREEMENT. EXCEPT
        AS EXPRESSLY PROVIDED HEREIN, THE SERVICES ARE PROVIDED ON AN &ldquo;AS
        IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS. THESE LIMITATIONS SHALL
        APPLY NOTWITHSTANDING THE FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
        REMEDY PROVIDED HEREIN. AMAZING HIRING DISCLAIMS ANY AND ALL
        RESPONSIBILITY OR LIABILITY IN RELATION TO THE CONTENT MADE AVAILABLE
        THROUGH THE SERVICES, INCLUDING USER CONTENT OR ANY CONTENT OR SERVICES
        PROVIDED BY THIRD PARTIES. AMAZING HIRING IS NOT RESPONSIBLE OR LIABLE
        IN ANY MANNER FOR ANY THIRD-PARTY SERVICES OR SUPPORTED PLATFORMS
        ASSOCIATED WITH OR UTILIZED IN CONNECTION WITH THE SERVICES, INCLUDING
        THE FAILURE OF ANY SUCH THIRD-PARTY SERVICES OR SUPPORTED PLATFORMS.
        AMAZING HIRING EXPRESSLY DENIES ANY RESPONSIBILITY RESULTING FROM
        HACKING, TAMPERING, OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICES
        OR YOUR ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
      </p>

      <p>
        SOME COUNTRIES AND JURISDICTIONS DO NOT ALLOW THE DISCLAIMER OF IMPLIED
        TERMS IN CONTRACTS WITH CONSUMERS AND AS A RESULT THE CONTENTS OF THIS
        SECTION MAY NOT APPLY TO YOU.
      </p>

      <p>
        <strong>9. INDEMNIFICATION</strong>
        You shall defend, indemnify, and hold harmless Amazing Hiring, its
        affiliates, directors, officers, employees, and agents from and against
        all claims, losses, damages, penalties, liability, and costs, including
        reasonable attorneys&rsquo; fees, of any kind or nature which are in
        connection with or arising out of a claim (a) alleging that your use of
        the Services infringes or violates the intellectual property rights,
        privacy rights, or other rights of a third party or violates applicable
        law; or (b) your breach of Section 3 of this Agreement.
      </p>

      <p>
        <strong>10. LIMITATION </strong>
        OF LIABILITY TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL
        AMAZING HIRING&rsquo;S AGGREGATE LIABILITY FOR ALL CLAIMS OF ANY KIND,
        INCLUDING ANY CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT,
        WHETHER BY STATUTE, CONTRACT, TORT, OR UNDER ANY OTHER THEORY OF
        LIABILITY, EXCEED THE GREATER OF (A) THE SUBSCRIPTION FEES PAID BY YOU
        FOR THE SERVICES HEREUNDER DURING THE THREE (3) MONTH PERIOD IMMEDIATELY
        PRECEDING THE DATE ON WHICH THE CAUSE OF ACTION AROSE AND (B) US$10,000.
        AMAZING HIRING DOES NOT OFFER ANY WARRANTY OR REMEDIES FOR THE
        INTERRUPTION OR CESSATION OF ACCESS OR TRANSMISSION TO OR FROM THE
        SERVICES.
      </p>

      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
        AMAZING HIRING, ITS DIRECTORS, EMPLOYEES, AGENTS, OR LICENSORS BE LIABLE
        FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR
        EXEMPLARY DAMAGES, INCLUDING DAMAGES FOR LOSS OF PROFITS, GOODWILL USE,
        OR DATA OR OTHER INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR
        INABILITY TO USE, THE SERVICES OR ANY OTHER ASPECT OF THIS AGREEMENT.
        UNDER NO CIRCUMSTANCES WILL AMAZING HIRING BE RESPONSIBLE FOR ANY
        DAMAGE, LOSS, OR INJURY RESULTING FROM HACKING, TAMPERING, OR OTHER
        UNAUTHORIZED ACCESS OR USE OF THE SERVICES OR YOUR ACCOUNT OR THE
        INFORMATION CONTAINED THEREIN.
      </p>

      <p>
        THE PARTIES ACKNOWLEDGE AND AGREE THAT THE ESSENTIAL PURPOSE OF THIS
        SECTION IS TO ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE PARTIES
        AND LIMIT THEIR POTENTIAL LIABILITY GIVEN THE FEES CHARGED UNDER THIS
        AGREEMENT, WHICH WOULD HAVE BEEN SUBSTANTIALLY HIGHER IF TechProfile
        WERE TO ASSUME ANY FURTHER LIABILITY OTHER THAN AS SET FORTH HEREIN. THE
        PARTIES HAVE RELIED ON THESE LIMITATIONS IN DETERMINING WHETHER TO ENTER
        INTO THESE TERMS. NOTHING IN THIS AGREEMENT IS INTENDED TO EXCLUDE OR
        RESTRICT OR SHALL BE CONSTRUED AS EXCLUDING OR RESTRICTING THE LIABILITY
        OF AMAZING HIRING FOR (I) DEATH OR PERSONAL INJURY CAUSED BY THE
        NEGLIGENCE OF AMAZING HIRING, ITS EMPLOYEES, OR ITS AGENTS; (II) WILLFUL
        MISCONDUCT OF AMAZING HIRING; OR (III) ANY LIABILITY WHICH CANNOT BE
        LIMITED OR EXCLUDED BY APPLICABLE LAW.
      </p>

      <p>
        SOME COUNTRIES DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY IN
        CONTRACTS WITH CONSUMERS AND AS A RESULT THE CONTENTS OF THIS SECTION
        MAY NOT APPLY TO YOU.
      </p>

      <p></p>
    </div>
  )
}

export default TermsPage
