import React from 'react'

const Error = () => {
  return (
    <div className="grid place-items-center">
      <lottie-player
        src="https://assets9.lottiefiles.com/packages/lf20_GrGBqA.json"
        background="transparent"
        speed="1"
        style={{ width: '100%', objectFit: 'contain' }}
        loop
        autoplay
      ></lottie-player>
    </div>
  )
}

export default Error
