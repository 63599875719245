import React from 'react'
import { Fragment } from 'react'
import uuid from 'react-uuid'
import ListingPagination from '../pagination/ListingPagination'
import { AdminContactHeader } from './data'
import SingleAdminContactTable from './SingleAdminContactTable'

const AdminContactTable = ({
  contactData,
  fetchAllContact,
  page,
  onPageChange,
  count,
  limit
}) => {
  return (
    <div>
      <div className="overflow-x-scroll w-full mt-10">
        <table className={`p-4 w-full rounded-2xl  shadow-custom bg-white`}>
          <tbody className="text-xs xl:text-sm rounded-lg ">
            <tr className="bg-blue-400 bg-opacity-20 p-2 ">
              {AdminContactHeader.map((head) => (
                <th key={uuid()} className="text-center p-2 py-3 ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {contactData.length !== 0 &&
              contactData.map((singleJob, idx) => (
                <SingleAdminContactTable
                  key={uuid()}
                  data={singleJob}
                  idx={idx}
                  fetchAllContact={fetchAllContact}
                  limit={limit}
                  page={page}
                />
              ))}
          </tbody>
        </table>
        {contactData.length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
              No Jobs Found
            </p>
          </Fragment>
        )}
        <ListingPagination
          page={page}
          onPageChange={onPageChange}
          count={count}
        />
      </div>
    </div>
  )
}

export default AdminContactTable
