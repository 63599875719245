import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import { repoHeaderData } from '../../repo-table/RepoHeaderData'
import SingleRepotable from '../../repo-table/SingleRepotable'

const RepoTable = ({ data, userData }) => {
  return (
    <div className="p-4 sm:p-6 ">
      <div className="">
        <div className="flex justify-between items-center mb-6 sm:px-6">
          <h3 className="font-semibold ">Top Repositories</h3>
        </div>

        <div className="overflow-x-scroll lg:overflow-auto">
          <table
            className={`p-4 w-full rounded-lg overflow-hidden shadow-custom bg-white`}
          >
            <tbody className="text-xs xl:text-sm ">
              <tr className="bg-blue-400 bg-opacity-20 p-2 ">
                {repoHeaderData.map((head) => (
                  <th
                    style={{ width: head.width }}
                    key={uuid()}
                    className="text-center p-2 py-3 "
                  >
                    {head.title}{' '}
                  </th>
                ))}
              </tr>

              {data.length !== 0 &&
                data
                  .sort((b, a) => a.stars - b.stars)
                  .slice(0, 3)
                  .map((repoData) => (
                    <SingleRepotable
                      key={uuid()}
                      data={repoData}
                      userData={userData}
                    />
                  ))}
            </tbody>
          </table>
        </div>

        {data.length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
              No repositories Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default RepoTable
