import React from 'react'
import AuthComponent from './AuthComponent'
import { IoClose } from 'react-icons/io5'
import { LOGIN_PAGE } from '../../../navigation/routes'
import { Link } from 'react-router-dom'

const AuthModal = ({ close }) => {
  return (
    <div className=" w-full grid place-items-center p-2 max-w-xl bg-white rounded-xl  md:p-10 lg:p-12 flex-col gap-4  relative">
      <button
        onClick={close}
        className="absolute top-4 right-4 text-xl font-semibold text-black"
      >
        <IoClose />
      </button>
      <h2 className="text-2xl text-center font-semibold mb-2">
        Join TechProfile
      </h2>

      <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>
      <div className="flex flex-col gap-8">
        <p className="text-center">
          To get the most out of our website, we encourage you to create an
          account and sign in. By doing so, you'll be able to access all of our
          content and features, as well as personalize your experience and save
          your preferences.
        </p>

        <Link
          className="p-3 px-6 text-sm mx-auto max-w-max bg-blue-500 text-white rounded-2xl"
          to={LOGIN_PAGE}
        >
          Login Now
        </Link>
      </div>
    </div>
  )
}

export default AuthModal
