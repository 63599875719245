import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ClickWrapper from '../../components/click-wrapper/ClickWrapper'
import CreateUserModal from '../../components/create-user/CreateUserModal'
import CreateUserTable from '../../components/create-user/CreateUserTable'
import ErrorComponent from '../../components/error/ErrorComponent'

import RippleLoader from '../../components/loader/RippleLoader'

const CreateUser = () => {
  const [loading, setLoading] = useState(true)
  const [showCreateUserModal, setShowCreateUserModal] = useState(false)
  const [createdUsers, setCreatedUsers] = useState([])
  const { showCreatedUser } = EndPoints.createUser
  const { user } = useSelector((state) => state.auth)

  const fetchCreatedUsers = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${showCreatedUser}/getchilduser?user_id=${user.user_id}`
      )

      console.log(JSON.parse(data.body).message)
      setCreatedUsers(JSON.parse(data.body).message)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchCreatedUsers()
  }, [])

  return (
    <div className="p-4 sm:p-6 md:p-10 min-h-screen w-full">
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="flex flex-col">
          <div className="flex justify-end items-center relative ">
            {showCreateUserModal && (
              <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
                <div className="w-full max-w-xl p-4 m-auto z-50">
                  <ClickWrapper func={() => setShowCreateUserModal(false)}>
                    <CreateUserModal
                      fetchCreatedUsers={fetchCreatedUsers}
                      closeTab={() => setShowCreateUserModal(false)}
                    />
                  </ClickWrapper>
                </div>
              </div>
            )}
          </div>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <CreateUserTable
              data={createdUsers}
              fetchCreatedUsers={fetchCreatedUsers}
              setShowCreateUserModal={setShowCreateUserModal}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default CreateUser
