import React from 'react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import uuid from 'react-uuid'
import YearSelect from '../../select/YearSelect'
import { contributorHeaderData } from './ContributorHeaderData'
import SingleContributorTable from './SingleContributorTable'

const ContributorTable = ({ data, commitFetching }) => {
  // const [year, setYear] = useState(new Date().getFullYear());
  // const [filteredData, setFilteredData] = useState(data);
  const [filteredContributorData, setFilteredContributorData] = useState(data)
  const [contributorText, setContributorText] = useState('')

  const filterChange = (text) => {
    setFilteredContributorData(
      data.filter(
        (user) =>
          user.user_name.toUpperCase().includes(text.toUpperCase()) ||
          user.github_id.toUpperCase().includes(text.toUpperCase())
      )
    )
  }

  const onTextChange = (e) => {
    setContributorText(e.target.value)
    filterChange(e.target.value)
  }

  // let optionArr = []

  // for (var i = 0; i <= new Date().getFullYear() - year_joined; i++) {
  // 	optionArr.push(i);
  // }

  // const filterObject = (obj, filter, filterValue) =>
  // 	Object.keys(obj).reduce(
  // 		(acc, val) =>
  // 			obj[val][filter]?.split('-')[0] !== filterValue.toString()
  // 				? acc
  // 				: {
  // 						...acc,
  // 						[val]: obj[val],
  // 				  },
  // 		{},
  // 	);

  // useEffect(() => {
  // 	const filteredObj = filterObject(data, 'created_date', year);
  // 	setFilteredData(filteredObj);
  // }, [year]);
  return (
    <div className="flex flex-col gap-4 mt-10">
      {/* <div className="flex justify-between items-center mb-6 sm:px-6">
					<h3 className="font-semibold ">Top Repositories</h3>

					<YearSelect
						arr={optionArr}
						value={year}
						onChange={(e) => setYear(e.target.value)}
					/>
				</div> */}
      {commitFetching && <p>Fetching commit data ...</p>}
      <form className="flex items-center border border-gray-500 border-opacity-40 rounded-md px-3 w-full max-w-xs ">
        <FiSearch />
        <input
          value={contributorText}
          onChange={onTextChange}
          type="text"
          placeholder="Find Contributors"
          className="bg-transparent placeholder-gray-400 p-3 text-sm  border-none outline-none "
        />
      </form>

      {/* single repo cards */}
      {/* <div className="py-6 grid grid-cols-1  gap-6 ">
					{Object.keys(filteredData).map((repoData) => (
						<SingleRepo data={data[repoData]} key={uuid()} />
					))}
				</div> */}
      <table
        className={`p-4 w-full rounded-lg overflow-hidden shadow-custom bg-white`}
      >
        <tbody className="text-xs xl:text-sm ">
          <tr className="bg-blue-400 bg-opacity-20 p-2 ">
            {contributorHeaderData.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>

          {filteredContributorData.length !== 0 &&
            filteredContributorData.map((repoData, idx) => (
              <SingleContributorTable
                key={uuid()}
                index={idx}
                data={repoData}
              />
            ))}
        </tbody>
      </table>
      {filteredContributorData.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
            No Contributors Found
          </p>
        </Fragment>
      )}
    </div>
  )
}

export default ContributorTable
