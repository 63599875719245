import React from 'react'
import { Fragment } from 'react'
import uuid from 'react-uuid'
import ListingPagination from '../pagination/ListingPagination'
import SingleResponsiveLeaderboardTable from './SingleResponsiveLeaderboardTable'

const ResponsiveLeaderboardTable = ({
  data,
  page,
  onPageChange,
  count,
  limit
}) => {
  return (
    <div className="flex flex-col gap-3">
      {data.map((singleLeaderboard, index) => (
        <SingleResponsiveLeaderboardTable
          key={uuid()}
          data={singleLeaderboard}
          limit={limit}
          index={index}
          page={page}
        />
      ))}
      {data.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
            No Applicants Found
          </p>
        </Fragment>
      )}
      <ListingPagination
        page={page}
        onPageChange={onPageChange}
        count={count}
      />
    </div>
  )
}

export default ResponsiveLeaderboardTable
