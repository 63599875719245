import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import RippleLoader from '../loader/RippleLoader'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'

const ForgetPassword = ({ toggleTab }) => {
  const [showEmail, setShowEmail] = useState(true)
  const [showOTP, setShowOTP] = useState(false)
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [OTP, setOTP] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const [emailToken, setEmailToken] = useState('')
  const [otpToken, setOtpToken] = useState('')
  const [errors, setErrors] = useState({
    email: '',
    otp: '',
    password: '',
    confirmPassword: ''
  })
  const alert = useAlert()

  const { authSendOtp, authValidateOtp, authChangePassword } = EndPoints.auth

  const handleEmailSubmit = async (e) => {
    e.preventDefault()
    if (email.length === 0) {
      setErrors({
        ...errors,
        email: 'Enter a valid Email'
      })
      return
    } else {
      setLoading(true)
      try {
        const { data } = await axiosInstance.post(authSendOtp, {
          email,
          type: 'FORGET'
        })
        console.log(JSON.parse(data.body))

        if (JSON.parse(data.body).error) {
          alert.error(JSON.parse(data.body).error)
        } else {
          setEmailToken(JSON.parse(data.body).message)
          // setOTP(JSON.parse(data.body).otp)
          setShowEmail(false)
          setShowOTP(true)
        }
        setLoading(false)
      } catch (err) {
        console.log(err.message)
      }
    }
  }
  const validateOTP = async (e) => {
    e.preventDefault()
    if (OTP.length === 0) {
      setErrors({
        ...errors,
        otp: 'Enter a valid OTP'
      })
      return
    }
    setLoading(true)
    const header = { headers: { Authorization: `Bearer ${emailToken}` } }
    try {
      const { data } = await axiosInstance.post(
        authValidateOtp,
        {
          otp: OTP
        },
        header
      )
      console.log(data)
      if (data.error) {
        alert.error(data.error)
      } else {
        setOtpToken(data.token)
        setShowOTP(false)
        setShowChangePassword(true)
      }
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }
  const handleChangePassword = async (e) => {
    e.preventDefault()
    if (password.length === 0 || confirmPassword.length === 0) {
      if (password.length === 0) {
        setErrors({
          ...errors,
          password: 'Enter a valid password'
        })
      } else if (confirmPassword.length === 0) {
        setErrors({
          ...errors,
          confirmPassword: 'Enter a valid password'
        })
      }

      return
    } else {
      if (password !== confirmPassword) {
        setErrors({
          ...errors,
          confirmPassword: "Password doesn't match"
        })
        return
      }

      setLoading(true)

      const header = { headers: { Authorization: `Bearer ${otpToken}` } }
      try {
        const { data } = await axiosInstance.post(
          authChangePassword,
          {
            type: 'FORGET',
            npassword: password
          },
          header
        )

        alert.success(JSON.parse(data.message).message)

        toggleTab(0)
        setLoading(false)
      } catch (err) {
        console.log(err.message)
      }
    }
  }
  return (
    <div className="flex flex-col items-center w-full h-full overflow-y-scroll  scrollbar-hidden pb-3 relative">
      {loading && (
        <div className="absolute grid place-items-center w-full h-full top-0 left-0 z-10 bg-white bg-opacity-20 backdrop-filter blur-sm">
          <RippleLoader />
        </div>
      )}

      <div className="flex flex-col w-full px-2">
        <h1 className="text-lg md:text-2xl mb-2 font-semibold text-blue-200">
          Password Assistance ?{' '}
        </h1>

        {showEmail && (
          <p className="text-xs font-500 text-gray-100">
            Enter your email address
          </p>
        )}
      </div>

      {showEmail && (
        <form
          onSubmit={handleEmailSubmit}
          className="w-full flex flex-col my-4 p-6 rounded-lg bg-white bg-opacity-10 backdrop-filter  backdrop-blur-sm "
        >
          <EmailField
            checkEmail={false}
            value={email}
            name="email"
            errorMessage={email.length === 0 && errors.email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <SubmitButton disabled={email.length === 0} text={' Send OTP'} />
        </form>
      )}

      {showOTP && (
        <form onSubmit={validateOTP} className="w-full flex flex-col my-4 p-2 ">
          <label className="flex flex-col text-xs w-full font-600 my-2 z-0">
            <p> A OTP has been sent to your email . Enter OTP* </p>

            <input
              id=""
              value={OTP}
              name="number"
              onChange={(e) => setOTP(e.target.value.toString())}
              className=" p-2 px-3 rounded-xl border-2 text-gray-700 text-lg tracking-widest outline-none  my-2 font-600 border-gray-300 placeholder-gray-400"
              type="number"
              placeholder="Enter OTP"
            />
            {OTP.length === 0 && (
              <p className="text-red-500 text-xs ml-4">{errors.otp}</p>
            )}
          </label>
          <button
            onClick={handleEmailSubmit}
            className="w-full text-center text-blue-500 text-xs font-600 hover:text-blue-200 cursor-pointer my-2 mb-4 "
          >
            Resend OTP
          </button>
          <SubmitButton text={' Validate OTP'} />
        </form>
      )}

      {showChangePassword && (
        <form
          onSubmit={handleChangePassword}
          className="w-full flex flex-col my-4 p-2 "
        >
          <PasswordField
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            errorMessage={password.length === 0 && errors.password}
            labelText={'Set Password'}
          />
          <PasswordField
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            errorMessage={
              (confirmPassword.length === 0 || password !== confirmPassword) &&
              errors.confirmPassword
            }
            labelText={'Confirm Password'}
          />

          <SubmitButton text={' Save Changes and Sign In'} />
        </form>
      )}
    </div>
  )
}

export default ForgetPassword
