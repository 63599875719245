export const HOME_PAGE = '/'
export const RECRUITER_PAGE = '/recruiter'
export const ALL_JOBS_PAGE = '/jobs'
export const APPLICANT_SIGNIN_PAGE = '/developer/login'
export const APPLICANT_SIGNUP_PAGE = '/developer/create'
export const SIGNUP_PAGE = '/recruiter/signup'
export const LOGIN_PAGE = '/recruiter/login'
export const DASHBOARD_PAGE = '/dashboard/:username'
export const RECRUITER_DASHBOARD_PAGE = '/recruiter/dashboard'
export const PUBLIC_DASHBOARD_PAGE = '/:username'
export const PROFILE_PAGE = '/profile/:username'
export const RECRUITER_PROFILE_PAGE = '/user/profile'
export const CREATE_USER_PROFILE_PAGE = '/profile/create'
export const REPOSITORY_PAGE = '/repository/:username/:repository'
export const CREATE_PROFILE_PAGE = '/apply'
export const CREATE_USER_PAGE = '/create-user/:userId'
export const JOB_LISTING_PAGE = '/job-listing'
export const PROJECT_LISTING_PAGE = '/project-listing'
export const APPLICANT_JOB_LISTING_PAGE = '/:username/jobs'
export const SINGLE_JOB_LISTING_PAGE = '/job-listing/:jobId'
export const SINGLE_PROJECT_LISTING_PAGE = '/project-listing/:projectId'
export const DYNAMIC_JOB_APPLY_PAGE = '/:companyName/:jobId/apply'
export const PROJECT_APPLY_PAGE = '/:companyName/projects/:projectId/apply'
export const PROCESS_CANDIDATE_PAGE = '/process-candidate'
export const SEARCH_USER_PAGE = '/search-user'
export const ADVANCED_SEARCH_USER_PAGE = '/advanced-search-user'
export const SHORTLIST_STUDENT_PAGE = '/shortlists'
export const LEADERBOARD_PAGE = '/leaderboard'
export const COLLEGE_LEADERBOARD_PAGE = '/leaderboard/colleges'
export const SINGLE_COLLEGE_PAGE = '/colleges/stats/:collegeName'
export const RESUME_SUMMARY_PAGE = '/resume-summary'
export const RESUME_JOB_PAGE = '/resumevsjob'
export const RESUME_INTRODUCTION_PAGE = '/resume-intro'
export const RESUME_INSIGHT_PAGE = '/resume-insight'
export const ADMIN_PAGE = '/admin/jobs'
export const SUPER_ADMIN_PAGE = '/admin/users'
export const ADMIN_CONTACT_PAGE = '/admin/contacts'
export const ADMIN_ACTIVITY_PAGE = '/admin/recent-activity'
export const CERTIFICATION_PAGE = '/certification'
export const POLICY_PAGE = '/privacy-policy'
export const TERMS_PAGE = '/terms-and-conditions'
export const PRICING_PAGE = '/pricing'
export const PROJECTS_INSIGHT_PAGE = '/projects-insights'
export const PROJECTS_PAGE = '/projects'
export const CONTACT_PAGE = '/contact'
export const ABOUT_PAGE = '/about-us'
export const ERROR_PAGE = '*'
