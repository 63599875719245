import React from 'react'

const TechProfileLogo = () => {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 239 222"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.2903 78.94V47.15H0.490234V78.94H32.2903ZM148.71 122.11V84.66H38.0002V122.11H86.4202V221.41H123.89V122.11H148.71ZM154.42 165.29H155.88C166.702 165.29 177.419 163.158 187.417 159.017C197.416 154.875 206.5 148.805 214.153 141.153C221.805 133.5 227.876 124.415 232.017 114.417C236.159 104.418 238.29 93.7022 238.29 82.88C238.29 72.0577 236.159 61.3415 232.017 51.343C227.876 41.3446 221.805 32.2598 214.153 24.6073C206.5 16.9548 197.416 10.8845 187.417 6.74306C177.419 2.60157 166.702 0.46997 155.88 0.469971H43.2502V37.93H155.88C161.961 37.6431 168.038 38.5931 173.741 40.7226C179.445 42.8521 184.657 46.1168 189.062 50.319C193.467 54.5212 196.974 59.5737 199.37 65.1704C201.766 70.7672 203.001 76.7919 203.001 82.88C203.001 88.968 201.766 94.9928 199.37 100.59C196.974 106.186 193.467 111.239 189.062 115.441C184.657 119.643 179.445 122.908 173.741 125.037C168.038 127.167 161.961 128.117 155.88 127.83H154.42V165.29Z"
        fill="#0091FE"
      />
    </svg>
  )
}

export default TechProfileLogo
