import React from 'react'
import uuid from 'react-uuid'
import SingleApplicantProjectCard from './SingleApplicantProjectCard'

const ApplicantProjectListingWrapper = ({
  allProjectData,
  fetchAllProjects
}) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 w-full max-w-7xl m-auto gap-4 sm:gap-6 md:gap-8 ">
      {allProjectData.length === 0 && (
        <p className="font-semibold text-center py-20 ">No Job Listed</p>
      )}
      {allProjectData.map((singleProjectData) => (
        <SingleApplicantProjectCard
          singleProjectData={singleProjectData}
          key={uuid()}
          fetchAllProjects={fetchAllProjects}
        />
      ))}
    </div>
  )
}

export default ApplicantProjectListingWrapper
