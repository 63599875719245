import React from 'react'
import uuid from 'react-uuid'
import LanguageLogo from '../../language-logo/LanguageLogo'
import LanguageBar from '../../radial-bar/LanguageBar'

const UserCommitLanguage = ({ languages }) => {
  return (
    <div className="p-4 sm:p-6">
      <div className="shadow-custom w-full flex flex-col gap-6 rounded-md bg-white p-3 sm:p-6 ">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold truncate mr-2 ">
            Languages based on user commits
          </h3>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
          {languages.map((language) => (
            <div key={uuid()} className="flex gap-4">
              <LanguageLogo
                language={language.language_name}
                logo={language.language_logo}
              />
              <div className="flex-1">
                <LanguageBar
                  value={language.language_value}
                  language={language.language_name}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UserCommitLanguage
