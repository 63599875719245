export const repoHeaderData = [
  {
    title: 'Repository',
    width: '30%'
  },
  {
    title: 'Languages',
    width: '25%'
  },
  {
    title: 'Commits',
    width: '10%'
  },
  {
    title: 'Stars',
    width: '10%'
  },
  {
    title: 'Forks',
    width: '10%'
  },
  {
    title: 'Size (KB)',
    width: '15%'
  }
]
