import React from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { CREATE_USER_PROFILE_PAGE } from '../../../../../navigation/routes'

const CreateProfileModal = ({ username, close }) => {
  const { githubUser } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  return (
    <div className="w-full bg-white rounded-2xl shadow-lg m-auto relative p-4 md:p-10 lg:p-12 flex flex-col gap-4 max-h-[90vh]   ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-3xl font-bold text-blue-500"
      >
        <IoMdCloseCircleOutline />
      </button>
      <h2 className="text-xl text-center font-semibold mb-2">Create Profile</h2>

      <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>

      <p className="py-6 text-center">
        {githubUser.username === username ? 'You' : 'This user'} haven't created
        your profile . To share or Download your Report , You need to Create
        Profile with TechProfile{' '}
      </p>
      <button
        className="p-2 px-4 rounded-full border-2 border-blue-500 text-blue-500 m-auto w-60 text-sm font-semibold hover:bg-blue-100"
        onClick={() => navigate(CREATE_USER_PROFILE_PAGE)}
      >
        Create Your Profile Now
      </button>
    </div>
  )
}

export default CreateProfileModal
