import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import creditAction from '../../../redux/actions/creditAction'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import ProjectApplicantsTable from '../../components/project/ProjectApplicantsTable'

const SingleProjectListing = () => {
  const { projectId } = useParams()
  const [loading, setLoading] = useState(true)
  const [singleProjectData, setSingleProjectData] = useState([])
  const { userCredits } = useSelector((state) => state.credits)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const fetchSingleProjectData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getProjApplciant?project_id=${projectId}`
      )

      setSingleProjectData(JSON.parse(data.message))
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleProjectData()
  }, [])

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10">
      {loading ? (
        <RippleLoader />
      ) : (
        <div>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <ProjectApplicantsTable
              data={singleProjectData}
              fetchAllApplicants={fetchSingleProjectData}
              fetchCreditData={() =>
                dispatch(creditAction.fetchUserCredits(user.user_id))
              }
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default SingleProjectListing
