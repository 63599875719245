import React from 'react'
import ReactApexChart from 'react-apexcharts'

const CollegeCommitChart = ({ data }) => {
  console.log(data)
  const series = [
    {
      name: 'Project',
      data: data.map((data) => data.total)
    }
  ]

  const options = {
    chart: {
      type: 'area',
      toolbar: {
        show: false
      }
    },

    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      //   type: 'datetime',

      categories: data.map((data) => data.month)
    },
    tooltip: {
      // x: {
      // 	format: 'dd/MM/yy HH:mm',
      // },
      theme: 'dark'
    }
  }
  return (
    <div className="p-4 sm:p-6 bg-white shadow-custom rounded-xl ">
      <div className="flex justify-between items-center">
        <h3 className="font-semibold">Total Project Commits</h3>
      </div>

      <ReactApexChart
        options={options}
        series={series}
        type="area"
        height={250}
      />
    </div>
  )
}

export default CollegeCommitChart
