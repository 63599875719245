export const reactSelectStyleUtils = {
  control: (base) => ({
    ...base,
    padding: '3px',
    borderColor: 'rgba(0,0,0,.25)',
    borderRadius: '.5rem',
    boxShadow: 'none',
    '&:hover': {
      borderColor: 'rgba(0,0,0,.25) !important'
    }
  })
}
