import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import ReactHtmlParser from 'react-html-parser'
import { AiFillFileWord } from 'react-icons/ai'
import { MdPictureAsPdf, MdSummarize, MdUpload } from 'react-icons/md'
import { toast } from 'react-toastify'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import ResumeLoader from '../../components/resume/ResumeLoader'
import IconButton from '../../ui-component/buttons/IconButton'

const ResumeIntroPage = () => {
  const [showSummary, setShowSummary] = useState(false)
  const [resumeFile, setResumeFile] = useState()
  const [fileName, setFileName] = useState('')
  const { uploadJobLogo } = EndPoints.createJob
  const [loading, setLoading] = useState(false)
  const [summary, setSummary] = useState('')
  const [email, setEmail] = useState('')

  const maxSize = 2097152

  const {
    getRootProps,
    getInputProps,
    open,
    acceptedFiles,
    isDragActive,
    isDragReject
  } = useDropzone({
    // Disable click and keydown behavior
    accept: {
      file: ['.doc', '.docx', '.pdf']
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true
  })

  const generateSummary = async () => {
    setLoading(true)
    console.log('files', acceptedFiles)
    let message
    if (!fileName || email.length === 0) {
      if (!fileName) {
        message = 'Upload a valid File'
      } else if (email === '') {
        message = 'Enter your Email'
      }
      showToast('error', message)

      return
    }
    try {
      await uploadResume()
      const { data } = await axiosInstance.post(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/userSelfIntroGPT`,
        {
          resume_name: fileName,
          email
        }
      )
      console.log(data)
      //console.log(JSON.parse(data.body));
      const parsedData = data.message.split('\n').join('<br>')
      setSummary(parsedData)
      setShowSummary(true)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
      setLoading(false)
      showToast('error', 'Server is Overloaded . Please try after 15 mins')
    }
  }
  const uploadResume = async () => {
    console.log(resumeFile)
    if (!resumeFile) return

    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': resumeFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'github-cv'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      const res = await axiosInstance.put(uploadUrl, resumeFile, options)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (acceptedFiles.length === 0) return

    setResumeFile(acceptedFiles[0])
    setFileName(`${uuid()}-${acceptedFiles[0].name.split(' ').join('')}`.trim())
  }, [acceptedFiles])

  return (
    <div className="w-full min-h-screen p-6 sm:p-8 md:p-10 relative">
      {loading && <ResumeLoader />}
      <div className="max-w-6xl mx-auto pb-10 flex gap-10">
        <div className="  flex flex-col gap-4  ">
          <h1
            style={{ lineHeight: '1.5' }}
            className="text-3xl md:text-4xl  font-bold "
          >
            " Tell Me <span className="text-blue-500"> About </span>
            <span className="text-orange-500 ml-2">Yourself</span> " answer
            generator
          </h1>
          <p>
            The easiest way to craft a winning response to one of the most
            common job interview questions!
          </p>
          <p>
            Simply upload your resume and our AI-powered tool will analyze your
            skills and experience to generate a personalized response that
            showcases your strengths and accomplishments.{' '}
          </p>
        </div>
      </div>

      {!showSummary ? (
        <div className="w-full flex flex-col gap-6 max-w-3xl mx-auto text-sm  shadow-lg  rounded-2xl  bg-white border-2 p-6">
          <label className="flex flex-col gap-4 max-w-xs ">
            <p className="font-bold">Enter your Email</p>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              placeholder="Enter your email ."
              className="h-full border-2   rounded-xl bg-white p-3  text-grey-500 outline-none resize-none "
            />
          </label>
          <div className=" w-full  flex flex-col gap-6 items-center  ">
            <div
              id="drag_drop"
              {...getRootProps({
                className: 'flex flex-col gap-6  items-center w-full'
              })}
            >
              <input {...getInputProps()} />
              <div className="grid place-items-center border-dashed border-2 rounded-2xl  shadow p-6 h-80 w-full">
                <div className="flex flex-col gap-2 items-center text-center font-semibold text-sm  text-gray-300">
                  <MdUpload className="text-3xl" />
                  <p className=" tracking-wider">Upload</p>
                  <p className="text-sm">OR</p>
                  <p className=" tracking-wider">Drag n Drop Your Resume</p>
                  <div className="flex gap-4 items-center text-3xl text-gray-500 ">
                    <AiFillFileWord /> <p className="text-sm">or</p>{' '}
                    <MdPictureAsPdf />
                  </div>
                </div>
                {isDragActive && !isDragReject && "Drop it like it's hot!"}
                {acceptedFiles[0] && acceptedFiles[0].size > maxSize && (
                  <p className="text-red-500 mt-2">
                    File is too large. Compress and Try Again . Max File Size
                    2MB
                  </p>
                )}
                <IconButton
                  toolTipTitle={'Upload Resume'}
                  text={'Upload Resume'}
                  icon={<MdUpload />}
                  onClick={open}
                  type="primary"
                />
              </div>
            </div>
            {acceptedFiles.length > 0 && (
              <aside className="w-full flex gap-4 items-center text-sm ">
                <h4>File</h4>
                <ul>
                  {acceptedFiles.map((file) => (
                    <li key={file.path}>
                      {file.path} - {(file.size / (1024 * 1024)).toFixed(2)} Mb
                    </li>
                  ))}
                </ul>
              </aside>
            )}
          </div>
          {acceptedFiles.length > 0 && (
            <div className="w-50">
              <IconButton
                toolTipTitle={'Generate Self Introduction'}
                text={'Generate Intro'}
                icon={<MdSummarize />}
                onClick={() => generateSummary()}
                disabled={email === ''}
              />{' '}
            </div>
          )}
        </div>
      ) : (
        <div className="w-full max-w-4xl mx-auto p-10 rounded-2xl bg-blue-50 shadow-lg flex flex-col  ">
          <h1 className="text-3xl font-semibold text-center ">About You</h1>

          <p>{ReactHtmlParser(summary)}</p>
        </div>
      )}
    </div>
  )
}

export default ResumeIntroPage
