import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ERROR_PAGE } from '../../../navigation/routes'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import SuperAdminUserTable from '../../components/super-admin/SuperAdminUserTable'
import TableSearch from '../../ui-component/inputs/TableSearch'

const SuperAdmin = () => {
  const { user } = useSelector((state) => state.auth)
  const [adminUsersData, setAdminUsersData] = useState([])
  const [loading, setLoading] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [userType, setUserType] = useState('ALL')
  const [totalPage, setTotalPage] = useState(0)
  const navigate = useNavigate()
  const [jobSearchText, setJobSearchText] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)

  const fetchAdminUsers = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/getusersadmin?page=${page}&limit=${limit}&type_user=${userType}`
      )
      console.log(data)
      setAdminUsersData(data.message)
      setTotalPage(data.total_pages)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const filterStaticData = async (searchText) => {
    if (searchText.length < 3) {
      //setAdminJobData([]);
      fetchAdminUsers()
      return
    }

    setLoadingSearch(true)

    try {
      const { data } = await axiosInstance.get(
        `https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/getContiUserSearch?search=${searchText}`
      )

      console.log(data)
      if (data.message) {
        setAdminUsersData(data.message)
        setTotalPage(1)
      }
      setLoadingSearch(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (user.type !== 'SUPER_ADMIN') {
      navigate(ERROR_PAGE)
      return
    }
    fetchAdminUsers()
  }, [page, limit, userType])

  useEffect(() => {
    if (jobSearchText.length > 0) {
      setLoadingSearch(true)
    } else setLoadingSearch(false)

    const coinSearchTimeout = setTimeout(() => {
      filterStaticData(jobSearchText)
    }, 2000)
    return () => {
      clearTimeout(coinSearchTimeout)
    }
  }, [jobSearchText])

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })
  }, [page])
  return (
    <div className="w-full  min-h-screen p-4 sm:p-6 md:p-10 ">
      {loading ? (
        <RippleLoader />
      ) : (
        <div>
          <div className="flex justify-between items-center flex-col md:flex-row gap-3 md:gap-10">
            <TableSearch
              value={jobSearchText}
              onChange={(e) => setJobSearchText(e.target.value)}
              placeholder="Enter User Name / Email "
            />
            <div className="p-2 px-3 text-sm border-4 rounded-md cursor-pointer ">
              <select
                className="cursor-pointer"
                value={userType}
                onChange={(e) => setUserType(e.target.value)}
              >
                <option value={'ALL'}>All Users</option>
                <option value={'PARENT'}>Parent Users</option>
                <option value={'CHILD'}>Child Users</option>
              </select>
            </div>
          </div>

          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <SuperAdminUserTable
              data={adminUsersData}
              page={page}
              fetchAllUsers={fetchAdminUsers}
              onPageChange={(page) => setPage(page)}
              count={totalPage}
              limit={limit}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default SuperAdmin
