import React from 'react'
import { useState } from 'react'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import SingleInterviewActionModal from '../interviews/SingleInterviewActionModal'
import relativeTime from 'dayjs/plugin/relativeTime'
import dayjs from 'dayjs'

const SingleNotification = ({ notification, close }) => {
  const {
    candidate_id,
    candidate_name,
    created,
    github_id,
    job_id,
    job_name,
    interview_id,
    notification: notifcation_text,
    notification_group,
    notification_id,
    notification_type,
    organisation,
    status,
    user_id
  } = notification
  dayjs.extend(relativeTime)
  const [showInterviewActionModal, setShowInterviewActionModal] =
    useState(false)
  return (
    <div>
      <div
        onClick={() =>
          notification_type === 'INTERVIEW' &&
          interview_id !== null &&
          setShowInterviewActionModal(!showInterviewActionModal)
        }
        className="flex gap-3 p-2 w-full rounded-md hover:bg-gray-100 cursor-pointer relative "
      >
        <div
          className={`w-2 h-2 rounded-full mt-[6px] ${
            status === 'UN_READ' ? 'bg-blue-500' : 'bg-blue-100'
          } `}
        ></div>
        <div className="flex flex-col items-end w-full flex-1">
          <h4
            className={`text-sm  ${
              status === 'UN_READ' ? 'font-semibold' : 'font-medium'
            }  mr-1 text-left w-full`}
          >
            {notifcation_text.toLowerCase()}
          </h4>
          <p
            className={`${
              status === 'UN_READ' ? 'text-gray-500' : 'text-gray-300'
            } text-xs font-medium text-right `}
          >
            {dayjs(created).fromNow()}
          </p>
        </div>
      </div>
      {showInterviewActionModal && (
        <SingleInterviewActionModal id={interview_id} close={close} />
      )}
    </div>
  )
}

export default SingleNotification
