import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import { getAuth, GithubAuthProvider } from 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyB2YEuhTLM7BSeVnOP6Yi8jed-qDUOTg3o',
  authDomain: 'techresume-78aee.firebaseapp.com',
  projectId: 'techresume-78aee',
  storageBucket: 'techresume-78aee.appspot.com',
  messagingSenderId: '59095137668',
  appId: '1:59095137668:web:960f32fc7a5825d882f1d1',
  measurementId: 'G-DNHN89Q7LL'
}

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig)
const auth = getAuth()
const githubProvider = new GithubAuthProvider()

export { auth, app, githubProvider }
