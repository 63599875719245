import React from 'react'
import { includeComma } from '../../../../services/global/globalFunction'
import RatingProgress from '../header/RatingProgress'
import InfoCard from './InfoCard'

const GithubStats = ({ data, language, rankData }) => {
  const {
    total_repos,
    total_stars,
    total_commits,
    repositories_contributed_to,
    profile_score
  } = data

  return (
    <div className="p-3 sm:p-6 text-gray-600 flex flex-col md:flex-row  gap-4">
      <InfoCard data={data} rankData={rankData} />
      <div className="bg-white shadow-custom rounded-md flex flex-1 flex-col xl:flex-row ">
        <RatingProgress points={profile_score} />
        <div className="flex justify-between  items-start px-3 p-6 sm:px-6 text-center xl:mt-2">
          <div className="flex flex-col items-center  mx-2 sm:mx-3 capitalize text-xs font-semibold">
            <p>No. of commits</p>

            <h1 className="text-xl sm:text-3xl font-bold mt-2">
              {total_commits === null ? 0 : includeComma(total_commits)}
            </h1>
          </div>
          <div className="flex flex-col items-center   mx-2 sm:mx-3 capitalize text-xs font-semibold">
            <p>Total Stars</p>

            <h1 className="text-xl sm:text-3xl font-bold mt-2">
              {includeComma(total_stars)}
            </h1>
          </div>
          <div className="flex flex-col items-center  mx-2 sm:mx-3 capitalize text-xs font-semibold">
            <p>Total Repos</p>

            <h1 className="text-xl sm:text-3xl font-bold mt-2">
              {includeComma(total_repos)}
            </h1>
          </div>
          <div className="flex flex-col items-center h-full   mx-2 sm:mx-3 capitalize text-xs font-semibold">
            <p>Contributed to</p>

            <h1 className="text-xl sm:text-3xl font-bold mt-2">
              {includeComma(repositories_contributed_to)}
            </h1>
          </div>
          <div className="flex flex-col items-center  mx-2 sm:mx-3 capitalize text-xs font-semibold relative">
            <p>Key Languages </p>

            <h1 className="text-xl sm:text-3xl font-bold mt-2">
              {Object.keys(language).length}
            </h1>
          </div>
          {/* <div className="flex flex-col items-center mx-2 sm:mx-3 capitalize text-xs font-semibold">
					<p>Github Strength</p>

					<h1 className="text-xl sm:text-3xl font-bold mt-2">87%</h1>
				</div> */}
        </div>
      </div>
    </div>
  )
}

export default GithubStats
