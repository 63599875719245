import React, { useState } from 'react'
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import uuid from 'react-uuid'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'

const ContactForm = () => {
  const [contactFormDetail, setContactFormDetail] = useState({
    name: '',
    email: '',
    message: '',
    type: ''
  })

  const types = [
    {
      name: 'New Feature',
      value: 'new-feature',
      checked: false
    },
    {
      name: 'Feedback',
      value: 'feedback',
      checked: false
    },
    {
      name: 'Partnership',
      value: 'partnership',
      checked: false
    },
    {
      name: 'Investment',
      value: 'investment',
      checked: false
    }
  ]

  const [messageType, setMessagetype] = useState(types)

  const handleTypeChange = (e, val) => {
    const index = types.findIndex((type) => type.value === val)

    const newMessageType = types.map((type, idx) => {
      if (idx === index) return { ...types[index], checked: true }
      return type
    })
    setMessagetype(newMessageType)
    setContactFormDetail({
      ...contactFormDetail,
      type: val
    })
  }

  const handleContactFormSubmit = async (e) => {
    e.preventDefault()
    if (contactFormDetail.email.length === 0) return

    const { message, email, type, name } = contactFormDetail
    const postData = {
      email,
      phone: '',
      name,
      message,
      reason: type,
      status: ''
    }
    try {
      await axiosInstance.post(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/contactus/post`,
        postData
      )
      setContactFormDetail({
        name: '',
        email: '',
        message: '',
        type: ''
      })
      showToast('success', 'Message Sent Successfully')
    } catch (err) {
      showToast('error', err.message)
    }
  }

  return (
    <form
      onSubmit={handleContactFormSubmit}
      className="flex flex-col  items-center gap-4 w-full px-4 md:px-8 p-8 rounded-2xl bg-blue-50 border shadow-lg text-gray-500 max-w-2xl"
    >
      <h1 className="text-blue-900 font-semibold text-3xl md:text-4xl text-center w-full mb-6 md:mb-10 ">
        Send Us a Message
      </h1>
      <div className="flex flex-col gap-8 w-full ">
        <div className="flex flex-col sm:flex-row gap-4 items-center">
          <label className="flex flex-col text-sm w-full  text-left font-600 gap-2 z-0 ">
            <p className="ml-2 font-semibold text-gray-600 ">Full Name</p>
            <input
              required
              className="p-4 rounded-xl border-2 outline-none font-600 border-gray-300 placeholder-gray-400"
              type="text"
              value={contactFormDetail.name}
              onChange={(e) =>
                setContactFormDetail({
                  ...contactFormDetail,
                  name: e.target.value
                })
              }
              placeholder="Enter your full name"
              name="text"
              style={{ width: '100%' }}
            />
          </label>
          <label className="flex flex-col text-sm w-full   text-left font-600 gap-2 z-0 ">
            <p className="ml-2  font-semibold text-gray-600">Email</p>
            <input
              className="p-4 rounded-xl border-2  outline-nonefont-600 border-gray-300 placeholder-gray-400"
              type="email"
              required
              value={contactFormDetail.email}
              onChange={(e) =>
                setContactFormDetail({
                  ...contactFormDetail,
                  email: e.target.value
                })
              }
              placeholder="Enter your Email"
              name="email"
              style={{ width: '100%' }}
            />
          </label>
        </div>

        <div className="col-span-2 flex flex-col gap-3 text-sm text-left ">
          <p className="font-semibold text-gray-600">
            What's with the website you need ?
          </p>
          <div className="flex flex-wrap sm:justify-between items-center gap-3">
            {messageType.map((type) => (
              <label
                key={uuid()}
                className="flex cursor-pointer gap-2 items-center "
              >
                {type.checked ? (
                  <MdRadioButtonChecked className="text-blue-500 text-xl" />
                ) : (
                  <MdRadioButtonUnchecked className="text-blue-500 text-xl" />
                )}

                <input
                  className="hidden"
                  type="checkbox"
                  value={type.checked}
                  onChange={(e) => handleTypeChange(e, type.value)}
                />
                <p>{type.name}</p>
              </label>
            ))}
          </div>
        </div>
        <label className="flex flex-col col-span-2 text-sm flex-1 text-left font-600 gap-2 z-0 ">
          <p className="ml-2 font-semibold text-gray-600 ">Message</p>
          <textarea
            className="p-4 resize-none h-40 rounded-xl border-2 outline-none font-600 border-gray-300 placeholder-gray-400"
            type="text"
            required
            value={contactFormDetail.message}
            onChange={(e) =>
              setContactFormDetail({
                ...contactFormDetail,
                message: e.target.value
              })
            }
            placeholder="Type your message"
            name="text"
          />
        </label>
      </div>
      <button
        type="submit"
        disabled={
          contactFormDetail.email.length === 0 ||
          contactFormDetail.name.length === 0
        }
        className="bg-blue-500  mt-4 rounded-full text-white  hover:bg-blue-400 grid place-items-center text-sm p-8 py-2 disabled:cursor-not-allowed "
      >
        Send Message
      </button>
    </form>
  )
}

export default ContactForm
