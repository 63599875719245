import React, { Fragment, useEffect, useState } from 'react'
import Select from 'react-select'
import uuid from 'react-uuid'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import TableSearch from '../../ui-component/inputs/TableSearch'
import ListingPagination from '../pagination/ListingPagination'
import ApplicantCard from '../single-job-listing/ApplicantCard'
import SingleJobChart from '../single-job-listing/SingleJobChart'

const AgencyApplicantsTable = ({
  data,
  fetchAllApplicants,
  fetchCreditData,
  page,
  totalPage,
  status,
  search,
  setSearch,
  company,
  setCompany,
  setStatus,
  setPage,
  applicantStatus,
  activePartner
}) => {
  const [summary, setSummary] = useState({
    selected: 0,
    inProgress: 0,
    rejected: 0
  })
  useEffect(() => {
    const selected = data.filter(
      (data) => data.status === 'AI SELECTED' || data.status === 'JOINED'
    ).length
    const rejected = data.filter((data) => data.status === 'REJECT').length
    const inProgress = data.length - selected - rejected
    setSummary({ selected, rejected, inProgress })
  }, [data])
  return (
    <div className="flex flex-col gap-4  max-w-[1400px] mx-auto relative ">
      <div className="flex flex-col sm:flex-row gap-4 items-center ">
        <div className="sm:w-1/2">
          <SingleJobChart
            data={{
              labels: ['Selected', 'Rejected', 'In-Progress'],
              series: [summary.selected, summary.rejected, summary.inProgress]
            }}
          />
        </div>
        <div className="flex flex-col gap-4 sm:w-1/2">
          <div className=" grid grid-cols-2 lg:grid-cols-4   gap-4">
            <div className="flex flex-col gap-3 p-4  rounded-lg bg-opacity-40  text-green-600 font-bold   bg-green-300  items-center">
              <h2 className="text-base  font-semibold">Total Students</h2>
              <p className=" text-2xl md:text-4xl ">{data.length}</p>
            </div>
            <div className="flex flex-col gap-3 p-4  rounded-lg bg-blue-300  text-blue-800 font-bold    items-center">
              <h2 className="text-base  font-semibold">Selected</h2>
              <p className=" text-2xl md:text-4xl ">{summary.selected}</p>
            </div>
            <div className="flex flex-col gap-3 p-4  rounded-lg bg-blue-50  text-blue-500 font-bold   items-center">
              <h2 className="text-base  font-semibold">In-Progress</h2>
              <p className=" text-2xl md:text-4xl ">{summary.inProgress}</p>
            </div>
            <div className="flex flex-col gap-3 p-4  rounded-lg bg-orange-50 font-bold  text-orange-500  items-center">
              <h2 className="text-base  font-semibold">Rejected</h2>
              <p className=" text-2xl md:text-4xl ">{summary.rejected}</p>
            </div>
          </div>
          <div className="hidden lg:flex flex-col gap-6  items-end my-4 md:my-8">
            <TableSearch
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder={'Search Candidate name / Jon name , job ID'}
            />

            <div className="flex gap-6 items-center">
              <Select
                className="basic-single w-40  bg-transparent text-sm"
                onChange={(val) => setCompany(val.value)}
                value={{
                  label: company === '' ? 'All' : company,
                  value: company
                }}
                classNamePrefix="Company"
                // isClearable={true}
                isSearchable={true}
                name="company"
                placeholder="Select Company"
                styles={reactSelectStyleUtils}
                options={[
                  { label: 'All', value: '' },
                  ...activePartner.map((partner) => ({
                    value: partner.company_name,
                    label: partner.company_name
                  }))
                ]}
              />
              <Select
                className="basic-single capitalize w-40  bg-transparent text-sm"
                onChange={(val) => setStatus(val)}
                value={status}
                classNamePrefix="Status"
                // isClearable={true}
                isSearchable={true}
                name="status"
                placeholder="Job Status"
                styles={reactSelectStyleUtils}
                options={applicantStatus}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex lg:hidden flex-col gap-6  items-end my-4 md:my-8">
        <TableSearch
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          placeholder={'Search Candidate name / Jon name , job ID'}
        />

        <div className="flex gap-6 items-center">
          <Select
            className="basic-single w-40  bg-transparent text-sm"
            onChange={(val) => setCompany(val.value)}
            value={{
              label: company === '' ? 'All' : company,
              value: company
            }}
            classNamePrefix="Company"
            // isClearable={true}
            isSearchable={true}
            name="company"
            placeholder="Select Company"
            styles={reactSelectStyleUtils}
            options={[
              { label: 'All', value: '' },
              ...activePartner.map((partner) => ({
                value: partner.company_name,
                label: partner.company_name
              }))
            ]}
          />
          <Select
            className="basic-single capitalize w-40   bg-transparent text-sm"
            onChange={(val) => setStatus(val)}
            value={status}
            classNamePrefix="Status"
            // isClearable={true}
            isSearchable={true}
            name="status"
            placeholder="Job Status"
            styles={reactSelectStyleUtils}
            options={applicantStatus}
          />
        </div>
      </div>

      <div className="text-xs w-full  m-auto xl:text-sm grid grid-cols-1  gap-6 lg:gap-10 my-6 ">
        {data.length !== 0 &&
          data.map((applicantData, idx) => (
            <ApplicantCard
              fetchCreditData={fetchCreditData}
              key={uuid()}
              fetchAllApplicants={fetchAllApplicants}
              data={applicantData}
              idx={idx}
              applicantStatus={applicantStatus}
              agency
            />
          ))}
      </div>

      {data.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
            No Applicants Found
          </p>
        </Fragment>
      )}
      <ListingPagination
        page={page}
        onPageChange={(val) => setPage(val)}
        count={totalPage}
      />
    </div>
  )
}

export default AgencyApplicantsTable
