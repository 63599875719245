import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BiLoaderAlt } from 'react-icons/bi'
import { GiMoneyStack } from 'react-icons/gi'
import { IoLocation, IoMedalOutline } from 'react-icons/io5'
import { MdAttachFile, MdOutlineDone, MdWorkOutline } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { HOME_PAGE, PROJECTS_PAGE } from '../../../navigation/routes'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import { getIpDetails } from '../../../services/global/globalFunction'
import SubmitButton from '../../components/auth/SubmitButton'
import ClickWrapper from '../../components/click-wrapper/ClickWrapper'
import EmailInput from '../../components/create-profile/email-input/EmailInput'
import GithubVerification from '../../components/create-profile/github-verification/GithubVerification'
import GreetingCard from '../../components/create-profile/greeting/GreetingCard'
import MultiSelect from '../../components/create-profile/multi-select/MultiSelect'
import NumberInput from '../../components/create-profile/num-input/NumberInput'
import ReapplyModal from '../../components/create-profile/reapply/ReapplyModal'
import TextInput from '../../components/create-profile/text-input/TextInput'
import RippleLoader from '../../components/loader/RippleLoader'
import { parseDuration } from '../../components/project/SingleProjectCard'
import { ProjectKeySkills } from '../../components/project/data'

const ApplyProjectPage = () => {
  const { projectId } = useParams()
  const [tnc, setTnc] = useState(false)
  const { user, githubUser } = useSelector((state) => state.auth)
  const [isGithubVerified, setIsGithubVerified] = useState(
    githubUser ? true : false
  )
  const [collegeInput, setCollegeInput] = useState('')
  const [colleges, setColleges] = useState([])
  const [currentProjectData, setCurrentProjectData] = useState({})
  const [showMessage, setshowMessage] = useState(false)
  const [showGreetings, setShowGreetings] = useState(false)
  const [showCollege, setShowCollege] = useState(false)
  const [imageFile, setImageFile] = useState()
  const [fileName, setFileName] = useState('')
  const [imageUploading, setImageUploading] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const { uploadJobLogo } = EndPoints.createJob
  const [isReadMore, setIsReadmore] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [profileValue, setProfileValue] = useState({
    name: '',
    email: '',
    dob: '',
    year_of_passout: '',
    college: '',
    cover_letter: '',
    key_skills: [],
    experience: 0,
    github: githubUser ? githubUser.username : ''
  })

  const [showReplyModal, setShowReplyModal] = useState(false)
  const navigate = useNavigate()

  const checkWhetherJobExist = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/ProjectExists?project_id=${projectId}`
      )
      if (data.body === 'null') {
        // navigate('*');
        // return;
        setshowMessage(true)
      } else {
        const status = JSON.parse(data.body)[0].EXIST
        if (status === 0) {
          navigate(HOME_PAGE)
          return
        }

        fetchJobDetails()
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchJobDetails = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getProject?project_id=${projectId}`
      )
      if (JSON.parse(data.message)) {
        setCurrentProjectData(JSON.parse(data.message))
        setIsLoading(false)
      }
      // if (JSON.parse(data.body.message).length === 0) {
      // 	setshowMessage(true);
      // }
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    checkWhetherJobExist()
  }, [projectId])

  const handleCreateProfile = async (postData) => {
    console.log(postData)
    if (!fileName) {
      showToast('warn', 'Please Upload your Resume')

      return
    }
    try {
      await uploadImage()
      const ipData = await getIpDetails()
      const { data } = await axiosInstance.post(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/postProjApplicant`,
        {
          ...postData,
          resume: fileName,
          ...ipData
        }
      )
      if (data.message === 'Already Applied') {
        setShowReplyModal(true)
        return
      }
      //console.log(JSON.parse(data.body));
      showToast('success', 'Successfully Applied for the Project')

      setShowGreetings(true)
      setTimeout(() => {
        navigate(PROJECTS_PAGE)
      }, 5000)
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }

  const fileChange = (e) => {
    console.log(e.target.files[0])
    if (!e.target.files[0]) {
      alert('please Select a valid file')
      return
    }
    const newFile = e.target.files[0]
    setImageFile(newFile)
    setFileName(
      `${projectId}-${uuid()}-${newFile.name.split(' ').join('')}`.trim()
    )
  }

  const uploadImage = async () => {
    console.log(imageFile)
    if (!imageFile) return

    setImageUploading(true)
    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': imageFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'github-cv'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      const res = await axiosInstance.put(uploadUrl, imageFile, options)
      console.log(res)
      setImageFile(undefined)
    } catch (err) {
      console.log(err.message)
    }
    setImageUploading(false)
    setImageUploaded(true)
  }

  const reapplyJobApplication = (e) => {
    e.preventDefault()
    setShowReplyModal(false)
    handleDirectApply(true)
  }

  const handleDirectApply = (reapply = false) => {
    handleCreateProfile({
      name: profileValue.name,
      email: profileValue.email,
      year_of_passout: profileValue.year_of_passout,
      //date_of_birth: profileValue.dob,
      college: profileValue.college,
      key_skills: profileValue.key_skills
        .map((skills) => skills.language_name)
        .join('|'),
      //cover_letter: profileValue.cover_letter,
      experience: profileValue.experience,
      reapply,
      github_profile: profileValue.github,
      hid: projectId.trim()
    })
  }

  const fetchCollege = async (college) => {
    try {
      const { data } = await axiosInstance.get(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getcolleges?search=${college}`
      )
      console.log(data)
      setColleges(data.message)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (collegeInput.length > 0) {
      //something for later
    } else {
      setColleges([])
      return
    }

    if (profileValue.college === collegeInput && collegeInput !== '') return

    const collegeSearchTimeout = setTimeout(() => {
      setShowCollege(true)
      fetchCollege(collegeInput)
    }, 1000)
    return () => {
      clearTimeout(collegeSearchTimeout)
    }
  }, [collegeInput])

  const onSubmit = (e) => {
    e.preventDefault()
    handleDirectApply()
  }

  return (
    <div className="p-2 sm:p-6 md:p-10 min-h-screen relative ">
      {showMessage ? (
        <div className="grid place-items-center ">
          <div className="w-full max-w-2xl shadow-lg bg-white rounded-md p-6">
            <h1 className="text-2xl text-center ">
              This Project is no longer available
            </h1>
          </div>
        </div>
      ) : (
        <Fragment>
          {showGreetings ? (
            <GreetingCard />
          ) : (
            <Fragment>
              {isLoading ? (
                <RippleLoader />
              ) : (
                <div>
                  <div className="bg-white max-w-4xl w-full m-auto p-3 sm:p-6 md:p-10 border shadow-outer border-gray-400 border-opacity-30 rounded-xl ">
                    <div className="flex  lg:flex-row justify-between items-start gap-2">
                      <div className="flex flex-col lg:flex-row  justify-center lg:items-center gap-4 ">
                        {currentProjectData?.company_logo && (
                          <img
                            className="w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40  rounded-md object-contain "
                            src={currentProjectData?.company_logo}
                            alt={''}
                          />
                        )}

                        <div className="flex flex-col gap-2">
                          <h1 className="text-sm md:text-xl font-semibold mb-4">
                            {currentProjectData?.organization}
                          </h1>
                          <p className="text-sm   font-semibold text-blue-500">
                            {currentProjectData?.project_title}
                          </p>
                          <p className="text-sm   font-semibold text-blue-500">
                            Project ID : {currentProjectData?.project_id}
                          </p>
                        </div>
                      </div>
                      <div className=" flex flex-col text-xs sm:text-sm gap-2 sm:gap-3 py-3 lg:py-0  max-w-md justify-center">
                        <p className=" flex  gap-2 items-center ">
                          <IoMedalOutline className="text-xl" />
                          {currentProjectData.years_of_experience}{' '}
                          {currentProjectData?.years_of_experience > 1
                            ? 'years'
                            : 'year'}{' '}
                          of experience{' '}
                        </p>

                        {currentProjectData?.payment && (
                          <p className=" flex  gap-2 items-center capitalize ">
                            <GiMoneyStack className="text-xl  " />
                            {currentProjectData.payment.toLowerCase()}
                          </p>
                        )}
                        {currentProjectData.project_category && (
                          <p className=" flex  gap-1 items-center ">
                            <MdWorkOutline className="text-xl" />
                            {currentProjectData.project_category}
                          </p>
                        )}
                        {currentProjectData.hours_required && (
                          <p className=" flex  gap-1 items-center ">
                            <AiOutlineClockCircle className="text-xl" />
                            {parseDuration(currentProjectData?.duration)}
                          </p>
                        )}
                        {currentProjectData.country && (
                          <p className=" flex  gap-1 items-center ">
                            <IoLocation className="text-xl" />
                            {currentProjectData.country}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="h-[2px] bg-gray-200 w-full mt-6"></div>

                    <div className="flex flex-col w-full gap-2 mb-10 text-sm py-8">
                      {currentProjectData.key_skills && (
                        <div className="flex gap-2 items-center my-2 flex-wrap">
                          {currentProjectData.key_skills && (
                            <div className="w-full ">
                              <p className="font-semibold mb-2 md:text-xl">
                                Skills Required :{' '}
                              </p>
                              <div className="flex gap-2 flex-wrap items-center">
                                {currentProjectData.key_skills
                                  .split('|')
                                  .map((skills) => (
                                    <p
                                      key={uuid()}
                                      className="bg-gray-100 p-2 px-3 text-gray-800 shadow rounded-full text-xs "
                                    >
                                      {' '}
                                      {skills}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {currentProjectData.description && (
                        <div className="text-sm ">
                          <p className=" mb-3">
                            <span className="font-semibold md:text-xl">
                              Description :
                            </span>
                          </p>
                          <p className={`${isReadMore ? 'truncate' : ''} `}>
                            {' '}
                            {ReactHtmlParser(
                              !isReadMore
                                ? currentProjectData.description
                                : currentProjectData.description.slice(0, 200)
                            )}
                          </p>
                          {currentProjectData.description.toString().split(' ')
                            .length > 15 && (
                            <button
                              onClick={() => setIsReadmore(!isReadMore)}
                              className="text-blue-400 text-sm font-semibold"
                            >
                              {!isReadMore ? 'Read less' : 'Read More'}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <form
                        onSubmit={onSubmit}
                        className="w-full flex flex-col gap-6 md:gap-10"
                      >
                        <div className="flex flex-col sm:grid sm:grid-cols-2 gap-4 md:gap-6 ">
                          <TextInput
                            value={profileValue.name}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, name: val })
                            }
                            label={'Name'}
                            placeholder={'Enter Your Name'}
                            required={true}
                          />
                          <EmailInput
                            value={profileValue.email}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, email: val })
                            }
                            label={'Email'}
                            placeholder={'Enter Your Email'}
                            required={true}
                          />
                          <NumberInput
                            value={profileValue.experience}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                experience: val
                              })
                            }
                            label={'Years of Experience'}
                            placeholder={'Years of Experience'}
                            required={true}
                          />

                          <TextInput
                            value={profileValue.year_of_passout}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                year_of_passout: val
                              })
                            }
                            label={'Year of passout'}
                            placeholder={'Enter Your Year of Passout '}
                          />
                          {/* <TextInput
														value={profileValue.college}
														setValue={(val) =>
															setProfileValue({
																...profileValue,
																college: val,
															})
														}
														label={'College'}
														placeholder={'Enter Your College '}
													/> */}

                          <div className="flex flex-col relative">
                            <TextInput
                              value={collegeInput}
                              setValue={(val) => setCollegeInput(val)}
                              label={'College'}
                              placeholder={'Enter College Name'}
                            />
                            {showCollege && colleges.length > 0 && (
                              <div className="flex mt-2 flex-col absolute top-full left-0 z-30 right-0 max-h-64 overflow-y-scroll bg-gray-100 rounded-md overflow-hidden border border-gray-200 border-opacity-50 ">
                                {colleges.map((college) => (
                                  <p
                                    key={uuid()}
                                    onClick={() => {
                                      setProfileValue({
                                        ...profileValue,
                                        college: college.institute_name
                                      })
                                      setShowCollege(false)
                                      setCollegeInput(college.institute_name)
                                    }}
                                    className="cursor-pointer hover:bg-white bg-gray-100 p-2 px-3 text-sm  "
                                  >
                                    {college.institute_name}
                                  </p>
                                ))}
                              </div>
                            )}
                          </div>

                          <GithubVerification
                            value={profileValue.github}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, github: val })
                            }
                            isGithubVerified={isGithubVerified}
                            setIsGithubVerified={(val) =>
                              setIsGithubVerified(val)
                            }
                          />

                          <label className="flex flex-col gap-2 text-xs md:text-sm mb-6 sm:mb-3 md:mb-0 col-span-1 ">
                            <p className=" font-semibold  ">Upload Resume</p>
                            <div className="flex gap-2 items-center w-[200px]">
                              <div
                                className={`flex  cursor-pointer   justify-center items-center text-sm font-semibold gap-2 p-[10px] disabled:bg-gray-400 px-4 bg-blue-500 text-white rounded-xl`}
                              >
                                <MdAttachFile className="text-xl" />
                                Attach Resume / CV
                              </div>
                              {imageUploading && (
                                <BiLoaderAlt className="animate-spin" />
                              )}
                              {imageUploaded && <MdOutlineDone />}
                            </div>

                            <p>{imageFile?.name}</p>
                            <input
                              type="file"
                              className="hidden"
                              onChange={fileChange}
                              accept=".doc, .docx,.pdf"
                            />
                          </label>
                          <MultiSelect
                            label={'Key Skills'}
                            placeholder={'Add Skill'}
                            value={profileValue.key_skills}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                key_skills: val
                              })
                            }
                            keySkills={ProjectKeySkills.map((skill) => ({
                              language_name: skill
                            }))}
                          />

                          {/* <label className="flex flex-col gap-2 text-xs md:text-sm mb-6 sm:mb-3 md:mb-0 col-span-2 ">
														<p className=" font-semibold  ">Cover Letter</p>

														<div className="bg-white h-40">
															<ReactQuill
																className=" h-32  "
																placeholder="Write Cover Letter"
																value={profileValue.cover_letter}
																onChange={(val) =>
																	setProfileValue({
																		...profileValue,
																		cover_letter: val,
																	})
																}
															/>
														</div>
													</label> */}
                        </div>

                        <div className=" md:w-1/2  flex flex-col justify-between gap-3">
                          <label className="flex items-center gap-2 font-600  cursor-pointer text-xs z-0">
                            <input
                              value={tnc}
                              onChange={(e) => setTnc(e.target.checked)}
                              className=" cursor-pointer"
                              type="checkbox"
                            />

                            <p>I Confirm the details provided are correct</p>
                          </label>

                          <SubmitButton text={'Apply'} disabled={!tnc} />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {showReplyModal && (
            <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
              <div className="w-full max-w-md p-6 m-auto z-50">
                <ClickWrapper func={() => setShowReplyModal(false)}>
                  <ReapplyModal
                    title={
                      'You have already applied to this Job . Do you want to reapply ?'
                    }
                    positiveText={'Reapply'}
                    negativeText={'Cancel'}
                    handleNegative={() => setShowReplyModal(false)}
                    handlePositive={reapplyJobApplication}
                  />
                </ClickWrapper>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default ApplyProjectPage
