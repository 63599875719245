import React from 'react'

const LanguageBar = ({ value, language }) => {
  return (
    <div className="my-1  ">
      <p className="text-xs text-left capitalize font-semibold">
        {language} ({value.toFixed(2)}%)
      </p>
      <div className="w-4/5 h-2 relative bg-gray-100  my-2  rounded-full shadow overflow-hidden ">
        <div
          style={{ width: `${value <= 1 ? 1 : value}%` }}
          className="absolute  top-0 h-full left-0  rounded-r-full bg-gradient-to-r from-blue-400  to-green-400  "
        ></div>
      </div>
    </div>
  )
}

export default LanguageBar
