export const recruiterJobsHeader = [
  {
    title: 'S No.',
    width: '30%'
  },
  {
    title: 'Job Info',
    width: '30%'
  },
  {
    title: 'Skills Required',
    width: '25%'
  },
  {
    title: 'Date Created',
    width: '10%'
  },
  {
    title: 'JobID',
    width: '10%'
  },
  {
    title: 'Applicants',
    width: '10%'
  }
]
export const recruiterProjectsHeader = [
  {
    title: 'S No.',
    width: '30%'
  },
  {
    title: 'Project Info',
    width: '30%'
  },
  {
    title: 'Skills Required',
    width: '25%'
  },
  {
    title: 'Date Created',
    width: '10%'
  },
  {
    title: 'projectID',
    width: '10%'
  },
  {
    title: 'Applicants',
    width: '10%'
  }
]
export const partneredJobHeader = [
  {
    title: 'S No.',
    width: '30%'
  },

  {
    title: 'Job Info',
    width: '30%'
  },
  {
    title: 'Company.',
    width: '30%'
  },
  {
    title: 'Skills Required',
    width: '25%'
  },
  {
    title: 'Date Created',
    width: '10%'
  },
  {
    title: 'JobID',
    width: '10%'
  },
  {
    title: 'Manage Candidate',
    width: '10%'
  }
]
