import React from 'react'
import uuid from 'react-uuid'
import LanguageLogo from '../../language-logo/LanguageLogo'
import LanguageBar from '../../radial-bar/LanguageBar'

const UserLanguage = ({ language }) => {
  return (
    <div className="p-4 sm:p-6">
      <div className="shadow-custom w-full flex flex-col gap-6 rounded-md bg-white p-3 sm:p-6 ">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold truncate mr-2 ">
            Languages based on Repositories
          </h3>
        </div>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4  gap-4">
          {Object.keys(language).map((keys) => (
            <div key={uuid()} className="flex gap-4 items-center">
              <LanguageLogo language={keys} logo={language[keys].logo} />
              <div className="flex-1">
                <LanguageBar
                  value={language[keys].in_percentage}
                  language={keys}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default UserLanguage
