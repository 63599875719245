import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import TextInput from '../create-profile/text-input/TextInput'

const CreateSelfJobModal = ({
  refetchData,
  setCreatedJobId,
  setShowCreateJob,
  showCreateJob,
  currentJobData,
  activeStep,
  setActiveStep
}) => {
  const { user } = useSelector((state) => state.auth)
  const [tnc, setTnc] = useState(false)
  const [profileValue, setProfileValue] = useState({
    user_id: user.user_id,
    job_name: '',
    status: 'OPEN_UNPUBLISHED',
    organization: user.type === 'AGENCY' ? '' : user?.name ?? '',
    description: ''
  })
  const { postJob } = EndPoints.createJob
  const [isReadMore, setIsReadmore] = useState(true)

  const handleCreateJob = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axiosInstance.post(`${postJob}`, profileValue)
      const res = JSON.parse(data.body)
      showToast('success', res.message)
      setCreatedJobId(res.job_id)
      refetchData(res.job_id)
      setShowCreateJob(false)
      setActiveStep(activeStep + 1)
    } catch (err) {
      showToast('error', err.message)
    }
  }

  const handleJobDescriptionChange = (value) => {
    setProfileValue({ ...profileValue, description: value })
  }
  return (
    <div
      className={` w-full max-w-4xl mx-auto py-10   ${
        showCreateJob && ' overflow-y-scroll'
      } bg-white  relative `}
    >
      {showCreateJob ? (
        <form onSubmit={handleCreateJob}>
          <div className="flex flex-col  sm:grid sm:grid-cols-2 gap-6  ">
            <TextInput
              value={profileValue.job_name}
              setValue={(val) =>
                setProfileValue({ ...profileValue, job_name: val })
              }
              label={'Title'}
              placeholder={'Enter Job Title'}
            />
            <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 mb-6 sm:mb-0 ">
              <p className=" font-semibold  ">Description</p>
              <div className="bg-white h-40">
                <ReactQuill
                  className=" h-32 rounded-2xl"
                  placeholder="Enter Job Description"
                  value={profileValue.description}
                  onChange={handleJobDescriptionChange}
                />
              </div>
            </label>
          </div>
          <div className=" md:w-1/2 mt-6 flex flex-col justify-between gap-3">
            <label className="flex items-center gap-2 font-600  cursor-pointer text-xs z-0">
              <input
                value={tnc}
                onChange={(e) => setTnc(e.target.checked)}
                className=" cursor-pointer"
                type="checkbox"
              />

              <p>I confirm the details provided are correct</p>
            </label>

            <SubmitButton text={'Create Job'} disabled={!tnc} />
          </div>
        </form>
      ) : (
        <div>
          <div className="flex  lg:flex-row justify-between items-start gap-2">
            <div className="flex flex-col lg:flex-row  justify-center lg:items-center gap-4 ">
              {currentJobData.company_logo && (
                <img
                  className="w-20 h-20   rounded-md object-contain "
                  src={currentJobData.company_logo}
                  alt={''}
                />
              )}

              <div className="flex flex-col gap-2">
                <h1 className=" md:text-xl font-semibold mb-4">
                  {currentJobData.organization}
                </h1>
                <p className="  font-semibold text-blue-500">
                  {currentJobData.job_name}
                </p>
                <p className="   font-semibold text-blue-500">
                  JOB ID : {currentJobData.job_id}
                </p>
              </div>
            </div>
          </div>

          <div className="h-[2px] bg-gray-200 w-full mt-6"></div>

          <div className="flex flex-col w-full gap-2 mb-10 text-sm py-8">
            {currentJobData.description && (
              <div className="text-sm ">
                <p className=" mb-3">
                  <span className="font-semibold md:text-xl">
                    Description :
                  </span>
                </p>
                <p className={`${isReadMore ? 'truncate' : ''} `}>
                  {' '}
                  {ReactHtmlParser(
                    !isReadMore
                      ? currentJobData.description
                      : currentJobData.description.slice(0, 200)
                  )}
                </p>
                {currentJobData.description.toString().split(' ').length >
                  15 && (
                  <button
                    onClick={() => setIsReadmore(!isReadMore)}
                    className="text-blue-400 text-sm font-semibold"
                  >
                    {!isReadMore ? 'Read less' : 'Read More'}
                  </button>
                )}
              </div>
            )}
          </div>

          <button
            onClick={() => setActiveStep(activeStep + 1)}
            className="p-3 px-5 self-end bg-yellow-500 text-white rounded-xl w-32 text-sm font-semibold "
          >
            Continue
          </button>
        </div>
      )}
    </div>
  )
}

export default CreateSelfJobModal
