import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import YearSelect from '../../select/YearSelect'

const ProjectCharts = ({ data, year_joined }) => {
  const series = [
    {
      name: 'Project',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'line',
      toolbar: {
        show: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#F37726', '#3E8FF5'],
        shadeIntensity: 0.5,
        type: 'horizontal',
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 100, 100, 100]
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      //   type: 'datetime',

      categories: []
    },
    tooltip: {
      // x: {
      // 	format: 'dd/MM/yy HH:mm',
      // },
      theme: 'dark'
    }
  }
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOption, setChartOption] = useState(options)
  const [year, setYear] = useState(new Date().getFullYear())

  let optionArr = []

  for (var i = 0; i <= new Date().getFullYear() - year_joined; i++) {
    optionArr.push(i)
  }

  const filterObject = (obj, filter, filterValue) =>
    Object.keys(obj).reduce(
      (acc, val) =>
        obj[val][filter]?.split('-')[0] !== filterValue.toString()
          ? acc
          : {
              ...acc,
              [val]: obj[val]
            },
      {}
    )

  useEffect(() => {
    const filteredObj = filterObject(data, 'created_date', year)

    const filteredData = Object.keys(filteredObj).map(
      (obj_id) => filteredObj[obj_id].total_commits
    )
    const filteredCategories = Object.keys(filteredObj).map(
      (obj_id) => filteredObj[obj_id].repo_name
    )
    setChartOption({
      ...chartOption,
      xaxis: {
        ...chartOption.xaxis,
        categories: filteredCategories
      }
    })

    setChartSeries([
      {
        name: 'Commits',
        data: filteredData
      }
    ])
  }, [year])

  return (
    <div className="p-4 sm:p-6">
      <div className="p-4 sm:p-6 bg-white shadow-custom rounded-xl ">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold">Projects Commited</h3>

          <YearSelect
            arr={optionArr}
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>

        <ReactApexChart
          options={chartOption}
          series={chartSeries}
          type="line"
          height={250}
        />
      </div>
    </div>
  )
}

export default ProjectCharts
