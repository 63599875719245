import React, { useEffect, useState } from 'react'
import SingleRepo from './SingleRepo'
import uuid from 'react-uuid'
import YearSelect from '../../select/YearSelect'
import { Fragment } from 'react'

const Repository = ({ data, year_joined, userData }) => {
  const [year, setYear] = useState(new Date().getFullYear())
  const [filteredData, setFilteredData] = useState(data)

  let optionArr = []

  for (var i = 0; i <= new Date().getFullYear() - year_joined; i++) {
    optionArr.push(i)
  }

  const filterObject = (obj, filter, filterValue) =>
    Object.keys(obj).reduce(
      (acc, val) =>
        obj[val][filter]?.split('-')[0] !== filterValue.toString()
          ? acc
          : {
              ...acc,
              [val]: obj[val]
            },
      {}
    )

  useEffect(() => {
    const filteredObj = filterObject(data, 'created_date', year)
    setFilteredData(filteredObj)
  }, [year])
  return (
    <div className="p-4 sm:p-6 ">
      <div className="sm:px-6">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold ">Top Repositories</h3>

          <YearSelect
            arr={optionArr}
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>

        {/* single repo cards */}
        <div className="py-6 grid grid-cols-1  gap-6 ">
          {Object.keys(filteredData).length > 0 &&
            Object.keys(filteredData).map((repoData) => (
              <SingleRepo
                data={data[repoData]}
                key={uuid()}
                userData={userData}
              />
            ))}
          {Object.keys(filteredData).length === 0 && (
            <Fragment>
              <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
                No repositories Found
              </p>
            </Fragment>
          )}
        </div>
      </div>
    </div>
  )
}

export default Repository
