import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import creditAction from '../../../redux/actions/creditAction'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import ShortlistUserTable from '../../components/shortlist/ShortlistUserTable'

const ShortListPage = () => {
  const [shortlistData, setShortlistData] = useState([])
  const [loading, setLoading] = useState(true)
  const { userCredits } = useSelector((state) => state.credits)
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  const { shortlistEndpoint } = EndPoints.dashboard
  const fetchShortlistedStudents = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Stage/shortlist?user_id=${user.user_id}`
      )

      setShortlistData(JSON.parse(data.body))
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchShortlistedStudents()
  }, [])

  return (
    <div className="w-full p-4 sm:p-6 md:p-10 min-h-screen ">
      {loading ? (
        <RippleLoader />
      ) : (
        <div>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <ShortlistUserTable
              fetchCreditData={() =>
                dispatch(creditAction.fetchUserCredits(user.user_id))
              }
              data={shortlistData}
              credits={userCredits?.credits_available}
              refetchData={fetchShortlistedStudents}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default ShortListPage
