import React, { Fragment, useEffect, useState } from 'react'
import Select from 'react-select'
import uuid from 'react-uuid'
import axiosInstance from '../../../services/axios/axios'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import { leaderboardCountries } from '../../components/leaderboard/tableData'
import RippleLoader from '../../components/loader/RippleLoader'
import ListingPagination from '../../components/pagination/ListingPagination'
import { projectCategory as projectCategoryData } from '../../components/project/data'
import {
  projectDurationData,
  ProjectPaymentData
} from '../../components/project/filterData'
import SingleProjectCard from '../../components/project/SingleProjectCard'

const ProjectPage = () => {
  const getAllProjectsData = () => {
    const data = sessionStorage.getItem('ALL_PROJECT_DATA')
    if (data === null) {
      return null
    }
    return JSON.parse(data)
  }

  const [loading, setLoading] = useState(true)
  const [limit, setLimit] = useState(
    getAllProjectsData()?.limit ? getAllProjectsData()?.limit : 20
  )
  const [page, setPage] = useState(
    getAllProjectsData()?.page ? getAllProjectsData()?.page : 1
  )
  const [totalPage, setTotalPage] = useState(
    getAllProjectsData()?.totalPage ? getAllProjectsData()?.totalPage : 0
  )
  const [totalCount, setTotalCount] = useState(
    getAllProjectsData()?.totalCount ? getAllProjectsData()?.totalCount : 0
  )

  const [selectedCountry, setSelectedCountry] = useState(
    getAllProjectsData()?.selectedCountry
      ? getAllProjectsData()?.selectedCountry
      : []
  )
  const [projectPayment, setProjectPayment] = useState(
    getAllProjectsData()?.projectPayment
      ? getAllProjectsData()?.projectPayment
      : []
  )

  const [projectDuration, setProjectDuration] = useState(
    getAllProjectsData()?.projectDuration
      ? getAllProjectsData()?.projectDuration
      : []
  )
  const [projectCategory, setProjectCategory] = useState(
    getAllProjectsData()?.projectCategory
      ? getAllProjectsData()?.projectCategory
      : []
  )

  const [projectData, setProjectData] = useState([])

  const getSearchQuery = (
    country,
    projectDuration,
    projectPayment,
    projectCategory
  ) => {
    //console.log(country, jobTime, workType, organization, jobType, jobCategory);
    const countryQuery =
      country === null ||
      country === '' ||
      country.length === 0 ||
      country[0] === 'ALL'
        ? 'ALL'
        : `[${[country].map((country) => `"${country.value}"`)}]`

    const projectDurationQuery =
      projectDuration === null ||
      projectDuration === '' ||
      projectDuration.length === 0 ||
      projectDuration[0] === 'ALL'
        ? 'ALL'
        : `[${projectDuration.map((duration) => `"${duration.value}"`)}]`

    const projectPaymentQuery =
      projectPayment === null ||
      projectPayment === '' ||
      projectPayment.length === 0 ||
      projectPayment[0] === 'ALL'
        ? 'ALL'
        : `[${projectPayment.map(
            (projectPayment) => `"${projectPayment.value}"`
          )}]`

    const projectCategoryQuery =
      projectCategory === null ||
      projectCategory === '' ||
      projectCategory.length === 0 ||
      projectCategory[0] === 'ALL'
        ? 'ALL'
        : `[${[projectCategory].map(
            (projectCategory) => `"${projectCategory.value}"`
          )}]`

    return `${'https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getAllProjects'}?page=${page}&limit=${limit}&country=${countryQuery}&payment=${projectPaymentQuery}&duration=${projectDurationQuery}&project_category=${projectCategoryQuery}`
  }

  const fetchAllProjects = async () => {
    const query = getSearchQuery(
      selectedCountry,
      projectDuration,
      projectPayment,
      projectCategory
    )
    try {
      const { data } = await axiosInstance.get(query)

      const res = JSON.parse(data.message)
      if (res) {
        setProjectData(res)
      }
      setTotalCount(data.total_count)
      setTotalPage(data.total_pages)
      const storedData = {
        page,
        limit,
        selectedCountry,
        projectDuration,
        projectPayment,
        projectCategory
      }
      sessionStorage.setItem('ALL_PROJECT_DATA', JSON.stringify(storedData))
      setLoading(false)
      console.log(res)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchAllProjects()
  }, [
    page,
    limit,
    selectedCountry,
    projectDuration,
    projectPayment,
    projectCategory
  ])
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })
  }, [page])
  return (
    <div className="min-h-screen w-full p-4 sm:p-6 md:p-10">
      <div className=" w-full max-w-7xl mx-auto flex flex-col gap-8 md:gap-14">
        <div className="w-full bg-blue-400 rounded-3xl shadow-xl bg-opacity-10 p-6 md:p-10 flex flex-col gap-6 items-center">
          <h1 className="text-3xl font-semibold">
            <span className="text-blue-500 mr-2">
              {loading ? 'Finding' : totalCount}
            </span>
            Projects Available Now
          </h1>
          <div className="flex flex-col md:flex-row justify-between md:items-end gap-4 bg-white w-full max-w-5xl m-auto rounded-md p-6 shadow-custom">
            <label className="flex flex-col gap-2 flex-1 text-xs md:text-sm ">
              <Select
                className="basic-single  bg-transparent text-sm"
                onChange={(val) => setProjectCategory(val)}
                value={projectCategory}
                classNamePrefix="Category"
                isClearable={true}
                isSearchable={true}
                name="projectCategory"
                placeholder="Select Project Category"
                styles={reactSelectStyleUtils}
                options={projectCategoryData.map((category) => ({
                  value: category,
                  label: category
                }))}
              />
            </label>

            <label className="flex flex-col gap-2 flex-1 text-xs md:text-sm ">
              <Select
                className="basic-single  bg-transparent text-sm"
                onChange={(val) => setSelectedCountry(val)}
                value={selectedCountry}
                classNamePrefix="Country"
                isClearable={true}
                isSearchable={true}
                name="country"
                placeholder="Select Job Country"
                styles={reactSelectStyleUtils}
                options={leaderboardCountries.map((country) => ({
                  value: country,
                  label: country
                }))}
              />
            </label>

            <button
              //onClick={fetchAllJobs}
              className="p-3 self-end py-2 border-2 border-blue-500 hover:bg-transparent text-sm hover:text-blue-500 transition-all duration-300 ease-in-out rounded-md w-40 bg-blue-500 text-white grid place-items-center"
            >
              Find Projects
            </button>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 gap-3  md:flex w-full md:gap-6 justify-center items-center">
            <label className="flex flex-col gap-2 sm:min-w-[200px] text-xs md:text-sm ">
              <Select
                className="basic-multi-select  bg-transparent text-sm"
                isMulti
                onChange={(val) => setProjectPayment(val)}
                value={projectPayment}
                classNamePrefix="Payment"
                isClearable={true}
                isSearchable={true}
                name="payment"
                placeholder="Project Pay"
                styles={reactSelectStyleUtils}
                options={ProjectPaymentData}
              />
            </label>
            <label className="flex flex-col gap-2 sm:min-w-[200px]  text-xs md:text-sm ">
              <Select
                className="basic-multi-select  bg-transparent text-sm"
                isMulti
                onChange={(val) => setProjectDuration(val)}
                value={projectDuration}
                classNamePrefix="Duration"
                isClearable={true}
                isSearchable={true}
                name="duration"
                placeholder="Project Duration"
                styles={reactSelectStyleUtils}
                options={projectDurationData}
              />
            </label>
          </div>
        </div>

        {loading ? (
          <RippleLoader />
        ) : (
          <>
            <Fragment>
              {projectData.length === 0 ? (
                <div className="w-full text-center">No Projects Found</div>
              ) : (
                <div className="flex flex-col gap-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
                    {projectData.map((project) => (
                      <SingleProjectCard key={uuid()} project={project} />
                    ))}
                  </div>
                </div>
              )}
            </Fragment>
            {projectData.length > 0 && (
              <ListingPagination
                page={page}
                onPageChange={(page) => setPage(page)}
                count={totalPage}
              />
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default ProjectPage
