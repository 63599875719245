import React from 'react'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleCollegeLeaderboard = ({ data, limit, index, page }) => {
  const {
    id,
    college_name,
    total_students,

    top_languages,

    average_rating
  } = data
  const navigate = useNavigate()
  return (
    <tr
      onClick={() => navigate(`/colleges/stats/${college_name}`)}
      className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   "
    >
      <td className="text-center">
        <p className=" p-2 px-4 ">{(page - 1) * limit + index + 1}</p>
      </td>

      <td className="text-center">
        <p className=" p-2 px-4 ">{college_name}</p>
      </td>

      <td className="text-center  min-w-[300px] max-w-[400px]">
        {top_languages && (
          <div className="flex gap-2 items-center flex-wrap">
            {JSON.parse(top_languages)
              .slice(0, 10)
              .map((skills) => (
                <p
                  key={uuid()}
                  className="bg-gray-100 p-2 px-3 text-gray-700 rounded-full shadow text-xs "
                >
                  {skills.language}
                </p>
              ))}
            {JSON.parse(top_languages).length > 10 &&
              `+ ${JSON.parse(top_languages).length - 10} more`}
          </div>
        )}
      </td>

      <td className="text-center">
        <p className=" p-2 px-4 ">{total_students} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{average_rating} </p>
      </td>
    </tr>
  )
}

export default SingleCollegeLeaderboard
