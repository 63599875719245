import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { FaLinkedin } from 'react-icons/fa'
import { MdDownload, MdWarning } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { APPLICANT_SIGNUP_PAGE } from '../../../../navigation/routes'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import AuthModal from '../../../components/auth/AuthModal'
import ClickWrapper from '../../../components/click-wrapper/ClickWrapper'
import ErrorComponent from '../../../components/error/ErrorComponent'
import Loader from '../../../components/loader/Loader'
import LanguageChart from '../../../components/public/dashboard/charts/LanguageChart'
import ProjectCharts from '../../../components/public/dashboard/charts/ProjectCharts'
import UserLanguage from '../../../components/public/dashboard/languages/UserLanguage'
import RepoTable from '../../../components/public/dashboard/repo-table/RepoTable'
import CreateProfileModal from '../../../components/public/dashboard/stats/CreateProfileModal'
import Profile from '../../../components/public/dashboard/stats/Profile'
import ProfileSummary from '../../../components/public/dashboard/stats/ProfileSummary'
import SharableProfileCard from '../../../components/public/dashboard/stats/SharableProfileCard'
import { ArrowTooltip } from '../../../ui-component/tooltip/Tooltip'
import ProfilePreview from '../../../components/public/dashboard/profile-preview/ProfilePreview'

const PublicDashboard = () => {
  const [dashboardData, setDashboardData] = useState([])
  const [loading, setLoading] = useState(true)
  const [dataFetching, setDataFetching] = useState(true)
  const [commitFetching, setCommitFetching] = useState(true)
  const [hasCreatedProfile, setHasCreatedProfile] = useState(true)
  const [candidateDetails, setCandidateDetails] = useState(null)
  const [showCreateProfileModal, setShowCreateProfileModal] = useState(false)
  const [showAuthModal, setShowAuthModal] = useState(false)
  const { username } = useParams()
  const [showUserMessage, setShowUserMessage] = useState(false)
  const { publicDashboardEndpoint, checkUserExist } = EndPoints.publicDashboard
  const { dashboardEndpoint, getUserLanguages } = EndPoints.dashboard
  const { githubUser, loggedIn } = useSelector((state) => state.auth)
  const [showPreview, setShowPreview] = useState(false)

  const fetchUserExist = async () => {
    //console.log('user exist is running');
    try {
      const { data } = await axiosInstance.get(
        `${checkUserExist}/iuserindb?GIHTUB_ID=${username}`
      )

      if (JSON.parse(data.body).USER_EXISTS === 0) {
        setShowUserMessage(true)
      }

      //fetchDataUpdate();
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchDataUpdate = async () => {
    if (!dataFetching) {
      return
    }

    try {
      // setLoading(true);
      const { data } = await axiosInstance.get(
        `${dashboardEndpoint}/getstatus?username=${username}`
      )
      // console.log(data);

      if (JSON.parse(data.body).status === 'completed') {
        setDataFetching(false)
        fetchDashboardData()
      }
      if (JSON.parse(data.body).status === null) {
        setDataFetching(false)
        //fetchDashboardData();
      }
      if (JSON.parse(data.body).commit_status === 'complete') {
        setCommitFetching(false)
        fetchDashboardData()
      }
      if (JSON.parse(data.body).commit_status === null) {
        setCommitFetching(false)
        //fetchDashboardData();
      } else {
        setCommitFetching(false)
        setDataFetching(false)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchDashboardData = async () => {
    //console.log('fetch dashboard is running');
    //setLoading(true);
    try {
      const res = await Promise.all([
        axiosInstance.get(
          `${publicDashboardEndpoint}/getUser?username=${username}`
        ),
        axiosInstance.get(
          `${publicDashboardEndpoint}/getRepo?username=${username}`
        ),
        axiosInstance.get(
          `${publicDashboardEndpoint}/getTopLanguage?username=${username}`
        ),
        axiosInstance.get(
          `${publicDashboardEndpoint}/getRanking?username=${username}`
        ),
        axiosInstance.get(
          `${publicDashboardEndpoint}/getCommitGraph?github_id=${username}`
        ),
        axiosInstance.get(
          `${publicDashboardEndpoint}/getFilesChangedGraph?limit=7&owner=${username}`
        ),
        axiosInstance.get(`${getUserLanguages}?username=${username}`)
      ])

      const newData = res.map((res) => JSON.parse(res.data.body))
      setDashboardData(newData)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchCandidateData = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://brkjj5ttja.execute-api.ap-south-1.amazonaws.com/dev/get?github_id=${username}`
      )

      if (data.body === 'null' || data.body === null) {
        setHasCreatedProfile(false)
      } else {
        const response = JSON.parse(data.body)

        setCandidateDetails({
          ...response,
          key_skill: response.key_skill
            ?.split(',')
            .map((skill) => ({ language_name: skill, language_logo: '' }))
        })
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const shareProfileCheck = (type) => {
    if (!loggedIn) {
      setShowAuthModal(true)
      return
    }
    if (githubUser && !hasCreatedProfile) {
      setShowCreateProfileModal(true)
      return
    }
    if (type === 'linkedin') {
      window.open(
        `https://www.linkedin.com/sharing/share-offsite/?url=${`https://myprofile.techprofile.org/${username}`}`,
        'blank'
      )
    } else if (type === 'download') {
      handlePrint()
    }
  }

  const componentRef = useRef(null)

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  useEffect(() => {
    //console.log('useeffect for username changes');

    setLoading(true)
    if (!dataFetching && !commitFetching) {
      return
    }
    fetchDataUpdate()
    const dataInterval = setInterval(() => {
      fetchDataUpdate()
    }, 5000)

    return () => {
      clearInterval(dataInterval)
    }
  }, [dataFetching, commitFetching, username])

  useEffect(() => {
    setDataFetching(true)
    setCommitFetching(true)
    fetchUserExist()
    fetchCandidateData()
  }, [username])

  return (
    <div className="min-h-screen w-full">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | {username}</title>
      </Helmet>

      {showUserMessage ? (
        <div className="w-full h-full grid place-items-center">
          <div className="shadow-lg p-4 rounded-lg bg-gray-200 flex flex-col gap-4 items-center text-center ">
            <MdWarning className="text-7xl" />
            <h1 className="text-2xl font-bold">Not Available</h1>
            <h5>
              It seems your profile is not associated with us . Please create
              your profile here.
            </h5>
            <Link
              to={APPLICANT_SIGNUP_PAGE}
              className=" p-2 text-sm  bg-blue-500  hover:bg-blue-400 text-white rounded-md ml-2"
            >
              Create Profile
            </Link>
          </div>
        </div>
      ) : (
        <>
          {loading ? (
            <Loader />
          ) : (
            <Fragment>
              <div className="flex flex-col lg:flex-row  items-center">
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <SharableProfileCard
                    data={dashboardData[0]}
                    language={dashboardData[2]}
                    rankData={dashboardData[3]}
                  />
                </ErrorBoundary>
                <div className="flex flex-col p-6 gap-4 w-full max-w-[400px] relative">
                  <div className="flex gap-1 items-center"></div>
                  <h2 className="text-3xl font-bold">Share Profile</h2>
                  <h3 className="text-sm font-semibold">
                    Share your Profile to show your work on your social network
                  </h3>
                  <ArrowTooltip title="Share">
                    <button
                      onClick={() => shareProfileCheck('linkedin')}
                      className="flex justify-center w-full items-center px-3 rounded-full py-2 bg-[#117EB8] hover:bg-[#117EB9] text-white gap-3"
                    >
                      <FaLinkedin />
                      <p className="uppercase flex gap-1 font-semibold">
                        Share{' '}
                        <span className="lg:hidden xl:block">
                          with Linkedin
                        </span>
                      </p>
                    </button>
                  </ArrowTooltip>

                  <ArrowTooltip title="Download">
                    <button
                      onClick={() => setShowPreview(true)}
                      className="w-full px-3 py-2  flex justify-center items-center gap-3  text-white bg-blue-400 rounded-full"
                    >
                      <MdDownload />
                      <p className="uppercase flex gap-1 font-semibold">
                        Download{' '}
                        <span className="lg:hidden xl:block">Profile</span>
                      </p>
                    </button>
                  </ArrowTooltip>
                  {/*  */}

                  {showPreview && (
                    <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
                      <div className="w-full max-w-6xl p-4  m-auto z-50">
                        <ClickWrapper func={() => setShowPreview(false)}>
                          <ProfilePreview
                            candidateDetails={candidateDetails}
                            dashboardData={dashboardData}
                            close={() => setShowPreview(false)}
                            download={() => shareProfileCheck('download')}
                            componentRef={componentRef}
                            hasCreatedProfile={hasCreatedProfile}
                          />
                        </ClickWrapper>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="flex flex-col lg:px-10 w-full">
                {hasCreatedProfile && (
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <Profile applicantData={candidateDetails} />
                  </ErrorBoundary>
                )}
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <ProfileSummary data={dashboardData[0]} />
                </ErrorBoundary>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <UserLanguage language={dashboardData[2]} />
                </ErrorBoundary>
                <div className="flex flex-col lg:flex-row w-full">
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <LanguageChart data={dashboardData[2]} />
                  </ErrorBoundary>
                  <ErrorBoundary FallbackComponent={ErrorComponent}>
                    <ProjectCharts data={dashboardData[1]} />
                  </ErrorBoundary>
                </div>

                {/* <ErrorBoundary FallbackComponent={ErrorComponent}>
									<div className="p-4 sm:p-6">
										<FileChangeChart
											data={dashboardData[5]}
											commitFetching={commitFetching}
											isRepoLevel={false}
										/>
									</div>
								</ErrorBoundary> */}
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <RepoTable
                    data={dashboardData[1]}
                    userData={dashboardData[0]}
                    year_joined={dashboardData[0].git_create_date.split('-')[0]}
                  />

                  {/* <div className="">
										<Repository
											data={dashboardData[1]}
											userData={dashboardData[0]}
											year_joined={
												dashboardData[0].git_create_date.split('-')[0]
											}
										/>
									</div> */}
                </ErrorBoundary>
              </div>

              {showCreateProfileModal && (
                <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
                  <div className="w-full max-w-3xl p-4  m-auto z-50">
                    <ClickWrapper func={() => setShowCreateProfileModal(false)}>
                      <CreateProfileModal
                        username={username}
                        close={() => setShowCreateProfileModal(false)}
                      />
                    </ClickWrapper>
                  </div>
                </div>
              )}
              {showAuthModal && (
                <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
                  <div className="w-full max-w-xl p-4  m-auto z-50">
                    <ClickWrapper func={() => setShowAuthModal(false)}>
                      <AuthModal close={() => setShowAuthModal(false)} />
                    </ClickWrapper>
                  </div>
                </div>
              )}
            </Fragment>
          )}
        </>
      )}
    </div>
  )
}

export default PublicDashboard
