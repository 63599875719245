import React, { useState } from 'react'
import { HiOutlineLink } from 'react-icons/hi'
import { RiMessage3Line } from 'react-icons/ri'
import { Link } from 'react-router-dom'
import { includeComma } from '../../../../services/global/globalFunction'
import IconButton from '../../../ui-component/buttons/IconButton'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import RaiseIssueModal from '../../job-listing/RaiseIssueModal'
import SendMailModal from '../../job-listing/SendMailModal'

const SingleUser = ({ data, idx, fetchCreditData }) => {
  const {
    avatar_url,
    email,
    search_id,
    name,
    repositories_contributed_to,
    total_commits,
    total_repos,
    total_stars
  } = data
  const [showMailModal, setShowMailModal] = useState(false)
  const [showIssueModal, setShowIssueModal] = useState(false)
  return (
    <tr className=" text-xs xl:text-sm font-semibold font-600 relative text-gray-600   ">
      <td>
        <p className="text-center">{idx + 1}</p>
      </td>
      <td className="min-w-[200px]">
        <div className="flex gap-1 sm:gap-2 items-center w-full">
          <img
            className="w-12 h-12 object-contain rounded-md"
            src={avatar_url}
            alt={search_id}
          />
          <Link
            to={`/dashboard/${search_id}`}
            className="px-4 flex flex-col gap-1 cursor-pointer"
          >
            <p className=" lg:text-sm font-semibold">{search_id}</p>
            <p className="  text-xs font-normal">{name}</p>
          </Link>
        </div>
      </td>

      <td className="text-center">
        <p className=" p-2 px-4 ">{includeComma(total_commits)}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{repositories_contributed_to} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{total_repos} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{total_stars} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{email ? email : '-'} </p>
      </td>

      <td className="text-center">
        <div className="grid place-items-center">
          {email !== null && email !== '' ? (
            <IconButton
              toolTipTitle={`Send mail to ${email}`}
              text={'Send Message'}
              icon={<RiMessage3Line />}
              onClick={() => setShowMailModal(true)}
              type="primary"
            />
          ) : (
            <IconButton
              toolTipTitle={`connect with ${search_id}`}
              text={'Connect'}
              onClick={() => setShowIssueModal(true)}
              icon={<HiOutlineLink />}
              type="dark"
            />
          )}
        </div>

        {showMailModal && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-2xl p-4 m-auto z-50">
              <ClickWrapper func={() => setShowMailModal(false)}>
                <SendMailModal
                  recieverDetails={[{ email, github_id: search_id }]}
                  refetchData={fetchCreditData}
                  close={() => setShowMailModal(false)}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showIssueModal && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-2xl p-4 m-auto z-50">
              <ClickWrapper func={() => setShowIssueModal(false)}>
                <RaiseIssueModal
                  refetchData={fetchCreditData}
                  close={() => setShowIssueModal(false)}
                  githubid={search_id}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SingleUser
