import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import uuid from 'react-uuid'
import {
  APPLICANT_SIGNIN_PAGE,
  CONTACT_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  POLICY_PAGE,
  PRICING_PAGE,
  TERMS_PAGE
} from '../../../navigation/routes'

import TechProfileLogo from '../navbar/TechProfileLogo'
import { footerData } from './footerData'

const Footer = () => {
  const { pathname } = useLocation()
  const isHidden =
    pathname.split('/')[pathname.split('/').length - 1] === 'apply'
  return (
    <div className={`${isHidden && 'hidden'} bg-gray-800 text-white z-10`}>
      <div className="border-b-2 border-gray-500 border-opacity-40 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-10 px-10 py-4 md:py-10">
        <div className="my-2 md:my-0">
          <div className="grid place-items-center  mr-10">
            <Link className="flex gap-1 items-end" to={HOME_PAGE}>
              <TechProfileLogo />
              <p className="flex font-semibold tracking-wider">
                <span className="text-blue-500">T</span>ech{' '}
                <span className="text-blue-500">P</span>rofile
              </p>
            </Link>
          </div>
        </div>

        <div className="flex flex-col gap-2 ">
          <p className="font-semibold">Quick Links</p>
          <ul className=" flex  flex-col md:gap-1 text-gray-300 text-base ">
            <Link to={HOME_PAGE}>Home</Link>

            <Link to={APPLICANT_SIGNIN_PAGE}>Login Developer</Link>

            <Link to={LOGIN_PAGE}>Login Recruiter</Link>

            <Link to={PRICING_PAGE}>Pricing</Link>

            <Link to={CONTACT_PAGE}>Contact US</Link>
          </ul>
        </div>

        <div className="flex flex-col gap-2 ">
          <p className="font-semibold">Contact Info</p>
          <ul className=" flex  flex-col text-gray-300 text-base ">
            <a
              href={`mailto:${'rileyjohnsontech@gmail.com'}`}
              className="md:mb-1"
            >
              rileyjohnsontech@gmail.com
            </a>
            <li className="md:mb-1">+44 9639862853</li>
            <li className="md:mt-1">71-75 Shelton Street, London , WC2H 9JQ</li>
          </ul>
        </div>

        <div className="flex flex-col gap-6 ">
          <p className="text-left  font-semibold  ">Stay connected with us</p>

          <ul className="flex gap-4 flex-wrap items-center text-xl ">
            {footerData.map(
              (footer) =>
                footer?.link !== '#' && (
                  <li key={uuid()}>
                    <a href={footer.link} target="blank">
                      {footer.component}
                    </a>
                  </li>
                )
            )}
          </ul>
          <ul className="flex gap-4 items-center text-base ">
            <li className="md:mt-1">
              <Link to={TERMS_PAGE}>Terms and Condition</Link>
            </li>
            <li className="md:mt-1">
              <Link to={POLICY_PAGE}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>

      <p className="p-6 md:p-10 text-xs text-center text-gray-300">
        Privacy Policy | Copyright © 2016-{new Date().getFullYear()} TechProfile
        PVT LTD. All rights reserved.
      </p>
    </div>
  )
}

export default Footer
