import React, { useState } from 'react'
import { BsBell } from 'react-icons/bs'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import NotificationWrapper from './NotificationWrapper'

const NotificationComponent = ({ userType, unreadNotif }) => {
  const [showUserMenu, setShowUserMenu] = useState(false)

  return (
    <div className="flex items-center mx-2 relative">
      {showUserMenu && (
        <div className="absolute translate-x-20 sm:translate-x-0 right-0 top-full bg-white shadow-md border z-50 my-2 w-[90vw] max-w-[350px] p-2 rounded-lg">
          <ClickWrapper func={() => setShowUserMenu(false)}>
            <NotificationWrapper
              unreadNotif={unreadNotif}
              close={() => setShowUserMenu(false)}
              userType={userType}
            />
          </ClickWrapper>
        </div>
      )}

      <div
        onClick={() => setShowUserMenu(true)}
        className="grid place-items-center cursor-pointer p-2 w-8 h-8 rounded-full bg-white relative"
      >
        <BsBell className="text-xl font-semibold " />
        {unreadNotif > 0 && (
          <p className="absolute top-0 text-[8px] font-medium right-0 h-4 w-4 grid place-items-center rounded-full bg-blue-500 text-white">
            {unreadNotif}
          </p>
        )}
      </div>
    </div>
  )
}

export default NotificationComponent
