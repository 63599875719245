import { Tab } from '@material-ui/core'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box } from '@mui/material'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import axiosInstance from '../../../services/axios/axios'
import RippleLoader from '../../components/loader/RippleLoader'
import JobDashboard from '../../components/recruiter-dashboard/JobDashboard'
import ProjectDashboard from '../../components/recruiter-dashboard/ProjectDashboard'
import PartnerJob from '../../components/job-listing/PartnerJob'
import AgencyApplicants from '../../components/agency/AgencyApplicants'
import RecruiterInterview from '../../components/interviews/RecruiterInterview'

const RecruiterDashboard = () => {
  const [loading, setLoading] = useState(true)
  const [value, setValue] = useState('1')
  const [recruiterJobData, setRecruiterJobData] = useState({})
  const [recruiterProjectData, setRecruiterProjectData] = useState({})
  const { user } = useSelector((state) => state.auth)
  const handleChange = (_, newValue) => {
    setValue(newValue)
  }

  const fetchrecruiterDashboardData = async () => {
    try {
      const res = await Promise.all([
        axiosInstance.get(
          `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getrecruiterjobs?user_id=${user.user_id}`
        ),
        axiosInstance.get(
          `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getrecruiterprojects?user_id=${user.user_id}`
        )
      ])
      const parsedData = res.map((res) => res.data)
      setRecruiterJobData({
        applicants: parsedData[0].applicants,
        connections: parsedData[0].user_connections,
        jobs: parsedData[0].message
      })
      setRecruiterProjectData({
        applicants: parsedData[1].applicants,
        connections: parsedData[1].user_connections,
        projects: parsedData[1].message
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchrecruiterDashboardData()
  }, [])

  return (
    <div className="p-4 sm:p-6 md:p-10 min-h-screen flex flex-col gap-10">
      {loading ? (
        <RippleLoader />
      ) : (
        <Fragment>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab label="Your Jobs" value="1" />
                {user.type !== 'AGENCY' && (
                  <Tab label="Your Projects" value="2" />
                )}
                {user.type === 'AGENCY' && (
                  <Tab label="Partnered Jobs " value="3" />
                )}
                {user.type === 'AGENCY' && <Tab label="Applicants" value="4" />}
                <Tab label="Upcoming Interviews" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <JobDashboard recruiterData={recruiterJobData} />
            </TabPanel>
            <TabPanel value="2">
              <ProjectDashboard recruiterData={recruiterProjectData} />
            </TabPanel>
            <TabPanel value="3">
              <PartnerJob />
            </TabPanel>
            <TabPanel value="4">
              <AgencyApplicants />
            </TabPanel>
            <TabPanel value="5">
              <RecruiterInterview />
            </TabPanel>
          </TabContext>
        </Fragment>
      )}
    </div>
  )
}

export default RecruiterDashboard
