import React, { useRef, useState } from 'react'
import { BiLoaderAlt } from 'react-icons/bi'
import { MdAttachFile, MdOutlineDone } from 'react-icons/md'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import { getIpDetails } from '../../../services/global/globalFunction'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import EmailInput from '../create-profile/email-input/EmailInput'
import ReapplyModal from '../create-profile/reapply/ReapplyModal'
import TextInput from '../create-profile/text-input/TextInput'

const NewApplicantForm = ({ jobID, setAddedApplicants, addedApplicants }) => {
  const [imageFile, setImageFile] = useState(null)
  const [fileName, setFileName] = useState('')
  const [imageUploading, setImageUploading] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const { createProfile } = EndPoints.createProfile
  const { uploadJobLogo } = EndPoints.createJob
  const { user } = useSelector((state) => state.auth)
  const [profileValue, setProfileValue] = useState({
    name: '',
    email: '',
    phone_number: '',
    github_profile: ''
  })

  const [showReplyModal, setShowReplyModal] = useState(false)
  const resumeUploadRef = useRef(null)

  const fileChange = (e) => {
    console.log(e.target.files[0])
    if (!e.target.files[0]) {
      alert('please Select a valid file')
      return
    }
    const newFile = e.target.files[0]
    setImageFile(newFile)
    setFileName(`${jobID}-${uuid()}-${newFile.name.split(' ').join('')}`.trim())
  }

  const uploadImage = async () => {
    console.log(imageFile)
    if (!imageFile) return

    setImageUploading(true)
    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': imageFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'github-cv'
      })

      const uploadUrl = JSON.parse(data).data.URL

      const res = await axiosInstance.put(uploadUrl, imageFile, options)
      console.log(res)
      setImageFile(undefined)
    } catch (err) {
      console.log(err.message)
    }
    setImageUploading(false)
    setImageUploaded(true)
  }

  const handleAddApplicants = async (reapply = false) => {
    if (!fileName) {
      showToast('warning', 'Please Upload your Resume')

      return
    }

    const postData = { ...profileValue, resume: fileName }

    try {
      await uploadImage()
      const ipData = await getIpDetails()
      const recruiterPost = {
        ...postData,
        reapply,
        hid: jobID.trim(),
        resume: fileName,
        ...ipData
      }

      const agencyPost = {
        ...recruiterPost,
        agency_id: user.user_id,
        agency_name: user.name,
        agency_status: 'OPEN'
      }
      console.log(agencyPost)
      const { data } = await axiosInstance.post(
        `${createProfile}`,
        user.type === 'AGENCY' ? agencyPost : recruiterPost
      )
      if (data.message === 'already applied') {
        setShowReplyModal(true)
        return
      }
      //console.log(JSON.parse(data.body));
      showToast('success', 'Added Candidate')

      setAddedApplicants([...addedApplicants, profileValue])
      setFileName('')
      setImageFile(null)
      setImageUploading(false)
      setImageUploaded(false)
      setProfileValue({
        name: '',
        email: '',
        phone_number: '',
        github_profile: ''
      })
      resumeUploadRef.current.value = ''
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }
  const onSubmit = (e) => {
    e.preventDefault()
    handleAddApplicants()
  }

  const reapplyJobApplication = (e) => {
    e.preventDefault()
    setShowReplyModal(false)
    handleAddApplicants(true)
  }

  return (
    <div className="relative  ">
      <form
        onSubmit={onSubmit}
        className="w-full flex flex-col gap-6 md:gap-10 "
      >
        <div className="flex flex-col md:grid grid-cols-2 gap-6 ">
          <TextInput
            value={profileValue.name}
            setValue={(val) => setProfileValue({ ...profileValue, name: val })}
            label={'Name'}
            placeholder={'Enter Applicant Name'}
            required={true}
          />
          <EmailInput
            value={profileValue.email}
            setValue={(val) => setProfileValue({ ...profileValue, email: val })}
            label={'Email'}
            placeholder={'Enter Applicant Email'}
            required={true}
          />

          <TextInput
            value={profileValue.phone_number}
            setValue={(val) =>
              setProfileValue({
                ...profileValue,
                phone_number: val
              })
            }
            label={'Phone'}
            placeholder={'Enter Applicant contact number '}
            allowNumber
          />
          <TextInput
            value={profileValue.github_profile}
            setValue={(val) =>
              setProfileValue({ ...profileValue, github_profile: val })
            }
            label={'Github'}
            placeholder={'Enter Candidate Github '}
          />

          <label className="flex flex-col justify-end gap-2 text-xs md:text-sm mb-6 sm:mb-3 md:mb-0 col-span-1 ">
            <p className=" font-semibold  ">Upload Resume</p>
            <div className="flex gap-1 items-center">
              <div className="flex gap-2 border-2 rounded-xl overflow-hidden border-blue-500 justify-between items-center w-full ">
                <p className="w-full px-2 truncate">{imageFile?.name}</p>
                <div
                  className={`flex   cursor-pointer  w-full  justify-center items-center text-sm font-semibold gap-2 p-[10px] disabled:bg-gray-400 px-4 bg-blue-500 text-white `}
                >
                  <MdAttachFile className="text-xl" />
                  Attach Resume
                </div>
              </div>
              {imageUploading && <BiLoaderAlt className="animate-spin" />}
              {imageUploaded && <MdOutlineDone />}
            </div>

            <input
              ref={resumeUploadRef}
              type="file"
              className="hidden"
              onChange={fileChange}
              accept=".doc, .docx,.pdf"
            />
          </label>
          <div className="flex flex-col justify-end">
            <button
              type="submit"
              className="p-3  px-5  bg-white border-2 border-blue-500  text-blue-500 hover:bg-blue-300 rounded-xl w-full text-sm font-semibold "
            >
              Save
            </button>
          </div>
        </div>
      </form>
      {showReplyModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-md p-6 m-auto z-50">
            <ClickWrapper func={() => setShowReplyModal(false)}>
              <ReapplyModal
                title={
                  'You have already applied to this Job . Do you want to reapply ?'
                }
                positiveText={'Reapply'}
                negativeText={'Cancel'}
                handleNegative={() => setShowReplyModal(false)}
                handlePositive={reapplyJobApplication}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default NewApplicantForm
