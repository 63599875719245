import { Avatar, Tooltip } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { AiOutlineGithub } from 'react-icons/ai'
import { BiLoaderAlt, BiRefresh } from 'react-icons/bi'
import { BsArrowRightShort } from 'react-icons/bs'
import { FiShare2, FiUserCheck, FiUserPlus } from 'react-icons/fi'
import { MdLink } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import { showToast } from '../../../../services/global/Toaster'
import { ArrowTooltip } from '../../../ui-component/tooltip/Tooltip'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import RippleLoader from '../../loader/RippleLoader'
import RefreshDataModal from './RefreshDataModal'

const Header = ({ data }) => {
  const { github_id, name, avatar_url, website_url } = data
  const [isLoading, setIsLoading] = useState(false)
  const [showShortlistModal, setShowShortlistModal] = useState(false)
  const [loginInput, setLoginInput] = useState({
    username: '',
    email: ''
  })
  const [shortlistStatus, setShortlistStatus] = useState(false)
  const navigate = useNavigate()
  const { user } = useSelector((state) => state.auth)
  const { dashboardEndpoint, shortlistEndpoint } = EndPoints.dashboard
  const [shortListLoading, setShortlistLoading] = useState(false)
  const [showRefreshDataModal, setShowRefreshDataModal] = useState(false)
  const [refreshDataMessage, setRefreshDataMessage] = useState({
    text: 'Your Request have been received successfully , Your profile data will be re-fetched within 24 hours . Thanks for your patience.'
  })

  const shortListTags = [
    'SHORTLIST1',
    'SHORTLIST2',
    'ROUND1',
    'ROUND2',
    'REJECT-PD',
    'JOINED',
    'MONITOR'
  ]
  const fetchUserExist = async (username) => {
    setIsLoading(true)
    if (username.length === 0) {
      return
    }
    try {
      const { data } = await axiosInstance.post(
        `${dashboardEndpoint}/adduser`,
        {
          username
        }
      )
      // console.log(data);
      if (data.user) {
        setLoginInput({
          username: '',
          email: ''
        })
        navigate(`/dashboard/${username}`)
      } else if (!data.user) {
        showToast('error', "username doesn't exist")
      }

      setIsLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchShortlistStatus = async () => {
    if (!user) {
      return
    } else {
      try {
        const { data } = await axiosInstance.get(
          `${shortlistEndpoint}/Dev/checkshortlist?user_id=${user.user_id}&github_id=${github_id}`
        )
        console.log(data.message[0].exists === 1 ? true : false)
        setShortlistStatus(data.message[0].exists === 1 ? true : false)
      } catch (err) {
        console.log(err.message)
      }
    }
  }

  const handleRefreshData = async () => {
    try {
      const { data } = await axiosInstance.post(
        `https://p5aymc8wt1.execute-api.ap-south-1.amazonaws.com/Stage/GithubUpdateProfile`,
        {
          github_id
        }
      )
      console.log(data)
      const res = JSON.parse(data.message).message
      if (res === 'already Requested Try after 24 hours') {
        setRefreshDataMessage({
          ...refreshDataMessage,
          text: "You've already requested your data re-fetching . Please be patient , We'll update your data within 24 hours of the request . Thank You !"
        })
      }

      setShowRefreshDataModal(true)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchShortlistStatus()
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault()

    // console.log(loginInput);
    fetchUserExist(loginInput.username)
  }

  const handleTagsChange = async (val) => {
    setShortlistLoading(true)
    try {
      const postData = {
        user_id: user.user_id,
        github_id,
        tag: val
      }
      await axiosInstance.post(`${shortlistEndpoint}/Stage/shortlist`, postData)
      showToast('success', 'Added to shortlist')

      fetchShortlistStatus()
      setShortlistLoading(false)
      setShowShortlistModal(false)
    } catch (err) {
      showToast('error', 'Unable to shortlist')
    }
  }

  return (
    <div className="bg-white flex flex-col-reverse md:flex-row justify-between md:items-center p-6 px-2 sm:px-6 shadow-custom relative">
      <form
        onSubmit={handleSubmit}
        className="border-2 rounded-full p-2 border-gray-200 md:w-64  bg-opacity-20  flex justify-between items-center "
      >
        <label className=" w-full flex justify-between gap-2 text-2xl items-center ">
          {' '}
          <div className="flex gap-2 items-center">
            <AiOutlineGithub />
            <input
              value={loginInput.username}
              onChange={(e) =>
                setLoginInput({ ...loginInput, username: e.target.value })
              }
              required
              type="text"
              name="username"
              placeholder="Enter Github-ID"
              className=" w-full outline-none border-none bg-transparent flex-1 focus:bg-transparent placeholder-gray-400 text-sm font-semibold"
            />
          </div>
          <button
            type="submit"
            className="bg-gray-700 text-white rounded-full font-bold text-3xl"
          >
            {isLoading ? (
              <div className="animate-spin">
                <BiLoaderAlt />
              </div>
            ) : (
              <BsArrowRightShort />
            )}
          </button>
        </label>
      </form>
      <div className="flex justify-between md:justify-start items-center md:px-4 mb-4 md:mb-0">
        <div className="flex flex-row-reverse md:flex-row items-center mx-4">
          <div className="mx-3 flex text-sm text-left md:text-right text-gray-600 font-semibold">
            <div className="hidden md:block mr-1 text-xl sm:text-3xl cursor-pointer hover:text-blue-500 transition-all duration-300 ease-in-out">
              <a href={`https://github.com/${github_id}`} target="blank">
                <AiOutlineGithub className="text-5xl" />
              </a>
            </div>
            <div className=" flex-col relative">
              <h3 className="flex gap-1   ">{name} </h3>
              <p className=" text-xs flex ">
                {github_id}{' '}
                <a
                  className="ml-1 text-base md:hidden"
                  href={`https://github.com/${github_id}`}
                  target="blank"
                >
                  <AiOutlineGithub />
                </a>{' '}
                {website_url !== null && website_url.length > 0 && (
                  <a
                    className="ml-1 text-xl"
                    href={`${website_url}`}
                    target="blank"
                  >
                    <MdLink />
                  </a>
                )}
              </p>
            </div>
          </div>
          <Avatar src={avatar_url} alt={name} sx={{ height: 50, width: 50 }} />
        </div>
        <div className="flex  gap-2 ">
          {user && (
            <div>
              {shortlistStatus ? (
                <Tooltip title="Shortlisted">
                  <button
                    className="w-10 h-10 text-white bg-green-400 rounded-full grid place-items-center text-xl"
                    disabled
                  >
                    <FiUserCheck />
                  </button>
                </Tooltip>
              ) : (
                <Tooltip title="Add to Shortlist">
                  <button
                    className="w-10 h-10 text-white bg-blue-400 rounded-full grid place-items-center text-xl"
                    onClick={() => setShowShortlistModal(true)}
                  >
                    <FiUserPlus />
                  </button>
                </Tooltip>
              )}
            </div>
          )}

          {showShortlistModal && (
            <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
              <div className="w-full max-w-xl p-6 m-auto z-50">
                <ClickWrapper func={() => setShowShortlistModal(false)}>
                  <div className="w-full bg-white rounded-md shadow-lg m-auto p-6  grid place-items-center relative">
                    {shortListLoading && (
                      <div className="grid place-items-center absolute inset-0">
                        <RippleLoader />
                      </div>
                    )}

                    <div className="flex flex-col w-full gap-1">
                      <div className="p-3 px-10 text-sm font-semibold text-left  w-full rounded-md bg-gray-100 ">
                        Select Shortlist Tag
                      </div>
                      {shortListTags.map((tag) => (
                        <div
                          onClick={() => handleTagsChange(tag)}
                          className={` p-2 px-10 text-sm font-semibold text-left cursor-pointer w-full rounded-md hover:bg-blue-50 `}
                          key={uuid()}
                        >
                          {tag}
                        </div>
                      ))}
                    </div>
                  </div>
                </ClickWrapper>
              </div>
            </div>
          )}

          <ArrowTooltip title="Refresh Data">
            <button
              onClick={handleRefreshData}
              className="px-4 py-2 bg-blue-500 text-sm rounded-full text-white font-semibold hover:bg-transparent border border-blue-500 hover:text-gray-600 transition-all duration-300 ease-in-out "
            >
              <p className="hidden sm:block">Refresh</p>
              <p className="sm:hidden grid place-items-center ">
                <BiRefresh />
              </p>
            </button>
          </ArrowTooltip>

          <ArrowTooltip title="Share your profile">
            <Link
              to={`/${github_id}`}
              className="px-4 py-2 bg-green-500 text-sm rounded-full text-white font-semibold hover:bg-transparent border border-green-500 hover:text-gray-600 transition-all duration-300 ease-in-out "
            >
              <p className="hidden sm:block">Share</p>{' '}
              <p className="sm:hidden grid place-items-center ">
                {' '}
                <FiShare2 />{' '}
              </p>
            </Link>
          </ArrowTooltip>
        </div>
      </div>
      {showRefreshDataModal && (
        <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-2xl p-4   m-auto z-50">
            <ClickWrapper func={() => setShowRefreshDataModal(false)}>
              <RefreshDataModal
                close={() => setShowRefreshDataModal(false)}
                message={refreshDataMessage}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default Header
