import React from 'react'
import { Fragment } from 'react'
import { recruiterJobsHeader } from './data'
import SingleRecruitersJobtable from './SingleRecruitersJobtable'
import uuid from 'react-uuid'

const RecruiterJobsTable = ({ jobData = [] }) => {
  return (
    <div>
      <div className="overflow-x-scroll w-full">
        <div className="flex justify-between mb-6 items-center">
          <h3 className="font-semibold truncate mr-2 px-6 md:px-10">
            All Jobs
          </h3>
        </div>
        <table className={`p-4 w-full rounded-2xl  shadow-custom bg-white`}>
          <tbody className="text-xs xl:text-sm rounded-lg ">
            <tr className="bg-blue-400 bg-opacity-20 p-2 ">
              {recruiterJobsHeader.map((head) => (
                <th key={uuid()} className="text-center p-2 py-3 ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {jobData.length !== 0 &&
              jobData.map((singleJob, idx) => (
                <SingleRecruitersJobtable
                  key={uuid()}
                  data={singleJob}
                  idx={idx}
                />
              ))}
          </tbody>
        </table>
        {jobData.length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
              No Jobs Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default RecruiterJobsTable
