import React from 'react'
import { IoMdCloseCircleOutline } from 'react-icons/io'

const RefreshDataModal = ({ close, message }) => {
  return (
    <div className="p-10 rounded-2xl bg-white flex flex-col gap-6 shadow-lg relative ">
      <button
        onClick={close}
        className=" text-3xl self-end font-bold absolute top-4 right-4"
      >
        <IoMdCloseCircleOutline />
      </button>
      <h2 className="text-xl text-center font-semibold ">Refresh Request</h2>
      <div className="h-[2px] bg-gradient-to-r from-blue-500 to-orange-500 w-full"></div>
      <p className="text-center">{message?.text}</p>
    </div>
  )
}

export default RefreshDataModal
