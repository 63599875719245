import React, { useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import 'react-quill/dist/quill.snow.css'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Footer from './main/components/footer/Footer'
import Navbar from './main/components/navbar/Navbar'
import StickyVideo from './main/components/sticky-video/StickyVideo'
import RouteConfig from './navigation/RouteConfig'
import creditAction from './redux/actions/creditAction'

function App() {
  const { loggedIn, githubUser, user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()
  useEffect(() => {
    if (loggedIn) {
      dispatch(creditAction.fetchUserCredits(user.user_id))
    }
  }, [loggedIn])

  return (
    <Router>
      <div className="App h-auto w-screen  text-gray-600">
        <Navbar />
        <RouteConfig />
        {(loggedIn || githubUser) && <StickyVideo />}
        <Footer />
        <CookieConsent
          //debug={true}
          buttonText="Accept All"
          // location="bottom"
          enableDeclineButton
          style={{ background: '#161616', fontSize: '13px' }}
          buttonStyle={{
            color: 'white',
            fontSize: '13px',
            backgroundColor: '#2759D0'
          }}
          declineButtonStyle={{ color: 'white', fontSize: '13px' }}
          onAccept={() => {
            // alert('yay!')
          }}
          onDecline={() => {
            // alert('nay!')
          }}
        >
          We use our own cookies as well as third-party cookies on our websites
          to enhance your experience, analyze our traffic, and for security and
          marketing. Select "Accept All" to allow them to be used.
        </CookieConsent>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          closeOnClick
          rtl={false}
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </Router>
  )
}

export default App
