import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.black
  }
}))

const LanguageLogo = ({ language, logo }) => {
  return (
    <BootstrapTooltip title={language}>
      {logo ? (
        <img
          className="w-8 h-8 rounded-md object-contain"
          src={logo}
          alt={language}
        />
      ) : (
        <p
          className={`text-lg font-semibold uppercase truncate px-1 w-8 h-8 rounded-md bg-gray-300 grid place-items-center`}
        >
          {language.split(' ').length > 1
            ? language.match(/[A-Z]/g).join('')
            : language.split('')[0] + language.split('')[1]}
        </p>
      )}
    </BootstrapTooltip>
  )
}

export default LanguageLogo
