import React, { Fragment, useEffect, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { AiOutlineClockCircle } from 'react-icons/ai'
import { BiLoaderAlt } from 'react-icons/bi'
import { GiDesk, GiMoneyStack } from 'react-icons/gi'
import { IoMedalOutline } from 'react-icons/io5'
import {
  MdAttachFile,
  MdOutlineDone,
  MdReport,
  MdWorkOutline
} from 'react-icons/md'
import ReactQuill from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { HOME_PAGE } from '../../../navigation/routes'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../../components/auth/SubmitButton'
import ClickWrapper from '../../components/click-wrapper/ClickWrapper'
import EmailInput from '../../components/create-profile/email-input/EmailInput'
import GithubVerification from '../../components/create-profile/github-verification/GithubVerification'
import GreetingCard from '../../components/create-profile/greeting/GreetingCard'
import NumberInput from '../../components/create-profile/num-input/NumberInput'
import ReapplyModal from '../../components/create-profile/reapply/ReapplyModal'
import TextInput from '../../components/create-profile/text-input/TextInput'
import RippleLoader from '../../components/loader/RippleLoader'

const DirectApplyPage = () => {
  const { jobId } = useParams()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [tnc, setTnc] = useState(false)
  const { user, githubUser } = useSelector((state) => state.auth)
  const [isGithubVerified, setIsGithubVerified] = useState(
    githubUser ? true : false
  )
  const [allSkills, setAllSkills] = useState([])
  const [currentJobData, setCurrentJobData] = useState({})
  const [showMessage, setshowMessage] = useState(false)
  const [showGreetings, setShowGreetings] = useState(false)
  const [imageFile, setImageFile] = useState()
  const [fileName, setFileName] = useState('')
  const [imageUploading, setImageUploading] = useState(false)
  const [imageUploaded, setImageUploaded] = useState(false)
  const { checkJobExist, createProfile, getAllSkills } = EndPoints.createProfile
  const { uploadJobLogo } = EndPoints.createJob
  const [isReadMore, setIsReadmore] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const [profileValue, setProfileValue] = useState({
    name: '',
    email: '',
    dob: '',
    phone_number: '',
    cover_letter: '',
    experience: 0,
    linkdin: '',
    portfolio: '',
    github: githubUser ? githubUser.username : ''
  })

  const [showReplyModal, setShowReplyModal] = useState(false)

  const checkWhetherJobExist = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${checkJobExist}/getjobstatus?job_id=${jobId}`
      )
      if (data.body === 'null') {
        // navigate('*');
        // return;
        setshowMessage(true)
      } else {
        const status = JSON.parse(data.body)?.status
        if (status.toLowerCase() !== 'open') {
          navigate(HOME_PAGE)
          return
        }
        fetchAllSkills()
        fetchJobDetails()
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchJobDetails = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${checkJobExist}/getjobdetails?job_id=${jobId}`
      )
      // if (JSON.parse(data.body.message).length === 0) {
      // 	setshowMessage(true);
      // }
      setCurrentJobData(JSON.parse(data.body.message)[0])
      setIsLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    checkWhetherJobExist()
  }, [jobId])

  const handleCreateProfile = async (postData) => {
    if (!isGithubVerified) {
      showToast('warn', 'Verify Your Github')

      return
    }

    try {
      await uploadImage()
      const { data } = await axiosInstance.post(`${createProfile}`, {
        ...postData,
        resume: fileName
      })
      if (data.message === 'already applied') {
        setShowReplyModal(true)
        return
      }
      //console.log(JSON.parse(data.body));
      showToast('success', JSON.parse(data.body))

      setShowGreetings(true)
      setTimeout(() => {
        navigate(HOME_PAGE)
      }, 5000)
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }

  const fileChange = (e) => {
    console.log(e.target.files[0])
    if (!e.target.files[0]) {
      alert('please Select a valid file')
      return
    }
    const newFile = e.target.files[0]
    setImageFile(newFile)
    setFileName(`${jobId}-${uuid()}-${newFile.name.split(' ').join('')}`.trim())
  }

  const uploadImage = async () => {
    console.log(imageFile)
    if (!imageFile) return

    setImageUploading(true)
    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': imageFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'github-cv'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      const res = await axiosInstance.put(uploadUrl, imageFile, options)
      console.log(res)
      setImageFile(undefined)
    } catch (err) {
      console.log(err.message)
    }
    setImageUploading(false)
    setImageUploaded(true)
  }

  const fetchAllSkills = async () => {
    try {
      const { data } = await axiosInstance.get(`${getAllSkills}`)
      //console.log(data);
      setAllSkills(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }
  const reapplyJobApplication = (e) => {
    e.preventDefault()
    setShowReplyModal(false)
    handleDirectApply(true)
  }

  const handleDirectApply = (reapply = false) => {
    handleCreateProfile({
      name: profileValue.name,
      email: profileValue.email,
      phone_number: profileValue.phone_number,
      date_of_birth: profileValue.dob,
      // key_skills: profileValue?.keySkills.map(
      // 	(skill) => skill.language_name,
      // ),
      linkdin: profileValue.linkdin,
      portfolio: profileValue.portfolio,
      cover_letter: profileValue.cover_letter,
      experience: profileValue.experience,
      reapply,
      github_profile: profileValue.github,
      hid: jobId.trim()
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    handleDirectApply()
  }

  return (
    <div className="p-2 sm:p-6 md:p-10 min-h-screen relative ">
      {showMessage ? (
        <div className="grid place-items-center ">
          <div className="w-full max-w-2xl shadow-lg bg-white rounded-md p-6">
            <h1 className="text-2xl text-center ">
              This Job is no longer available
            </h1>
          </div>
        </div>
      ) : (
        <Fragment>
          {showGreetings ? (
            <GreetingCard />
          ) : (
            <Fragment>
              {isLoading ? (
                <RippleLoader />
              ) : (
                <div>
                  <div className="bg-white max-w-4xl w-full m-auto p-3 sm:p-6 md:p-10 border shadow-outer border-gray-400 border-opacity-30 rounded-xl ">
                    <div className="flex  lg:flex-row justify-between items-start gap-2">
                      <div className="flex flex-col lg:flex-row  justify-center lg:items-center gap-4 ">
                        {currentJobData.company_logo && (
                          <img
                            className="w-20 h-20 sm:w-32 sm:h-32 md:w-40 md:h-40  rounded-md object-contain "
                            src={currentJobData.company_logo}
                            alt={''}
                          />
                        )}

                        <div className="flex flex-col gap-2">
                          <h1 className="text-sm md:text-xl font-semibold mb-4">
                            {currentJobData.organization}
                          </h1>
                          <p className="text-sm   font-semibold text-blue-500">
                            {currentJobData.job_name}
                          </p>
                          <p className="text-sm   font-semibold text-blue-500">
                            JOB ID : {currentJobData.job_id}
                          </p>
                        </div>
                      </div>
                      <div className=" flex flex-col text-xs sm:text-sm gap-2 sm:gap-3 py-3 lg:py-0  max-w-md justify-center">
                        <p className=" flex  gap-2 items-center ">
                          <IoMedalOutline className="text-xl" />
                          {currentJobData.years_of_experience}{' '}
                          {currentJobData.years_of_experience > 1
                            ? 'years'
                            : 'year'}{' '}
                          of experience{' '}
                        </p>

                        {currentJobData.pay_range && (
                          <p className=" flex  gap-2 items-center ">
                            <GiMoneyStack className="text-xl" />
                            {currentJobData.pay_range}
                          </p>
                        )}
                        {currentJobData.job_type && (
                          <p className=" flex  gap-1 items-center ">
                            <MdWorkOutline className="text-xl" />
                            {currentJobData.job_type}
                          </p>
                        )}
                        {currentJobData.work_hours && (
                          <p className=" flex  gap-1 items-center ">
                            <GiDesk className="text-xl" />
                            {currentJobData.work_hours}
                          </p>
                        )}
                        {currentJobData.weekly_hours && (
                          <p className=" flex  gap-1 items-center ">
                            <AiOutlineClockCircle className="text-xl" />
                            {currentJobData.weekly_hours} hours
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="h-[2px] bg-gray-200 w-full mt-6"></div>

                    <div className="flex flex-col w-full gap-2 mb-10 text-sm py-8">
                      {currentJobData.key_skills && (
                        <div className="flex gap-2 items-center my-2 flex-wrap">
                          {currentJobData.key_skills && (
                            <div className="w-full ">
                              <p className="font-semibold mb-2 md:text-xl">
                                Skills Required :{' '}
                              </p>
                              <div className="flex gap-2 flex-wrap items-center">
                                {currentJobData.key_skills
                                  .split(',')
                                  .map((skills) => (
                                    <p
                                      key={uuid()}
                                      className="bg-gray-100 p-2 px-3 text-gray-800 shadow rounded-full text-xs "
                                    >
                                      {' '}
                                      {skills}
                                    </p>
                                  ))}
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                      {currentJobData.description && (
                        <div className="text-sm ">
                          <p className=" mb-3">
                            <span className="font-semibold md:text-xl">
                              Description :
                            </span>
                          </p>
                          <p className={`${isReadMore ? 'truncate' : ''} `}>
                            {' '}
                            {ReactHtmlParser(
                              !isReadMore
                                ? currentJobData.description
                                : currentJobData.description.slice(0, 200)
                            )}
                          </p>
                          {currentJobData.description.toString().split(' ')
                            .length > 15 && (
                            <button
                              onClick={() => setIsReadmore(!isReadMore)}
                              className="text-blue-400 text-sm font-semibold"
                            >
                              {!isReadMore ? 'Read less' : 'Read More'}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div>
                      <p className="text-center text-yellow-500 flex justify-center items-center gap-2 mb-3 text-sm">
                        {' '}
                        <MdReport />
                        You have to<strong>Login</strong> after applying and
                        verify the job application to be verified .
                      </p>
                      <form
                        onSubmit={onSubmit}
                        className="w-full flex flex-col gap-6 md:gap-10"
                      >
                        <div className="flex flex-col sm:grid sm:grid-cols-2 gap-4 md:gap-6 ">
                          <TextInput
                            value={profileValue.name}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, name: val })
                            }
                            label={'Name'}
                            placeholder={'Enter Your Name'}
                            required={true}
                          />
                          <EmailInput
                            value={profileValue.email}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, email: val })
                            }
                            label={'Email'}
                            placeholder={'Enter Your Email'}
                            required={true}
                          />
                          <NumberInput
                            value={profileValue.experience}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                experience: val
                              })
                            }
                            label={'Years of Experience'}
                            placeholder={'Years of Experience'}
                            required={true}
                            min={0}
                          />

                          <TextInput
                            value={profileValue.phone_number}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                phone_number: val
                              })
                            }
                            label={'Phone'}
                            placeholder={'Enter Your contact number '}
                            allowNumber
                          />
                          <TextInput
                            value={profileValue.linkdin}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                linkdin: val
                              })
                            }
                            label={'Linkedin url'}
                            placeholder={'Enter Your linkedin profile url '}
                          />
                          <TextInput
                            value={profileValue.portfolio}
                            setValue={(val) =>
                              setProfileValue({
                                ...profileValue,
                                portfolio: val
                              })
                            }
                            label={'Portfolio'}
                            placeholder={'Enter personal portfolio link '}
                          />
                          <label className="flex flex-col gap-2 text-xs md:text-sm mb-6 sm:mb-3 md:mb-0 col-span-1 ">
                            <p className=" font-semibold  ">Upload Resume</p>
                            <div className="flex gap-2 items-center w-[200px]">
                              <div
                                className={`flex  cursor-pointer   justify-center items-center text-sm font-semibold gap-2 p-[10px] disabled:bg-gray-400 px-4 bg-blue-500 text-white rounded-xl`}
                              >
                                <MdAttachFile className="text-xl" />
                                Attach Resume / CV
                              </div>
                              {imageUploading && (
                                <BiLoaderAlt className="animate-spin" />
                              )}
                              {imageUploaded && <MdOutlineDone />}
                            </div>

                            <p>{imageFile?.name}</p>
                            <input
                              type="file"
                              className="hidden"
                              onChange={fileChange}
                              accept=".doc, .docx,.pdf"
                            />
                          </label>
                          <GithubVerification
                            value={profileValue.github}
                            setValue={(val) =>
                              setProfileValue({ ...profileValue, github: val })
                            }
                            isGithubVerified={isGithubVerified}
                            setIsGithubVerified={(val) =>
                              setIsGithubVerified(val)
                            }
                          />

                          <label className="flex flex-col gap-2 text-xs md:text-sm mb-6 sm:mb-3 md:mb-0 col-span-2 ">
                            <p className=" font-semibold  ">Cover Letter</p>

                            <div className="bg-white h-40">
                              <ReactQuill
                                className=" h-32  "
                                placeholder="Write Cover Letter"
                                value={profileValue.cover_letter}
                                onChange={(val) =>
                                  setProfileValue({
                                    ...profileValue,
                                    cover_letter: val
                                  })
                                }
                              />
                            </div>
                          </label>
                        </div>

                        <div className=" md:w-1/2  flex flex-col justify-between gap-3">
                          <label className="flex items-center gap-2 font-600  cursor-pointer text-xs z-0">
                            <input
                              value={tnc}
                              onChange={(e) => setTnc(e.target.checked)}
                              className=" cursor-pointer"
                              type="checkbox"
                            />

                            <p>I Confirm the details provided are correct</p>
                          </label>

                          <SubmitButton text={'Apply'} disabled={!tnc} />
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
            </Fragment>
          )}
          {showReplyModal && (
            <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
              <div className="w-full max-w-md p-6 m-auto z-50">
                <ClickWrapper func={() => setShowReplyModal(false)}>
                  <ReapplyModal
                    title={
                      'You have already applied to this Job . Do you want to reapply ?'
                    }
                    positiveText={'Reapply'}
                    negativeText={'Cancel'}
                    handleNegative={() => setShowReplyModal(false)}
                    handlePositive={reapplyJobApplication}
                  />
                </ClickWrapper>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </div>
  )
}

export default DirectApplyPage
