import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import {
  ABOUT_PAGE,
  APPLICANT_SIGNIN_PAGE,
  APPLICANT_SIGNUP_PAGE,
  CERTIFICATION_PAGE,
  CONTACT_PAGE,
  HOME_PAGE,
  LOGIN_PAGE,
  RECRUITER_PAGE,
  SIGNUP_PAGE
} from '../../../navigation/routes'
import AuthenticatedNavbar from './AuthenticatedNavbar'
import TechProfileLogo from './TechProfileLogo'
import UnauthenticatedNavbar from './UnauthenticatedNavbar'
import axiosInstance from '../../../services/axios/axios'
import EndPoints from '../../../services/api/api'

const transparentNavRoutes = [
  HOME_PAGE,
  RECRUITER_PAGE,
  ABOUT_PAGE,
  SIGNUP_PAGE,
  LOGIN_PAGE,
  APPLICANT_SIGNIN_PAGE,
  APPLICANT_SIGNUP_PAGE,
  CONTACT_PAGE,
  CERTIFICATION_PAGE
]

const Navbar = () => {
  const { user, githubUser, loggedIn } = useSelector((state) => state.auth)
  const { pathname } = useLocation()
  const isHidden =
    pathname.split('/')[pathname.split('/').length - 1] === 'apply'
  const { shortlistEndpoint } = EndPoints.dashboard
  const isTransparent = transparentNavRoutes.includes(pathname)

  const [pendingNotif, setPendingNotifs] = useState(0)

  const fetchPendingNotif = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Dev/notification/count/get?${
          loggedIn
            ? `user_id=${user.user_id}`
            : `github_id=${githubUser.username}`
        }`
      )
      // console.log(data);
      setPendingNotifs(data)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (user || githubUser) {
      fetchPendingNotif()
    }
  }, [user, githubUser, loggedIn])

  return (
    <div
      className={`${
        isHidden ? 'hidden' : 'flex'
      } shadow-md relative justify-between items-center p-3 sm:px-6 md:px-10 ${
        isTransparent && ' bg-sky-800 text-white'
      } `}
    >
      <Link
        className={` flex gap-3 justify-start items-center scale-90 sm:scale-100 text-lg sm:text-2xl tracking-tight  uppercase font-semibold ${
          isTransparent ? 'text-white' : 'text-gray-900'
        }   `}
        to={HOME_PAGE}
      >
        {user?.type !== 'AGENCY' ? (
          user?.profile_image ? (
            <img
              className="h-[50px] object-contain rounded-md"
              src={`${user?.profile_image}?rand=${new Date()}`}
              alt=""
            />
          ) : (
            <Fragment>
              <TechProfileLogo />
              <h1 className="font-bold  tracking-wide ">
                <span className="text-blue-500">T</span>ech
                <span className="text-blue-500">P</span>
                rofile
              </h1>
            </Fragment>
          )
        ) : (
          <Fragment>
            <TechProfileLogo />
            <h1 className="font-bold  tracking-wide ">
              {' '}
              <span className="text-blue-500">T</span>ech
              <span className="text-blue-500">P</span>
              rofile
            </h1>
          </Fragment>
        )}
      </Link>
      <div>
        {user ? (
          <AuthenticatedNavbar
            unreadNotif={pendingNotif}
            isTransparent={isTransparent}
          />
        ) : (
          <UnauthenticatedNavbar
            unreadNotif={pendingNotif}
            isTransparent={isTransparent}
          />
        )}
      </div>
    </div>
  )
}

export default Navbar
