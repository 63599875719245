import christ from '../../../assets/img/colleges/christ-logo.jpg'
import ciet from '../../../assets/img/colleges/ciet-logo.jpg'
import gitam from '../../../assets/img/colleges/gitam-logo.jpeg'
import iiser from '../../../assets/img/colleges/iiser-pune.jpeg'
import imu from '../../../assets/img/colleges/imu-logo.jpeg'
import ipec from '../../../assets/img/colleges/ipec-logo.jpg'
import lpsc from '../../../assets/img/colleges/lpsc-logo.jpg'
import lpu from '../../../assets/img/colleges/lpu-logo.jpg'
import miet from '../../../assets/img/colleges/miet-logo.jpg'
import nec from '../../../assets/img/colleges/nec-logo.jpg'
import niet from '../../../assets/img/colleges/niet-logo.jpg'
import rcew from '../../../assets/img/colleges/rcew-logo.jpg'
import rgmcet from '../../../assets/img/colleges/rgmcet-logo.jpg'
import sastra from '../../../assets/img/colleges/sastra-logo.jpeg'
import sjc from '../../../assets/img/colleges/sjc-logo.jpg'
import srit from '../../../assets/img/colleges/srit-logo.jpg'
import srm from '../../../assets/img/colleges/srm-logo.jpg'
import tecnrt from '../../../assets/img/colleges/tecnrt-logo.jpg'
import upes from '../../../assets/img/colleges/upes-logo.jpg'
import vit from '../../../assets/img/colleges/vit-logo.jpg'
import crf from '../../../assets/img/companies/crf.png'
import jupiter from '../../../assets/img/companies/jupiter.jpg'
import natwest from '../../../assets/img/companies/natwest.jpg'
import policy from '../../../assets/img/companies/policy.jpg'
import rug from '../../../assets/img/companies/rug.jpg'

export const collegLogos = [
  christ,
  ciet,
  gitam,
  iiser,
  imu,
  ipec,
  lpsc,
  lpu,
  miet,
  nec,
  niet,
  rcew,
  rgmcet,
  sastra,
  sjc,
  srit,
  srm,
  tecnrt,
  upes,
  vit
]

export const companyLogos = [crf, jupiter, natwest, policy, rug]
