import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import { includeComma } from '../../../../services/global/globalFunction'

const SingleRepotable = ({ data, userData }) => {
  const { repo_name, stars, description, total_commits, lang, forks, size } =
    data
  const navigate = useNavigate()

  return (
    <tr
      onClick={() => navigate(`/repository/${userData.github_id}/${repo_name}`)}
      className="text-xs xl:text-sm font-semibold font-600 text-gray-600 cursor-pointer  "
    >
      <td className="text-left">
        <p className="  px-4 lg:text-sm font-semibold ">{repo_name}</p>
        <p className="  px-4 text-xs font-normal">{description}</p>
      </td>
      <td className="text-center  ">
        <div className="flex justify-center h-full">
          {lang.length > 0 && (
            <Fragment>
              {' '}
              {lang.map((language) => (
                <p key={uuid()} className=" text-xs lg:text-sm mx-1">
                  {` ${language.language}  `}
                  <span className="text-xs font-normal">{`(${Math.ceil(
                    parseInt(language.language_percentage)
                  )}%)`}</span>
                </p>
              ))}
            </Fragment>
          )}
        </div>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{total_commits}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{stars}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{forks}</p>
      </td>
      <td className="text-center">{includeComma(size)}</td>
    </tr>
  )
}

export default SingleRepotable
