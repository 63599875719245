import React from 'react'

const SinglePoint = ({ title, render, type }) => {
  return (
    <div
      className={`py-3 flex justify-between items-center gap-8 border-b border-opacity-40`}
    >
      <p className=" text-sm">{title}</p>

      <p
        className={`font-semibold ${
          typeof render === 'string' ? 'text-sm' : 'text-2xl'
        } ${
          type === 'startup' ? 'text-green-600' : 'text-green-100'
        } text-center px-6`}
      >
        {render}
      </p>
    </div>
  )
}

const SinglePricingCard = ({ data, type = 'startup' }) => {
  const { title, details, price } = data
  return (
    <div>
      <div
        className={` flex flex-col gap-8 p-8 shadow-xl rounded-2xl  ${
          type === 'startup'
            ? 'text-gray-500 bg-gradient-to-b from-blue-50 to-white'
            : 'text-white bg-gradient-to-b from-[#003E8C] to-[#276dc4]'
        }  `}
      >
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-semibold">{title}</h1>
          <h1 className="text-3xl font-semibold">{price}</h1>
        </div>
        <div className="flex flex-col">
          {details.map((detail, idx) => (
            <SinglePoint key={idx} {...detail} type={type} />
          ))}
        </div>
        <button
          className={`${
            type === 'startup'
              ? 'bg-[#003E8C] text-white'
              : 'bg-white font-semibold text-[#003E8C]'
          } rounded-full p-2 px-3 text-lg grid place-items-center w-full`}
        >
          Get Started
        </button>
      </div>
    </div>
  )
}

export default SinglePricingCard
