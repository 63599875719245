import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import EmailInput from '../create-profile/email-input/EmailInput'
import MultiSelect from '../create-profile/multi-select/MultiSelect'
import TextInput from '../create-profile/text-input/TextInput'
import RippleLoader from '../loader/RippleLoader'

const EditProfileModal = ({ refetchData, close, profileData }) => {
  const [allSkills, setAllSkills] = useState([])
  const [collegeInput, setCollegeInput] = useState(profileData.college)
  const [colleges, setColleges] = useState([])
  const { githubUser } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const [showCollege, setShowCollege] = useState(false)
  const { username } = useParams()
  const navigate = useNavigate()
  const { getAllSkills } = EndPoints.createProfile

  const {
    name,
    email_id,
    phone_no,
    year_of_passout,
    key_skill,
    kaggle_id,
    hacker_rank_id,
    college
  } = profileData

  const fetchAllSkills = async () => {
    try {
      const { data } = await axiosInstance.get(`${getAllSkills}`)
      //console.log(data);
      setAllSkills(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }

  const [profileValue, setProfileValue] = useState({
    github_id: githubUser.username,
    name,
    email_id,
    phone_no,
    year_of_passout,
    key_skill,
    kaggle_id,
    hacker_rank_id,
    college
  })
  const fetchCollege = async (college) => {
    try {
      const { data } = await axiosInstance.get(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getcolleges?search=${college}`
      )
      console.log(data)
      setColleges(data.message)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleEditProfile = async (e) => {
    e.preventDefault()
    setLoading(true)
    const putData = {
      ...profileValue,
      key_skill: profileValue.key_skill
        .map((skill) => skill.language_name)
        .join(', ')
    }

    console.log(putData)
    try {
      const { data } = await axiosInstance.put(
        `https://brkjj5ttja.execute-api.ap-south-1.amazonaws.com/dev/update`,
        putData
      )
      console.log(JSON.parse(data.body))
      setLoading(false)
      showToast('success', JSON.parse(data.body).message)

      // navigate(`/profile/${githubUser.username}`);
      await refetchData()
      close()
    } catch (err) {
      showToast('error', err.message)
    }
  }

  useEffect(() => {
    if (githubUser.username !== username) {
      navigate('*')
      return
    }
    fetchAllSkills()
    //getProfileDetails();
  }, [])

  useEffect(() => {
    if (collegeInput.length > 0) {
      //something for later
    } else {
      setColleges([])
      return
    }

    if (profileValue.college === collegeInput && collegeInput !== '') return

    const collegeSearchTimeout = setTimeout(() => {
      setShowCollege(true)
      fetchCollege(collegeInput)
    }, 1000)
    return () => {
      clearTimeout(collegeSearchTimeout)
    }
  }, [collegeInput])

  return (
    <div className="p-3 sm:p-6 md:p-10 w-full  m-auto h-[95vh] bg-white overflow-y-scroll relative ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-xl font-bold"
      >
        <MdClose />
      </button>
      {loading && (
        <div className="grid place-items-center inset-0 fixed z-20 bg-white bg-opacity-20 p-10">
          <RippleLoader />
        </div>
      )}
      <form onSubmit={handleEditProfile}>
        <div className="w-full max-w-3xl m-auto flex flex-col gap-6 md:gap-10">
          <div className="flex flex-col gap-4">
            <h1 className="text-2xl md:text-3xl font-semibold  border-b-2 border-gray-200 pb-4 ">
              Edit Profile
            </h1>
          </div>

          <div className="flex flex-col  sm:grid sm:grid-cols-2 gap-6  ">
            <TextInput
              value={profileValue.name}
              setValue={(val) =>
                setProfileValue({ ...profileValue, name: val })
              }
              label={'Name'}
              placeholder={'Enter Full Name'}
            />
            <EmailInput
              value={profileValue.email_id}
              setValue={(val) =>
                setProfileValue({ ...profileValue, email_id: val })
              }
              label={'Email'}
              placeholder={'Enter your Email'}
            />
            <div className="flex flex-col relative">
              <TextInput
                value={collegeInput}
                setValue={(val) => setCollegeInput(val)}
                label={'Institution'}
                placeholder={'Enter Institution name'}
              />
              {showCollege && colleges.length > 0 && (
                <div className="flex mt-2 flex-col absolute top-full left-0 z-30 right-0 max-h-64 overflow-y-scroll bg-gray-100 rounded-md overflow-hidden border border-gray-200 border-opacity-50 ">
                  {colleges.map((college) => (
                    <p
                      key={uuid()}
                      onClick={() => {
                        setProfileValue({
                          ...profileValue,
                          college: college.institute_name
                        })
                        setShowCollege(false)
                        setCollegeInput(college.institute_name)
                      }}
                      className="cursor-pointer hover:bg-white bg-gray-100 p-2 px-3 text-sm  "
                    >
                      {college.institute_name}
                    </p>
                  ))}
                </div>
              )}
            </div>

            <TextInput
              value={profileValue.phone_no}
              setValue={(val) =>
                setProfileValue({ ...profileValue, phone_no: val })
              }
              label={'Phone Number'}
              placeholder={'Enter your phone number'}
              allowNumber
            />

            <TextInput
              value={profileValue.year_of_passout}
              setValue={(val) =>
                setProfileValue({ ...profileValue, year_of_passout: val })
              }
              label={'Passout Year'}
              placeholder={'Enter Year of Passout'}
            />
            <TextInput
              value={profileValue.kaggle_id}
              setValue={(val) =>
                setProfileValue({ ...profileValue, kaggle_id: val })
              }
              label={'Kaggle ID'}
              placeholder={'Enter Kaggle ID'}
            />
            <TextInput
              value={profileValue.hacker_rank_id}
              setValue={(val) =>
                setProfileValue({ ...profileValue, hacker_rank_id: val })
              }
              label={'Hackerank'}
              placeholder={'Enter Hackerrank ID'}
            />
            <MultiSelect
              label={'Key Skills'}
              placeholder={'Add Skill'}
              value={profileValue.key_skill}
              setValue={(val) =>
                setProfileValue({ ...profileValue, key_skill: val })
              }
              keySkills={allSkills}
            />
          </div>

          <div className=" md:w-1/2  flex flex-col justify-between gap-3">
            <SubmitButton text={'Save Changes'} />
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditProfileModal
