import React, { Fragment, useEffect, useRef, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useReactToPrint } from 'react-to-print'
import { CREATE_USER_PROFILE_PAGE } from '../../../navigation/routes'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import CandidateDetails from '../../components/dashboard/candidate-details/CandidateDetails'
import CommitChart from '../../components/dashboard/charts/CommitChart'
import LanguageChart from '../../components/dashboard/charts/LanguageChart'
import ProjectCharts from '../../components/dashboard/charts/ProjectCharts'
import Header from '../../components/dashboard/header/Header'
import UserCommitLanguage from '../../components/dashboard/languages/UserCommitLanguage'
import UserLanguage from '../../components/dashboard/languages/UserLanguage'
import RepoTable from '../../components/dashboard/repo-table/RepoTable'
import Repository from '../../components/dashboard/repo/Repository'
import GithubStats from '../../components/dashboard/stats/GithubStats'
import ErrorComponent from '../../components/error/ErrorComponent'
import Loader from '../../components/loader/Loader'
import RippleLoader from '../../components/loader/RippleLoader'
import FileChangeChart from '../../components/single-repository/file-change-chart/FileChangeChart'

const DashboardPage = () => {
  const [dashboardData, setDashboardData] = useState([])
  const [loading, setLoading] = useState(true)
  const [dataFetching, setDataFetching] = useState(true)
  const [commitFetching, setCommitFetching] = useState(true)
  const [candidateLoading, setCandidateLoading] = useState(false)
  const [allJobData, setAllJobData] = useState([])
  const [profileValue, setProfileValue] = useState({})
  const [showCreateProfileMessage, setShowCreateProfileMessage] =
    useState(false)
  const [interviewData, setInterviewData] = useState([])
  const { username } = useParams()
  const navigate = useNavigate()
  const { githubUser } = useSelector((state) => state.auth)
  const { dashboardEndpoint, getUserLanguages } = EndPoints.dashboard
  const { getFilechangeGraph } = EndPoints.repository
  const { shortlistEndpoint } = EndPoints.dashboard

  const fetchUserExist = async () => {
    //console.log('user exist is running');
    try {
      const { data } = await axiosInstance.post(
        `${dashboardEndpoint}/adduser`,
        {
          username
        }
      )
      console.log('exist', data)

      if (!data.user) {
        navigate('*')
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchCandidateData = async () => {
    setCandidateLoading(true)
    try {
      const res = await Promise.all([
        axiosInstance.get(
          `https://7g6izqwdr4.execute-api.ap-south-1.amazonaws.com/dev/getapplicantapplications?GITHUB_ID=${username}`
        ),
        axiosInstance.get(
          `https://brkjj5ttja.execute-api.ap-south-1.amazonaws.com/dev/get?github_id=${username}`
        ),
        axiosInstance.get(
          `${shortlistEndpoint}/Dev/interviewDetails/get?github_id=${username}`
        )
      ])

      console.log('candidate-details', res)

      if (res[1].data.body === 'null' || res[1].data.body === null) {
        setShowCreateProfileMessage(true)
        setCandidateLoading(false)
        setLoading(false)
        // return;
      }

      const response = JSON.parse(res[1].data.body)

      setProfileValue({
        ...response,
        key_skill: response.key_skill
          ?.split(',')
          .map((skill) => ({ language_name: skill, language_logo: '' }))
      })
      if (Array.isArray(JSON.parse(res[0].data.body))) {
        setAllJobData(JSON.parse(res[0].data.body))
      } else {
        setAllJobData([])
      }
      setInterviewData(res[2].data)
      setCandidateLoading(false)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchDataUpdate = async () => {
    if (!dataFetching) {
      return
    }
    console.log('fetchdataupdate is running')
    try {
      // setLoading(true);
      const { data } = await axiosInstance.get(
        `${dashboardEndpoint}/getstatus?username=${username}`
      )
      console.log(data)
      console.log(JSON.parse(data.body))

      if (JSON.parse(data.body).status === 'completed') {
        setDataFetching(false)
        fetchDashboardData()
      }
      if (
        JSON.parse(data.body).status === null ||
        JSON.parse(data.body) === null
      ) {
        setDataFetching(false)
        //fetchDashboardData();
      }
      if (JSON.parse(data.body).commit_status === 'complete') {
        setCommitFetching(false)
        fetchDashboardData()
      }
      if (JSON.parse(data.body).commit_status === null) {
        setCommitFetching(false)
        //fetchDashboardData();
      } else {
        setCommitFetching(false)
        setDataFetching(false)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchDashboardData = async () => {
    //console.log('fetch dashboard is running');
    //setLoading(true);
    try {
      const res = await Promise.all([
        axiosInstance.get(`${dashboardEndpoint}/getuser?username=${username}`),
        axiosInstance.get(`${dashboardEndpoint}/getrepo?username=${username}`),
        axiosInstance.get(
          `${dashboardEndpoint}/getlanguage?username=${username}`
        ),
        axiosInstance.get(
          `${dashboardEndpoint}/getranking?username=${username}`
        ),
        axiosInstance.get(
          `${dashboardEndpoint}/getcommitgraph?github_id=${username}`
        ),
        axiosInstance.get(
          `${getFilechangeGraph}/getfilesmodificationgraph?limit=7&owner=${username}`
        ),
        axiosInstance.get(`${getUserLanguages}?username=${username}`)
      ])
      console.log(res)
      const newData = res.map((res) => JSON.parse(res.data.body))
      console.log(newData)
      setDashboardData(newData)

      fetchCandidateData()
    } catch (err) {
      console.log(err.message)
    }
  }

  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  useEffect(() => {
    //console.log('useeffect for username changes');
    console.log(dataFetching, commitFetching)
    setLoading(true)
    if (!dataFetching && !commitFetching) {
      return
    }
    fetchDataUpdate()
    const dataInterval = setInterval(() => {
      fetchDataUpdate()
    }, 5000)

    return () => {
      clearInterval(dataInterval)
    }
  }, [dataFetching, commitFetching, username])

  useEffect(() => {
    setDataFetching(true)
    setCommitFetching(true)
    fetchUserExist()
    //fetchDataUpdate();
    //console.log('checking user');
  }, [username])

  return (
    <div ref={componentRef} className="">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | {username}</title>
      </Helmet>

      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          {githubUser?.username === username && showCreateProfileMessage && (
            <div className="w-full bg-blue-500 text-white p-10 py-4 text-center flex justify-center gap-4 items-center">
              <h1>
                <b>Better your reach, Get recognised by Recruiters | </b> Create
                your Profile with TechProfile
              </h1>
              <Link
                className="p-3 py-2  bg-orange-400 shadow-outer text-sm font-semibold rounded-full w-32"
                to={CREATE_USER_PROFILE_PAGE}
              >
                Create Profile
              </Link>
            </div>
          )}{' '}
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <Header data={dashboardData[0]} print={handlePrint} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <GithubStats
              data={dashboardData[0]}
              language={dashboardData[2]}
              rankData={dashboardData[3]}
            />
          </ErrorBoundary>
          {candidateLoading ? (
            <RippleLoader />
          ) : (
            <>
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <CandidateDetails
                  applicantData={profileValue}
                  interviewData={interviewData}
                  jobData={allJobData}
                  showCreateProfileMessage={showCreateProfileMessage}
                  candidateLoading={candidateLoading}
                />
              </ErrorBoundary>
            </>
          )}
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <UserLanguage language={dashboardData[2]} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <UserCommitLanguage languages={dashboardData[6].message} />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <LanguageChart
              data={dashboardData[2]}
              year_joined={dashboardData[0].git_create_date.split('-')[0]}
            />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <CommitChart
              data={dashboardData[4]}
              commitFetching={commitFetching}
            />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <div className="p-4 sm:p-6">
              <FileChangeChart
                data={dashboardData[5]}
                commitFetching={commitFetching}
                isRepoLevel={false}
              />
            </div>
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <ProjectCharts
              data={dashboardData[1]}
              year_joined={dashboardData[0].git_create_date.split('-')[0]}
            />
          </ErrorBoundary>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <div className="hidden : md:block">
              <RepoTable
                data={dashboardData[1]}
                userData={dashboardData[0]}
                year_joined={dashboardData[0].git_create_date.split('-')[0]}
              />
            </div>
            <div className="md:hidden">
              <Repository
                data={dashboardData[1]}
                userData={dashboardData[0]}
                year_joined={dashboardData[0].git_create_date.split('-')[0]}
              />
            </div>
          </ErrorBoundary>
        </Fragment>
      )}
    </div>
  )
}

export default DashboardPage
