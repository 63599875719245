import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { AiOutlineProfile } from 'react-icons/ai'
import { BsPeople } from 'react-icons/bs'
import { FaConnectdevelop } from 'react-icons/fa'
import { GiStarsStack } from 'react-icons/gi'
import { IoBarChartOutline } from 'react-icons/io5'
import { SiCircle } from 'react-icons/si'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import uuid from 'react-uuid'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import CodingLogo from '../../../assets/img/coding-history.jpg'
import HomeHeaderLogo from '../../../assets/img/home-header-logo.png'
import LanguageLogo from '../../../assets/img/language.jpg'
import ProfileLogo from '../../../assets/img/profile.jpg'
import RepoLogo from '../../../assets/img/repo-logo.png'
import {
  APPLICANT_SIGNUP_PAGE,
  RESUME_INTRODUCTION_PAGE,
  RESUME_JOB_PAGE,
  RESUME_SUMMARY_PAGE
} from '../../../navigation/routes'
import ClickWrapper from '../../components/click-wrapper/ClickWrapper'
import { collegLogos } from '../../components/home/data'
import FaqAccordion from '../../components/homepage/FaqAccordion'
import VideoModal from '../../components/homepage/VideoModal'
import WelcomeModal from '../../components/homepage/WelcomeModal'

const homepageTabs = [
  {
    title: 'Languages Details',
    text: "Based on the public projects you've worked on, you can get an idea of your strong areas and the languages you're skilled in. The last 12 months view gives a more accurate picture of which languages you've worked with recently. Create easily shareable public profie.",
    id: 1,
    image: LanguageLogo
  },
  {
    title: 'Coding History',
    text: 'Keep track of your coding journey with our visual history feature. By seeing your progress over time, you can get a better idea of how strong your profile is and where you need to continue to improve. We provide summary details about your work over the past year so that you can stay on top of your progress.',
    id: 2,
    image: CodingLogo
  },
  {
    title: 'Repo Deep Dive',
    text: 'Make your work stand out against other team members with an easy-to-use summary. Showcase your individual repo summary page with beautiful graphs and charts. ',
    id: 3,
    image: RepoLogo
  }
]

const FaqArr = [
  {
    question: 'What is TechProfile.org?',
    answer:
      'TechProfile.org is an online platform that connects tech professionals with job opportunities. Our platform uses proprietary algorithms and industry benchmarked machine learning models to match qualified candidates with job openings.'
  },
  {
    question:
      'What kind of job opportunities are available on TechProfile.org?',
    answer:
      'TechProfile.org offers a wide range of job opportunities for tech professionals, including software engineers, web developers, data scientists, product managers, and more.			'
  },
  {
    question: 'How does TechProfile.org work for candidates?',
    answer:
      "Candidates can sign up for TechProfile.org and create a profile that showcases their skills, experience, and work history. Our algorithms analyze each candidate's profile and match them with relevant job opportunities. Candidates can also search for job openings and apply directly through our platform."
  },
  {
    question: 'How does TechProfile.org assess candidates?',
    answer:
      "TechProfile.org uses a data-driven approach to candidate assessment, utilizing proprietary algorithms and industry benchmarked machine learning models to score each candidate. We analyze each candidate's resume and public code from GitHub to provide a comprehensive data set for hiring managers to make informed decisions."
  },
  {
    question:
      'Can candidates communicate directly with companies through TechProfile.org?',
    answer:
      'Yes, TechProfile.org provides a direct communication channel between companies and candidates, allowing for streamlined collaboration on the recruitment process. Candidates can use the platform to send messages, schedule interviews, and collaborate with their potential employers on the recruitment process.'
  },
  {
    question: 'Is TechProfile.org free to use for candidates?',
    answer:
      'Yes, TechProfile.org is free for candidates to use. Candidates can sign up, create a profile, and search for job openings at no cost.			'
  },
  {
    question:
      ' How can I improve my chances of being matched with relevant job opportunities on TechProfile.org?',
    answer:
      'To improve your chances of being matched with relevant job opportunities, make sure your profile is complete and up-to-date. Be sure to include your skills, experience, and work history. You can also use our platform to take online coding challenges and add your GitHub profile to showcase your coding abilities.'
  },
  {
    question: 'Is my personal information and data secure on TechProfile.org?',
    answer:
      "Yes, we take the security and privacy of our users' data very seriously. We use industry-standard encryption and security protocols to protect our platform and ensure that your data is secure. We also have a privacy policy in place to protect your personal information."
  }
]

const SingleTab = ({ title, text, image, idx }) => {
  return (
    <div className={`${idx === 1 && 'py-10 bg-blue-300'}`}>
      <div
        className={`flex flex-col-reverse ${
          (idx + 1) % 2 === 0 ? 'md:flex-row' : 'md:flex-row-reverse'
        } items-center text-center md:text-left p-16 px-6 md:p-20  gap-10 md:gap-16 lg:gap-20  lg:py-32  ${
          idx === 1 && 'bg-blue-100 text-gray-800'
        }  `}
      >
        <div
          className={`flex flex-col gap-6  justify-center   sm:w-[80%] md:w-1/2 max-w-xl m-auto text-base md:text-xl text-center md:text-left p-4 md:p-0 `}
        >
          <h3 className="text-2xl font-semibold md:text-4xl ">{title}</h3>
          <p className="font-medium leading-relaxed ">{text}</p>
        </div>
        <div className="grid sm:w-[80%] md:w-1/2 place-items-center ">
          <img
            src={image}
            alt="tech profile"
            className={`max-w-[600px] w-4/5 object-contain ${
              idx !== 2 && 'drop-shadow-xl'
            } `}
          />
        </div>
      </div>
    </div>
  )
}

export const settings = {
  speed: 750,
  autoplay: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  infinite: true,
  responsive: [
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 5
      }
    },
    {
      breakpoint: 750,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2
      }
    }
  ]
}

const HomePage = () => {
  const [showWelcomeModal, setShowWelcomeModal] = useState(true)
  const [showVideoModal, setShowVideoModal] = useState(false)
  return (
    <div className="relative w-screen min-h-screen overflow-hidden ">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Developers Resume - TechProfile</title>
      </Helmet>

      <div className="bg-sky-600 pb-20 w-[150vw] rotate-[8deg] ">
        <div className="bg-sky-700 pb-20 w-[150vw] -rotate-[3deg] ">
          <div className="flex -rotate-[5deg] -translate-x-4 justify-between items-center w-screen p-4 sm:p-10 md:px-20 min-h-[95vh] text-white bg-gradient-to-t from-sky-900 to-sky-800    ">
            <div className="justify-center p-4 ">
              <div className="flex flex-col max-w-2xl gap-4 md:gap-8">
                <h1 className="text-5xl font-bold leading-snug md:text-6xl ">
                  Your <span className="text-blue-500">Code</span> could be your{' '}
                  <span className="text-orange-500">Resume</span>{' '}
                </h1>
                <h1 className="text-3xl font-semibold md:text-4xl">
                  Built For Developers !
                </h1>

                <div className="flex flex-col gap-3 mt-4 mb-10 ">
                  <p className="text-sm font-normal leading-loose md:text-xl">
                    Highlight Your Strengths By The Most Accurate Commit-Based
                    Developer Resume.
                  </p>
                </div>
              </div>
              <div className="flex flex-col gap-3 md:flex-row ">
                <div className="w-full min-w-max max-w-[350px] grid place-items-center border-2 border-white  rounded-full  overflow-hidden">
                  <Link
                    to={APPLICANT_SIGNUP_PAGE}
                    className="flex items-center w-full px-2 py-3 text-sm text-white uppercase transition-all duration-300 ease-in-out bg-transparent outline-none hover:text-sky-900 hover:bg-white font-500 "
                  >
                    <p className="flex-1 text-base font-semibold text-center uppercase ">
                      Create your developer profile
                    </p>
                  </Link>
                </div>
                <div className="max-w-[200px] grid place-items-center   rounded-full  overflow-hidden">
                  <button
                    onClick={() => setShowVideoModal(true)}
                    className="flex items-center w-full text-sm text-white uppercase transition-all duration-300 ease-in-out bg-blue-400 outline-none sm:px-8 hover:bg-blue-500 font-500 "
                  >
                    <p className="flex-1 px-2 py-3 text-base font-semibold text-center uppercase ">
                      Learn More
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div className="hidden lg:flex w-1/2 max-w-[600px] justify-end flex-1  ">
              <img
                src={HomeHeaderLogo}
                alt="tech profile"
                className="object-contain w-full drop-shadow-2xl"
              />
            </div>
            {showVideoModal && (
              <div className="fixed top-0 left-0 right-0 z-40 grid w-screen h-screen backdrop-filter backdrop-blur-sm place-items-center">
                <div className="z-50 ">
                  <ClickWrapper func={() => setShowVideoModal(false)}>
                    <VideoModal
                      videoUrl={'https://www.youtube.com/watch?v=bBctds3zFYk'}
                      close={() => setShowVideoModal(false)}
                    />
                  </ClickWrapper>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="px-6 my-10 md:px-10">
        <div className="relative flex flex-col items-center w-full gap-20 py-10 m-auto max-w-7xl">
          <h1 className="text-4xl font-bold leading-relaxed text-center md:text-5xl ">
            Try Our <span className="text-blue-500">AI</span>{' '}
            <span className="text-orange-500">Services</span>
          </h1>

          <div className="grid grid-cols-1 gap-6 pb-20 md:grid-cols-3">
            <div className="flex flex-col justify-end">
              <div className="flex flex-col items-center gap-5 p-4 py-8 text-center bg-white border shadow-xl rounded-2xl">
                <AiOutlineProfile className="text-5xl text-sky-900" />
                <h2 className="text-xl font-semibold">
                  Free Resume Summary Generator
                </h2>

                <p>
                  Revolutionary free resume summary generator powered by
                  cutting-edge AI technology!
                </p>

                <Link
                  to={RESUME_SUMMARY_PAGE}
                  className="p-2 px-6 text-sm text-center text-white rounded-full bg-sky-900"
                >
                  Generate Summary
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <div className="shadow-xl min-h-[350px] flex flex-col justify-between gap-5 p-4 py-8 rounded-2xl text-white bg-sky-600 items-center text-center">
                <BsPeople className="text-6xl text-white" />
                <h2 className="text-xl font-semibold">
                  Free Tell Me About Yourself
                </h2>

                <p>
                  The easiest way to craft a winning response to one of the most
                  common job interview questions!
                </p>

                <Link
                  to={RESUME_INTRODUCTION_PAGE}
                  className="p-2 px-6 text-sm text-center bg-white rounded-full text-sky-900"
                >
                  Tell Me About Myself
                </Link>
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <div className="flex flex-col items-center gap-5 p-4 py-8 text-center bg-white border shadow-xl rounded-2xl">
                <GiStarsStack className="text-5xl text-sky-900" />
                <h2 className="text-xl font-semibold">Cover Letter Service</h2>

                <p>
                  Compare your resume against Job Description and make
                  improvements
                </p>

                <Link
                  to={RESUME_JOB_PAGE}
                  className="p-2 px-6 text-sm font-bold text-center text-white rounded-full bg-sky-900"
                >
                  Get You Score
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 my-10 md:px-10">
        <div className="relative flex flex-col items-center w-full gap-20 py-10 m-auto max-w-7xl">
          <h1 className="text-3xl font-bold leading-relaxed text-center md:text-4xl ">
            <span className="text-blue-500">Top</span>{' '}
            <span className="text-orange-500">Colleges</span> with us
          </h1>

          <div className="w-full">
            <Slider {...settings}>
              {collegLogos.map((item, index) => (
                <div className="grid px-4 place-items-center" key={index}>
                  <img className="object-contain w-full " src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="bg-sky-600 py-20 mt-40 w-[200vw] -translate-x-1/4 rotate-[8deg] ">
        <div className="bg-sky-700 py-20 w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg] -translate-x-1 text-white w-screen flex bg-gradient-to-l from-sky-900 to-sky-800 flex-col gap-10 md:gap-20 p-10 lg:p-20 items-center">
            <div className="flex flex-col-reverse items-center gap-10 text-center md:flex-row md:text-left ">
              <div className="flex flex-col gap-10 sm:w-[80%] md:w-1/2 text-lg md:text-xl">
                <h1 className="flex flex-wrap items-center justify-start w-full gap-2 text-4xl font-bold text-center md:text-5xl md:text-left sm:leading-relaxed ">
                  A <span className="text-blue-500">Professional</span>
                  <span className="text-orange-500">Profile</span>
                  <span className="w-full text-4xl leading-relaxed text-left md:text-5xl ">
                    that showcase the real you
                  </span>
                </h1>
                <p className="font-medium leading-relaxed text-left ">
                  Telling your developer story is not easy. It is not just a
                  list of work experience or a bunch of code. We aggregate your
                  developer activity from different platforms such as GitHub,
                  GitLab, StackOverflow, LinkedIn and provide the tools (API,
                  Widgets, Community) to create a profile you want.
                </p>
                <div className="grid overflow-hidden border-2 border-white rounded-full sm:w-80 place-items-center">
                  <Link
                    to={APPLICANT_SIGNUP_PAGE}
                    className="flex items-center w-full px-2 py-3 text-sm text-white uppercase transition-all duration-300 ease-in-out bg-transparent outline-none hover:text-sky-900 hover:bg-white font-500 "
                  >
                    <p className="flex-1 text-base font-semibold text-center uppercase ">
                      Get Started
                    </p>
                  </Link>
                </div>
              </div>
              <div className="flex sm:w-[80%] md:w-1/2 justify-center items-center ">
                <img
                  src={ProfileLogo}
                  alt="tech profile"
                  className=" w-4/5 max-w-[800px]  object-contain drop-shadow-xl "
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col-reverse items-center justify-center w-full max-w-6xl gap-10 p-10 mx-auto lg:p-20">
        <div className="flex justify-end w-full">
          <div className="flex w-full gap-8 p-8 shadow-md bg-blue-50 rounded-2xl max-w-max ">
            <FaConnectdevelop className="text-4xl" />
            <p className="text-lg font-semibold text-center md:text-2xl">
              Connect Your Coding Experience
            </p>
          </div>
        </div>
        <div className="flex justify-center w-full ">
          <div className="flex w-full gap-8 p-8 shadow-md bg-blue-50 rounded-2xl max-w-max ">
            <SiCircle className="text-4xl" />
            <p className="text-lg font-semibold text-center md:text-2xl">
              Extend Your Profile With Your Professional Needs
            </p>
          </div>
        </div>
        <div className="flex w-full ">
          <div className="flex w-full gap-8 p-8 shadow-md bg-blue-50 rounded-2xl max-w-max ">
            <IoBarChartOutline className="text-4xl" />
            <p className="text-lg font-semibold text-center md:text-2xl">
              Get The Best Growth Opportunities & Jobs
            </p>
          </div>
        </div>
      </div>

      <div className="bg-sky-600 py-20 mt-10 w-[200vw] -translate-x-1/4 translate-y-2 rotate-[8deg] ">
        <div className="bg-sky-700 py-20 w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg] -translate-x-1 text-white w-screen flex bg-gradient-to-r from-sky-900 to-sky-800 flex-col gap-10 md:gap-20 items-center">
            <div className="flex flex-col items-center w-full gap-10 pt-20 md:gap-20 ">
              <h1 className="max-w-3xl text-4xl font-bold leading-relaxed text-center md:text-5xl ">
                <span className="text-blue-500">Show</span>{' '}
                <span className="text-orange-500">Off</span> What You Know
              </h1>

              <div className="flex flex-col w-full ">
                {homepageTabs.map((tabs, idx) => (
                  <SingleTab {...tabs} key={uuid()} idx={idx} />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 my-10 md:px-10">
        <div className="relative flex flex-col items-center w-full gap-20 py-20 m-auto max-w-7xl">
          <h1 className="text-3xl font-bold leading-relaxed text-center md:text-4xl ">
            <span className="text-blue-500">Frequently</span>
            <span className="ml-1 text-orange-500">Asked</span> Questions
          </h1>

          <FaqAccordion data={FaqArr} />
        </div>
      </div>

      {showWelcomeModal && (
        <div className="fixed top-0 left-0 right-0 z-10 grid w-full h-screen text-left bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm place-items-center">
          <div className="z-50 w-full max-w-4xl p-4 m-auto">
            <ClickWrapper func={() => setShowWelcomeModal(false)}>
              <WelcomeModal close={() => setShowWelcomeModal(false)} />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default HomePage
