import { signInWithPopup } from 'firebase/auth'
import React from 'react'
import { FaGithub } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { APPLICANT_SIGNUP_PAGE, LOGIN_PAGE } from '../../../navigation/routes'
import authAction from '../../../redux/actions/authAction'
import { auth, githubProvider } from '../../../services/firebase'

const ApplicantSigninPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const signUpWithGithub = async () => {
    try {
      const res = await signInWithPopup(auth, githubProvider)
      console.log(res)
      const { email, screenName, oauthAccessToken, photoUrl } =
        res._tokenResponse
      dispatch(
        authAction.githubLogin({
          email,
          oauthAccessToken,
          username: screenName,
          photoUrl
        })
      )
      navigate(`/dashboard/${screenName}`)
    } catch (error) {
      console.log(error.message)
    }
  }
  return (
    <div className="w-screen min-h-screen overflow-hidden relative bg-gradient-to-l from-sky-900 to-sky-600 ">
      <div className="bg-gradient-to-l from-sky-900 to-sky-600 pb-20 w-[150vw] rotate-[8deg]  ">
        <div className="bg-gradient-to-l from-sky-900 to-sky-700 pb-20 w-[150vw] -rotate-[3deg] ">
          <div className="flex -rotate-[5deg] -translate-x-4 justify-between items-center w-screen min-h-[90vh] p-4 sm:p-10 md:px-20  text-white bg-gradient-to-t from-sky-900 to-sky-800    ">
            <div className="h-full   lg:w-1/2 p-6 sm:p-10  flex justify-center items-center  ">
              <div className=" flex flex-col gap-6 justify-center items-start transform -translate-y-1/4   ">
                <h1 className="text-4xl md:text-5xl leading-snug font-bold ">
                  Create Your <span className="text-blue-500">Developer</span>
                  <span className="text-orange-500 ml-2">Profile</span> now
                </h1>
                <h3 className="text-lg leading-relaxed font-semibold ">
                  Highlight Your Strengths By The Most Accurate Commit-Based
                  Developer Resume.
                </h3>
                <div className=" w-full  max-w-[300px] grid place-items-center   rounded-xl overflow-hidden">
                  <button
                    onClick={() => signUpWithGithub()}
                    className="flex items-center uppercase w-full outline-none text-sm bg-gradient-to-t from-gray-900 to-gray-800 transition-all duration-300 ease-in-out text-white font-500 "
                  >
                    <span className="text-xl  text-center p-3 ">
                      <FaGithub />
                    </span>
                    <p className="flex-1 py-3 px-2 text-center ">
                      Sign in with Github
                    </p>
                  </button>
                </div>
                <div className="flex flex-col gap-2  ">
                  <div className="flex justify-center gap-2 items-center text-xs md:text-sm   font-semibold ">
                    <p>Don't have an account ? </p>

                    <Link
                      to={APPLICANT_SIGNUP_PAGE}
                      className="text-blue-200 text-xs md:text-sm  font-semibold hover:text-blue-300"
                    >
                      Sign Up
                    </Link>
                  </div>
                  <div className="flex gap-2 items-center text-xs md:text-sm   font-semibold ">
                    <p>OR</p>

                    <Link
                      to={LOGIN_PAGE}
                      className="text-blue-200 text-xs md:text-sm  font-semibold hover:text-blue-300"
                    >
                      Login as Company
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full lg:w-1/2 max-w-2xl grid place-items-center relative">
              <div className="hidden lg:block">
                <lottie-player
                  src="https://assets9.lottiefiles.com/packages/lf20_gnb0jsok.json"
                  background="transparent"
                  speed="1"
                  style={{
                    width: '100%',
                    height: '500px',
                    objectFit: 'contain'
                  }}
                  loop
                  autoplay
                ></lottie-player>
              </div>

              <p className="hidden lg:block absolute min-w-[150px] text-center bg-orange-400 text-white px-4 p-2 rounded-full text-base font-600 top-0 left-1/3 transform ">
                up-to-date profile
              </p>
              <p className="hidden lg:block  absolute min-w-[150px] text-center bg-blue-400 text-white px-4 p-2 rounded-full text-base font-600 top-1/3 left-0">
                Free
              </p>
              <p className="hidden lg:block absolute min-w-[150px] text-center bg-orange-400 text-white px-4 p-2 rounded-full text-base font-600 bottom-1/4 -left-10">
                Relevant Job Offers
              </p>
              <p className="hidden lg:block absolute min-w-[150px] text-center bg-blue-400 text-white px-4 p-2 rounded-full text-base font-600 top-1/4 right-20">
                Secure
              </p>
              <p className="hidden lg:block absolute min-w-[150px] text-center bg-blue-400 text-white px-4 p-2 rounded-full text-base font-600 bottom-40 right-10">
                Track Improvements
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ApplicantSigninPage
