import React, { Fragment, useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { MdClose } from 'react-icons/md'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import axiosInstance from '../../../services/axios/axios'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import EmailInput from '../create-profile/email-input/EmailInput'
import MultiSelect from '../create-profile/multi-select/MultiSelect'
import NumberInput from '../create-profile/num-input/NumberInput'
import TextInput from '../create-profile/text-input/TextInput'
import { leaderboardCountries } from '../leaderboard/tableData'
import RippleLoader from '../loader/RippleLoader'
import { ProjectKeySkills, projectCategory } from './data'

const EditProjectModal = ({
  refetchData,
  close,
  id,
  projectData: editProjectData
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const [projectData, setProjectData] = useState({
    user_id: user.user_id,
    project_title: editProjectData?.project_title ?? '',
    key_skills:
      editProjectData?.key_skills
        .split('|')
        .map((skill) => ({ language_name: skill, language_logo: '' })) ?? [],
    status: editProjectData?.status,
    organization: editProjectData?.organization ?? '',
    email: editProjectData?.email ?? '',
    years_of_experience: editProjectData?.years_of_experience ?? 0,
    description: editProjectData?.description ?? '',
    project_category: editProjectData?.project_category ?? '',
    duration: editProjectData?.duration ?? 0,
    payment: editProjectData?.payment.toString() ?? '',
    country: editProjectData?.country ?? '',
    guidance: editProjectData?.guidance ?? ''
  })

  const handleJobDescriptionChange = (value) => {
    setProjectData({ ...projectData, description: value })
  }

  const handleEditProject = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const putData = {
      ...projectData,
      id,
      key_skills: projectData.key_skills
        .map((skills) => skills.language_name)
        .join('|')
    }

    try {
      const { data } = await axiosInstance.put(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/UpdateProject`,
        putData
      )
      console.log(data)
      showToast('success', JSON.parse(data.message).message)

      //navigate(`${JOB_LISTING_PAGE}`);
      await refetchData()
      setIsLoading(false)
      close()
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }

  return (
    <div className="p-3 sm:p-6 md:p-10 w-full  m-auto h-[95vh] bg-white overflow-y-scroll relative ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-xl font-bold"
      >
        <MdClose />
      </button>

      <Fragment>
        {isLoading && (
          <div className="bg-opacity-20 bg-white fixed inset-0 grid place-items-center">
            <RippleLoader />
          </div>
        )}

        <form onSubmit={handleEditProject}>
          <div className="w-full max-w-3xl m-auto flex flex-col gap-6 md:gap-10">
            <div className="flex flex-col gap-4">
              <h1 className="text-2xl md:text-3xl font-semibold  border-b-2 border-gray-200 pb-4 ">
                Edit Project
              </h1>
            </div>

            <div className="flex flex-col  sm:grid sm:grid-cols-2 gap-6  ">
              <TextInput
                value={projectData.project_title}
                setValue={(val) =>
                  setProjectData({ ...projectData, project_title: val })
                }
                label={'Title'}
                placeholder={'Enter Project Title'}
              />

              <TextInput
                value={projectData.organization}
                setValue={(val) =>
                  setProjectData({ ...projectData, organization: val })
                }
                label={'Organization'}
                placeholder={'Enter Organization name'}
              />

              <EmailInput
                value={projectData.email}
                setValue={(val) =>
                  setProjectData({ ...projectData, email: val })
                }
                label={'Email'}
                placeholder={'Enter Email'}
              />
              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Country</p>
                <Select
                  className="basic-single  bg-transparent text-sm"
                  onChange={(val) =>
                    setProjectData({ ...projectData, country: val.value })
                  }
                  value={{
                    value: projectData.country,
                    label: projectData.country
                  }}
                  classNamePrefix="Country"
                  defaultValue={
                    leaderboardCountries.map((country) => ({
                      value: country,
                      label: country
                    }))[0]
                  }
                  isClearable={true}
                  isSearchable={true}
                  styles={reactSelectStyleUtils}
                  name="country"
                  placeholder="Select Project Country"
                  options={leaderboardCountries.map((country) => ({
                    value: country,
                    label: country
                  }))}
                />
              </label>
              <NumberInput
                value={projectData.years_of_experience}
                setValue={(val) =>
                  setProjectData({ ...projectData, years_of_experience: val })
                }
                label={'Years of Experience'}
                placeholder={'Years of Experience'}
              />

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Project Category</p>
                <Select
                  className="basic-single  bg-transparent text-sm"
                  onChange={(val) =>
                    setProjectData({
                      ...projectData,
                      project_category: val.value
                    })
                  }
                  value={{
                    value: projectData.project_category,
                    label: projectData.project_category
                  }}
                  classNamePrefix="Category"
                  defaultValue={
                    projectCategory.map((category) => ({
                      value: category,
                      label: category
                    }))[0]
                  }
                  isClearable={true}
                  isSearchable={true}
                  name="jobCategory"
                  placeholder="Select Job Category"
                  styles={reactSelectStyleUtils}
                  options={projectCategory.map((category) => ({
                    value: category,
                    label: category
                  }))}
                />
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Payment</p>
                <select
                  value={projectData.payment}
                  onChange={(e) =>
                    setProjectData({ ...projectData, payment: e.target.value })
                  }
                  className="bg-white border  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'PAID'}>
                    Paid
                  </option>
                  <option className="" value={'UNPAID'}>
                    Unpaid
                  </option>
                </select>
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Status</p>
                <select
                  value={projectData.status}
                  onChange={(e) =>
                    setProjectData({ ...projectData, status: e.target.value })
                  }
                  className="bg-white border  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'OPEN'}>
                    Open
                  </option>
                  <option className="" value={'CLOSE'}>
                    Closed
                  </option>
                </select>
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Duration</p>
                <select
                  value={projectData.duration}
                  onChange={(e) =>
                    setProjectData({
                      ...projectData,
                      duration: e.target.value
                    })
                  }
                  className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'1'}>
                    1 Month or less
                  </option>
                  <option className="" value={'3'}>
                    3 Months
                  </option>
                  <option className="" value={'6'}>
                    6 Months
                  </option>
                  <option className="" value={'open'}>
                    Open Discussion
                  </option>
                </select>
              </label>
              <MultiSelect
                label={'Key Skills'}
                placeholder={'Add Skill'}
                value={projectData.key_skills}
                setValue={(val) =>
                  setProjectData({ ...projectData, key_skills: val })
                }
                keySkills={ProjectKeySkills.map((skill) => ({
                  language_name: skill
                }))}
              />

              <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 mb-6 sm:mb-0 ">
                <p className=" font-semibold  ">Description</p>
                <div className="bg-white h-40">
                  <ReactQuill
                    className=" h-32 rounded-2xl"
                    placeholder="Enter Project Description"
                    value={projectData.description}
                    onChange={handleJobDescriptionChange}
                  />
                </div>
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 ">
                <p className=" font-semibold  ">Description Preview</p>
                <div className="bg-white border min-h-[10rem] outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 ">
                  {ReactHtmlParser(projectData.description.toString())}
                </div>
              </label>
            </div>

            <div className=" md:w-1/2  flex flex-col justify-between gap-3">
              <SubmitButton text={'Update'} />
            </div>
          </div>
        </form>
      </Fragment>
    </div>
  )
}

export default EditProjectModal
