import React from 'react'
import ReactQuill from 'react-quill'
import SubmitButton from '../auth/SubmitButton'
import EmailInput from '../create-profile/email-input/EmailInput'
import MultiSelect from '../create-profile/multi-select/MultiSelect'
import NumberInput from '../create-profile/num-input/NumberInput'
import TextInput from '../create-profile/text-input/TextInput'
import Select from 'react-select'
import { BiImageAdd } from 'react-icons/bi'
import { useSelector } from 'react-redux'
import { leaderboardCountries } from '../leaderboard/tableData'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import { jobCategoryData } from './data'
import ReactHtmlParser from 'react-html-parser'

const JobDescriptionTab = ({
  profileValue,
  fileChange,
  setProfileValue,
  imageFile,
  previewImage,
  imageUploading,
  allSkills,
  handleJobDescriptionChange,
  setTab
}) => {
  const { user } = useSelector((state) => state.auth)
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        setTab(2)
      }}
    >
      <div className="w-full flex flex-col gap-6 md:gap-10 pb-10">
        <div className="flex flex-col gap-6 ">
          <div className="w-full flex flex-col gap-4 justify-center items-center">
            {imageFile !== null && previewImage != null ? (
              <img
                className="w-20 h-20 object-contain"
                src={previewImage}
                alt={''}
              />
            ) : (
              <img
                className="w-20 h-20 object-contain"
                src={
                  'https://daluscapital.com/wp-content/uploads/2016/04/dummy-post-square-1-1.jpg'
                }
                alt={''}
              />
            )}
          </div>

          {!imageUploading && (
            <div className="flex flex-col gap-2 items-center">
              <label className="grid place-items-center text-2xl cursor-pointer">
                <BiImageAdd />
                <p className="text-xs">Upload Company Logo</p>
                <input
                  className="hidden"
                  type="file"
                  onChange={fileChange}
                  accept="image/*"
                />
              </label>
            </div>
          )}
        </div>

        <div className="flex flex-col  sm:grid sm:grid-cols-2 gap-6  ">
          <TextInput
            value={profileValue.job_name}
            setValue={(val) =>
              setProfileValue({ ...profileValue, job_name: val })
            }
            label={'Title'}
            placeholder={'Enter Job Title'}
            required
          />

          <TextInput
            value={profileValue.organization}
            setValue={(val) =>
              setProfileValue({ ...profileValue, organization: val })
            }
            label={'Organization'}
            placeholder={'Enter Organization name'}
            required
          />

          <div className="flex flex-col md:flex-row gap-6 ">
            <div>
              <TextInput
                value={profileValue.user_id}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, user_id: val })
                }
                allowNumber
                label={'Organization ID'}
                placeholder={'Enter Organization ID'}
                disabled={user.type !== 'SUPER_ADMIN'}
              />
            </div>

            <div>
              <NumberInput
                value={profileValue.years_of_experience}
                setValue={(val) =>
                  setProfileValue({
                    ...profileValue,
                    years_of_experience: val
                  })
                }
                label={'Years of Experience'}
                placeholder={'Years of Experience'}
                min={0}
              />
            </div>
          </div>

          <EmailInput
            value={profileValue.job_email}
            setValue={(val) =>
              setProfileValue({ ...profileValue, job_email: val })
            }
            label={'Job Email'}
            placeholder={'Enter Job Email'}
            required
          />
          <label className="flex flex-col gap-2 text-xs md:text-sm ">
            <p className=" font-semibold  ">Country</p>
            <Select
              className="basic-single  bg-transparent text-sm"
              onChange={(val) =>
                setProfileValue({ ...profileValue, country: val.value })
              }
              value={{
                value: profileValue.country,
                label: profileValue.country
              }}
              classNamePrefix="Country"
              defaultValue={
                leaderboardCountries.map((country) => ({
                  value: country,
                  label: country
                }))[0]
              }
              isClearable={true}
              isSearchable={true}
              styles={reactSelectStyleUtils}
              name="country"
              placeholder="Select Job Country"
              options={leaderboardCountries.map((country) => ({
                value: country,
                label: country
              }))}
            />
          </label>
          <label className="flex flex-col gap-2 text-xs md:text-sm ">
            <p className=" font-semibold  ">Job Category</p>
            <Select
              className="basic-single  bg-transparent text-sm"
              onChange={(val) =>
                setProfileValue({ ...profileValue, job_category: val.value })
              }
              value={{
                value: profileValue.job_category,
                label: profileValue.job_category
              }}
              classNamePrefix="Category"
              defaultValue={
                jobCategoryData.map((category) => ({
                  value: category,
                  label: category
                }))[0]
              }
              isClearable={true}
              isSearchable={true}
              name="jobCategory"
              placeholder="Select Job Category"
              styles={reactSelectStyleUtils}
              options={jobCategoryData.map((category) => ({
                value: category,
                label: category
              }))}
            />
          </label>

          <div className="flex flex-col md:flex-row gap-6 ">
            <TextInput
              value={profileValue.pay_range}
              setValue={(val) =>
                setProfileValue({ ...profileValue, pay_range: val })
              }
              label={'Pay Range'}
              placeholder={'Enter Pay Range'}
            />

            <label className="flex flex-col gap-2 text-xs md:text-sm w-full ">
              <p className=" font-semibold  ">Job Type</p>
              <select
                value={profileValue.job_type}
                onChange={(e) =>
                  setProfileValue({
                    ...profileValue,
                    job_type: e.target.value
                  })
                }
                className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 "
              >
                <option className="p-4" value={''}>
                  None
                </option>
                <option className="p-4" value={'permanent'}>
                  Permanent
                </option>
                <option className="" value={'contract'}>
                  Contract
                </option>
                <option className="" value={'intern'}>
                  Intern
                </option>
              </select>
            </label>
          </div>

          <div className="flex flex-col md:flex-row  gap-6 ">
            <label className="flex flex-col gap-2 text-xs md:text-sm w-full ">
              <p className=" font-semibold  ">Weekly Hours</p>
              <select
                value={profileValue.weekly_hours}
                onChange={(e) =>
                  setProfileValue({
                    ...profileValue,
                    weekly_hours: e.target.value
                  })
                }
                className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 "
              >
                <option className="p-4" value={''}>
                  None
                </option>
                <option className="p-4" value={'10'}>
                  10 hrs
                </option>
                <option className="" value={'20'}>
                  20 hrs
                </option>
                <option className="" value={'40'}>
                  40 hrs
                </option>
              </select>
            </label>
            <label className="flex flex-col gap-2 text-xs md:text-sm w-full">
              <p className=" font-semibold  ">Work Location</p>
              <select
                value={profileValue.work_hours}
                onChange={(e) =>
                  setProfileValue({
                    ...profileValue,
                    work_hours: e.target.value
                  })
                }
                className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 "
              >
                <option className="p-4" value={''}>
                  None
                </option>
                <option className="p-4" value={'onsite'}>
                  Onsite
                </option>
                <option className="" value={'remote'}>
                  Remote
                </option>
                <option className="" value={'hybrid'}>
                  Hybrid
                </option>
              </select>
            </label>
          </div>

          <MultiSelect
            label={'Key Skills'}
            placeholder={'Add Skill'}
            value={profileValue.key_skills}
            setValue={(val) =>
              setProfileValue({ ...profileValue, key_skills: val })
            }
            keySkills={allSkills}
          />

          {/* <JobDescription
                value={profileValue.description}
                onChange={handleJobDescriptionChange}
            /> */}

          <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 mb-6 sm:mb-0 ">
            <p className=" font-semibold  ">Description</p>
            <div className="bg-white h-40">
              <ReactQuill
                className=" h-32 rounded-2xl"
                placeholder="Enter Job Description"
                value={profileValue.description}
                onChange={handleJobDescriptionChange}
              />
            </div>
          </label>

          <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 ">
            <p className=" font-semibold  ">Description Preview</p>
            <div className="bg-white border min-h-[10rem] outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 ">
              {ReactHtmlParser(profileValue.description.toString())}
            </div>
          </label>
        </div>

        <div className=" md:w-1/2  flex flex-col justify-between gap-3">
          <SubmitButton text={'Continue'} />
        </div>
      </div>
    </form>
  )
}

export default JobDescriptionTab
