export const AdminJobTableHeader = [
  {
    title: 'S No.',
    width: '30%'
  },
  {
    title: 'Service ',
    width: '30%'
  },
  {
    title: 'Organisation',
    width: '25%'
  },
  {
    title: 'Service Email',
    width: '10%'
  },
  {
    title: 'Category',
    width: '10%'
  },
  {
    title: 'Country',
    width: '10%'
  },
  {
    title: 'Created Date',
    width: '10%'
  },
  {
    title: 'Service Type',
    width: '10%'
  },
  {
    title: 'Service ID',
    width: '10%'
  },
  {
    title: 'Actions',
    width: '10%'
  }
]
export const AdminContactHeader = [
  {
    title: 'S No.',
    width: '30%'
  },
  {
    title: 'Email ',
    width: '30%'
  },
  {
    title: 'Phone ',
    width: '30%'
  },
  {
    title: 'Contact Date',
    width: '25%'
  },
  {
    title: 'Message',
    width: '10%'
  },
  {
    title: 'Reason',
    width: '10%'
  },
  {
    title: 'Status',
    width: '10%'
  },
  {
    title: '#',
    width: '10%'
  }
]

export const githubTableHeader = [
  {
    title: 'S No.',
    width: '10%'
  },
  {
    title: 'Github',
    width: '10%'
  },
  {
    title: 'Email',
    width: '10%'
  },
  {
    title: 'Created At',
    width: '10%'
  }
]
