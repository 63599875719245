import React from 'react'
import { SuperAdminUsersHeader } from './data'
import uuid from 'react-uuid'
import { Fragment } from 'react'
import ListingPagination from '../pagination/ListingPagination'
import SingleSuperAdminUser from './SingleSuperAdminUser'

const SuperAdminUserTable = ({
  data,
  page = 1,
  onPageChange = () => null,
  count = 0,
  limit = 20,
  fetchAllUsers = () => null
}) => {
  return (
    <div className="overflow-x-scroll w-full mt-10">
      <table className={`p-4 w-full rounded-lg  shadow-custom bg-white`}>
        <tbody className="text-xs xl:text-sm rounded-lg ">
          <tr className="bg-blue-400 bg-opacity-20 p-2 ">
            {SuperAdminUsersHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>

          {data.length !== 0 &&
            data.map((applicantData, index) => (
              <SingleSuperAdminUser
                key={uuid()}
                data={applicantData}
                fetchAllUsers={fetchAllUsers}
                limit={limit}
                index={index}
                page={page}
              />
            ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
            No Applicants Found
          </p>
        </Fragment>
      )}
      {count > 0 && (
        <ListingPagination
          page={page}
          onPageChange={onPageChange}
          count={count}
        />
      )}
    </div>
  )
}

export default SuperAdminUserTable
