import axios from '../../../services/axios/axios'
import React, { useState, useEffect, Fragment } from 'react'
import EndPoints from '../../../services/api/api'

const EmailField = ({
  onChange,
  value,
  checkEmail = false,
  errorMessage = '',
  setEmailValidity,
  label = 'Email',
  required = false
}) => {
  const [text, setText] = useState(value)
  const [isAvailable, setIsAvailable] = useState(null)
  const { authCheckEmailAvailability } = EndPoints.auth

  const checkEmailAvailability = async (email) => {
    if (checkEmail && email && email.length > 0 && email.indexOf('@') > 0) {
      try {
        const { data } = await axios.post(`${authCheckEmailAvailability}`, {
          email
        })

        const res = JSON.parse(data.response).message
        if (res === true) {
          setIsAvailable(true)
          setEmailValidity(true)
        } else if (res === false) {
          setIsAvailable(false)
          setEmailValidity(false)
        }
      } catch (err) {
        console.log(err.message)
      }
    }
  }

  useEffect(() => {
    const checkEmailTimeout = setTimeout(() => {
      checkEmailAvailability(value)
    }, 1000)
    return () => {
      clearTimeout(checkEmailTimeout)
    }
  }, [text])

  return (
    <Fragment>
      <label className="flex flex-col text-sm w-full font-600 my-2 z-0">
        <p className="font-semibold tracking-wide">{label}* </p>

        <input
          className="p-3 rounded-xl border-2 outline-none text-gray-700  my-2 font-600 border-gray-400 border-opacity-50 placeholder-gray-400"
          type="email"
          name="email"
          value={value}
          required={required}
          onChange={onChange}
          onKeyUp={(e) => {
            if (!checkEmail) {
              return
            }

            setText(e.target.value)
          }}
          placeholder="mail@website.com"
        />
        {isAvailable !== null && (
          <p
            className={`text-xs  ml-4 text-${
              isAvailable ? `green-200` : `red-200`
            }`}
          >
            {isAvailable
              ? '✔ Email available for use'
              : '！Email already in use . Use different email or Sign In instead'}
          </p>
        )}
        {errorMessage && (
          <p className="text-red-200 text-xs font-semibold ml-4">
            {errorMessage}
          </p>
        )}
      </label>
    </Fragment>
  )
}

export default EmailField
