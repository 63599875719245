import React from 'react'
import { ArrowTooltip } from '../../ui-component/tooltip/Tooltip'
import { MdInfo } from 'react-icons/md'

const SkillScore = ({ label, score, matchedSkill = '' }) => {
  return (
    <div className="flex flex-col  gap-1 w-full relative ">
      <div className="flex gap-3 items-center">
        <p className="text-xs text-left capitalize font-semibold">
          {label} ({score?.toFixed(2) ?? 0}%)
        </p>

        <ArrowTooltip
          title={
            matchedSkill === null || matchedSkill.length === 0
              ? 'No matched skill'
              : `Matched Skills : ${matchedSkill.split(',').join(' , ')}`
          }
        >
          <div>
            <MdInfo className="text-lg cursor-pointer " />
          </div>
        </ArrowTooltip>
      </div>

      <div className="w-full h-2 relative bg-gray-100   rounded-full shadow overflow-hidden ">
        <div
          style={{
            width: `${score}%`
          }}
          className="absolute  top-0 h-full left-0 z-10 rounded-r-full bg-gradient-to-r from-blue-400  to-green-400  "
        ></div>
      </div>
    </div>
  )
}

export default SkillScore
