import { Buffer } from 'buffer'
import html2canvas from 'html2canvas'
import React, { useEffect, useRef } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { AiOutlineGithub } from 'react-icons/ai'
import uuid from 'react-uuid'
import EndPoints from '../../../../../services/api/api'
import axiosInstance from '../../../../../services/axios/axios'
import { includeComma } from '../../../../../services/global/globalFunction'
import TechProfileLogo from '../../../navbar/TechProfileLogo'

const SharableProfileCard = ({ data, language, rankData }) => {
  const {
    total_repos,
    total_stars,
    total_commits,
    repositories_contributed_to,
    profile_score,
    followers,
    following,
    github_id,
    name,
    meta_status,
    avatar_url
  } = data
  const profileRef = useRef()

  const { uploadJobLogo } = EndPoints.createJob

  const updateProfileMeta = async (filename) => {
    try {
      await axiosInstance.put(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/updateMeta`,
        {
          meta_status: 1,
          github_id,
          meta: `https://github-meta.s3.ap-south-1.amazonaws.com/${filename}`
        }
      )
    } catch (err) {
      console.log(err.message)
    }
  }

  const percentileFormat = (points) => {
    if (points <= 2) {
      return 0
    }
    if (points > 2 && points < 5) {
      return 60
    }
    if (points >= 5 && points < 10) {
      return 75
    }
    if (points >= 10 && points <= 20) {
      return 80
    }
    if (points >= 20 && points < 50) {
      return 90
    }
    if (points >= 50 && points < 90) {
      return 95
    }
    return 99
  }

  const exportAsImage = async (element) => {
    const canvas = await html2canvas(element, { useCORS: true })
    const image = canvas.toDataURL('image/png', 1.0)
    await uploadMetatag(image)
  }

  const uploadMetatag = async (base64) => {
    console.log('upoadingmeta')
    const type = base64.split(';')[0].split('/')[1]
    if (base64 === '') return
    try {
      const options = {
        headers: {
          //Authorization: `Bearer ${user.token}`,
          'Content-Type': `image/${type}`,
          'Content-Encoding': 'base64'
        }
      }
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: `${github_id}-profile-meta`,
        bucketname: 'github-meta'
      })

      const uploadUrl = JSON.parse(data).data.URL
      const buffer = Buffer.from(
        base64.replace(/^data:image\/\w+;base64,/, ''),
        'base64'
      )
      await axiosInstance.put(uploadUrl, buffer, options)
      await updateProfileMeta(`${github_id}-profile-meta`)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (meta_status) return
    exportAsImage(profileRef?.current)
  }, [])

  return (
    <div className="w-full p-6 ">
      <div
        ref={profileRef}
        className="rounded-xl overflow-hidden shadow-lg border flex  flex-col"
      >
        <div id="profile_bg" className="h-40 relative ">
          <div className="flex flex-col items-end absolute right-2 bottom-2  ">
            <div className="flex gap-1 justify-end w-full -mb-3 items-center scale-75 ">
              <TechProfileLogo />
              <h1 className="font-bold text-white tracking-wide uppercase ">
                {' '}
                <span className="text-blue-500">T</span>ech
                <span className="text-blue-500">P</span>
                rofile
              </h1>
            </div>
            <p className="text-white text-sm ">techprofile.org</p>
          </div>
        </div>
        <div className="flex flex-col md:flex-row gap-6 justify-between p-4 min-h-[220px]">
          <div className="relative md:w-48 ">
            <div className="flex md:flex-col   gap-1 items-center">
              <div className="p-2 bg-white absolute -top-1/2   rounded-full">
                <img
                  className="w-40 h-40  rounded-full object-cover"
                  src={avatar_url}
                  alt=""
                />
              </div>

              <div className="flex justify-between w-full gap-4">
                <div className="pt-20">
                  <a href={`https://github.com/${github_id}`} target="blank">
                    <div className="flex gap-2 items-center ">
                      <AiOutlineGithub className="text-5xl" />
                      <div className="flex flex-col w-full">
                        <h2 className="font-semibold text-lg">{github_id}</h2>
                        <p className="text-sm font-semibold">{name}</p>
                      </div>
                    </div>
                  </a>

                  <div className="flex gap-6 mt-1 justify-evenly items-center">
                    <div className="flex flex-col gap-1 text-sm font-semibold items-center">
                      <h3>Followers</h3>
                      <p>{includeComma(followers) ?? 0}</p>
                    </div>
                    <div className="flex flex-col gap-1 text-sm font-semibold items-center">
                      <h3>Following</h3>
                      <p>{includeComma(following) ?? 0}</p>
                    </div>
                  </div>
                </div>

                <div className=" flex md:hidden flex-col gap-4 justify-between items-end">
                  <div style={{ width: '100%', maxWidth: '100px' }}>
                    <CircularProgressbar
                      value={percentileFormat(profile_score)}
                      text={`Top ${100 - percentileFormat(profile_score)}% `}
                      strokeWidth={10}
                      styles={buildStyles({
                        textColor: 'black',
                        pathColor: '#0091FE',
                        trailColor: 'white',
                        textSize: '12px'
                      })}
                    />
                    <h2 className="text-xs font-semibold text-center mt-1">
                      Of all TechProfile Users
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-4 flex-1 max-w-4xl ">
            <div className="flex justify-between flex-wrap md:flex-nowrap items-start p-4 bg-gray-200 rounded-xl gap-6 ">
              <div className="flex flex-col gap-1 text-sm font-semibold items-center ">
                <p>Commits</p>
                <h3 className="text-xl font-semibold">
                  {total_commits === null ? 0 : includeComma(total_commits)}
                </h3>
              </div>
              <div className="flex flex-col gap-1 text-sm font-semibold items-center">
                <p>Contributions</p>
                <h3 className="text-xl font-semibold">
                  {includeComma(repositories_contributed_to)}
                </h3>
              </div>
              <div className="flex flex-col gap-1 text-sm font-semibold items-center">
                <p>Grade</p>
                <h3 className="text-xl font-semibold">{rankData?.ranking}</h3>
              </div>
              <div className="flex flex-col gap-1 text-sm font-semibold items-center">
                <p>Repo</p>
                <h3 className="text-xl font-semibold">
                  {includeComma(total_repos)}
                </h3>
              </div>
              <div className="flex flex-col gap-1 text-sm font-semibold items-center">
                <p>Stars</p>
                <h3 className="text-xl font-semibold">
                  {includeComma(total_stars)}
                </h3>
              </div>
            </div>
            <div className="grid grid-cols-1  gap-2">
              {Object.keys(language)
                .slice(0, 4)
                .map((keys) => (
                  <div key={uuid()}>
                    <div className="flex   gap-8 items-center w-full ">
                      <div className="w-3/5 h-2 relative bg-gray-100   rounded-full shadow overflow-hidden ">
                        <div
                          style={{
                            width: `${
                              language[keys].in_percentage <= 1
                                ? 1
                                : language[keys].in_percentage
                            }%`
                          }}
                          className="absolute  top-0 h-full left-0 z-10 rounded-r-full bg-gradient-to-r from-blue-400  to-green-400  "
                        ></div>
                      </div>
                      <p className="text-xs text-left capitalize font-semibold">
                        {keys}
                      </p>
                    </div>
                  </div>
                ))}
              <p className="text-sm font-semibold">
                +{Object.keys(language).length - 4} more
              </p>
            </div>
          </div>
          <div className="hidden md:flex flex-col gap-4 justify-between items-end">
            <div style={{ width: '100%', maxWidth: '100px' }}>
              <CircularProgressbar
                value={percentileFormat(profile_score)}
                text={`Top ${100 - percentileFormat(profile_score)}% `}
                strokeWidth={10}
                styles={buildStyles({
                  textColor: 'black',
                  pathColor: '#0091FE',
                  trailColor: 'white',
                  textSize: '12px'
                })}
              />
              <h2 className="text-xs font-semibold text-center mt-1">
                Of all TechProfile Users
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SharableProfileCard
