import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import Select from 'react-select'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import RippleLoader from '../loader/RippleLoader'
import ListingPagination from '../pagination/ListingPagination'
import { partneredJobHeader } from '../recruiter-dashboard/dashboard-table/data'
import SinglePartnerJobTable from './SinglePartnerJobTable'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import TableSearch from '../../ui-component/inputs/TableSearch'

const jobStatus = [
  {
    label: 'All',
    value: 'ALL'
  },
  {
    label: 'Open',
    value: 'OPEN'
  },
  {
    label: 'Close',
    value: 'CLOSE'
  },
  {
    label: 'Open Unpublished',
    value: 'OPEN_UNPUBLISHED'
  }
]

const PartnerJob = () => {
  const [partnerJobs, setPartnerJobs] = useState([])
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [company, setCompany] = useState('')
  const [search, setSearch] = useState('')
  const [status, setStatus] = useState(jobStatus[0])
  const [totalPage, setTotalPage] = useState(0)
  const [activePartner, setActivePartner] = useState([])
  const [loadingSearch, setLoadingSearch] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { shortlistEndpoint } = EndPoints.dashboard

  const fetchAllPartner = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Stage/partnerAgency/agency/get?agency_id=${user.user_id}`
      )
      if (data.message) {
        const partners = data.message

        setActivePartner(
          partners.filter((partner) => partner.status === 'APPROVED')
        )
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchPartneredJob = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Stage/agencyLinkedJobs/get?agency_id=${user.user_id}&page=${page}&limit=${limit}&status=${status.value}&company_name=${company}&search=${search}`
      )
      const res = data.message
      console.log(res)
      if (res) {
        setPartnerJobs(res)
        setTotalPage(data?.total_pages ?? 1)
      }

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }

  const applySearchFilter = async () => {
    setLoadingSearch(true)
    setPage(1)
    fetchPartneredJob()
  }

  useEffect(() => {
    if (user.type === 'AGENCY') {
      fetchPartneredJob()
      window.scrollTo({ behavior: 'smooth', top: '0px' })
    }
  }, [page, limit, company, status])

  useEffect(() => {
    fetchAllPartner()
  }, [])

  useEffect(() => {
    if (search.length > 0) {
      setLoadingSearch(true)
    } else setLoadingSearch(false)

    const jobSearchTimeout = setTimeout(() => {
      applySearchFilter()
    }, 2000)
    return () => {
      clearTimeout(jobSearchTimeout)
    }
  }, [search])

  return (
    <div>
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="overflow-x-scroll w-full flex flex-col gap-10 ">
          <div className="flex justify-between flex-col lg:flex-row  items-center gap-10">
            <TableSearch
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Enter Job ID / Job Name"
            />
            <div className="flex flex-col sm:flex-row gap-3">
              <Select
                className="basic-single w-40  bg-transparent text-sm"
                onChange={(val) => setCompany(val.value)}
                value={{
                  label: company === '' ? 'All' : company,
                  value: company
                }}
                classNamePrefix="Company"
                // isClearable={true}
                isSearchable={true}
                name="company"
                placeholder="Select Company"
                styles={reactSelectStyleUtils}
                options={[
                  { label: 'All', value: '' },
                  ...activePartner.map((partner) => ({
                    value: partner.company_name,
                    label: partner.company_name
                  }))
                ]}
              />
              <Select
                className="basic-single capitalize w-40   bg-transparent text-sm"
                onChange={(val) => setStatus(val)}
                value={status}
                classNamePrefix="Status"
                // isClearable={true}
                isSearchable={true}
                name="status"
                placeholder="Job Status"
                styles={reactSelectStyleUtils}
                options={jobStatus}
              />
            </div>
          </div>
          <table className={`p-4 w-full rounded-2xl  shadow-custom bg-white`}>
            <tbody className="text-xs xl:text-sm rounded-lg ">
              <tr className="bg-blue-400 bg-opacity-20 p-2 ">
                {partneredJobHeader.map((head) => (
                  <th key={uuid()} className="text-center p-2 py-3 ">
                    {head.title}
                  </th>
                ))}
              </tr>

              {partnerJobs.length !== 0 &&
                partnerJobs.map((singleJob, idx) => (
                  <SinglePartnerJobTable
                    key={uuid()}
                    data={singleJob}
                    idx={idx}
                    limit={limit}
                    page={page}
                  />
                ))}
            </tbody>
          </table>
          {partnerJobs.length === 0 && (
            <Fragment>
              <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
                No Jobs Found
              </p>
            </Fragment>
          )}
          <ListingPagination
            page={page}
            onPageChange={(page) => setPage(page)}
            count={totalPage}
          />
        </div>
      )}
    </div>
  )
}

export default PartnerJob
