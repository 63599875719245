import React from 'react'
import ReactApexChart from 'react-apexcharts'

const RecruiterHeader = ({
  data = {
    labels: ['New Applicants', 'In Progress', 'Selected', 'Rejected'],
    series: [600, 300, 200, 100]
  },
  label = 'jobs'
}) => {
  const { applicants, connections } = data

  const { NEW, JOINED, REJECT } = applicants

  const total = Object.values(applicants).reduce((a, b) => a + b, 0)

  const selected = JOINED + applicants['AI SELECTED']

  const rejected = REJECT + applicants['REJECT-PD']

  const inProgress = total - (selected + rejected)

  const pieSeries = [NEW, selected, inProgress, rejected]
  const options = {
    chart: {
      type: 'donut'
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: ['New Applications', 'Selected', 'In Progress', 'Rejected'],
    dataLabels: {
      enabled: true,
      textAnchor: 'middle'
    },
    fill: {
      type: 'solid'
    },
    colors: ['#3E8FF6', ' #7BB3FA ', '#FAE9DF', '#CBE1FD'],
    legend: {
      formatter: function (val, opts) {
        return val + ' - ' + opts.w.globals.series[opts.seriesIndex]
      },
      position: 'bottom'
    },
    title: {
      text: `Applications`,
      align: 'left',
      style: {
        fontSize: '16px',
        fontWeight: 600
      }
    }
  }

  return (
    <div className="flex flex-col lg:flex-row gap-10 w-full">
      <div
        id="chart"
        className="grid place-items-center lg:flex-1 shadow-custom rounded-xl py-10 text-white text-xs text-center"
      >
        <ReactApexChart
          options={options}
          series={pieSeries}
          type="pie"
          height={400}
          className="w-full h-full grid place-items-center"
        />
      </div>
      <div className=" grid grid-cols-2  lg:flex-1  gap-10">
        <div className="flex flex-col justify-center shadow-custom gap-3 p-4  rounded-lg bg-opacity-40  text-green-600 font-bold   bg-green-300  items-center">
          <h2 className="text-base  font-semibold">Total Applications</h2>
          <p className=" text-2xl md:text-4xl ">{total}</p>
        </div>
        <div className="flex flex-col justify-center shadow-custom gap-3 p-4  rounded-lg bg-blue-300  text-blue-800 font-bold    items-center">
          <h2 className="text-base  font-semibold">Total Selections</h2>
          <p className=" text-2xl md:text-4xl ">{selected}</p>
        </div>
        <div className="flex flex-col justify-center shadow-custom gap-3 p-4  rounded-lg bg-blue-50  text-blue-500 font-bold   items-center">
          <h2 className="text-base  font-semibold">Total Jobs Created</h2>
          <p className=" text-2xl md:text-4xl ">{data[label].length}</p>
        </div>
        <div className="flex flex-col justify-center shadow-custom gap-3 p-4  rounded-lg bg-orange-50 font-bold  text-orange-500  items-center">
          <h2 className="text-base  font-semibold">Total Connections</h2>
          <p className=" text-2xl md:text-4xl ">{connections}</p>
        </div>
      </div>
    </div>
  )
}

export default RecruiterHeader
