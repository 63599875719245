import React, { Fragment, useState } from 'react'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'
import { BiLoader } from 'react-icons/bi'
import { MdRemoveCircleOutline } from 'react-icons/md'
import { useSelector } from 'react-redux'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import { showToast } from '../../../../services/global/Toaster'
import { ArrowTooltip } from '../../../ui-component/tooltip/Tooltip'

const SinglePartnerComponent = ({ type = 'active', data, fetchAll }) => {
  const { user } = useSelector((state) => state.auth)
  const [loading, setLoading] = useState(false)
  const { shortlistEndpoint } = EndPoints.dashboard
  const { agency_name, company_name, percentage } = data

  const handlePartnerOperation = async (type) => {
    setLoading(true)
    try {
      const putData = {
        ...data,
        status: type === 'APPROVE' ? 'APPROVED' : 'DENIED'
      }
      await axiosInstance.put(
        `${shortlistEndpoint}/Stage/partnerAgency/put `,
        putData
      )
      fetchAll()
    } catch (err) {
      showToast('error', 'Something went wrong')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex justify-between items-center gap-6 p-3 border-b text-base font-medium ">
      <div className="flex flex-col gap-1">
        <h3>{user.type !== 'AGENCY' ? agency_name : company_name}</h3>
        <p className="text-xs">Contract : {percentage.toFixed(2)} % </p>
      </div>
      <Fragment>
        {loading ? (
          <BiLoader className="animate-spin text-2xl" />
        ) : (
          <>
            {type === 'active' ? (
              <div className="flex flex-col gap-2">
                <ArrowTooltip title="Remove">
                  <button
                    onClick={() => handlePartnerOperation('REMOVE')}
                    className=""
                  >
                    <MdRemoveCircleOutline className="text-red-500 text-2xl" />
                  </button>
                </ArrowTooltip>
              </div>
            ) : (
              <>
                {user.type === 'AGENCY' && (
                  <div className="flex gap-2">
                    <ArrowTooltip title="Approve">
                      <button
                        onClick={() => handlePartnerOperation('APPROVE')}
                        className=""
                      >
                        <AiOutlineCheckCircle className="text-yellow-500 text-2xl" />
                      </button>
                    </ArrowTooltip>
                    <ArrowTooltip title="Deny">
                      <button
                        onClick={() => handlePartnerOperation('REMOVE')}
                        className=""
                      >
                        <AiOutlineCloseCircle className="text-red-500 text-2xl" />
                      </button>
                    </ArrowTooltip>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </Fragment>
    </div>
  )
}

export default SinglePartnerComponent
