import React, { Fragment, useState } from 'react'
import { FiFilter, FiSearch } from 'react-icons/fi'
import uuid from 'react-uuid'
import SingleUser from './SingleUser'
import { jobTableHeader } from '../TableData'
import TableSearch from '../../../ui-component/inputs/TableSearch'

const SearchUserTable = ({ data, fetchCreditData }) => {
  const [filterApplicantData, setfilterApplicantData] = useState(data)
  // const [applicantText, setApplicantText] = useState('');

  // const filterChange = (text) => {
  // 	const filteredData = data.filter(
  // 		(user) =>
  // 			user?.name?.toUpperCase()?.includes(text.toUpperCase()) ||
  // 			user?.search_id?.toUpperCase()?.includes(text.toUpperCase()),
  // 	);

  // 	setfilterApplicantData(filteredData);
  // };

  // const onTextChange = (e) => {
  // 	setApplicantText(e.target.value);
  // 	filterChange(e.target.value);
  // };
  return (
    <div className="flex flex-col gap-4  ">
      {/* <div className="flex flex-col gap-4 md:gap-6 md:flex-row  justify-between items-center mb-2 md:mb-6">
				<TableSearch value={applicantText} onChange={onTextChange} />
			</div> */}

      <h2 className="text-xl font-semibold">Recently Generated Profiles</h2>
      <div className="overflow-x-scroll w-full">
        <table
          className={`p-4 w-full rounded-lg overflow-hidden shadow-custom bg-white`}
        >
          <tbody className="text-xs xl:text-sm ">
            <tr className="bg-blue-400 bg-opacity-25 p-2 ">
              {jobTableHeader.map((head) => (
                <th key={uuid()} className="text-center p-2 py-3 ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {filterApplicantData.length !== 0 &&
              filterApplicantData.map((applicantData, idx) => (
                <SingleUser
                  fetchCreditData={fetchCreditData}
                  key={uuid()}
                  data={applicantData}
                  idx={idx}
                />
              ))}
          </tbody>
        </table>
        {filterApplicantData.length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
              No Applicants Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default SearchUserTable
