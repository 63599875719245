import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ERROR_PAGE } from '../../../navigation/routes'
import axiosInstance from '../../../services/axios/axios'
import AdminJobTable from '../../components/admin/AdminJobTable'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import TableSearch from '../../ui-component/inputs/TableSearch'

const AdminPage = () => {
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [adminJobData, setAdminJobData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(20)
  const [totalPage, setTotalPage] = useState(0)
  const [jobSearchText, setJobSearchText] = useState('')
  const [loadingSearch, setLoadingSearch] = useState(false)

  const fetchAdminJobs = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/admingetjobsnproj?page=${page}&limit=${limit}&search=${jobSearchText}`
      )
      const res = data.message
      console.log(res)
      if (res) {
        setAdminJobData(res)
        setTotalPage(data?.total_pages ?? 1)
      }
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (user.type !== 'ADMIN' && user.type !== 'SUPER_ADMIN') {
      navigate(ERROR_PAGE)
      return
    }
    fetchAdminJobs()
  }, [page, limit])

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })
  }, [page])

  const filterStaticData = async (searchText) => {
    if (searchText.length < 3) {
      fetchAdminJobs()
      return
    }

    setLoadingSearch(true)

    setPage(1)
    fetchAdminJobs()
  }

  useEffect(() => {
    if (jobSearchText.length > 0) {
      setLoadingSearch(true)
    } else setLoadingSearch(false)

    const jobSearchTimeout = setTimeout(() => {
      filterStaticData(jobSearchText)
    }, 2000)
    return () => {
      clearTimeout(jobSearchTimeout)
    }
  }, [jobSearchText])
  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10 ">
      {loading ? (
        <RippleLoader />
      ) : (
        <div>
          <TableSearch
            value={jobSearchText}
            onChange={(e) => setJobSearchText(e.target.value)}
            placeholder="Enter Job ID / Email / Organisation"
          />
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <AdminJobTable
              jobData={adminJobData}
              fetchAllJobs={fetchAdminJobs}
              page={page}
              onPageChange={(page) => setPage(page)}
              count={totalPage}
              limit={limit}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default AdminPage
