import dayjs from 'dayjs'
import React from 'react'
import localizedFormat from 'dayjs/plugin/localizedFormat'

const getCreditAbbr = (data) => {
  const { activity, search_id, job_id } = data
  switch (activity) {
    case 'CREATED_JOB':
      return {
        heading: 'Created Job',
        subHeading: `Created job ${job_id}`
      }
    case 'MESSAGE':
      return {
        heading: 'Message',
        subHeading: `Messaged ${search_id}`
      }
    case 'SEARCHED_SAVED_USER':
      return {
        heading: 'Searched User',
        subHeading: ``
      }
    case 'CREATED_ASSIGNMENT':
      return {
        heading: 'Created Assignment',
        subHeading: `Created Assignment for ${job_id}`
      }
    case 'PROFILE_GENERATE':
      return {
        heading: 'Generated Profile',
        subHeading: `Generated profile of ${search_id}`
      }
    default:
      return {
        heading: '',
        subHeading: ''
      }
  }
}

const SingleCreditTransaction = ({ data }) => {
  const { activity_date, credit_used } = data
  dayjs.extend(localizedFormat)

  return (
    <div className="p-3 flex justify-between gap-6 items-start border-b">
      <div className="flex flex-col font-medium">
        <h3 className=" text-gray-900 "> {getCreditAbbr(data).heading}</h3>
        <p className="text-sm text-gray-500 ">
          {getCreditAbbr(data).subHeading}
        </p>
      </div>
      <div className="flex flex-col items-end font-medium">
        <p
          className={` text-${
            credit_used < 0 ? 'red' : 'green'
          }-500 font-semibold `}
        >
          {' '}
          {credit_used}
        </p>
        <p className="text-sm text-gray-500 ">
          {dayjs(activity_date).format('LLL')}
        </p>
      </div>
    </div>
  )
}

export default SingleCreditTransaction
