import { Chip } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import useCreditHook from '../../../../hooks/useCreditHook'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import NoCreditComponent from '../../credits/NoCreditComponent'
import creditAction from '../../../../redux/actions/creditAction'

const AdvanceUserForm = ({ setSearchedUser, setLoading }) => {
  const { searchAdvancedUser, getSearchResult } = EndPoints.searchPage
  const [skillInput, setSkillInput] = useState('')
  const [selectedSkill, setSelectedSkill] = useState('')
  const [searchedSkills, setSearchedSkills] = useState([])
  const [countryInput, setCountryInput] = useState('')
  const [selectedCountry, setSelectedCountry] = useState('')
  const [searchedCountry, setSearchedCountry] = useState([])
  const [locationInput, setLocationInput] = useState('')
  const [selectedLocation, setSelectedLocation] = useState([])
  const [searchedLocation, setSearchedLocation] = useState([])
  const [showCountrySearch, setShowCountrySearch] = useState(false)
  const [level, setLevel] = useState('')
  const [experience, setExperience] = useState('')
  const { user } = useSelector((state) => state.auth)
  const [showTokenModal, setShowTokenModal] = useState(false)
  const { searchCandidateEligibility } = useCreditHook()
  const dispatch = useDispatch()

  const getSearchQuery = (skill, country, location, level, experience) => {
    const skillQuery = skill === '' ? 'ALL' : skill
    const locationQuery =
      location === '' || location.length === 0 || location[0] === 'ALL'
        ? 'ALL'
        : `[${location.map((location) => `"${location}"`)}]`
    const countryQuery = country === '' ? 'ALL' : country
    const levelQuery = level === '' ? 'ALL' : level
    const experienceQuery = experience === '' ? 'ALL' : experience
    if (skill === '' && location === '' && level === '' && experience === '') {
      return searchAdvancedUser
    }
    return `${searchAdvancedUser}?language=${skillQuery}&country=${countryQuery}&city=${locationQuery}&level=${levelQuery}&experience=${experienceQuery}&user_id=${user.user_id}`
  }

  const fetchAdvancedSearchUsers = async () => {
    setLoading(true)

    try {
      const { data } = await axiosInstance.get(
        getSearchQuery(
          selectedSkill,
          selectedCountry,
          selectedLocation,
          level,
          experience
        )
      )

      sessionStorage.setItem(
        'ADVANCED_USER_QUERY',
        JSON.stringify(
          getSearchQuery(
            selectedSkill,
            selectedCountry,
            selectedLocation,
            level,
            experience
          )
        )
      )

      console.log('fetched users', JSON.parse(data.body).message)
      setSearchedUser(JSON.parse(data.body).message)
      dispatch(creditAction.fetchUserCredits(user.user_id))
      sessionStorage.setItem(
        'ADVANCED_USER',
        JSON.stringify(JSON.parse(data.body).message)
      )
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!searchCandidateEligibility) {
      setShowTokenModal(true)
      return
    }
    fetchAdvancedSearchUsers()
  }

  const fetchSkills = async (language) => {
    try {
      const { data } = await axiosInstance.get(
        `${getSearchResult}/getlanguage?language=${language}`
      )
      console.log(JSON.parse(data.body).message)
      setSearchedSkills(JSON.parse(data.body).message)
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchCountry = async (location) => {
    setShowCountrySearch(true)
    try {
      const { data } = await axiosInstance.get(
        `${getSearchResult}/getcityandcountry?country=${location}`
      )
      console.log(JSON.parse(data.body))
      setSearchedCountry(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }
  const fetchLocation = async (location) => {
    try {
      const { data } = await axiosInstance.get(
        `${getSearchResult}/getcityandcountry?country=${selectedCountry}&city=${location}`
      )
      console.log(JSON.parse(data.body))
      setSearchedLocation(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (countryInput.length > 0) {
      //something for later
    } else {
      setSearchedCountry([])
      return
    }

    if (countryInput === selectedCountry) {
      return
    }

    const locationSearchTimeout = setTimeout(() => {
      fetchCountry(countryInput)
    }, 1000)
    return () => {
      clearTimeout(locationSearchTimeout)
    }
  }, [countryInput])

  useEffect(() => {
    if (locationInput.length > 0) {
      //something for later
    } else {
      setSearchedLocation([])
      return
    }
    if (locationInput === selectedLocation) {
      return
    }

    const locationSearchTimeout = setTimeout(() => {
      fetchLocation(locationInput)
    }, 1000)
    return () => {
      clearTimeout(locationSearchTimeout)
    }
  }, [locationInput])

  useEffect(() => {
    if (skillInput.length > 0) {
      //something for later
    } else {
      setSearchedSkills([])
      return
    }

    const skillSearchTimeout = setTimeout(() => {
      fetchSkills(skillInput)
    }, 1000)
    return () => {
      clearTimeout(skillSearchTimeout)
    }
  }, [skillInput])

  return (
    <div className=" w-full relative">
      <form
        onSubmit={handleSubmit}
        className=" p-2 w-full  grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 "
      >
        <div className="flex flex-col gap-2 relative">
          <label className="flex flex-col gap-2">
            <p className="font-semibold text-gray-700 text-sm ">Skills</p>
            <input
              // ref={inputRef}
              className="bg-transparent outline-none text-xs w-full border-2 border-gray-500 border-opacity-40 rounded-xl p-3  "
              value={skillInput}
              type="text"
              placeholder="Search Skills "
              onChange={(e) => setSkillInput(e.target.value.trim())}
            />
          </label>

          <div>{selectedSkill !== '' && <Chip label={selectedSkill} />}</div>
          {searchedSkills.length > 0 && (
            <div className="flex mt-2 flex-col absolute top-full left-0 z-30 right-0 max-h-64 overflow-y-scroll bg-gray-100 rounded-md overflow-hidden border border-gray-200 border-opacity-50 ">
              {searchedSkills.map((skills) => (
                <p
                  onClick={() => {
                    setSelectedSkill(skills.language)
                    setSkillInput('')
                  }}
                  className="cursor-pointer hover:bg-white bg-gray-100 p-2 px-3 text-sm  "
                  key={uuid()}
                >
                  {' '}
                  {skills.language}
                </p>
              ))}
              {searchedSkills.length === 0 && (
                <div className="grid place-items-center text-center text-xs">
                  No lanuage found
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <label className="flex flex-col gap-2">
            <p className="font-semibold text-gray-700 text-sm ">Level</p>
            <select
              // ref={inputRef}
              className="bg-transparent outline-none text-xs w-full border-2 border-gray-500 border-opacity-40 rounded-xl p-3  "
              value={level}
              onChange={(e) => setLevel(e.target.value.trim())}
            >
              <option value={''} className="p-2 text-sm">
                All
              </option>
              <option value={'learner'} className="p-2 text-sm">
                Learner
              </option>
              <option value={'coder'} className="p-2 text-sm">
                Coder
              </option>
              <option value={'procoder'} className="p-2 text-sm">
                Pro Coder
              </option>
              <option value={'ninja'} className="p-2 text-sm">
                Ninja
              </option>
            </select>
          </label>
          <div className="mt-2">{level !== '' && <Chip label={level} />}</div>
        </div>
        <div>
          <label className="flex flex-col gap-2">
            <p className="font-semibold text-gray-700 text-sm ">Experience</p>
            <select
              // ref={inputRef}
              className="bg-transparent outline-none text-xs w-full border-2 border-gray-500 border-opacity-40 rounded-xl p-3  "
              value={experience}
              onChange={(e) => setExperience(e.target.value.trim())}
            >
              <option value={''} className="p-2 text-sm">
                All
              </option>
              <option value={'fresher'} className="p-2 text-sm">
                Fresher (0-3 years)
              </option>
              <option value={'experience'} className="p-2 text-sm">
                Experienced (3-7 years)
              </option>
              <option value={'expert'} className="p-2 text-sm">
                Expert (7+ years)
              </option>
            </select>
          </label>
          <div className="mt-2">
            {experience !== '' && <Chip label={experience} />}
          </div>
        </div>
        <div className="flex flex-col gap-2 relative ">
          <label className="flex flex-col gap-2">
            <p className="font-semibold text-gray-700 text-sm ">Country</p>
            <input
              // ref={inputRef}
              className="bg-transparent outline-none text-xs w-full border-2 border-gray-500 border-opacity-40 rounded-xl p-3  "
              value={countryInput}
              type="text"
              placeholder="Search Country "
              onChange={(e) => setCountryInput(e.target.value.trim())}
            />
          </label>

          {showCountrySearch && searchedCountry.length > 0 && (
            <div className="flex mt-2 flex-col md:absolute md:top-full md:left-0 md:right-0 md:z-30 max-h-64 overflow-y-scroll bg-gray-100 rounded-md overflow-hidden border border-gray-200 border-opacity-50 ">
              {searchedCountry.map((location) => (
                <p
                  onClick={() => {
                    setSelectedCountry(location.countries)
                    setCountryInput(location.countries)
                    setShowCountrySearch(false)
                    setSelectedLocation([])
                    setLocationInput('')
                  }}
                  className=" cursor-pointer  hover:bg-white bg-gray-100 p-2 px-3 text-sm "
                  key={uuid()}
                >
                  {' '}
                  {location.countries}
                </p>
              ))}
              {searchedCountry.length === 0 && (
                <div className="grid place-items-center text-center text-xs">
                  No Country found
                </div>
              )}
            </div>
          )}
          <div>
            {selectedCountry !== '' && <Chip label={selectedCountry} />}
          </div>
        </div>

        {selectedCountry.length > 0 && (
          <div className="flex flex-col gap-2 relative ">
            <label className="flex flex-col gap-2">
              <p className="font-semibold text-gray-700 text-sm ">Location</p>
              <input
                // ref={inputRef}
                className="bg-transparent outline-none text-xs w-full border-2 border-gray-500 border-opacity-40 rounded-xl p-3  "
                value={locationInput}
                type="text"
                placeholder="Search Location "
                onChange={(e) => setLocationInput(e.target.value.trim())}
              />
            </label>

            {searchedLocation.length > 0 && (
              <div className="flex flex-col md:absolute md:top-full md:left-0 md:right-0 md:z-30  max-h-64 overflow-y-scroll bg-gray-100 rounded-xl  overflow-hidden border border-gray-200 border-opacity-50 ">
                {searchedLocation.map((location) => (
                  <p
                    onClick={() => {
                      setSelectedLocation([
                        ...selectedLocation,
                        location.cities
                      ])

                      //setLocationInput('');
                    }}
                    className="cursor-pointer  hover:bg-white bg-gray-100 p-2 px-3 text-sm "
                    key={uuid()}
                  >
                    {' '}
                    {location.cities}
                  </p>
                ))}
                {searchedLocation.length === 0 && (
                  <div className="grid place-items-center text-center text-xs">
                    No Cities found
                  </div>
                )}
              </div>
            )}
            <div className="my-2 flex gap-2 items-center flex-wrap ">
              {selectedLocation.length > 0 &&
                selectedLocation.map((location) => (
                  <Chip key={uuid()} label={location} />
                ))}
            </div>
          </div>
        )}

        <div className="sm:col-span-2 md:col-span-1 flex flex-col gap-2  ">
          <button
            className="p-3	 text-white bg-blue-500 rounded-lg border border-blue-500 text-xs font-semibold mt-6 hover:bg-transparent hover:text-blue-500 transition-all duration-300 ease-in-out uppercase "
            type="submit"
          >
            Search
          </button>
        </div>
      </form>
      {showTokenModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-xl p-6 m-auto z-50">
            <ClickWrapper func={() => setShowTokenModal(false)}>
              <NoCreditComponent />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default AdvanceUserForm
