import EndPoints from '../../services/api/api'
import axios from '../../services/axios/axios'
import { auth } from '../../services/firebase'
import {
  GITHUB_LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS
} from './actionTypes'

const { authSignIn, authSignUp } = EndPoints.auth

const register =
  (
    { email, password, name, profile_image, type, promo_code },
    setLoading,
    closeTab,
    userId,
    isLoggedIn,
    notifySignup,
    ipData
  ) =>
  async (dispatch) => {
    setLoading(true)
    try {
      const { data } = await axios.post(
        isLoggedIn ? `${authSignUp}?user_id=${userId}` : authSignUp,
        {
          email,
          password,
          name,
          profile_image,
          type,
          promo_code,
          ...ipData
        }
      )
      console.log(JSON.parse(data.body).message)

      notifySignup(JSON.parse(data.body).message)
      const user = JSON.parse(data.body)

      if (user.error) {
        //alert(user.error)
        notifySignup(user.error)
        dispatch({
          type: REGISTER_FAIL,
          payload: user.error
        })
        setLoading(false)
        return
      }
      dispatch({
        type: REGISTER_SUCCESS
      })
      setLoading(false)
      // refresh();
      // closeTab();

      dispatch(login({ email, password }, setLoading))
    } catch (err) {
      if (!err.message.includes('refresh')) {
        notifySignup(err.message)
      }
    }
  }

const login =
  ({ email, password }, setLoading, closeTab, notifyLogin, ipData) =>
  async (dispatch) => {
    setLoading(true)
    try {
      const { data } = await axios.post(authSignIn, {
        email,
        password,
        ...ipData
      })
      console.log(JSON.parse(data.body))
      const user = JSON.parse(data.body)

      if (user.error) {
        notifyLogin(user.error)
        dispatch({
          type: LOGIN_FAIL,
          payload: user.error
        })
        setLoading(false)
        return
      }

      dispatch({
        type: LOGIN_SUCCESS,
        payload: user
      })
      localStorage.setItem(
        'TECH_RESUME_USER',
        JSON.stringify(JSON.parse(data.body))
      )
      setLoading(false)
      closeTab()
    } catch (err) {
      notifyLogin(err.message)
    }
  }

const logout = () => (dispatch) => {
  localStorage.clear()
  sessionStorage.clear()
  auth.signOut()
  dispatch({
    type: LOGOUT
  })
}

const githubLogin = (user) => (dispatch) => {
  sessionStorage.setItem('GITHUB_AUTH', JSON.stringify(user))
  dispatch({
    type: GITHUB_LOGIN,
    payload: user
  })
}

const authAction = {
  register,
  login,
  logout,
  githubLogin
}

export default authAction
