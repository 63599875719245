import React, { Link, useLocation } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleHeaderLink = ({ menu, isTransparent }) => {
  const { pathname } = useLocation()

  return (
    <div id="single__header__link" className="my-1 relative capitalize">
      <Link
        key={uuid()}
        to={menu.target}
        className={` ${
          pathname === menu.target
            ? 'text-blue-400 font-semibold  border-b-2 border-blue-400 '
            : ''
        }  font-600   hover:text-blue-400 hover:font-semibold transition-all duration-300 ease-in-out `}
      >
        {menu.title}{' '}
      </Link>
      {menu.child.length > 0 && (
        <div
          id="single__link__child"
          className={`absolute ${
            menu.title === 'AI Tools' ? 'w-[250px]' : 'w-[200px]'
          }   top-full transform -translate-x-1/2 left-1/2  flex flex-col rounded-lg overflow-hidden p-2 z-50 ${
            !isTransparent ? 'bg-white' : 'bg-sky-600'
          } shadow-md `}
        >
          {menu.child.map((submenu) => (
            <Link
              key={uuid()}
              to={submenu.target}
              className={` ${
                pathname === submenu.target
                  ? 'text-blue-400 font-semibold  '
                  : ''
              }  text-sm pb-1 font-600 p-2   hover:text-blue-400 ${
                isTransparent ? 'hover:bg-sky-600' : 'hover:bg-gray-100'
              }  transition-all duration-300 ease-in-out`}
            >
              {submenu.title}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default SingleHeaderLink
