import React from 'react'

const ResumeLoader = () => {
  return (
    <div className="fixed w-screen top-0 left-0 bg-white bg-opacity-50 backdrop-blur-sm h-screen z-50  flex flex-col gap-10 items-center justify-center ">
      <div>
        <lottie-player
          src="https://assets2.lottiefiles.com/packages/lf20_a2chheio.json"
          background="transparent"
          speed="1"
          style={{ width: '100%', maxWidth: '200px', objectFit: 'contain' }}
          loop
          autoplay
        ></lottie-player>
      </div>

      <div className="scroller w-full h-full mt-6 sm:mt-10 max-h-40 text-2xl sm:text-4xl   ">
        <div className="scroller__div flex flex-col">
          <div className="w-full h-40 p-10 text-center">
            Analyzing Resume...
          </div>
          <div className="w-full h-40 p-10 text-center">
            {' '}
            Fetching Keywords...
          </div>
          <div className="w-full h-40 p-10 text-center">
            Fetching Details...
          </div>
          <div className="w-full h-40 p-10 text-center">
            Creating Summary...
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResumeLoader
