import React from 'react'
import { BsLightbulb } from 'react-icons/bs'
import { FaQuoteLeft, FaUsers } from 'react-icons/fa'
import { HiCubeTransparent } from 'react-icons/hi'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import { HOME_PAGE } from '../../../navigation/routes'
import { collegLogos } from '../../components/home/data'
import { settings } from '../home/HomePage'

const TestimonialData = [
  {
    name: 'John',
    designation: 'Software Developer',
    text: 'Testimonials : "I was struggling to find the right tech job until I came across TechProfile.org. The platform helped me showcase my skills and experience in a clear and concise way, making it easy for recruiters to find me. Within weeks, I landed my dream job!'
  },
  {
    name: 'Sarah',
    designation: 'Data Scientist',
    text: 'TechProfile.org  is an excellent platform for tech professionals who want to take their career to the next level. The team behind the platform is dedicated to providing users with the best possible experience, and their commitment to transparency and authenticity is truly refreshing.'
  },
  {
    name: 'Tom',
    designation: 'IT Director',
    text: "As a hiring manager, I've found TechProfile.org to be an invaluable tool for finding top talent. The platform's verified profiles make it easy to find the right candidate for the job, and the community aspect of the platform ensures that we're connecting with qualified and engaged professionals."
  },
  {
    name: 'Emily',
    designation: 'UX Designer',
    text: "I love using TechProfile.org to connect with other tech professionals and share knowledge and resources. The platform's focus on community is what sets it apart from other job sites, and I've made some valuable connections through the platform"
  },
  {
    name: 'David',
    designation: 'Full Stack Developer',
    text: "TechProfile.org is an essential resource for anyone looking to build a career in tech. The platform's user-friendly interface and robust features make it easy to showcase your skills and experience, and the community support is invaluable."
  }
]

const SingleTestimonial = ({ name, designation, text }) => {
  return (
    <div className="px-4 ">
      <div className="flex flex-col h-full gap-6 p-8 bg-white rounded-lg shadow-md ">
        <FaQuoteLeft className="text-3xl text-sky-600 " />
        <p className="text-base leading-relaxed text-gray-500">{text}</p>

        <div className="flex flex-col items-end">
          <p className="text-lg font-semibold"> - {name}</p>
          <p className="text-sm font-semibold">{designation}</p>
        </div>
      </div>
    </div>
  )
}

const AboutPage = () => {
  const testimonialSliderSetting = {
    ...settings,
    arrow: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 625,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  }
  return (
    <div className="w-screen min-h-screen overflow-hidden ">
      <div className="bg-sky-600 pb-20 w-[150vw] rotate-[8deg] ">
        <div className="bg-sky-700 pb-20 w-[150vw] -rotate-[3deg] ">
          <div className="flex -rotate-[5deg] -translate-x-4 justify-between items-center w-screen p-4 sm:p-10 md:px-20 min-h-[75vh] text-white bg-gradient-to-t from-sky-900 to-sky-800    ">
            <div className="justify-center p-4 ">
              <div className="flex flex-col max-w-2xl gap-4 md:gap-8">
                <h1 className="text-5xl font-bold leading-snug md:text-6xl ">
                  <span className="text-blue-500">About</span>
                  <span className="ml-2 text-orange-500">US</span>{' '}
                </h1>

                <div className="flex flex-col gap-3 mt-4 mb-10 ">
                  <p
                    style={{ lineHeight: '2' }}
                    className="text-sm font-normal md:text-xl "
                  >
                    TechProfile.org is a community-driven platform that aims to
                    connect tech professionals with the right opportunities and
                    help companies find top talent. With a focus on transparency
                    and authenticity, TechProfile allows users to showcase their
                    skills and experience through verified profiles, making it
                    easier for recruiters and hiring managers to find the
                    perfect candidate.
                  </p>
                </div>
              </div>
              <Link
                to={HOME_PAGE}
                className="flex items-center py-3 border-2 border-white rounded-full max-w-max min-w-[200px] px-6 uppercase w-full outline-none text-sm bg-transparent text-white  hover:text-sky-900  hover:bg-white transition-all duration-300 ease-in-out  font-500 "
              >
                <p className="flex-1 text-base font-semibold text-center uppercase ">
                  Explore More
                </p>
              </Link>
            </div>
            <div className="hidden lg:flex w-1/2 max-w-[600px] justify-end flex-1  ">
              <lottie-player
                src="https://assets8.lottiefiles.com/packages/lf20_mK7qUzkwYA.json"
                background="transparent"
                speed="1"
                style={{
                  width: '70%',
                  height: '70%',
                  objectFit: 'contain',
                  margin: 'auto'
                }}
                loop
                autoplay
              ></lottie-player>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 my-10 md:px-10">
        <div className="relative flex flex-col items-center w-full gap-20 py-10 m-auto max-w-7xl">
          <h1 className="text-4xl font-bold leading-relaxed text-center md:text-5xl ">
            <span className="text-blue-500">Our</span>{' '}
            <span className="text-orange-500">Values</span>
          </h1>

          <div className="grid grid-cols-1 gap-6 md:grid-cols-3 ">
            <div className="flex flex-col justify-end">
              <div className="flex flex-col items-center gap-5 p-4 py-8 text-center bg-white border shadow-xl sm:p-8 md:p-6 lg:p-10 rounded-2xl">
                <HiCubeTransparent className="text-5xl text-sky-900" />
                <h2 className="text-xl font-semibold">Transparency </h2>

                <p>
                  We believe in transparency and authenticity, ensuring that our
                  users have access to accurate and up-to-date information.
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <div className="shadow-xl min-h-[350px] flex flex-col  gap-5 p-4 sm:p-8 md:p-6 lg:p-10 py-8 rounded-2xl text-white bg-sky-600 items-center text-center">
                <FaUsers className="text-6xl text-white" />
                <h2 className="text-xl font-semibold">Community </h2>

                <p>
                  We foster a strong sense of community, bringing tech
                  professionals and companies together to build meaningful
                  connections.
                </p>
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <div className="flex flex-col items-center gap-5 p-4 py-8 text-center bg-white border shadow-xl sm:p-8 md:p-6 lg:p-10 rounded-2xl">
                <BsLightbulb className="text-5xl text-sky-900" />
                <h2 className="text-xl font-semibold">Innovation </h2>

                <p>
                  We are committed to innovation, constantly striving to improve
                  our platform and provide our users with the best possible
                  experience.
                </p>
              </div>
            </div>
          </div>

          <div className="m-auto max-w-7xl">
            <div className="grid grid-cols-1 gap-16 sm:grid-cols-2 lg:grid-cols-4 py-28">
              <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
                <h1 className="text-4xl ">65 %</h1>
                <h3 className="text-2xl">Effort saved in hiring</h3>
              </div>
              <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-sky-600 text-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
                <h1 className="text-4xl ">80 %</h1>
                <h3 className="text-2xl">Time reduced to hire candidates</h3>
              </div>
              <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
                <h1 className="text-4xl ">6319</h1>
                <h3 className="text-2xl">Total resumes generated</h3>
              </div>
              <div className="p-10 flex flex-col font-semibold gap-4 text-center bg-sky-600 text-white shadow-xl rounded-tr-[128px] py-16 border rounded-2xl">
                <h1 className="text-4xl ">1525 </h1>
                <h3 className="text-2xl">Total candidates selected</h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-sky-600 py-20 mt-20 w-[200vw] -translate-x-1/4 rotate-[8deg] ">
        <div className="bg-sky-700 py-20 w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg] -translate-x-1 text-white w-screen flex bg-gradient-to-l from-sky-900 to-sky-800 flex-col gap-10 md:gap-20 p-10 lg:p-20 items-center">
            <div className="flex flex-col-reverse items-center gap-10 text-center md:flex-row-reverse md:text-left ">
              <div className="flex flex-col gap-10 sm:w-[80%] md:w-1/2 text-lg md:text-xl">
                <h1 className="flex flex-wrap items-center justify-start w-full gap-2 text-4xl font-bold text-center md:text-5xl md:text-left sm:leading-relaxed ">
                  <span className="text-blue-500">Our</span>
                  <span className="text-orange-500">Vision</span>
                  <span className="w-full text-4xl leading-relaxed text-left md:text-5xl "></span>
                </h1>
                <p className="text-3xl leading-loose text-left ">
                  To create a world where tech professionals have the tools and
                  resources they need to succeed and where companies can easily
                  find and hire top talent.
                </p>
              </div>
              <div className="flex sm:w-[80%] md:w-1/2 justify-center items-center ">
                <lottie-player
                  src="https://assets6.lottiefiles.com/packages/lf20_yottltwd.json"
                  background="transparent"
                  speed="1"
                  style={{
                    width: '70%',
                    height: '70%',
                    objectFit: 'contain',
                    margin: 'auto'
                  }}
                  loop
                  autoplay
                ></lottie-player>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-6 py-20 md:px-10">
        <div className="relative flex flex-col items-center w-full gap-20 py-10 m-auto max-w-7xl">
          <h1 className="text-3xl font-bold leading-relaxed text-center md:text-4xl ">
            <span className="text-blue-500">Top</span>{' '}
            <span className="text-orange-500">Colleges</span> with Us
          </h1>

          <div className="w-full">
            <Slider {...settings}>
              {collegLogos.map((item, index) => (
                <div className="grid px-4 place-items-center" key={index}>
                  <img className="object-contain w-full " src={item} alt="" />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="bg-sky-600 pt-20 mt-20 w-[200vw] -translate-x-1/4 translate-y-2 rotate-[8deg] ">
        <div className="bg-sky-700 pt-20 w-[200vw] grid place-items-center -rotate-[3deg] ">
          <div className="  -rotate-[5deg] -translate-x-1 text-white w-screen flex bg-gradient-to-b from-sky-900 to-sky-800 flex-col gap-10 md:gap-20 p-10 lg:p-20 items-center">
            <div className="relative flex flex-col items-center w-full gap-20 py-10 m-auto max-w-7xl">
              <h1 className="text-4xl font-bold leading-relaxed text-center md:text-5xl ">
                What <span className="text-blue-500"> Our </span>{' '}
                <span className="text-orange-500">Users</span> say
              </h1>

              <div className="w-full text-gray-800">
                <Slider {...testimonialSliderSetting}>
                  {TestimonialData.map((testimonial, index) => (
                    <SingleTestimonial key={index} {...testimonial} />
                  ))}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutPage
