import React from 'react'
import {
  FaTwitter,
  FaTelegramPlane,
  FaFacebookSquare,
  FaYoutube,
  FaGoogle,
  FaInstagram,
  FaLinkedin
} from 'react-icons/fa'

export const footerData = [
  {
    title: 'Twitter',
    link: 'https://twitter.com/TechProfileorg',
    component: <FaTwitter />
  },
  {
    title: 'Telegram',
    link: '#',
    component: <FaTelegramPlane />
  },
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/people/TechProfile-Digital-Resume',
    component: <FaFacebookSquare />
  },
  {
    title: 'Youtube',
    link: '#',
    component: <FaYoutube />
  },
  //   {
  //     title: 'Google',
  //     link: '',
  //     component: <FaGoogle />
  //   },
  {
    title: 'Instagram',
    link: '#',
    component: <FaInstagram />
  },
  {
    title: 'Linkedin',
    link: 'https://www.linkedin.com/company/techprofile',
    component: <FaLinkedin />
  }
]
