import { Tooltip } from '@material-ui/core'
import React, { useState } from 'react'
import HtmlParser from 'react-html-parser'
import { FiMessageSquare } from 'react-icons/fi'
import { HiOutlineLink } from 'react-icons/hi'
import { RiMessage3Line } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import { includeComma } from '../../../../services/global/globalFunction'
import IconButton from '../../../ui-component/buttons/IconButton'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import RaiseIssueModal from '../../job-listing/RaiseIssueModal'
import SendMailModal from '../../job-listing/SendMailModal'

const SingleAdvanceUser = ({
  searchedUsers,
  data,
  idx,
  lastIdx = false,
  fetchCreditData,
  refetchUserData
}) => {
  const {
    id,
    email,
    github_id,
    search_query,
    search_date,
    message_title,
    message,
    repositories,
    followers,
    location,
    following,
    createdAt,
    language,
    sponsors,
    updatedAt,
    experience,
    avatar_url,
    username,
    activity_date
  } = data

  const [isLoading, setIsLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const { dashboardEndpoint } = EndPoints.dashboard
  const { searchUserEndpoint } = EndPoints.searchPage
  const [showMailModal, setShowMailModal] = useState(false)
  const [showIssueModal, setShowIssueModal] = useState(false)

  const elapsedTime = (date) => {
    if (date === null || date === '') return 2
    const prev_date = new Date(date)
    const curr_date = new Date()

    return (curr_date - prev_date) / (1000 * 60 * 60 * 24)
  }

  const sendSearchData = async () => {
    const postData = {
      user_id: user?.user_id,
      activity: 'SEARCH',
      search_id: github_id,
      credit_used: 1
    }

    console.log(postData)

    try {
      const { data } = await axiosInstance.post(
        `${searchUserEndpoint}/postactivity`,
        postData
      )
      console.log(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }
  return (
    <tr
      onClick={() => sendSearchData()}
      className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   "
    >
      <td>
        <p className="text-center">{idx + 1}</p>
      </td>
      <td className="">
        <div className="flex gap-1 sm:gap-2 items-center w-full">
          <img
            className="w-12 h-12 object-contain rounded-md"
            src={avatar_url}
            alt={github_id}
          />
          <Link
            to={`/dashboard/${github_id}`}
            className="px-4 flex flex-col gap-1 cursor-pointer"
          >
            <p className=" lg:text-sm font-semibold">{github_id}</p>
            <p className="  text-xs font-normal">{username}</p>
          </Link>
        </div>
      </td>

      <td className="text-center">
        <p className=" p-2 px-4 ">{includeComma(followers)}</p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{following} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{repositories} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{language} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{location} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">
          {experience} {experience > 1 ? 'Years' : 'Year'}{' '}
        </p>
      </td>
      <td className="text-center relative">
        <div className="flex flex-col">
          <div className="flex gap-2 items-center w-[180px] m-auto">
            <div>
              {email !== null && email !== '' ? (
                <IconButton
                  toolTipTitle={`Send mail to ${email}`}
                  text={'Send Message'}
                  icon={<RiMessage3Line />}
                  onClick={() => setShowMailModal(true)}
                  type="primary"
                />
              ) : (
                <IconButton
                  toolTipTitle={`connect with ${github_id}`}
                  text={'Connect'}
                  onClick={() => setShowIssueModal(true)}
                  icon={<HiOutlineLink />}
                  type="dark"
                />
              )}
            </div>

            {activity_date !== null && message && (
              <Tooltip title={'Last sent Message'}>
                <button
                  onClick={() => setShowMessage(true)}
                  className=" text-lg text-gray-600 "
                >
                  <FiMessageSquare />
                </button>
              </Tooltip>
            )}
          </div>
          {activity_date && (
            <p className="text-xs mt-2">
              last connected :{' '}
              {activity_date.split(' ')[0].split('-').reverse().join('/') +
                ' ' +
                activity_date.split(' ')[1]}
            </p>
          )}
        </div>
        {showMessage && (
          <ClickWrapper func={() => setShowMessage(false)}>
            <div
              className={`flex flex-col gap-2 p-3 text-sm bg-gray-100 z-30 absolute  ${
                lastIdx ? 'bottom-0' : 'top-10'
              }  rounded-md left-0 w-[90%] border border-gray-400 border-opacity-30 text-left shadow-sm`}
            >
              <p className="text-xs pb-3 border-b border-gray-400 border-opacity-30">
                <span className="font-bold">Title :</span> {message_title}
              </p>
              <p className="text-sm  w-full break-words">
                {' '}
                <span className="font-semibold ">Message :</span>{' '}
                {HtmlParser(message)}
              </p>
            </div>
          </ClickWrapper>
        )}
        {showMailModal && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-2xl p-6 m-auto z-50">
              <ClickWrapper func={() => setShowMailModal(false)}>
                <SendMailModal
                  recieverDetails={[{ email, github_id }]}
                  refetchData={() => {
                    fetchCreditData()
                    refetchUserData()
                  }}
                  close={() => setShowMailModal(false)}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
        {showIssueModal && (
          <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-2xl p-4 m-auto z-50">
              <ClickWrapper func={() => setShowIssueModal(false)}>
                <RaiseIssueModal
                  refetchData={() => {
                    fetchCreditData()
                    refetchUserData()
                  }}
                  close={() => setShowIssueModal(false)}
                  githubid={github_id}
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SingleAdvanceUser
