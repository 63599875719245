import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { LOGIN_PAGE } from '../../../navigation/routes'
import authAction from '../../../redux/actions/authAction'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import { getIpDetails } from '../../../services/global/globalFunction'
import RippleLoader from '../loader/RippleLoader'
import EmailField from './EmailField'
import PasswordField from './PasswordField'
import SubmitButton from './SubmitButton'
import TextInput from './TextInput'

const SignUp = ({ toggleTab = () => {}, closeTab = () => {} }) => {
  const dispatch = useDispatch()
  const [tnc, setTnc] = useState(false)
  const [isEmailAvailable, setIsEmailAvailable] = useState(true)
  const [OTP, setOTP] = useState('')
  const [showOTP, setShowOTP] = useState(false)
  const [otpToken, setOtpToken] = useState('')
  const [emailToken, setEmailToken] = useState('')
  const { authSendOtp, authValidateOtp } = EndPoints.auth

  const [signUpDetails, setSignUpDetails] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    profile_image: '',
    type: 'COMPANY',
    promo_code: ''
  })
  const [signUpErrors, setSignupErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    type: '',
    name: '',
    otp: ''
  })

  const [loading, setLoading] = useState(false)
  const [showPromoCode, setShowPromoCode] = useState(false)

  const notifySignup = (text) => {
    showToast('success', 'Registered Successfully')
  }

  const handleSignUp = async (e) => {
    e.preventDefault()
    const { email, password, name, type, confirmPassword } = signUpDetails
    if (email.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        email: 'Enter a valid Email'
      })
      return
    } else if (type.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        type: 'Please Select user Type'
      })
      return
    } else if (password.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        password: 'Enter a valid password'
      })
      return
    } else if (password !== confirmPassword) {
      setSignupErrors({
        ...signUpErrors,
        confirmPassword: "Password doesn't match"
      })
      return
    } else if (name.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        name: 'Enter a valid Name'
      })
      return
    }
    handleEmailSubmit(signUpDetails.email)
  }

  const handleEmailSubmit = async (email) => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.post(
        `https://gh1woca0ql.execute-api.ap-south-1.amazonaws.com/Dev/generateRegisterOTP`,
        {
          email
        }
      )

      if (data.message) {
        setEmailToken(data.message.message)
        // setOTP(JSON.parse(data.body).otp)
        setShowOTP(true)
      }

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const validateOTP = async (e) => {
    e.preventDefault()
    if (OTP.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        otp: 'Enter a valid OTP'
      })
      return
    }
    setLoading(true)
    const header = { headers: { Authorization: `Bearer ${emailToken}` } }
    try {
      const { data } = await axiosInstance.post(
        `https://gh1woca0ql.execute-api.ap-south-1.amazonaws.com/Dev/VerifyRegisterOTP`,
        {
          otp: OTP
        },
        header
      )
      console.log(data)
      if (data.error) {
        alert.error(data.error)
      } else {
        setOtpToken(data.token)
        const ipData = await getIpDetails()
        await dispatch(
          authAction.register(
            signUpDetails,
            setLoading,
            closeTab,
            null,
            false,
            notifySignup,
            ipData
          )
        )
        toggleTab(0)
      }
    } catch (err) {
      console.log(err.message)
      let message = 'Something went wrong'
      if (err.response.status === 403) {
        message = 'Invalid OTP'
      }
      showToast('error', message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="flex flex-col items-center w-full h-full pb-3 relative scrollbar-hidden overflow-y-scroll">
      {loading && (
        <div className="absolute grid place-items-center w-full h-full top-0 left-0 z-10 bg-white bg-opacity-20 backdrop-filter blur-sm">
          <RippleLoader />
        </div>
      )}

      <div className="w-full flex flex-col px-2">
        <h1 className="text-4xl md:text-5xl leading-snug font-bold ">
          <span className="text-blue-500">Sign Up</span> as a{' '}
          <span className="text-orange-500">Recruiter</span>{' '}
        </h1>

        <p className="text-sm font-500 text-gray-100 my-6">
          Already have an account? Login
          <Link
            to={LOGIN_PAGE}
            className="text-blue-200 text-xs p-2 px-4  ml-2 font-semibold border-2 border-blue-200 border-opacity-40 rounded-full hover:text-blue-700"
          >
            Sign In
          </Link>
        </p>
      </div>

      {!showOTP ? (
        <form
          onSubmit={handleSignUp}
          className="w-full flex flex-col gap-4  p-6 rounded-lg bg-white bg-opacity-10  "
        >
          <div className="w-full flex flex-col">
            <EmailField
              label={'Business Email'}
              value={signUpDetails.email}
              checkEmail={true}
              errorMessage={
                signUpDetails.email.length === 0 && signUpErrors.email
              }
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  email: e.target.value
                })
              }
              setEmailValidity={setIsEmailAvailable}
              required
            />

            <div className="flex flex-col  ">
              <div className="flex flex-col gap-3 text-sm w-full font-600 my-2 z-0">
                <p className="font-semibold">Type* </p>

                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={signUpDetails.type}
                  name="radio-buttons-group"
                  sx={{
                    fontSize: '.8rem',
                    display: 'flex',
                    gap: '20px',
                    paddingLeft: '10px'
                  }}
                >
                  <FormControlLabel
                    value="COMPANY"
                    control={
                      <Radio
                        size="small"
                        onChange={(e) =>
                          setSignUpDetails({
                            ...signUpDetails,
                            type: e.target.value
                          })
                        }
                        sx={{
                          '&, &.Mui-checked': {
                            color: 'white'
                          }
                        }}
                      />
                    }
                    label={<p className="font-medium text-sm ml-2">Company </p>}
                  />
                  <FormControlLabel
                    value="AGENCY"
                    control={
                      <Radio
                        size="small"
                        onChange={(e) =>
                          setSignUpDetails({
                            ...signUpDetails,
                            type: e.target.value
                          })
                        }
                        sx={{
                          '&, &.Mui-checked': {
                            color: 'white'
                          }
                        }}
                      />
                    }
                    label={<p className="font-medium text-sm ml-2">Agency </p>}
                  />
                </RadioGroup>

                {signUpDetails.type.length === 0 && signUpErrors.type && (
                  <p className="text-red-500 text-xs ml-4">
                    {signUpErrors.type}
                  </p>
                )}
              </div>
            </div>

            <TextInput
              value={signUpDetails.name}
              errorMessage={
                signUpDetails.name.length === 0 && signUpErrors.name
              }
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  name: e.target.value
                })
              }
              labelText={`${
                signUpDetails.type === 'COMPANY' ? 'Company' : 'Agency'
              } Name`}
              placeholder={'Tech Profile'}
              required
            />

            <PasswordField
              labelText={'Set Password'}
              value={signUpDetails.password}
              errorMessage={
                signUpDetails.password.length === 0 && signUpErrors.password
              }
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  password: e.target.value
                })
              }
              required
            />
            <PasswordField
              labelText={'Confirm Password'}
              value={signUpDetails.confirmPassword}
              errorMessage={
                (signUpDetails.confirmPassword === 0 ||
                  signUpDetails.password !== signUpDetails.confirmPassword) &&
                signUpErrors.confirmPassword
              }
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  confirmPassword: e.target.value
                })
              }
              required
            />
            {showPromoCode && (
              <TextInput
                value={signUpDetails.promo_code}
                errorMessage={''}
                onChange={(e) =>
                  setSignUpDetails({
                    ...signUpDetails,
                    promo_code: e.target.value
                  })
                }
                labelText={`Promo Code`}
                type="code"
                placeholder={'XXXXXX'}
              />
            )}
            <div className="flex justify-between items-center">
              <label className="flex items-center font-semibold my-3 cursor-pointer text-xs z-0">
                <input
                  value={tnc}
                  onChange={(e) => setTnc(e.target.checked)}
                  className="mr-2 cursor-pointer"
                  type="checkbox"
                />

                <p>
                  I agree with{' '}
                  <Link to={'#'} className="text-blue-400">
                    Terms and Conditions
                  </Link>
                </p>
              </label>

              <div
                className="text-xs font-semibold cursor-pointer "
                onClick={() => setShowPromoCode(!showPromoCode)}
              >
                {showPromoCode
                  ? "No , I don't have Promo Code ?"
                  : 'Have a SignUp Promo Code ?'}
              </div>
            </div>
            {isEmailAvailable && (
              <SubmitButton disabled={!tnc} text={' Sign Up'} />
            )}
          </div>
        </form>
      ) : (
        <form
          onSubmit={validateOTP}
          className="w-full flex flex-col gap-4  p-6 rounded-lg bg-white bg-opacity-10  "
        >
          <p className="text-xs">
            Enter OTP sent to{' '}
            <span className="text-base ml-2 ">
              {signUpDetails.email.substring(0, 3)}xxxxxx
              {signUpDetails.email.substring(
                signUpDetails.email.length - 3,
                signUpDetails.email.length
              )}
            </span>
          </p>
          <TextInput
            value={OTP}
            errorMessage={''}
            onChange={(e) => setOTP(e.target.value)}
            labelText={`OTP`}
            type="code"
            placeholder={'XXXXXX'}
          />
          <SubmitButton disabled={OTP.length === 0} text={' Validate'} />
        </form>
      )}
    </div>
  )
}

export default SignUp
