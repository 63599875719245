export const ProjectPaymentData = [
  {
    label: 'Paid Projects',
    value: 'paid'
  },
  {
    label: 'UnPaid Projects',
    value: 'unpaid'
  }
]
export const projectDurationData = [
  {
    label: '1 Month or less',
    value: '1'
  },
  {
    label: '3 Months',
    value: '3'
  },
  {
    label: '6 Months',
    value: '6'
  },
  {
    label: 'Open Discussion',
    value: 'open'
  }
]
