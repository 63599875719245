import React, { Fragment, useEffect, useState } from 'react'
import { useRef } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useNavigate, useParams } from 'react-router-dom'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import Loader from '../../components/loader/Loader'
import { useReactToPrint } from 'react-to-print'
import CommitChart from '../../components/single-repository/commit-chart/CommitChart'
import ContributorTable from '../../components/single-repository/contributors-table/ContributorTable'
import Contributors from '../../components/single-repository/contributors/Contributors'
import FileChangeChart from '../../components/single-repository/file-change-chart/FileChangeChart'
import LanguageCommitChart from '../../components/single-repository/language-commit-chart/LanguageCommitChart'
import RepoDetails from '../../components/single-repository/repo-details/RepoDetails'
import { repoCommitData } from './repoData'

const RepositoryPage = () => {
  const { username, repository } = useParams()
  const { getRepoCommits, getFilechangeGraph } = EndPoints.repository
  const { dashboardEndpoint } = EndPoints.dashboard
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [repositoryData, setRepositoryData] = useState({
    repoData: [],
    commitData: { message: [] }
  })

  const convertCommitData = (data) => {
    const arrayHashmap = data.reduce((obj, user) => {
      if (obj[user.user_id]) {
        obj[user.user_id].changedFiles += user.changedFiles
        obj[user.user_id].deletions += user.deletions
        obj[user.user_id].additions += user.additions
        obj[user.user_id].total_commits += 1
        obj[user.user_id].languages = [
          ...obj[user.user_id].languages,
          ...user.languages
        ].reduce((unique, o) => {
          if (
            !unique.some((obj) => obj.name === o.name && obj.logo === o.logo)
          ) {
            unique.push(o)
          }
          return unique
        }, [])
      } else {
        obj[user.user_id] = {
          ...user,
          total_commits: 1,
          last_commit: user.commit_date,
          languages: user.languages
        }
      }

      return obj
    }, {})
    return Object.values(arrayHashmap)
  }

  const convertCommitedDataLanguage = (data) => {
    let res = {}
    data.forEach((data) => {
      data.language_name.forEach((lang) => {
        if (lang in res) {
          res[lang] += 1
        } else {
          res[lang] = 1
        }
      })
    })
    return res
  }

  const componentRef = useRef(null)
  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  })

  const [parsedCommitData, setParsedCommitData] = useState(
    convertCommitData(repoCommitData.message)
  )
  const [dataFetching, setDataFetching] = useState(true)
  const [commitFetching, setCommitFetching] = useState(true)
  const [parsedLanguageData, setParsedLanguageData] = useState({})

  const fetchDataUpdate = async () => {
    if (!dataFetching) {
      return
    }
    console.log('fetchdataupdate is running')
    try {
      // setLoading(true);
      const { data } = await axiosInstance.get(
        `${dashboardEndpoint}/getstatus?username=${username}`
      )
      //console.log(JSON.parse(data.body));
      if (JSON.parse(data.body).status === 'completed') {
        setDataFetching(false)
        fetchRepositoryData()
      }

      if (JSON.parse(data.body).commit_status === 'complete') {
        setCommitFetching(false)
        fetchRepositoryData()
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchRepositoryData = async () => {
    try {
      const { data } = await axiosInstance.post(
        `${dashboardEndpoint}/adduser`,
        {
          username
        }
      )

      if (!data.user) {
        navigate('*')
      } else {
        const res = await Promise.all([
          axiosInstance.get(
            `${dashboardEndpoint}/getrepo?username=${username}&repo=${repository}`
          ),
          axiosInstance.get(
            `${getRepoCommits}?repo=${repository}&github_id=${username}`
          ),
          axiosInstance.get(
            `${getFilechangeGraph}/getfilesmodificationgraph?limit=7&owner=${username}`
          )
        ])

        const parsedData = res.map((res) => res.data.body)

        setRepositoryData({
          repoData: JSON.parse(parsedData[0]),
          commitData: JSON.parse(parsedData[1]).message,
          fileChangeData: JSON.parse(parsedData[2])
        })

        setParsedCommitData(
          convertCommitData(JSON.parse(parsedData[1]).message)
        )
        setParsedLanguageData(
          convertCommitedDataLanguage(JSON.parse(parsedData[1]).message)
        )

        setLoading(false)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (!dataFetching && !commitFetching) {
      return
    }
    fetchDataUpdate()
    const dataInterval = setInterval(() => {
      fetchDataUpdate()
    }, 5000)

    return () => {
      clearInterval(dataInterval)
    }
  }, [dataFetching, username, commitFetching, repository])

  useEffect(() => {
    setDataFetching(true)
    setCommitFetching(true)
  }, [username, repository])

  return (
    <div ref={componentRef} className="p-4 sm:p-6 md:p-10 min-h-screen  ">
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="w-full max-w-7xl m-auto flex flex-col gap-4 sm:gap-6 md:gap-10">
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <RepoDetails
                data={repositoryData?.repoData}
                no_of_contributors={parsedCommitData.length}
                print={handlePrint}
              />
            </ErrorBoundary>
            {parsedCommitData.length > 0 && (
              <Fragment>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <LanguageCommitChart
                    data={parsedLanguageData}
                    commitFetching={commitFetching}
                  />
                </ErrorBoundary>
                <ErrorBoundary FallbackComponent={ErrorComponent}>
                  <CommitChart
                    data={parsedCommitData}
                    commitFetching={commitFetching}
                  />
                </ErrorBoundary>
              </Fragment>
            )}

            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className="">
                <FileChangeChart
                  data={repositoryData.fileChangeData}
                  Contributorsdata={parsedCommitData}
                  isRepoLevel={true}
                  repoName={repository}
                  commitFetching={commitFetching}
                />
              </div>
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className="md:hidden">
                <Contributors
                  data={parsedCommitData}
                  commitFetching={commitFetching}
                />
              </div>
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorComponent}>
              <div className="hidden md:block">
                <ContributorTable
                  data={parsedCommitData}
                  commitFetching={commitFetching}
                />
              </div>
            </ErrorBoundary>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default RepositoryPage
