import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'

const LanguageChart = ({ data, year_joined }) => {
  const series = [
    {
      name: 'language',
      data: []
    }
  ]

  const options = {
    chart: {
      type: 'bar',
      foreColor: '#000000',
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        dataLabels: {
          position: 'top'
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#F37726', '#3E8FF5'],
        shadeIntensity: 0.5,
        type: 'vertical',
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 100, 100, 100]
      }
    },
    grid: {
      show: false
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#333']
      }
    },
    tooltip: {
      theme: 'dark'
    },

    xaxis: {
      categories: [],
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        formatter: (value) => {
          return Math.ceil(value) + '%'
        }
      }
    }
  }
  const [chartSeries, setChartSeries] = useState(series)
  const [chartOption, setChartOption] = useState(options)

  useEffect(() => {
    const series = Object.keys(data).map((keys) =>
      data[keys].in_percentage.toFixed(2)
    )
    const categories = Object.keys(data).map((keys) => keys)
    var optimalColumnWidthPercent =
      20 + 60 / (1 + 30 * Math.exp(-Object.values(series).length / 3))
    setChartOption({
      ...chartOption,
      xaxis: {
        ...chartOption.xaxis,
        categories: categories
      },
      plotOptions: {
        ...chartOption.plotOptions,

        bar: {
          ...chartOption.plotOptions.bar,
          columnWidth: optimalColumnWidthPercent
        }
      }
    })

    setChartSeries([
      {
        name: 'language (%)',
        data: series
      }
    ])
  }, [])

  return (
    <div className="p-4 sm:p-6">
      <div className="p-3 sm:p-6 bg-white shadow-custom rounded-xl ">
        <div className="flex justify-between items-center">
          <h3 className="font-semibold truncate mr-2 ">
            Languages used throughout all the projects
          </h3>
        </div>

        <div className="text-gray-600 " id="chart">
          <ReactApexChart
            options={chartOption}
            series={chartSeries}
            type="bar"
            height={300}
          />
        </div>
      </div>
    </div>
  )
}

export default LanguageChart
