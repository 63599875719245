import React, { Fragment, useEffect, useState } from 'react'
import SingleInterviewCard from './SingleInterviewCard'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import { leaderboardCountries } from '../leaderboard/tableData'
import Select from 'react-select'
import axiosInstance from '../../../services/axios/axios'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api/api'
import RippleLoader from '../loader/RippleLoader'
import uuid from 'react-uuid'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorComponent from '../error/ErrorComponent'

const durationArr = [
  {
    value: 0,
    label: 'Today'
  },
  {
    value: 7,
    label: 'This Week'
  },
  {
    value: 30,
    label: 'This Month'
  },
  {
    value: -30,
    label: 'Last 30 Days'
  }
]

const RecruiterInterview = () => {
  const [openInterviewJobs, setOpenInterviewJobs] = useState([
    { job_id: 'ALL' }
  ])
  const [allInterviews, setAllInterviews] = useState([])
  const [duration, setDuration] = useState(durationArr[1])
  const [selectedJob, setSelectedJob] = useState('ALL')
  const [loading, setLoading] = useState(true)
  const { user } = useSelector((state) => state.auth)
  const { shortlistEndpoint } = EndPoints.dashboard

  const fetchAllOpenInterviewJobs = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Dev/openInterviewDetails/get?company_id=${user.user_id}`
      )
      console.log(data)
      setOpenInterviewJobs([...openInterviewJobs, ...data])
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchAllInterview = async (jobID, duration) => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Dev/interviewDetails/get?company_id=${user.user_id}&job_id=${jobID}&last_n_days=${duration}`
      )
      console.log('interview', data)
      setAllInterviews(data)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchAllOpenInterviewJobs()
  }, [])

  useEffect(() => {
    fetchAllInterview('ALL', duration.value)
  }, [selectedJob, duration])

  return (
    <Fragment>
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="flex flex-col gap-8 items-end">
          <div className="flex gap-4 items-center">
            <label className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">JobID</p>
              <Select
                className="basic-single w-40  bg-transparent text-sm"
                onChange={(val) => setSelectedJob(val.value)}
                value={{
                  value: selectedJob,
                  label: selectedJob
                }}
                classNamePrefix="Country"
                defaultValue={
                  leaderboardCountries.map((country) => ({
                    value: country,
                    label: country
                  }))[0]
                }
                isSearchable={true}
                styles={reactSelectStyleUtils}
                name="country"
                placeholder="Select Job Country"
                options={openInterviewJobs.map((job) => ({
                  value: job.job_id,
                  label: job.job_id
                }))}
              />
            </label>

            <label className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Duration</p>
              <Select
                className="basic-single w-40  bg-transparent text-sm"
                onChange={(val) => setDuration(val)}
                value={duration}
                classNamePrefix="Duration"
                defaultValue={durationArr[0]}
                styles={reactSelectStyleUtils}
                name="duration"
                placeholder="Select Duration"
                options={durationArr}
              />
            </label>
          </div>

          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 w-full">
              {allInterviews.map((interview) => (
                <SingleInterviewCard interview={interview} key={uuid()} />
              ))}
            </div>
          </ErrorBoundary>

          {allInterviews.length === 0 && (
            <div className="grid place-items-center w-full">
              <h2 className="text-center text-gray-300 text-2xl font-semibold">
                No Upcoming Interviews
              </h2>
            </div>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default RecruiterInterview
