import React from 'react'
import { hydrateRoot } from 'react-dom'
import { createRoot } from 'react-dom/client'
import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { Provider } from 'react-redux'
import store from './redux/store'

const options = {
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: '20px',
  transition: transitions.FADE
}
const rootElement = document.getElementById('root')
const APP = (
  <React.StrictMode>
    <Provider store={store}>
      <AlertProvider template={AlertTemplate} {...options}>
        <App />
      </AlertProvider>
    </Provider>
  </React.StrictMode>
)

if (rootElement.hasChildNodes()) {
  hydrateRoot(APP, rootElement)
} else {
  createRoot(rootElement).render(APP)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
