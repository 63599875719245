export const createUserTableHeader = [
  {
    title: 'No.',
    width: '30%'
  },
  {
    title: 'User',
    width: '30%'
  },
  {
    title: 'Email',
    width: '25%'
  },
  {
    title: 'Password',
    width: '10%'
  },
  {
    title: 'Created At',
    width: '10%'
  },
  {
    title: 'Status',
    width: '10%'
  },
  {
    title: 'Options',
    width: '10%'
  }
]
