import React, { useState } from 'react'
import ReactHtmlParser from 'react-html-parser'
import { MdClose } from 'react-icons/md'
import ReactQuill from 'react-quill'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import axiosInstance from '../../../services/axios/axios'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import EmailInput from '../create-profile/email-input/EmailInput'
import MultiSelect from '../create-profile/multi-select/MultiSelect'
import NumberInput from '../create-profile/num-input/NumberInput'
import TextInput from '../create-profile/text-input/TextInput'
import { leaderboardCountries } from '../leaderboard/tableData'
import { projectCategory, ProjectKeySkills } from './data'

const CreateProjectModal = ({ close, refetchData }) => {
  const { user } = useSelector((state) => state.auth)
  const [tnc, setTnc] = useState(false)
  const [projectData, setProjectData] = useState({
    user_id: user.user_id,
    project_title: '',
    key_skills: [],
    status: user.type === 'AGENCY' ? 'PENDING' : 'OPEN',
    organization: user.type === 'AGENCY' ? '' : user?.name ?? '',
    email: '',
    years_of_experience: 0,
    description: '',
    project_category: '',
    duration: 0,
    payment: '',
    country: '',
    guidance: '',
    company_logo: ''
  })

  const handleJobDescriptionChange = (value) => {
    setProjectData({ ...projectData, description: value })
  }
  const handleCreateProject = async (e) => {
    e.preventDefault()
    const postData = {
      ...projectData,
      key_skills: projectData.key_skills
        .map((skills) => skills.language_name)
        .join('|')
    }

    try {
      await axiosInstance.post(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/postProject`,
        postData
      )
      showToast('success', 'Project Created Successfully')

      await refetchData()
      close()
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }

  return (
    <div className="p-3 sm:p-6 md:p-10 w-full  m-auto h-[95vh] bg-white overflow-y-scroll relative ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-xl font-bold"
      >
        <MdClose />
      </button>
      <form onSubmit={handleCreateProject}>
        <div className="py-10 flex flex-col gap-4">
          <h1 className="text-3xl font-semibold">Create Project</h1>
          <p>
            This project will allow the organisation to outsource students or
            freshers to complete projects while providing the candidates with
            the experience they need to kickstart their careers.
          </p>
        </div>
        <div className="w-full flex flex-col gap-6 md:gap-10 pb-10">
          <div className="flex flex-col  sm:grid sm:grid-cols-2 gap-6  ">
            <TextInput
              value={projectData.project_title}
              setValue={(val) =>
                setProjectData({ ...projectData, project_title: val })
              }
              label={'Title'}
              placeholder={'Enter Project Title'}
            />

            <TextInput
              value={projectData.organization}
              setValue={(val) =>
                setProjectData({ ...projectData, organization: val })
              }
              label={'Organization'}
              placeholder={'Enter Organization name'}
            />
            <TextInput
              value={projectData.user_id}
              setValue={(val) =>
                setProjectData({ ...projectData, user_id: val })
              }
              allowNumber
              label={'Organization ID'}
              placeholder={'Enter Organization ID'}
              disabled={user.type !== 'SUPER_ADMIN'}
            />

            <EmailInput
              value={projectData.email}
              setValue={(val) => setProjectData({ ...projectData, email: val })}
              label={'Email'}
              placeholder={'Enter Email'}
            />
            <label className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Country</p>
              <Select
                className="basic-single  bg-transparent text-sm"
                onChange={(val) =>
                  setProjectData({ ...projectData, country: val.value })
                }
                value={{
                  value: projectData.country,
                  label: projectData.country
                }}
                classNamePrefix="Country"
                defaultValue={
                  leaderboardCountries.map((country) => ({
                    value: country,
                    label: country
                  }))[0]
                }
                isClearable={true}
                isSearchable={true}
                styles={reactSelectStyleUtils}
                name="country"
                placeholder="Select Project Country"
                options={leaderboardCountries.map((country) => ({
                  value: country,
                  label: country
                }))}
              />
            </label>
            <NumberInput
              value={projectData.years_of_experience}
              setValue={(val) =>
                setProjectData({ ...projectData, years_of_experience: val })
              }
              label={'Years of Experience'}
              placeholder={'Years of Experience'}
            />

            <label className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Project Category</p>
              <Select
                className="basic-single  bg-transparent text-sm"
                onChange={(val) =>
                  setProjectData({
                    ...projectData,
                    project_category: val.value
                  })
                }
                value={{
                  value: projectData.project_category,
                  label: projectData.project_category
                }}
                classNamePrefix="Category"
                defaultValue={
                  projectCategory.map((category) => ({
                    value: category,
                    label: category
                  }))[0]
                }
                isClearable={true}
                isSearchable={true}
                name="jobCategory"
                placeholder="Select Job Category"
                styles={reactSelectStyleUtils}
                options={projectCategory.map((category) => ({
                  value: category,
                  label: category
                }))}
              />
            </label>

            <label className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Payment</p>
              <select
                value={projectData.payment}
                onChange={(e) =>
                  setProjectData({ ...projectData, payment: e.target.value })
                }
                className="bg-white border  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
              >
                <option className="p-4" value={''}>
                  None
                </option>
                <option className="p-4" value={'PAID'}>
                  Paid
                </option>
                <option className="" value={'UNPAID'}>
                  Unpaid
                </option>
              </select>
            </label>

            <label className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Status</p>
              <select
                value={projectData.status}
                onChange={(e) =>
                  setProjectData({ ...projectData, status: e.target.value })
                }
                className="bg-white border  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
              >
                <option className="p-4" value={''}>
                  None
                </option>
                <option className="p-4" value={'OPEN'}>
                  Open
                </option>
                <option className="" value={'CLOSE'}>
                  Closed
                </option>
              </select>
            </label>

            <label className="flex flex-col gap-2 text-xs md:text-sm ">
              <p className=" font-semibold  ">Duration</p>
              <select
                value={projectData.duration}
                onChange={(e) =>
                  setProjectData({
                    ...projectData,
                    duration: e.target.value
                  })
                }
                className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 "
              >
                <option className="p-4" value={''}>
                  None
                </option>
                <option className="p-4" value={'1'}>
                  1 Month or less
                </option>
                <option className="" value={'3'}>
                  3 Months
                </option>
                <option className="" value={'6'}>
                  6 Months
                </option>
                <option className="" value={'open'}>
                  Open Discussion
                </option>
              </select>
            </label>
            <MultiSelect
              label={'Key Skills'}
              placeholder={'Add Skill'}
              value={projectData.key_skills}
              setValue={(val) =>
                setProjectData({ ...projectData, key_skills: val })
              }
              keySkills={ProjectKeySkills.map((skill) => ({
                language_name: skill
              }))}
            />

            <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 mb-6 sm:mb-0 ">
              <p className=" font-semibold  ">Description</p>
              <div className="bg-white h-40">
                <ReactQuill
                  className=" h-32 rounded-2xl"
                  placeholder="Enter Project Description"
                  value={projectData.description}
                  onChange={handleJobDescriptionChange}
                />
              </div>
            </label>

            <label className="flex flex-col gap-2 text-xs md:text-sm col-span-2 ">
              <p className=" font-semibold  ">Description Preview</p>
              <div className="bg-white border min-h-[10rem] outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 ">
                {ReactHtmlParser(projectData.description.toString())}
              </div>
            </label>
          </div>

          <div className=" md:w-1/2  flex flex-col justify-between gap-3">
            <label className="flex items-center gap-2 font-600  cursor-pointer text-xs z-0">
              <input
                value={tnc}
                onChange={(e) => setTnc(e.target.checked)}
                className=" cursor-pointer"
                type="checkbox"
              />

              <p>I confirm the details provided are correct</p>
            </label>

            <SubmitButton text={'Create Project'} />
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateProjectModal
