import React, { Fragment } from 'react'
import uuid from 'react-uuid'
import ListingPagination from '../pagination/ListingPagination'
import { AdminJobTableHeader } from './data'
import SingleAdminJobTable from './SingleAdminJobTable'

const AdminJobTable = ({
  jobData,
  fetchAllJobs,
  page,
  onPageChange,
  count,
  limit
}) => {
  return (
    <div>
      <div className="overflow-x-scroll w-full mt-10">
        <table className={`p-4 w-full rounded-2xl  shadow-custom bg-white`}>
          <tbody className="text-xs xl:text-sm rounded-lg ">
            <tr className="bg-blue-400 bg-opacity-20 p-2 ">
              {AdminJobTableHeader.map((head) => (
                <th key={uuid()} className="text-center p-2 py-3 ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {jobData.length !== 0 &&
              jobData.map((singleJob, idx) => (
                <SingleAdminJobTable
                  key={uuid()}
                  data={singleJob}
                  idx={idx}
                  fetchAllJobs={fetchAllJobs}
                  limit={limit}
                  page={page}
                />
              ))}
          </tbody>
        </table>
        {jobData.length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
              No Jobs Found
            </p>
          </Fragment>
        )}
        <ListingPagination
          page={page}
          onPageChange={onPageChange}
          count={count}
        />
      </div>
    </div>
  )
}

export default AdminJobTable
