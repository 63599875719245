import React, { useRef, useState } from 'react'
import { MdAddCircle } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'
import IconButton from '../../ui-component/buttons/IconButton'
import ClickWrapper from '../click-wrapper/ClickWrapper'
import AddApplicantModal from '../self-jobs/AddApplicantModal'

const SinglePartnerJobTable = ({ key, data, idx, limit, page }) => {
  const {
    company_logo,
    company_name,
    create_date,
    description,
    status,
    job_id,
    job_name,
    key_skills
  } = data
  const navigate = useNavigate()
  const [showAddApplicantModal, setShowAddApplicationModal] = useState(false)
  const toolRef = useRef(null)

  const handleJobClick = (e) => {
    if (toolRef.current.contains(e.target)) {
      return
    } else {
      navigate(`/${company_name.split(' ').join('-')}/${job_id}/apply`)
    }
  }

  return (
    <tr
      onClick={handleJobClick}
      className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   "
    >
      <td className="text-center">{(page - 1) * limit + idx + 1}</td>

      <td className=" p-3 px-4 min-w-[300px] max-w-[400px]">
        <div className="flex gap-2 items-center">{job_name}</div>
      </td>
      <td className="text-center">
        <div className="flex gap-3 items-center">
          {company_logo && company_logo !== '' && (
            <img
              className="w-8 h-8 object-contain rounded-md"
              src={company_logo}
              alt=""
            />
          )}
          <h2 className="text-base   font-bold">{company_name}</h2>
        </div>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4  ">
          <div className="flex justify-center items-center gap-3">
            {key_skills &&
              key_skills.split(',').map((lang) => (
                <p
                  key={uuid()}
                  className="bg-gray-100 shadow p-2 px-4 rounded-full text-xs text-gray-900 "
                >
                  {' '}
                  {lang}
                </p>
              ))}
          </div>{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">
          {create_date.split(' ')[0].split('-').reverse().join('/')}{' '}
        </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{job_id} </p>
      </td>

      <td ref={toolRef} className="text-center relative">
        <div className="flex gap-2 items-center justify-center">
          {status === 'OPEN' && (
            <IconButton
              toolTipTitle={'Add Applicants'}
              text={'Add Applicants'}
              icon={<MdAddCircle />}
              onClick={() => setShowAddApplicationModal(true)}
              type={'primary'}
              outline
            />
          )}
        </div>
        {showAddApplicantModal && (
          <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
            <div className="w-full max-w-4xl p-6 m-auto z-50 text-left">
              <ClickWrapper func={() => {}}>
                <AddApplicantModal
                  close={() => setShowAddApplicationModal()}
                  jobID={job_id}
                  isModal
                />
              </ClickWrapper>
            </div>
          </div>
        )}
      </td>
    </tr>
  )
}

export default SinglePartnerJobTable
