import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import JobListing from '../../components/job-listing/JobListing'
import RippleLoader from '../../components/loader/RippleLoader'

const JobListingPage = () => {
  const [loading, setLoading] = useState(true)

  const [allJobData, setAllJobData] = useState([])

  const { getJobListing } = EndPoints.jobListing
  const { user } = useSelector((state) => state.auth)

  const fetchAllJobs = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${getJobListing}/getrecruiterposts?user_id=${user.user_id}`
      )
      console.log(JSON.parse(data.body).message)
      const jobData = JSON.parse(data.body).message
      if (jobData) {
        setAllJobData(jobData)
      }
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchAllJobs()
  }, [])

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10 relative ">
      {loading ? (
        <RippleLoader />
      ) : (
        <JobListing allJobData={allJobData} fetchAllJobs={fetchAllJobs} />
      )}
    </div>
  )
}

export default JobListingPage
