import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Navigate as Redirect,
  Route,
  Routes as Switch,
  useLocation
} from 'react-router-dom'
import AboutPage from '../main/pages/about/AboutPage'
import AdminActivity from '../main/pages/admin-activity/AdminActivity'
import AdminContactPage from '../main/pages/admin-contacts/AdminContactPage'
import AdminPage from '../main/pages/admin/AdminPage'
import AdvancedSearch from '../main/pages/advanced-search/AdvancedSearch'
import ApplicantJobPage from '../main/pages/applicant-jobs/ApplicantJobPage'
import ApplicantSigninPage from '../main/pages/applicant/ApplicantSigninPage'
import ApplicantSignupPage from '../main/pages/applicant/ApplicantSignupPage'
import ApplyProjectPage from '../main/pages/apply-project/ApplyProjectPage'
import AuthPage from '../main/pages/auth/AuthPage'
import CollegeLeaderboard from '../main/pages/college-leaderboard/CollegeLeaderboard'
import ContactPage from '../main/pages/contact-us/ContactPage'
import CreateProfilePage from '../main/pages/create-profile/CreateProfilePage'
import CreateUser from '../main/pages/create-user/CreateUser'
import DashboardPage from '../main/pages/dashboard/DashboardPage'
import DirectApplyPage from '../main/pages/direct-apply/DirectApplyPage'
import ErrorPage from '../main/pages/error/ErrorPage'
import HomePage from '../main/pages/home/HomePage'
import JobListingPage from '../main/pages/job-listing/JobListingPage'
import AllJobPage from '../main/pages/job-page/AllJobPage'
import LeaderboardPage from '../main/pages/leaderboard/LeaderboardPage'
import PricingPage from '../main/pages/pricing/PricingPage'
import PrivacyPolicyPage from '../main/pages/privacy-policy/PrivacyPolicyPage'
import ProcessCandidatePage from '../main/pages/process-caandidate/ProcessCandidatePage'
import ProfilePage from '../main/pages/profile/ProfilePage'
import ProjectInsightPage from '../main/pages/project-insights/ProjectInsightPage'
import ProjectListingPage from '../main/pages/project-listing/ProjectListingPage'
import ProjectPage from '../main/pages/project/ProjectPage'
import PublicDashboard from '../main/pages/public/dashboard/PublicDashboard'
import RecruiterDashboard from '../main/pages/recruiter-dashboard/RecruiterDashboard'
import RecruiterPage from '../main/pages/recruiter/RecruiterPage'
import RepositoryPage from '../main/pages/repository/RepositoryPage'
import ResumeInsights from '../main/pages/resume-insights/ResumeInsights'
import ResumeIntroPage from '../main/pages/resume-intro/ResumeIntroPage'
import ResumeSummary from '../main/pages/resume-summary/ResumeSummary'
import ResumeJobPage from '../main/pages/resume-vs-job/ResumeJobPage'
import SearchPage from '../main/pages/search-user/SearchPage'
import ShortListPage from '../main/pages/shortlist/ShortListPage'
import SignupPage from '../main/pages/signup/SignupPage'
import SingleCollegeStats from '../main/pages/single-college-stats/SingleCollegeStats'
import SingleJobPage from '../main/pages/single-job-listing/SingleJobPage'
import SingleProjectListing from '../main/pages/single-project-listing/SingleProjectListing'
import SuperAdmin from '../main/pages/super-admin/SuperAdmin'
import TermsPage from '../main/pages/terms-page/TermsPage'
import {
  ABOUT_PAGE,
  ADMIN_ACTIVITY_PAGE,
  ADMIN_CONTACT_PAGE,
  ADMIN_PAGE,
  ADVANCED_SEARCH_USER_PAGE,
  ALL_JOBS_PAGE,
  APPLICANT_JOB_LISTING_PAGE,
  APPLICANT_SIGNIN_PAGE,
  APPLICANT_SIGNUP_PAGE,
  CERTIFICATION_PAGE,
  COLLEGE_LEADERBOARD_PAGE,
  CONTACT_PAGE,
  CREATE_USER_PAGE,
  CREATE_USER_PROFILE_PAGE,
  DASHBOARD_PAGE,
  DYNAMIC_JOB_APPLY_PAGE,
  ERROR_PAGE,
  HOME_PAGE,
  JOB_LISTING_PAGE,
  LEADERBOARD_PAGE,
  LOGIN_PAGE,
  POLICY_PAGE,
  PRICING_PAGE,
  PROCESS_CANDIDATE_PAGE,
  PROFILE_PAGE,
  PROJECTS_INSIGHT_PAGE,
  PROJECTS_PAGE,
  PROJECT_APPLY_PAGE,
  PROJECT_LISTING_PAGE,
  PUBLIC_DASHBOARD_PAGE,
  RECRUITER_DASHBOARD_PAGE,
  RECRUITER_PAGE,
  RECRUITER_PROFILE_PAGE,
  REPOSITORY_PAGE,
  RESUME_INSIGHT_PAGE,
  RESUME_INTRODUCTION_PAGE,
  RESUME_JOB_PAGE,
  RESUME_SUMMARY_PAGE,
  SEARCH_USER_PAGE,
  SHORTLIST_STUDENT_PAGE,
  SIGNUP_PAGE,
  SINGLE_COLLEGE_PAGE,
  SINGLE_JOB_LISTING_PAGE,
  SINGLE_PROJECT_LISTING_PAGE,
  SUPER_ADMIN_PAGE,
  TERMS_PAGE
} from './routes'
import CertificationPage from '../main/pages/certification/CertificationPage'
import RecruiterProfilePage from '../main/pages/recruiter-profile/RecruiterProfilePage'

const RouteConfig = () => {
  const { pathname } = useLocation()
  const { user, githubUser } = useSelector((state) => state.auth)
  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })
  }, [pathname])
  return (
    <Switch>
      <Route
        path={LOGIN_PAGE}
        element={
          !user ? <AuthPage /> : <Redirect to={RECRUITER_DASHBOARD_PAGE} />
        }
      />
      <Route
        path={SIGNUP_PAGE}
        element={
          !user ? <SignupPage /> : <Redirect to={RECRUITER_DASHBOARD_PAGE} />
        }
      />

      <Route
        exact
        path={HOME_PAGE}
        element={
          user ? (
            <Redirect to={RECRUITER_DASHBOARD_PAGE} />
          ) : githubUser ? (
            <Redirect to={`/dashboard/${githubUser?.username}`} />
          ) : (
            <HomePage />
          )
        }
      />
      <Route
        exact
        path={RECRUITER_PAGE}
        element={
          user || githubUser ? <Redirect to={HOME_PAGE} /> : <RecruiterPage />
        }
      />
      <Route
        exact
        path={APPLICANT_SIGNUP_PAGE}
        element={
          githubUser ? <Redirect to={HOME_PAGE} /> : <ApplicantSignupPage />
        }
      />
      <Route
        exact
        path={APPLICANT_SIGNIN_PAGE}
        element={
          githubUser ? <Redirect to={HOME_PAGE} /> : <ApplicantSigninPage />
        }
      />
      <Route exact path={PUBLIC_DASHBOARD_PAGE} element={<PublicDashboard />} />
      <Route
        exact
        path={COLLEGE_LEADERBOARD_PAGE}
        element={<CollegeLeaderboard />}
      />
      <Route
        exact
        path={SINGLE_COLLEGE_PAGE}
        element={<SingleCollegeStats />}
      />
      <Route
        exact
        path={DASHBOARD_PAGE}
        element={
          githubUser || user ? (
            <DashboardPage />
          ) : (
            <Redirect to={APPLICANT_SIGNIN_PAGE} />
          )
        }
      />
      <Route
        exact
        path={RECRUITER_DASHBOARD_PAGE}
        element={user ? <RecruiterDashboard /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={ADMIN_PAGE}
        element={user ? <AdminPage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={SUPER_ADMIN_PAGE}
        element={user ? <SuperAdmin /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={ADMIN_ACTIVITY_PAGE}
        element={user ? <AdminActivity /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={ADMIN_CONTACT_PAGE}
        element={user ? <AdminContactPage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={APPLICANT_JOB_LISTING_PAGE}
        element={
          githubUser ? (
            <ApplicantJobPage />
          ) : (
            <Redirect to={APPLICANT_SIGNIN_PAGE} />
          )
        }
      />
      <Route
        exact
        path={PROFILE_PAGE}
        element={
          githubUser ? <ProfilePage /> : <Redirect to={APPLICANT_SIGNIN_PAGE} />
        }
      />
      <Route
        exact
        path={REPOSITORY_PAGE}
        element={
          githubUser || user ? (
            <RepositoryPage />
          ) : (
            <Redirect to={APPLICANT_SIGNIN_PAGE} />
          )
        }
      />

      <Route
        exact
        path={CREATE_USER_PROFILE_PAGE}
        element={
          githubUser ? (
            <CreateProfilePage />
          ) : (
            <Redirect to={APPLICANT_SIGNIN_PAGE} />
          )
        }
      />

      <Route
        exact
        path={DYNAMIC_JOB_APPLY_PAGE}
        element={<DirectApplyPage />}
      />
      <Route exact path={PROJECT_APPLY_PAGE} element={<ApplyProjectPage />} />
      <Route exact path={PRICING_PAGE} element={<PricingPage />} />
      <Route exact path={LEADERBOARD_PAGE} element={<LeaderboardPage />} />
      <Route exact path={RESUME_SUMMARY_PAGE} element={<ResumeSummary />} />
      <Route exact path={RESUME_JOB_PAGE} element={<ResumeJobPage />} />
      <Route exact path={CERTIFICATION_PAGE} element={<CertificationPage />} />
      <Route
        exact
        path={RESUME_INTRODUCTION_PAGE}
        element={<ResumeIntroPage />}
      />
      <Route exact path={RESUME_INSIGHT_PAGE} element={<ResumeInsights />} />
      <Route exact path={ALL_JOBS_PAGE} element={<AllJobPage />} />
      <Route exact path={PROJECTS_PAGE} element={<ProjectPage />} />
      <Route
        exact
        path={PROJECTS_INSIGHT_PAGE}
        element={<ProjectInsightPage />}
      />

      <Route
        exact
        path={JOB_LISTING_PAGE}
        element={user ? <JobListingPage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={PROCESS_CANDIDATE_PAGE}
        element={user ? <ProcessCandidatePage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={PROJECT_LISTING_PAGE}
        element={user ? <ProjectListingPage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={SINGLE_JOB_LISTING_PAGE}
        element={user ? <SingleJobPage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={SINGLE_PROJECT_LISTING_PAGE}
        element={user ? <SingleProjectListing /> : <Redirect to={LOGIN_PAGE} />}
      />

      <Route
        exact
        path={CREATE_USER_PAGE}
        element={user?.parent ? <CreateUser /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={SEARCH_USER_PAGE}
        element={user ? <SearchPage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={ADVANCED_SEARCH_USER_PAGE}
        element={user ? <AdvancedSearch /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={SHORTLIST_STUDENT_PAGE}
        element={user ? <ShortListPage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route
        exact
        path={RECRUITER_PROFILE_PAGE}
        element={user ? <RecruiterProfilePage /> : <Redirect to={LOGIN_PAGE} />}
      />
      <Route path={TERMS_PAGE} element={<TermsPage />} />
      <Route path={POLICY_PAGE} element={<PrivacyPolicyPage />} />
      <Route path={CONTACT_PAGE} element={<ContactPage />} />
      <Route path={ABOUT_PAGE} element={<AboutPage />} />
      <Route path={ERROR_PAGE} element={<ErrorPage />} />
    </Switch>
  )
}

export default RouteConfig
