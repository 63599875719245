import React from 'react'
import { Fragment } from 'react'
import uuid from 'react-uuid'
import { recruiterProjectsHeader } from './data'
import SingleRecruiterProjectTable from './SingleRecruiterProjectTable'

const ProjectTable = ({ projectData = [] }) => {
  return (
    <div>
      <div className="w-full overflow-x-scroll">
        <div className="flex items-center justify-between mb-6">
          <h3 className="px-6 mr-2 font-semibold truncate md:px-10">
            All Projects
          </h3>
        </div>
        <table className={`p-4 w-full rounded-2xl  shadow-custom bg-white`}>
          <tbody className="text-xs rounded-lg xl:text-sm ">
            <tr className="p-2 bg-blue-400 bg-opacity-20 ">
              {recruiterProjectsHeader.map((head) => (
                <th key={uuid()} className="p-2 py-3 text-center ">
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {projectData.length !== 0 &&
              projectData.map((singleJob, idx) => (
                <SingleRecruiterProjectTable
                  key={uuid()}
                  data={singleJob}
                  idx={idx}
                />
              ))}
          </tbody>
        </table>
        {projectData.length === 0 && (
          <Fragment>
            <p className="w-full py-4 my-4 text-center text-gray-500 font-600">
              No Jobs Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default ProjectTable
