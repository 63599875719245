import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import creditAction from '../../../redux/actions/creditAction'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import AdvanceUserForm from '../../components/search-user/advanced/AdvanceUserForm'
import AdvanceUserTable from '../../components/search-user/advanced/AdvanceUserTable'

const getSearchedUserFromLocal = () => {
  const data = sessionStorage.getItem('ADVANCED_USER')
  if (data) return JSON.parse(data)

  return []
}

const AdvancedSearch = () => {
  const [loading, setLoading] = useState(false)
  const [searchedUser, setSearchedUser] = useState(getSearchedUserFromLocal())
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const refetchUserData = async () => {
    const query = JSON.parse(sessionStorage.getItem('ADVANCED_USER_QUERY'))

    if (query === null || query === '') {
      return
    }
    try {
      const { data } = await axiosInstance.get(query)
      setSearchedUser(JSON.parse(data.body).message)
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div className="w-full p-4 sm:p-6 md:p-10 min-h-screen">
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="flex flex-col gap-4 md:gap-6">
          <div className="grid place-items-center w-full ">
            <AdvanceUserForm
              setSearchedUser={setSearchedUser}
              setLoading={setLoading}
            />
          </div>
          {loading ? (
            <RippleLoader />
          ) : (
            <div>
              <ErrorBoundary FallbackComponent={ErrorComponent}>
                <AdvanceUserTable
                  fetchCreditData={() =>
                    dispatch(creditAction.fetchUserCredits(user.user_id))
                  }
                  data={searchedUser}
                  refetchUserData={refetchUserData}
                />
              </ErrorBoundary>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default AdvancedSearch
