import React from 'react'
import { Fragment } from 'react'
import ListingPagination from '../pagination/ListingPagination'
import SingleCollegeLeaderboard from './SingleCollegeLeaderboard'
import { collegeLeaderboardHeader } from './tableData'
import uuid from 'react-uuid'

const CollegeLeaderboardTable = ({
  data,
  page,
  onPageChange,
  count,
  limit
}) => {
  return (
    <div className="overflow-x-scroll w-full">
      <table className={`p-4 w-full rounded-lg  shadow-custom bg-white`}>
        <tbody className="text-xs xl:text-sm rounded-lg ">
          <tr className="bg-blue-400 bg-opacity-20 p-2 ">
            {collegeLeaderboardHeader.map((head) => (
              <th key={uuid()} className="text-center p-2 py-3 ">
                {head.title}{' '}
              </th>
            ))}
          </tr>

          {data.length !== 0 &&
            data.map((applicantData, index) => (
              <SingleCollegeLeaderboard
                key={uuid()}
                data={applicantData}
                limit={limit}
                index={index}
                page={page}
              />
            ))}
        </tbody>
      </table>
      {data.length === 0 && (
        <Fragment>
          <p className="text-center w-full font-600 py-4 my-4 text-gray-500">
            No Colleges Found
          </p>
        </Fragment>
      )}
      <ListingPagination
        page={page}
        onPageChange={onPageChange}
        count={count}
      />
    </div>
  )
}

export default CollegeLeaderboardTable
