import React from 'react'
import { useNavigate } from 'react-router-dom'
import { PROJECTS_PAGE } from '../../../navigation/routes'

const projectInsightData = {
  Candidates: {
    data: [
      {
        title: 'Gain real world experience',
        text: 'Students can work on projects that are aligned with industry needs and gain hands-on experience working on real-world problems.'
      },
      {
        title: 'Enhance employability',
        text: 'By working on industry-aligned projects, students can improve their skills and enhance their job prospects.'
      },
      {
        title: 'Receive guidance and mentorship',
        text: ' Students can receive guidance and mentorship from professionals in their chosen fields, which can help them develop their skills and build their network.'
      },
      {
        title: 'Work on challenging projects',
        text: 'Students can work on challenging projects that require critical thinking, problem-solving, and creativity, which can help them develop their skills and knowledge.				'
      },
      {
        title: 'Build their portfolio',
        text: 'By working on industry-aligned projects, students can build a portfolio of work that showcases their skills and abilities to future employers.'
      }
    ],
    buttonText: 'Create a Project',
    route: PROJECTS_PAGE
  },
  Organisations: {
    data: [
      {
        title: 'Access to fresh talent',
        text: ' Organizations can tap into the next generation of talent and bring in fresh perspectives, ideas, and approaches to solving problems.				.'
      },
      {
        title: 'Cost-effective solutions',
        text: ' By posting projects on the platform, organizations can get work done by students at a lower cost than hiring professional.'
      },
      {
        title: 'Enhanced brand image',
        text: 'Organizations can demonstrate their commitment to supporting education and the next generation of talent, which can enhance their brand image and reputation.'
      },
      {
        title: 'Early identification of potential employees',
        text: 'Organizations can use the platform to identify potential employees who have the skills and expertise they need for their business.'
      },
      {
        title: 'Flexibility',
        text: 'Organizations can post projects on the platform as and when they need them, providing them with flexibility to address their specific needs and requirements.'
      }
    ],
    buttonText: 'Find Projects',
    route: PROJECTS_PAGE
  }
}

const ProjectCard = ({ data, cardKey }) => {
  const navigate = useNavigate()
  return (
    <div className="rounded-2xl p-6 sm:p-10 flex flex-col gap-8 bg-gradient-to-b from-blue-100 to-orange-50 items-center shadow-lg justify-between h-full">
      <div className="flex flex-col gap-8  ">
        {' '}
        <h2 className="text-3xl font-semibold">{cardKey}</h2>
        <div className="flex flex-col gap-2 text-sm  ">
          {data.data.map((singleList) => (
            <li key={singleList.title}>
              <span className="font-semibold mr-1">{singleList?.title} : </span>
              {singleList.text}
            </li>
          ))}
        </div>
      </div>

      <button
        onClick={() => navigate(data.route)}
        className="p-3 py-2  font-semibold text-sm rounded-full w-full grid place-items-center bg-blue-500 text-white "
      >
        {data.buttonText}
      </button>
    </div>
  )
}

const ProjectInsightPage = () => {
  return (
    <div className="w-full min-h-screen p-4 md:p-10">
      <div className="flex flex-col gap-10">
        <div className="flex justify-between gap-8">
          <div className="flex flex-col gap-4 pt-10 sm:pt-20 sm:px-10 ">
            <h1
              style={{ lineHeight: '1.5' }}
              className="text-3xl sm:text-4xl lg:text-5xl  font-bold "
            >
              Unlock the <span className="text-blue-500"> Untapped</span>
              <span className="text-orange-500 ml-1">Potential</span> of{' '}
              <span className="text-blue-500"> Student</span>
              <span className="text-orange-500 ml-1">Projects</span> with our
              Industry-Aligned Project Platform.
            </h1>

            <p className="text-sm md:text-xl font-normal leading-loose">
              Join us today and unlock the potential of student projects for the
              benefit of everyone involved.
            </p>
          </div>
          <div className="hidden md:block">
            <lottie-player
              src="https://assets5.lottiefiles.com/packages/lf20_16MhZz.json"
              background="transparent"
              speed="1"
              style={{ width: '75%', objectFit: 'contain' }}
              loop
              autoplay
            ></lottie-player>
          </div>
        </div>
        <div className="grid grid-cols-1 sm:px-10  gap-10 w-full  ">
          <p>
            Every year, millions of graduates spend countless hours working on
            year-end projects as part of their learning. However, many of these
            projects end up gathering dust in college files, never seeing the
            light of day. This is a waste of valuable resources and potential.
          </p>

          <p>
            At our platform, we believe in turning this around by connecting
            students with companies to create projects that are aligned with
            industry needs. By posting their projects on our platform, companies
            can tap into the talent and enthusiasm of students who are eager to
            gain real-world experience and apply their knowledge to real-world
            problems.
          </p>

          <p>
            Through this collaboration, both students and companies can benefit.
            Students can gain invaluable guidance and mentorship from
            professionals in their chosen fields, while also gaining hands-on
            experience that will enhance their job prospects. Meanwhile,
            companies can get valuable work done and gain fresh insights and
            perspectives from the next generation of talent.
          </p>
        </div>
      </div>

      <div className="flex flex-col gap-10 py-20 w-full max-w-6xl mx-auto">
        <h1 className="text-4xl text-center">Benefits of Projects</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-10">
          {Object.keys(projectInsightData).map((key, idx) => (
            <ProjectCard
              data={projectInsightData[key]}
              key={idx}
              cardKey={key}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ProjectInsightPage
