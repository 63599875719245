import React from 'react'
import { Fragment } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import uuid from 'react-uuid'
import YearSelect from '../../select/YearSelect'
import { repoHeaderData } from './RepoHeaderData'
import SingleRepotable from './SingleRepotable'

const RepoTable = ({ data, year_joined, userData }) => {
  const [year, setYear] = useState(new Date().getFullYear())
  const [filteredData, setFilteredData] = useState(data)

  let optionArr = []

  for (var i = 0; i <= new Date().getFullYear() - year_joined; i++) {
    optionArr.push(i)
  }

  const filterObject = (obj, filter, filterValue) =>
    Object.keys(obj).reduce(
      (acc, val) =>
        obj[val][filter]?.split('-')[0] !== filterValue.toString()
          ? acc
          : {
              ...acc,
              [val]: obj[val]
            },
      {}
    )

  useEffect(() => {
    const filteredObj = filterObject(data, 'created_date', year)
    setFilteredData(filteredObj)
  }, [year])
  return (
    <div className="p-4 sm:p-6 ">
      <div className="">
        <div className="flex justify-between items-center mb-6 sm:px-6">
          <h3 className="font-semibold ">Top Repositories</h3>

          <YearSelect
            arr={optionArr}
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </div>

        <table
          className={`p-4 w-full rounded-lg overflow-hidden shadow-custom bg-white`}
        >
          <tbody className="text-xs xl:text-sm ">
            <tr className="bg-blue-400 bg-opacity-20 p-2 ">
              {repoHeaderData.map((head) => (
                <th
                  style={{ width: head.width }}
                  key={uuid()}
                  className="text-center p-2  "
                >
                  {head.title}{' '}
                </th>
              ))}
            </tr>

            {Object.keys(filteredData).length !== 0 &&
              Object.keys(filteredData).map((repoData) => (
                <SingleRepotable
                  key={uuid()}
                  data={data[repoData]}
                  userData={userData}
                />
              ))}
          </tbody>
        </table>
        {Object.keys(filteredData).length === 0 && (
          <Fragment>
            <p className="text-center w-full font-600 py-4 my-4 text-gray-300">
              No repositories Found
            </p>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default RepoTable
