export const leaderboardHeader = [
  {
    title: 'Rank.',
    width: '10%'
  },
  {
    title: 'Name',
    width: '10%'
  },

  {
    title: 'Experience',
    width: '10%'
  },

  {
    title: 'Commit',
    width: '10%'
  },
  {
    title: 'TP Rating',
    width: '10%'
  },
  {
    title: 'Languages',
    width: '10%'
  },
  {
    title: 'Country',
    width: '10%'
  },
  {
    title: 'City',
    width: '10%'
  }
  // {
  // 	title: 'Connect',
  // 	width: '10%',
  // },
]

export const leaderboardCountries = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antigua And Barbuda',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bosnia And Herzegovina',
  'Botswana',
  'Brazil',
  'British Virgin Islands',
  'Brunei',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Central African Republic',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Colombia',
  'Comoros',
  'Congo (Brazzaville)',
  'Congo (Kinshasa)',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Ethiopia',
  'Falkland Islands (Islas Malvinas)',
  'Faroe Islands',
  'Federated States of Micronesia',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Gaza Strip',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle Of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Kosovo',
  'Kuwait',
  'Kyrgyzstan',
  'Laos',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macau',
  'Macedonia',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'North Korea',
  'Northern Mariana Islands',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn Islands',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Reunion',
  'Romania',
  'Russia',
  'Rwanda',
  'Saint Barthelemy',
  'Saint Helena, Ascension, And Tristan Da Cunha',
  'Saint Kitts And Nevis',
  'Saint Lucia',
  'Saint Martin',
  'Saint Pierre And Miquelon',
  'Saint Vincent And The Grenadines',
  'Samoa',
  'San Marino',
  'Sao Tome And Principe',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Sint Maarten',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia And South Sandwich Islands',
  'South Korea',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Svalbard',
  'Swaziland',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'The Bahamas',
  'The Gambia',
  'Timor-Leste',
  'Togo',
  'Tonga',
  'Trinidad And Tobago',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Turks And Caicos Islands',
  'Tuvalu',
  'U.S. Virgin Islands',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Vatican City',
  'Venezuela',
  'Vietnam',
  'Wallis And Futuna',
  'West Bank',
  'Yemen',
  'Zambia',
  'Zimbabwe'
]

export const leaderboardTechnologies = [
  'ALL',
  'ABAP',
  'AGS Script',
  'AMPL',
  'ANTLR',
  'API Blueprint',
  'APL',
  'ASP',
  'ATS',
  'ActionScript',
  'Ada',
  'Agda',
  'Alloy',
  'ApacheConf',
  'Apex',
  'AppleScript',
  'Arc',
  'Arduino',
  'AsciiDoc',
  'AspectJ',
  'Assembly',
  'Augeas',
  'AutoHotkey',
  'AutoIt',
  'Awk',
  'Batchfile',
  'Befunge',
  'Bison',
  'BitBake',
  'BlitzBasic',
  'BlitzMax',
  'Bluespec',
  'Boo',
  'Brainfuck',
  'Brightscript',
  'Bro',
  'C',
  'C#',
  'C++',
  'C-ObjDump',
  'C2hs Haskell',
  'CLIPS',
  'CMake',
  'COBOL',
  'CSS',
  'CSV',
  "Cap'n Proto",
  'CartoCSS',
  'Ceylon',
  'Chapel',
  'Charity',
  'ChucK',
  'Cirru',
  'Clarion',
  'Clean',
  'Click',
  'Clojure',
  'CoffeeScript',
  'ColdFusion',
  'ColdFusion CFC',
  'Common Lisp',
  'Component Pascal',
  'Cool',
  'Coq',
  'Cpp-ObjDump',
  'Creole',
  'Crystal',
  'Cucumber',
  'Cuda',
  'Cycript',
  'Cython',
  'D',
  'D-ObjDump',
  'DIGITAL Command Language',
  'DM',
  'DNS Zone',
  'DTrace',
  'Darcs Patch',
  'Dart',
  'Diff',
  'Dockerfile',
  'Dogescript',
  'Dylan',
  'E',
  'ECL',
  'ECLiPSe',
  'Eagle',
  'Ecere Projects',
  'Eiffel',
  'Elixir',
  'Elm',
  'Emacs Lisp',
  'EmberScript',
  'Erlang',
  'F#',
  'FLUX',
  'FORTRAN',
  'Factor',
  'Fancy',
  'Fantom',
  'Filterscript',
  'Formatted',
  'Forth',
  'FreeMarker',
  'Frege',
  'G-code',
  'GAMS',
  'GAP',
  'GAS',
  'GDScript',
  'GLSL',
  'Game Maker Language',
  'Genshi',
  'Gentoo Ebuild',
  'Gentoo Eclass',
  'Gettext Catalog',
  'Glyph',
  'Gnuplot',
  'Go',
  'Golo',
  'Gosu',
  'Grace',
  'Gradle',
  'Grammatical Framework',
  'Graph Modeling Language',
  'GraphQL',
  'Graphviz (DOT)',
  'Groff',
  'Groovy',
  'Groovy Server Pages',
  'HCL',
  'HLSL',
  'HTML',
  'HTML+Django',
  'HTML+EEX',
  'HTML+ERB',
  'HTML+PHP',
  'HTTP',
  'Hack',
  'Haml',
  'Handlebars',
  'Harbour',
  'Haskell',
  'Haxe',
  'Hy',
  'HyPhy',
  'IDL',
  'IGOR Pro',
  'INI',
  'IRC log',
  'Idris',
  'Inform 7',
  'Inno Setup',
  'Io',
  'Ioke',
  'Isabelle',
  'J',
  'JFlex',
  'JSON',
  'JSON5',
  'JSONLD',
  'JSONiq',
  'JSX',
  'Jade',
  'Jasmin',
  'Java',
  'Java Server Pages',
  'JavaScript',
  'Julia',
  'Jupyter Notebook',
  'KRL',
  'KiCad',
  'Kit',
  'Kotlin',
  'LFE',
  'LLVM',
  'LOLCODE',
  'LSL',
  'LabVIEW',
  'Lasso',
  'Latte',
  'Lean',
  'Less',
  'Lex',
  'LilyPond',
  'Limbo',
  'Linker Script',
  'Linux Kernel Module',
  'Liquid',
  'Literate Agda',
  'Literate CoffeeScript',
  'Literate Haskell',
  'LiveScript',
  'Logos',
  'Logtalk',
  'LookML',
  'LoomScript',
  'Lua',
  'M',
  'M4',
  'M4Sugar',
  'MAXScript',
  'MTML',
  'MUF',
  'Makefile',
  'Mako',
  'Markdown',
  'Mask',
  'Mathematica',
  'Matlab',
  'Max',
  'MediaWiki',
  'Mercury',
  'Metal',
  'MiniD',
  'Mirah',
  'Modelica',
  'Modula-2',
  'Module Management System',
  'Monkey',
  'Moocode',
  'MoonScript',
  'Myghty',
  'NCL',
  'NL',
  'NSIS',
  'Nemerle',
  'NetLinx',
  'NetLinx+ERB',
  'NetLogo',
  'NewLisp',
  'Nginx',
  'Nimrod',
  'Ninja',
  'Nit',
  'Nix',
  'Nu',
  'NumPy',
  'OCaml',
  'ObjDump',
  'Objective-C',
  'Objective-C++',
  'Objective-J',
  'Omgrofl',
  'Opa',
  'Opal',
  'OpenCL',
  'OpenEdge ABL',
  'OpenSCAD',
  'Org',
  'Ox',
  'Oxygene',
  'Oz',
  'PAWN',
  'PHP',
  'PLSQL',
  'PLpgSQL',
  'POV-Ray SDL',
  'Pan',
  'Papyrus',
  'Parrot',
  'Parrot Assembly',
  'Parrot Internal Representation',
  'Pascal',
  'Perl',
  'Perl6',
  'Pickle',
  'PicoLisp',
  'PigLatin',
  'Pike',
  'Pod',
  'PogoScript',
  'Pony',
  'PostScript',
  'PowerShell',
  'Processing',
  'Prolog',
  'Propeller Spin',
  'Protocol Buffer',
  'Public Key',
  'Puppet',
  'Pure Data',
  'PureBasic',
  'PureScript',
  'Python',
  'Python traceback',
  'QML',
  'QMake',
  'R',
  'RAML',
  'RDoc',
  'REALbasic',
  'RHTML',
  'RMarkdown',
  'Racket',
  'Ragel in Ruby Host',
  'Raw token data',
  'Rebol',
  'Red',
  'Redcode',
  "Ren'Py",
  'RenderScript',
  'RobotFramework',
  'Rouge',
  'Ruby',
  'Rust',
  'SAS',
  'SCSS',
  'SMT',
  'SPARQL',
  'SQF',
  'SQL',
  'SQLPL',
  'STON',
  'SVG',
  'Sage',
  'SaltStack',
  'Sass',
  'Scala',
  'Scaml',
  'Scheme',
  'Scilab',
  'Self',
  'Shell',
  'ShellSession',
  'Shen',
  'Slash',
  'Slim',
  'Smali',
  'Smalltalk',
  'Smarty',
  'SourcePawn',
  'Squirrel',
  'Stan',
  'Standard ML',
  'Stata',
  'Stylus',
  'SuperCollider',
  'Swift',
  'SystemVerilog',
  'TOML',
  'TXL',
  'Tcl',
  'Tcsh',
  'TeX',
  'Tea',
  'Terra',
  'Text',
  'Textile',
  'Thrift',
  'Turing',
  'Turtle',
  'Twig',
  'TypeScript',
  'Unified Parallel C',
  'Unity3D Asset',
  'Uno',
  'UnrealScript',
  'UrWeb',
  'VCL',
  'VHDL',
  'Vala',
  'Verilog',
  'VimL',
  'Visual Basic',
  'Volt',
  'Vue',
  'Web Ontology Language',
  'WebIDL',
  'X10',
  'XC',
  'XML',
  'XPages',
  'XProc',
  'XQuery',
  'XS',
  'XSLT',
  'Xojo',
  'Xtend',
  'YAML',
  'YANG',
  'Yacc',
  'Zephir',
  'Zimpl',
  'desktop',
  'eC',
  'edn',
  'fish',
  'mupad',
  'nesC',
  'ooc',
  'reStructuredText',
  'wisp',
  'xBase'
]
