import {
  GITHUB_LOGIN,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOGOUT,
  REGISTER_FAIL,
  REGISTER_SUCCESS
} from '../actions/actionTypes'

const authUser = sessionStorage.getItem('GITHUB_AUTH')
const registeredUser = localStorage.getItem('TECH_RESUME_USER')

const initialState = {
  loggedIn: registeredUser ? true : false,
  githubUser: authUser ? JSON.parse(authUser) : null,
  user: registeredUser ? JSON.parse(registeredUser) : null,
  authError: ''
}

export const authReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state
      }
    case REGISTER_FAIL:
      return {
        loggedIn: false,
        user: null,
        githubUser: false,
        authError: 'Error signing up'
      }
    case LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        user: payload,
        authError: ''
      }
    case LOGIN_FAIL:
      return {
        loggedIn: false,
        user: null,
        githubUser: null,
        authError: payload
      }
    case LOGOUT:
      return {
        loggedIn: false,
        user: null,
        githubUser: null,
        authError: ''
      }
    case GITHUB_LOGIN:
      return {
        ...state,
        githubUser: payload
      }
    default:
      return state
  }
}
