export const SuperAdminUsersHeader = [
  {
    title: 'S No.'
  },
  {
    title: 'User'
  },
  {
    title: 'Email'
  },
  {
    title: 'Last Login'
  },
  {
    title: 'Type'
  },
  {
    title: 'Status'
  },
  {
    title: 'Parent Name'
  },
  {
    title: 'Parent Email'
  },
  {
    title: 'Parent Credits'
  },
  {
    title: 'Total Credits'
  },
  {
    title: 'Credits Available'
  },
  {
    title: 'Actions'
  }
]
