import React, { useEffect, useState } from 'react'
import { AiOutlineEdit, AiOutlineProfile } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import uuid from 'react-uuid'
import { CREATE_USER_PROFILE_PAGE } from '../../../navigation/routes'
import authAction from '../../../redux/actions/authAction'
import axiosInstance from '../../../services/axios/axios'
import ClickWrapper from '../../components/click-wrapper/ClickWrapper'
import RippleLoader from '../../components/loader/RippleLoader'
import DeleteUserModal from '../../components/profile/DeleteUserModal'
import EditProfileModal from '../../components/profile/EditProfileModal'
import IconButton from '../../ui-component/buttons/IconButton'
import { ArrowTooltip } from '../../ui-component/tooltip/Tooltip'

const ProfilePage = () => {
  const [profileValue, setProfileValue] = useState({})
  const [loading, setLoading] = useState(true)
  const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false)
  const [showCreateProfileMessage, setShowCreateProfileMessage] =
    useState(false)
  const [showEditProfileModal, setShowEditProfileModal] = useState(false)
  const { username } = useParams()
  const navigate = useNavigate()
  const { githubUser } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const handleDeleteUser = async () => {
    try {
      await axiosInstance.delete(
        `https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/deletegituser`,
        {
          github_id: githubUser.username
        }
      )
      dispatch(authAction.logout())
    } catch (err) {
      console.log(err.message)
    }
  }

  const getProfileDetails = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://brkjj5ttja.execute-api.ap-south-1.amazonaws.com/dev/get?github_id=${username}`
      )

      if (data.body === 'null' || data.body === null) {
        setShowCreateProfileMessage(true)
        setLoading(false)

        return
      }
      console.log(JSON.parse(data.body))
      const response = JSON.parse(data.body)

      setProfileValue({
        ...response,
        key_skill: response.key_skill
          ?.split(',')
          .map((skill) => ({ language_name: skill, language_logo: '' }))
      })
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    if (username !== githubUser.username) {
      navigate('/')
    }
    getProfileDetails()
  }, [])

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10 w-full relative">
      {loading ? (
        <RippleLoader />
      ) : (
        <>
          {showCreateProfileMessage ? (
            <div className="w-full h-full grid place-items-center">
              <div className=" p-4 rounded-lg bg-gray-200 shadow-outer flex flex-col gap-4 items-center text-center ">
                <AiOutlineProfile className="text-7xl" />
                <h1 className="text-2xl font-bold">Thank You for visiting</h1>
                <h5>
                  It seems you haven't created your profile with us . We highly
                  recommend you to create your profile before applying for jobs.
                </h5>
                <Link
                  to={CREATE_USER_PROFILE_PAGE}
                  className=" p-2 text-sm  bg-blue-500  hover:bg-blue-400 text-white rounded-md ml-2"
                >
                  Create Profile
                </Link>
              </div>
            </div>
          ) : (
            <div className="w-full max-w-3xl m-auto flex flex-col gap-6 md:gap-10">
              <div className="flex justify-between  items-center gap-4 border-b-2 border-gray-200 pb-4">
                <h1 className="text-2xl md:text-3xl font-semibold   ">
                  {username}'s Profile
                </h1>
                <div className="flex gap-3">
                  <ArrowTooltip title="Edit your profile">
                    <button
                      className="text-xl grid place-items-center w-10 h-10 rounded-full bg-gray-200"
                      onClick={() => setShowEditProfileModal(true)}
                    >
                      <AiOutlineEdit />
                    </button>
                  </ArrowTooltip>
                  <IconButton
                    onClick={() => setShowDeleteProfileModal(true)}
                    toolTipTitle={'Delete Account'}
                    text={'Delete Account'}
                    outline
                    type="error"
                  />
                </div>
              </div>

              <div className=" bg-white p-6 shadow-outer rounded-md">
                <h3 className="text-center text-xl font-semibold mb-4 ">
                  Personal Details
                </h3>
                <div className="mb-6 grid place-items-center">
                  <img
                    className="w-20 h-20 rounded-full object-contain"
                    src={profileValue.avatar_url}
                    alt=""
                  />
                </div>

                <div className="my-3 flex gap-2 justify-center items-center flex-wrap">
                  {profileValue?.key_skill.map((skill) => (
                    <p
                      className="p-2 px-3 bg-gray-100 rounded-full text-gray-700 shadow text-xs"
                      key={uuid()}
                    >
                      {skill.language_name}
                    </p>
                  ))}
                </div>

                <div className="flex flex-col  md:grid md:grid-cols-2 gap-6 ">
                  <div className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Name</p>
                    <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 ">
                      {profileValue?.name}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Email</p>
                    <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 ">
                      {profileValue?.email_id}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Phone </p>
                    <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 ">
                      {profileValue?.phone_no}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Year Passout</p>
                    <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 ">
                      {profileValue?.year_of_passout}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Institution</p>
                    <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 ">
                      {profileValue?.college}
                    </div>
                  </div>

                  <div className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Kaggle ID</p>
                    <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 ">
                      {profileValue?.kaggle_id}
                    </div>
                  </div>
                  <div className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Hackerrank ID</p>
                    <div className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-xl placeholder:gray-200 ">
                      {profileValue?.hacker_rank_id}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}

      {showEditProfileModal && (
        <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-4xl p-4 m-auto z-50">
            <ClickWrapper func={() => {}}>
              <EditProfileModal
                refetchData={getProfileDetails}
                close={() => setShowEditProfileModal(false)}
                profileData={profileValue}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
      {showDeleteProfileModal && (
        <div className="fixed text-left left-0 right-0 top-0 h-screen w-full z-10 bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-xl p-4 m-auto z-50">
            <ClickWrapper
              func={() => {
                setShowDeleteProfileModal(false)
              }}
            >
              <DeleteUserModal
                close={() => setShowDeleteProfileModal(false)}
                handleDeleteProfile={handleDeleteUser}
              />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfilePage
