export const ShortlistTableHeader = [
  {
    title: 'No.',
    width: '10%'
  },
  {
    title: 'User',
    width: '10%'
  },

  {
    title: 'Followers',
    width: '10%'
  },

  {
    title: 'Following',
    width: '10%'
  },
  {
    title: 'Total Repositories',
    width: '10%'
  },
  {
    title: 'Tag',
    width: '10%'
  },
  {
    title: 'Location',
    width: '10%'
  },
  {
    title: 'Experience',
    width: '10%'
  },
  {
    title: 'Connect',
    width: '10%'
  },
  {
    title: 'Remove',
    width: '10%'
  }
]
