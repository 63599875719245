export const getStatusType = (status) => {
  if (
    status === 'INTERVIEW' ||
    status === 'SECOND_INTERVIEW' ||
    status === 'BACKGROUND_CHECK' ||
    status === 'REFERENCE_CHECK' ||
    status === 'ASSESSMENT' ||
    status === 'PHONE_SCREENING' ||
    status === 'SHORTLISTED' ||
    status === 'UNDER_REVIEW' ||
    status === 'ON_HOLD'
  )
    return 'warning'
  if (status === 'OFFER_DECLINED' || status === 'NOT_SELECTED') return 'error'
  if (status === 'OFFER_ACCEPTED' || status === 'JOB_OFFER') return 'success'
}
export const getAgencyStatusType = (status) => {
  if (status === 'REVIEW' || status === 'INTERVIEW' || status === 'HOLD')
    return 'warning'
  if (status === 'REJECT') return 'error'
  if (status === 'OFFER_PROCESSING') return 'success'
}

export const applicantStatus = [
  {
    label: 'All',
    value: 'ALL'
  },
  {
    label: 'New applicant',
    value: 'NEW'
  },
  {
    label: 'Under Review',
    value: 'UNDER_REVIEW'
  },
  {
    label: 'Shortlisted',
    value: 'SHORTLISTED'
  },
  {
    label: 'Phone Screening',
    value: 'PHONE_SCREENING'
  },
  {
    label: 'Assessment',
    value: 'ASSESSMENT'
  },
  {
    label: 'Interview',
    value: 'INTERVIEW'
  },
  {
    label: 'Second Interview',
    value: 'SECOND_INTERVIEW'
  },
  {
    label: 'Background Check',
    value: 'BACKGROUND_CHECK'
  },
  {
    label: 'Reference Check',
    value: 'REFERENCE_CHECK'
  },
  {
    label: 'Job Offer',
    value: 'JOB_OFFER'
  },
  {
    label: 'Offer Accepted',
    value: 'OFFER_ACCEPTED'
  },
  {
    label: 'Offer Declined',
    value: 'OFFER_DECLINED'
  },
  {
    label: 'On Hold',
    value: 'ON_HOLD'
  },
  {
    label: 'Not Selected',
    value: 'NOT_SELECTED'
  }
]

export const agencyStatus = [
  {
    label: 'All',
    value: 'ALL'
  },
  {
    label: 'Open',
    value: 'OPEN'
  },
  {
    label: 'Review',
    value: 'REVIEW'
  },
  {
    label: 'Interview',
    value: 'INTERVIEW'
  },
  {
    label: 'Offer Processing',
    value: 'OFFER_PROCESSING'
  },
  {
    label: 'Hold',
    value: 'HOLD'
  },
  {
    label: 'Reject',
    value: 'REJECT'
  }
]
