import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import uuid from 'react-uuid'
import { includeComma } from '../../../services/global/globalFunction'

const SingleLeaderboard = ({ data, limit, index, page }) => {
  const {
    avatar_url,
    city,
    country,
    github_id,
    languages_used,
    name,
    profile_score,
    experience,
    total_commits
  } = data
  const [showMoreSkill, setShowMoreSkill] = useState(false)
  return (
    <tr className="text-xs xl:text-sm cursor-pointer font-semibold font-600 relative text-gray-600   ">
      <td className="text-center">
        <p className=" p-2 px-4 ">{(page - 1) * limit + index + 1}</p>
      </td>
      <td className=" ">
        <div className="flex gap-3 items-start sm:px-3 min-w-[200px]">
          <img
            className="w-10 h-10 object-contain rounded-lg"
            src={avatar_url}
            alt={github_id}
          />
          <Link
            to={`/${github_id}`}
            className=" flex flex-col gap-1 cursor-pointer hover:text-blue-500"
          >
            <p className=" lg:text-sm font-semibold">{github_id}</p>
            <p className="  text-xs font-normal">{name}</p>
          </Link>
        </div>
      </td>

      <td className="text-center">
        {experience ? (
          <p className=" p-2 px-4 ">
            {experience} {experience > 1 ? 'years' : 'year'}
          </p>
        ) : (
          <p className=" p-2 px-4 ">-</p>
        )}
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{includeComma(total_commits)} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{profile_score} </p>
      </td>
      <td className="text-center min-w-[200px] max-w-[400px]">
        {languages_used && (
          <div className="flex gap-2  my-2 flex-wrap items-center ">
            {showMoreSkill ? (
              <>
                {languages_used.split(',').map((skills) => (
                  <p
                    key={uuid()}
                    className="bg-gray-100 shadow p-2 px-4 rounded-full text-xs text-gray-900 "
                  >
                    {' '}
                    {skills}
                  </p>
                ))}
              </>
            ) : (
              <>
                {languages_used
                  .split(',')
                  .slice(0, 6)
                  .map((skills) => (
                    <p
                      key={uuid()}
                      className="bg-gray-100 shadow p-2 px-4 rounded-full text-xs text-gray-900 "
                    >
                      {' '}
                      {skills}
                    </p>
                  ))}
              </>
            )}

            <p
              className="text-blue-500 underline underline-offset-2 ml-2 cursor-pointer "
              onClick={() => setShowMoreSkill(!showMoreSkill)}
            >
              {showMoreSkill ? ' Less...' : ' More...'}
            </p>
          </div>
        )}
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{country} </p>
      </td>
      <td className="text-center">
        <p className=" p-2 px-4 ">{city}</p>
      </td>
    </tr>
  )
}

export default SingleLeaderboard
