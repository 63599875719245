import { TextField } from '@material-ui/core'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { MdModeEdit } from 'react-icons/md'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import Select from 'react-select'
import { interviewDurations, interviewTImezone, interviewTypes } from './data'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import timezone from 'dayjs/plugin/timezone'
import RippleLoader from '../loader/RippleLoader'

const SingleSlot = ({ idx, interviewDetails, setInterviewDetails }) => {
  const [slotDetails, setSlotDetails] = useState(dayjs())

  const [showEdit, setShowEdit] = useState(false)

  const handleAddSlot = () => {
    if (interviewDetails.interviewSlots[idx]) {
      interviewDetails.interviewSlots[idx] = slotDetails
    } else {
      setInterviewDetails({
        ...interviewDetails,
        interviewSlots: [...interviewDetails.interviewSlots, slotDetails]
      })
    }
    setShowEdit(false)
  }
  if (interviewDetails.interviewSlots.length <= idx && !showEdit) {
    return (
      <button
        className="text-sm text-blue-500 max-w-max"
        onClick={() => setShowEdit(true)}
      >
        + Slot {idx + 1} details
      </button>
    )
  }
  return (
    <div className="relative overflow-auto">
      <p className="text-sm font-semibold mb-1">Slot {idx + 1}</p>
      <div className="bg-gray-100 p-3 rounded-xl  gap-2  ">
        <div className=" ">
          {showEdit ? (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                className="bg-white w-full  text-sm rounded-lg border"
                label="Select Slot Date"
                value={slotDetails.time}
                onChange={(newValue) =>
                  setSlotDetails({
                    ...slotDetails,
                    time: newValue
                  })
                }
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    sx={{ fontSize: '.8rem' }}
                    {...params}
                  />
                )}
              />
            </LocalizationProvider>
          ) : (
            <p className="p-2 text-sm bg-white border rounded-lg border-gray-300">
              {dayjs(slotDetails.time).format('LLL')}
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-end py-2">
        {showEdit ? (
          <button
            onClick={handleAddSlot}
            className="bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md text-xs"
          >
            Save Slot
          </button>
        ) : (
          <button
            onClick={() => setShowEdit(true)}
            className="bg-blue-500 absolute top-0 right-0 -translate-y-1/8 hover:bg-blue-600 text-white p-1 rounded-full  text-sm"
          >
            <MdModeEdit className="text-lg" />
          </button>
        )}
      </div>
    </div>
  )
}

const InterviewModal = ({
  close,
  candidateID,
  candidateName,
  jobID,
  agencyID,
  jobName,
  githubID
}) => {
  const [numberOfSlots, setNumberOfSlots] = useState(1)

  // dayjs.extend(utc)
  dayjs.extend(timezone)

  const [interviewDetails, setInterviewDetails] = useState({
    type: 'ONLINE',
    location: '',
    interviewSlots: [],
    duration: '45 Minutes',
    timezone: dayjs.tz.guess()
  })
  const [loading, setLoading] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const { shortlistEndpoint } = EndPoints.dashboard
  const handleSlotChange = (slot) => {
    setNumberOfSlots(slot)
    setInterviewDetails({
      ...interviewDetails,
      interviewSlots: interviewDetails.interviewSlots.splice(0, slot)
    })
  }
  const handleScheduleInterview = async () => {
    const postData = {
      candidate_id: candidateID,
      candidate_name: candidateName,
      job_id: jobID,
      organisation: user.name,
      company_id: user.user_id,
      job_name: jobName,
      github_id: githubID,
      agency_id: agencyID,
      time_zone: interviewDetails.timezone,
      type: interviewDetails.type,
      location: interviewDetails.location,
      slot_time: parseInt(interviewDetails.duration.split(' ')[0]),
      status: 'REQUESTED',
      ...interviewDetails.interviewSlots.reduce(
        (obj, slot, idx) => ({
          ...obj,
          [`interview${idx + 1}`]: dayjs(slot.time).format()
        }),
        {}
      )
    }
    console.log(postData)
    setLoading(true)
    try {
      await axiosInstance.post(
        `${shortlistEndpoint}/Dev/interviewDetails/post`,
        postData
      )
      showToast('success', 'Interview Request Sent Successfully')
      setLoading(false)
      close()
    } catch (err) {
      console.log(err.message)
    }
  }

  return (
    <div className="w-full p-4 md:p-10 py-10 max-h-[80vh] bg-white rounded-2xl shadow-lg m-auto  relative flex flex-col gap-6">
      {loading && (
        <div className="grid place-items-center absolute inset-0 z-20 bg-white/30">
          <RippleLoader />
        </div>
      )}
      <button
        onClick={close}
        className="absolute top-6 right-6 text-3xl font-bold text-blue-500"
      >
        <IoMdCloseCircleOutline />
      </button>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col ">
          <h3 className="text-lg font-semibold">Select Number of Slots</h3>
          <p className="text-xs text-gray-400 flex items-center gap-1 ">
            <AiOutlineInfoCircle /> Number of slots given as option for the
            candidate to pick{' '}
          </p>
        </div>

        <div className="flex justify-between items-center gap-6">
          {[1, 2, 3].map((item) => (
            <div
              key={item}
              onClick={() => handleSlotChange(item)}
              className={` px-4 w-full text-center rounded-md p-2 border-2 font-semibold border-gray-600 hover:text-white hover:bg-gray-700 cursor-pointer ${
                numberOfSlots === item
                  ? 'bg-gray-600 text-white'
                  : 'bg-white text-gray-700 '
              } `}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="flex gap-4 flex-col overflow-y-scroll scrollbar-hidden">
        <div className="flex flex-col ">
          <h3 className="text-lg font-semibold">Slot Details</h3>
          <p className="text-xs text-gray-400 flex items-center gap-1 ">
            <AiOutlineInfoCircle /> Enter details for each slot
          </p>
          <div className="flex flex-col  gap-4 mt-3 w-full max-w-xl">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 w-full items-center">
              <label className="flex flex-col gap-1  w-full">
                <p className="font-semibold text-sm"> Type</p>
                <Select
                  className="basic-single  bg-transparent text-sm"
                  onChange={(val) =>
                    setInterviewDetails({
                      ...interviewDetails,
                      type: val.value
                    })
                  }
                  value={{
                    value: interviewDetails.type,
                    label: interviewDetails.type.toLowerCase()
                  }}
                  classNamePrefix="Type"
                  styles={reactSelectStyleUtils}
                  name="type"
                  placeholder="Select Interview Mode"
                  options={interviewTypes.map((type) => ({
                    value: type,
                    label: type.toLowerCase()
                  }))}
                />
              </label>
              <label className="flex flex-col gap-1  w-full">
                <p className="font-semibold text-sm"> Duration</p>
                <Select
                  className="basic-single  bg-transparent text-sm"
                  onChange={(val) =>
                    setInterviewDetails({
                      ...interviewDetails,
                      duration: val.value
                    })
                  }
                  value={{
                    value: interviewDetails.duration,
                    label: interviewDetails.duration
                  }}
                  classNamePrefix="Duration"
                  styles={reactSelectStyleUtils}
                  name="type"
                  placeholder="Select Interview Duration"
                  options={interviewDurations.map((duration) => ({
                    value: duration,
                    label: duration
                  }))}
                />
              </label>
              <label className="flex flex-col gap-1  w-full">
                <p className="font-semibold text-sm"> Timezone</p>
                <Select
                  className="basic-single  bg-transparent text-sm"
                  onChange={(val) =>
                    setInterviewDetails({
                      ...interviewDetails,
                      timezone: val.value
                    })
                  }
                  value={{
                    value: interviewDetails.timezone,
                    label: interviewDetails.timezone
                  }}
                  classNamePrefix="Timexone"
                  styles={reactSelectStyleUtils}
                  name="type"
                  placeholder="Select Timezone"
                  options={interviewTImezone.map((timezone) => ({
                    value: timezone,
                    label: timezone
                  }))}
                />
              </label>
            </div>

            <label className="w-full flex flex-col gap-1">
              <p className="font-semibold text-sm"> Interview Details</p>
              <textarea
                className="resize-none p-2 h-20 rounded-lg border grid place-items-center border-gray-300 w-full outline-none "
                value={interviewDetails.location}
                placeholder={
                  interviewDetails.type === 'OFFLINE'
                    ? 'Enter Venue Details'
                    : 'Enter Meeting Link'
                }
                onChange={(e) =>
                  setInterviewDetails({
                    ...interviewDetails,
                    location: e.target.value
                  })
                }
              />
            </label>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2  gap-4  ">
          {Array(numberOfSlots)
            .fill('#')
            .map((slot, idx) => (
              <SingleSlot
                key={idx}
                idx={idx}
                slotArr={Array(numberOfSlots).fill('#')}
                interviewDetails={interviewDetails}
                setInterviewDetails={setInterviewDetails}
              />
            ))}
        </div>
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleScheduleInterview}
          disabled={numberOfSlots !== interviewDetails.interviewSlots.length}
          className="bg-blue-500 hover:bg-blue-600 text-white disabled:bg-gray-200 disabled:text-gray-500 p-3 py-2 rounded-md text-sm"
        >
          Schedule Interview
        </button>
      </div>
    </div>
  )
}

export default InterviewModal
