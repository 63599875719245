import React from 'react'
import { useState } from 'react'
import ApplicantProjectListingWrapper from './ApplicantProjectListingWrapper'

const ApplicantProjectListing = ({ allProjectData, fetchAllProjects }) => {
  const [tab, setTab] = useState(1)
  return (
    <div className="">
      <div className="flex w-full max-w-7xl mx-auto gap-6 items-center text xs  sm:text-sm mb-10 ">
        <button
          className={` p-3 grid w-40 place-items-center ${
            tab === 1 &&
            'bg-blue-50 shadow-md  rounded-full border  font-semibold  '
          } `}
          onClick={() => setTab(1)}
        >
          Open Project
        </button>
        <button
          className={` p-3 grid w-40 place-items-center ${
            tab === 2 &&
            'bg-orange-50  shadow-md rounded-full border font-semibold  '
          }  `}
          onClick={() => setTab(2)}
        >
          Closed Project
        </button>
      </div>
      {tab === 1 && (
        <ApplicantProjectListingWrapper
          allProjectData={allProjectData.filter((job) => job.status === 'OPEN')}
          fetchAllProjects={fetchAllProjects}
        />
      )}
      {tab === 2 && (
        <ApplicantProjectListingWrapper
          allProjectData={allProjectData.filter(
            (job) => job.status === 'CLOSE'
          )}
          fetchAllProjects={fetchAllProjects}
        />
      )}
    </div>
  )
}

export default ApplicantProjectListing
