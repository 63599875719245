import React, { useEffect, useState } from 'react'
import { MdAdd, MdClose } from 'react-icons/md'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import { reactSelectStyleUtils } from '../../../../services/components/react-select'
import { showToast } from '../../../../services/global/Toaster'
import IconButton from '../../../ui-component/buttons/IconButton'
import SubmitButton from '../../auth/SubmitButton'
import NumberInput from '../../create-profile/num-input/NumberInput'
import RippleLoader from '../../loader/RippleLoader'
import SinglePartnerComponent from './SinglePartnerComponent'

const PartnerComponent = () => {
  const [allAgencies, setAllAgencies] = useState([])
  const [showAddPartner, setShowAddPartner] = useState(false)
  const [partnerValue, setPartnerValue] = useState({
    agency_name: '',
    percentage: 0,
    agency_id: ''
  })
  const { user } = useSelector((state) => state.auth)
  const [pendingPartner, setPendingPartner] = useState([])
  const [activePartner, setActivePartner] = useState([])
  const [loading, setLoading] = useState(true)
  const { shortlistEndpoint } = EndPoints.dashboard

  const fetchAllAgencies = async () => {
    try {
      const { data } = await axiosInstance.get(
        `https://p3a2rsvtce.execute-api.ap-south-1.amazonaws.com/Stage/admin/getusers?type=AGENCY`
      )
      const res = JSON.parse(data.body)
      if (res) {
        setAllAgencies(JSON.parse(data.body))
      }
    } catch (err) {
      console.log(err.message)
    }
  }
  const fetchAllPartner = async () => {
    setLoading(true)
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Stage/partnerAgency/${
          user.type === 'AGENCY' ? 'agency' : 'company'
        }/get?${user.type === 'AGENCY' ? 'agency_id' : 'company_id'}=${
          user.user_id
        }`
      )
      if (data.message) {
        const partners = data.message
        setPendingPartner(
          partners.filter((partner) => partner.status === 'REQUESTED')
        )
        setActivePartner(
          partners.filter((partner) => partner.status === 'APPROVED')
        )
      }
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    } finally {
      setLoading(false)
    }
  }

  const handleAddPartner = async (e) => {
    e.preventDefault()
    const postData = {
      ...partnerValue,
      company_id: user?.user_id,
      company_name: user?.name,
      agreement: '',
      status: 'REQUESTED'
    }

    console.log(postData)
    try {
      const { data } = await axiosInstance.post(
        `${shortlistEndpoint}/Stage/partnerAgency/post `,
        postData
      )
      console.log(data)
      setShowAddPartner(false)
      showToast('success', 'Partnership Requested')

      fetchAllPartner()
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchAllPartner()
    fetchAllAgencies()
  }, [])

  return (
    <div>
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="flex flex-col-reverse md:flex-row md:px-6 justify-between gap-8 w-full px-2">
          <div className="flex flex-col gap-8 flex-1 max-w-md">
            <div className="flex flex-col rounded-lg shadow overflow-hidden">
              <div className="p-3 bg-yellow-100">
                <h3 className="text-sm">Pending</h3>
              </div>
              {pendingPartner.map((partner, idx) => (
                <SinglePartnerComponent
                  key={idx}
                  data={partner}
                  type={'pending'}
                  fetchAll={fetchAllPartner}
                />
              ))}
            </div>
            <div className="flex flex-col rounded-lg shadow overflow-hidden">
              <div className="p-3 bg-green-100">
                <h3 className="text-sm">Active</h3>
              </div>
              {activePartner.map((partner, idx) => (
                <SinglePartnerComponent
                  key={idx}
                  data={partner}
                  type={'active'}
                  fetchAll={fetchAllPartner}
                />
              ))}
            </div>
          </div>
          <div className="flex flex-col items-end gap-6">
            <div>
              {user?.type !== 'AGENCY' && (
                <IconButton
                  toolTipTitle={`Add Partner`}
                  text={'Add Partner'}
                  onClick={() => setShowAddPartner(true)}
                  icon={<MdAdd />}
                  type="primary"
                />
              )}
            </div>

            {showAddPartner && (
              <div className="rounded-lg shadow flex flex-col w-full sm:w-80 ">
                <div className="relative p-3 border-b">
                  <h3 className="text-lg text-center ">Add Partner</h3>
                  <MdClose
                    onClick={() => setShowAddPartner(false)}
                    className="absolute cursor-pointer text-xl right-2 top-1/2 -translate-y-1/2"
                  />
                </div>
                <form
                  onSubmit={handleAddPartner}
                  className="p-3 flex flex-col gap-4 "
                >
                  <label className="flex flex-col gap-2 text-xs md:text-sm ">
                    <p className=" font-semibold  ">Select Partner</p>
                    <Select
                      className="basic-single  bg-transparent text-sm"
                      onChange={(val) =>
                        setPartnerValue({
                          ...partnerValue,
                          agency_id: val.value,
                          agency_name: val.label
                        })
                      }
                      value={{
                        value: partnerValue.agency_id,
                        label: partnerValue.agency_name
                      }}
                      classNamePrefix="Partner"
                      defaultValue={
                        allAgencies.map((agency) => ({
                          value: agency.user_id,
                          label: agency.name
                        }))[0]
                      }
                      isClearable={true}
                      isSearchable={true}
                      styles={reactSelectStyleUtils}
                      name="partner"
                      placeholder="Select Partner"
                      options={allAgencies.map((agency) => ({
                        value: agency.user_id,
                        label: agency.name
                      }))}
                    />
                  </label>
                  <NumberInput
                    value={partnerValue.percentage}
                    setValue={(val) =>
                      setPartnerValue({
                        ...partnerValue,
                        percentage: val
                      })
                    }
                    label={'Agreement Percentage'}
                    placeholder={'Agreement Percentage'}
                    min={0}
                  />
                  <SubmitButton text={'Add Partner'} />
                </form>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default PartnerComponent
