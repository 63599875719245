import React, { Fragment, useState } from 'react'
import { MdClose } from 'react-icons/md'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import SubmitButton from '../auth/SubmitButton'
import NumberInput from '../create-profile/num-input/NumberInput'
import TextInput from '../create-profile/text-input/TextInput'
import RippleLoader from '../loader/RippleLoader'

const EditUserModal = ({ refetchData, close, id, userData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [profileValue, setProfileValue] = useState({
    credits_available: userData.credits_available
      ? userData.credits_available
      : '',
    user_id: userData.user_id,
    email: userData.email ? userData.email : '',
    github_id: userData.github_id ? userData.github_id : '',
    github_token: userData.github_token ? userData.github_token : '',
    linked_to: userData.linked_to ? userData.linked_to : '',
    name: userData.name ? userData.name : '',
    sonar_cloud_token: userData.sonar_cloud_token
      ? userData.sonar_cloud_token
      : '',
    sonar_user_key: userData.sonar_user_key ? userData.sonar_user_key : '',
    status: userData.status ? userData.status : '',
    total_credit: userData.total_credit ? userData.total_credit : '',
    type: userData.type ? userData.type : ''
  })

  const handleEditUser = async (e) => {
    e.preventDefault()
    setIsLoading(true)

    const putData = {
      ...profileValue,
      create_time: userData.create_time,
      update_time: userData.update_time
    }
    console.log(putData)
    try {
      const { data } = await axiosInstance.put(
        ' https://to5ycnuhg9.execute-api.ap-south-1.amazonaws.com/Dev/updateusersadmin',
        putData
      )
      showToast('success', JSON.parse(data.message).message)

      await refetchData()
      setIsLoading(false)
      close()
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }
  return (
    <div className="p-3 sm:p-6 md:p-10 w-full  m-auto h-[95vh] bg-white overflow-y-scroll relative ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-xl font-bold"
      >
        <MdClose />
      </button>
      <Fragment>
        {isLoading && (
          <div className="bg-opacity-20 bg-white fixed inset-0 grid place-items-center">
            <RippleLoader />
          </div>
        )}

        <form onSubmit={handleEditUser}>
          <div className="w-full max-w-3xl m-auto flex flex-col gap-6 md:gap-10">
            <div className="flex flex-col gap-4">
              <h1 className="text-2xl md:text-3xl font-semibold  border-b-2 border-gray-200 pb-4 ">
                Edit User
              </h1>
            </div>

            <div className="flex flex-col  sm:grid sm:grid-cols-2 gap-6  ">
              <TextInput
                value={profileValue.name}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, name: val })
                }
                label={'Name'}
                placeholder={'Enter User Name'}
              />
              <TextInput
                value={profileValue.email}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, email: val.trim() })
                }
                label={'Email'}
                placeholder={'Enter User Email'}
              />
              <TextInput
                value={profileValue.github_id}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, github_id: val.trim() })
                }
                label={'Github ID'}
                placeholder={'Enter Github ID '}
              />
              <TextInput
                value={profileValue.github_token}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, github_token: val.trim() })
                }
                label={'Github Token'}
                placeholder={'Enter Github Token '}
              />
              <NumberInput
                value={profileValue.linked_to}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, linked_to: val })
                }
                label={'Linked To'}
                placeholder={'Enter Links'}
              />
              <TextInput
                value={profileValue.sonar_cloud_token}
                setValue={(val) =>
                  setProfileValue({
                    ...profileValue,
                    sonar_cloud_token: val.trim()
                  })
                }
                label={'Sonar Cloud Token'}
                placeholder={'Enter Sonar Cloud Token'}
              />
              <TextInput
                value={profileValue.sonar_user_key}
                setValue={(val) =>
                  setProfileValue({
                    ...profileValue,
                    sonar_user_key: val.trim()
                  })
                }
                label={'Sonar User Key'}
                placeholder={'Enter Sonar User key'}
              />
              <NumberInput
                value={profileValue.total_credit}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, total_credit: val })
                }
                label={'Total Credits'}
                placeholder={'Enter Total Credits'}
              />
              <NumberInput
                value={profileValue.credits_available}
                setValue={(val) =>
                  setProfileValue({ ...profileValue, credits_available: val })
                }
                label={'Credits Available'}
                placeholder={'Enter Credits Available'}
              />

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">Status</p>
                <select
                  value={profileValue.status}
                  onChange={(e) =>
                    setProfileValue({
                      ...profileValue,
                      status: e.target.value
                    })
                  }
                  className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'NEW'}>
                    New
                  </option>
                  <option className="" value={'ADDED'}>
                    ADDED
                  </option>
                  <option className="" value={'REQUESTED'}>
                    REQUESTED
                  </option>
                </select>
              </label>

              <label className="flex flex-col gap-2 text-xs md:text-sm ">
                <p className=" font-semibold  ">User Type</p>
                <select
                  value={profileValue.type}
                  onChange={(e) =>
                    setProfileValue({
                      ...profileValue,
                      type: e.target.value
                    })
                  }
                  className="bg-white border-2  outline-none border-gray-500 border-opacity-20 p-3 rounded-md placeholder:gray-200 "
                >
                  <option className="p-4" value={''}>
                    None
                  </option>
                  <option className="p-4" value={'AGENCY'}>
                    AGENCY
                  </option>
                  <option className="" value={'COMPANY'}>
                    COMPANY
                  </option>
                  <option className="" value={'ADMIN'}>
                    ADMIN
                  </option>
                </select>
              </label>
            </div>

            <div className=" md:w-1/2  flex flex-col justify-between gap-3">
              <SubmitButton text={'Update'} />
            </div>
          </div>
        </form>
      </Fragment>
    </div>
  )
}

export default EditUserModal
