import React from 'react'
import { useState } from 'react'
import { useAlert } from 'react-alert'
import { MdClose } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import authAction from '../../../redux/actions/authAction'
import { showToast } from '../../../services/global/Toaster'
import EmailField from '../auth/EmailField'
import PasswordField from '../auth/PasswordField'
import SubmitButton from '../auth/SubmitButton'
import TextInput from '../auth/TextInput'
import RippleLoader from '../loader/RippleLoader'

const CreateUserModal = ({ toggleTab, closeTab, fetchCreatedUsers }) => {
  const dispatch = useDispatch()
  const [tnc, setTnc] = useState(false)
  const [isEmailAvailable, setIsEmailAvailable] = useState(true)
  const alert = useAlert()
  const { user } = useSelector((state) => state.auth)
  const [signUpDetails, setSignUpDetails] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
    profile_image: ''
  })
  const [signUpErrors, setSignupErrors] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstname: ''
  })

  const [loading, setLoading] = useState(false)

  const handleSignUp = async (e) => {
    e.preventDefault()
    const { email, password, firstname, confirmPassword } = signUpDetails
    if (email.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        email: 'Enter a valid Email'
      })
      return
    } else if (password.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        password: 'Enter a valid password'
      })
      return
    } else if (password !== confirmPassword) {
      setSignupErrors({
        ...signUpErrors,
        confirmPassword: "Password doesn't match"
      })
      return
    } else if (firstname.length === 0) {
      setSignupErrors({
        ...signUpErrors,
        firstname: 'Enter a valid Name'
      })
      return
    }

    console.log(signUpDetails)
    console.log(isEmailAvailable)
    console.log(signUpErrors)
    await dispatch(
      authAction.register(
        signUpDetails,
        setLoading,
        closeTab,
        user.user_id,
        true,
        notifySignup
      )
    )
    fetchCreatedUsers()
    closeTab()
  }

  const notifySignup = (text) => {
    showToast('success', text)
  }

  return (
    <div className="flex flex-col items-center  w-full h-full p-4 md:p-10 rounded-lg relative bg-white  ">
      {loading && (
        <div className="absolute grid place-items-center w-full h-full top-0 left-0 z-10 bg-white bg-opacity-20 backdrop-filter blur-sm">
          <RippleLoader />
        </div>
      )}

      <button
        onClick={closeTab}
        className="absolute top-6 right-6 text-xl font-bold"
      >
        <MdClose />
      </button>
      <div className="w-full flex flex-col px-2">
        <h1 className="text-lg md:text-2xl mb-2 font-semibold text-blue-500">
          Add Users
        </h1>
        <p className="text-sm font-500 text-gray-700">
          You can add users under your organization for better flexibility in
          recruitment process .
        </p>
      </div>
      <form
        onSubmit={handleSignUp}
        className="w-full max-w-xl m-auto flex flex-col gap-4 my-4 p-2 "
      >
        <div className="w-full flex flex-col">
          <div className="flex gap-4 items-center">
            <TextInput
              value={signUpDetails.firstname}
              errorMessage={
                signUpDetails.firstname.length === 0 && signUpErrors.firstname
              }
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  firstname: e.target.value
                })
              }
              labelText={'First Name'}
              placeholder={'John'}
            />
            <TextInput
              value={signUpDetails.lastname}
              errorMessage={signUpDetails.lastname.length === 0}
              onChange={(e) =>
                setSignUpDetails({
                  ...signUpDetails,
                  lastname: e.target.value
                })
              }
              labelText={'Last Name'}
              placeholder={'Doe'}
            />
          </div>{' '}
          <EmailField
            value={signUpDetails.email}
            checkEmail={true}
            errorMessage={
              signUpDetails.email.length === 0 && signUpErrors.email
            }
            onChange={(e) =>
              setSignUpDetails({
                ...signUpDetails,
                email: e.target.value
              })
            }
            setEmailValidity={setIsEmailAvailable}
          />
          <PasswordField
            labelText={'Set Password'}
            value={signUpDetails.password}
            errorMessage={
              signUpDetails.password.length === 0 && signUpErrors.password
            }
            onChange={(e) =>
              setSignUpDetails({
                ...signUpDetails,
                password: e.target.value
              })
            }
          />
          <PasswordField
            labelText={'Confirm Password'}
            value={signUpDetails.confirmPassword}
            errorMessage={
              (signUpDetails.confirmPassword === 0 ||
                signUpDetails.password !== signUpDetails.confirmPassword) &&
              signUpErrors.confirmPassword
            }
            onChange={(e) =>
              setSignUpDetails({
                ...signUpDetails,
                confirmPassword: e.target.value
              })
            }
          />
          <div className="flex justify-between items-center">
            <label className="flex items-center font-600 my-3 cursor-pointer text-xs z-0">
              <input
                value={tnc}
                onChange={(e) => setTnc(e.target.checked)}
                className="mr-2 cursor-pointer"
                type="checkbox"
              />

              <p>
                I agree with{' '}
                <Link to={'#'} className="text-blue-500">
                  Terms and Conditions
                </Link>
              </p>
            </label>
          </div>
          {isEmailAvailable && (
            <SubmitButton disabled={!tnc} text={'Add User'} />
          )}
        </div>
      </form>
    </div>
  )
}

export default CreateUserModal
