import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { FiSearch } from 'react-icons/fi'
import uuid from 'react-uuid'
import SingleContributors from './SingleContributors'

const Contributors = ({ data, commitFetching }) => {
  const [filteredContributorData, setFilteredContributorData] = useState(data)
  const [contributorText, setContributorText] = useState('')

  const filterChange = (text) => {
    setFilteredContributorData(
      data.filter(
        (user) =>
          user.user_name.toUpperCase().includes(text.toUpperCase()) ||
          user.github_id.toUpperCase().includes(text.toUpperCase())
      )
    )
  }

  const onTextChange = (e) => {
    setContributorText(e.target.value)
    filterChange(e.target.value)
  }

  return (
    <div className="flex flex-col gap-6 md:gap-10 mt-10">
      {commitFetching && <p>Fetching commit data ...</p>}
      <form className="flex items-center border border-gray-500 border-opacity-40 rounded-md px-3 w-full max-w-xs ">
        <FiSearch />
        <input
          value={contributorText}
          onChange={onTextChange}
          type="text"
          placeholder="Find Contributors"
          className="bg-transparent placeholder-gray-400 p-3 text-sm  border-none outline-none "
        />
      </form>
      <div className="grid grid-cols-1 sm:grid-cols-2  gap-4 ">
        {filteredContributorData.map((singleContributors) => (
          <SingleContributors key={uuid()} data={singleContributors} />
        ))}
      </div>
    </div>
  )
}

export default Contributors
