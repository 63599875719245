import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import ProfileEditComponent from '../../components/recruiter-profile/profile/ProfileEditComponent'
import CreditHistory from '../../components/recruiter-profile/credit/CreditHistory'
import TransactionHistory from '../../components/recruiter-profile/transaction/TransactionHistory'
import PartnerComponent from '../../components/recruiter-profile/partner/PartnerComponent'
import CreditComponent from '../../components/recruiter-profile/credit/CreditComponent'
import { MdAttachMoney, MdPeopleOutline } from 'react-icons/md'
import { AiOutlineCreditCard } from 'react-icons/ai'
import { BsPerson } from 'react-icons/bs'
import { useMediaQuery, useTheme } from '@material-ui/core'

const RecruiterProfilePage = () => {
  const theme = useTheme()
  const largeView = useMediaQuery(theme.breakpoints.up('md'))

  const [tab, setTab] = useState(0)
  const { user } = useSelector((state) => state.auth)
  const { userCredits } = useSelector((state) => state.credits)

  const getUserTypeAbbr = (userType) => {
    switch (userType) {
      case 'COMPANY':
        return 'Company'
      case 'ADMIN':
        return 'Admin'
      case 'SUPER_ADMIN':
        return 'Super Admin'
      case 'AGENCY':
        return 'Agency'
      default:
        return ''
    }
  }

  const profileNav = [
    {
      title: 'Your Profile',
      component: <ProfileEditComponent />,
      icon: <BsPerson />
    },
    {
      title: 'Credit History',
      component: <CreditHistory />,
      icon: <AiOutlineCreditCard />
    },
    {
      title: 'Transaction History',
      component: <TransactionHistory />,
      icon: <MdAttachMoney />
    },
    {
      title: 'Partners',
      component: <PartnerComponent />,
      icon: <MdPeopleOutline />
    }
  ]

  return (
    <div className="min-h-screen flex  sm:p-6 md:p-10 ">
      <div className="w-full max-w-7xl mx-auto flex flex-col h-full    ">
        <div className=" p-3 flex flex-col-reverse gap-4 md:flex-row justify-between md:gap-10 md:items-center ">
          <CreditComponent creditData={userCredits} />
          <div className="text-right">
            <h1 className=" text-xl md:text-2xl font-semibold text-gray-500 ">
              {user.name ?? ''}
            </h1>
            <h3 className="text-sm text-gray-500">
              {getUserTypeAbbr(user.type)}
            </h3>
          </div>
        </div>
        <div className="flex  flex-1 gap-4 md:gap-10  ">
          <div
            className={`flex flex-col ${
              largeView && 'min-w-[200px] lg:min-w-[250px] '
            } text-sm `}
          >
            {profileNav.map((nav, idx) => (
              <div
                key={idx}
                onClick={() => setTab(idx)}
                className={`${
                  tab === idx
                    ? 'text-blue-400 bg-gray-400 bg-opacity-10 font-semibold '
                    : ''
                } w-full cursor-pointer flex gap-2 items-center  hover:bg-gray-400  hover:bg-opacity-10 hover:font-semibold   font-600  transition-all duration-300 ease-in-out p-5 py-2   rounded-lg `}
              >
                {nav.icon}
                {largeView && nav.title}
              </div>
            ))}
          </div>

          <div className=" flex-1 ">
            <div className="text-2xl border-b font-semibold py-3 ">
              {profileNav[tab].title}
            </div>
            <div className="py-8">{profileNav[tab].component}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RecruiterProfilePage
