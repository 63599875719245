import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ProjectListing from '../../components/job-listing/ProjectListing'
import RippleLoader from '../../components/loader/RippleLoader'

const ProjectListingPage = () => {
  const [loading, setLoading] = useState(true)
  const [allProjectData, setAllProjectData] = useState([])
  const { getJobListing } = EndPoints.jobListing
  const { user } = useSelector((state) => state.auth)

  const fetchAllProjects = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${getJobListing}/getrecruiterposts?user_id=${user.user_id}&type=PROJECT`
      )
      console.log(JSON.parse(data.body).message)
      const projectData = JSON.parse(data.body).message
      if (projectData) {
        setAllProjectData(projectData)
      }

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchAllProjects()
  }, [])
  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10 relative ">
      {loading ? (
        <RippleLoader />
      ) : (
        <ProjectListing
          allProjectData={allProjectData}
          fetchAllProjects={fetchAllProjects}
        />
      )}
    </div>
  )
}

export default ProjectListingPage
