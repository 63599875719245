import React from 'react'
import ReactApexChart from 'react-apexcharts'

const CommitChart = ({ data: commitData, commitFetching }) => {
  const getSum = (arr, property) => {
    let sum = 0
    arr.forEach((data) => (sum += data[property]))
    return sum
  }
  var optimalColumnWidthPercent =
    20 + 60 / (1 + 30 * Math.exp(-Object.values(commitData).length / 3))
  const series = [
    {
      name: 'Number of Commits',
      data: [...commitData].reverse().map((data) => data.total)
    }
  ]

  const options = {
    chart: {
      type: 'bar',
      foreColor: '#000000',
      toolbar: {
        show: false
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        gradientToColors: ['#F37726', '#3E8FF5'],
        shadeIntensity: 0.5,
        type: 'vertical',
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0.5,
        stops: [0, 100, 100, 100]
      }
    },
    plotOptions: {
      bar: {
        columnWidth: optimalColumnWidthPercent,
        borderRadius: 10,
        dataLabels: {
          position: 'top'
        }
      }
    },
    dataLabels: {
      enabled: true,
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ['#333']
      }
    },
    stroke: {
      curve: 'smooth'
    },
    grid: {
      show: false
    },

    xaxis: {
      categories: [...commitData]
        .reverse()
        .map(
          (data) =>
            data.month.split(' ')[0].slice(0, 3) +
            ' ' +
            data.month.split(' ')[1]
        ),
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      crosshairs: {
        fill: {
          type: 'gradient',
          gradient: {
            colorFrom: '#D8E3F0',
            colorTo: '#BED1E6',
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5
          }
        }
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: true,
        formatter: (value) => {
          return Math.ceil(value) + '%'
        }
      }
    },
    tooltip: {
      // x: {
      // 	format: 'dd/MM/yy HH:mm',
      // },
      theme: 'dark'
    }
  }

  return (
    <div className="p-4 sm:p-6">
      <div className="p-4 sm:p-6 bg-white shadow-custom rounded-xl ">
        <div className="flex justify-between items-center">
          <div className="flex gap-4 items-center">
            <h3 className="font-semibold">User Commits : </h3>
            <p className="text-xl font-bold">{getSum(commitData, 'total')}</p>
          </div>

          {commitFetching && <p>Fetching commit data ...</p>}
        </div>

        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={250}
        />
      </div>
    </div>
  )
}

export default CommitChart
