import { CREDITS_LOADING, UPDATE_CREDITS } from '../actions/actionTypes'

export const creditState = {
  JOB_CREATE: {
    id: 1,
    category: 'CREDIT_SYSTEM',
    type: 'JOB_CREDITS',
    userid: null,
    parameter: 'JOB_CREATE',
    value: 1
  },
  APPLICATION_PROCESS: {
    id: 2,
    category: 'CREDIT_SYSTEM',
    type: 'ALL_CREDITS',
    userid: null,
    parameter: 'APPLICATION_PROCESS',
    value: 100
  },
  SEARCH_CANDIDATE: {
    id: 3,
    category: 'CREDIT_SYSTEM',
    type: 'ALL_CREDITS',
    userid: null,
    parameter: 'SEARCH_CANDIDATE',
    value: 10
  },
  PROFILE_GENERATE: {
    id: 4,
    category: 'CREDIT_SYSTEM',
    type: 'ALL_CREDITS',
    userid: null,
    parameter: 'PROFILE_GENERATE',
    value: 10
  },
  MESSAGE: {
    id: 5,
    category: 'CREDIT_SYSTEM',
    type: 'ALL_CREDITS',
    userid: null,
    parameter: 'MESSAGE',
    value: 100
  },
  CREATE_ASSIGNMENT: {
    id: 6,
    category: 'CREDIT_SYSTEM',
    type: 'ALL_CREDITS',
    userid: null,
    parameter: 'CREATE_ASSIGNMENT',
    value: 200
  }
}

export const userCreditsState = {
  credits_available: 0,
  job_credits: 0,
  total_credit: 0
}

const initialState = {
  userCredits: userCreditsState,
  loading: true,
  creditValues: creditState
}

export const creditReducer = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case UPDATE_CREDITS:
      return {
        ...state,
        userCredits: payload.userCredits,
        creditValues: payload.creditValues
      }
    case CREDITS_LOADING:
      return {
        ...state,
        loading: payload.loading
      }
    default:
      return state
  }
}
