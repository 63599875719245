import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import creditAction from '../../../redux/actions/creditAction'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import ErrorComponent from '../../components/error/ErrorComponent'
import RippleLoader from '../../components/loader/RippleLoader'
import JobApplicantsTable from '../../components/single-job-listing/JobApplicantsTable'
import { applicantStatus } from '../../components/single-job-listing/data'

const SingleJobPage = () => {
  const { jobId } = useParams()
  const [loading, setLoading] = useState(true)
  const [singleJobData, setSingleJobData] = useState([])
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(10)
  const [totalPage, setTotalPage] = useState(0)
  const [status, setStatus] = useState(applicantStatus[0])
  const [search, setSearch] = useState('')
  const [isProcessingJob, setIsProcessingJob] = useState(false)
  const [loadingSearch, setLoadingSearch] = useState(false)
  const { getJobListing } = EndPoints.jobListing
  const { checkJobExist } = EndPoints.createProfile
  const { user } = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  const getJobStatus = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${checkJobExist}/getjobstatus?job_id=${jobId}`
      )
      const res = JSON.parse(data.body)
      if (res?.status.toLowerCase() === 'open_unpublished') {
        setIsProcessingJob(true)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchSingleJobApplicants = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${getJobListing}/getjobapplicants?job_id=${jobId}&page=${page}&limit=${limit}&status=${status.value}&search=${search}`
      )

      const parsedData = JSON.parse(data.body)

      setSingleJobData(parsedData.message)
      setTotalPage(parsedData.total_pages)

      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const applySearchFilter = async () => {
    setLoadingSearch(true)
    setPage(1)
    fetchSingleJobApplicants()
  }

  useEffect(() => {
    getJobStatus()
  }, [])

  useEffect(() => {
    fetchSingleJobApplicants()
  }, [page, limit, status])

  useEffect(() => {
    if (search.length > 0) {
      setLoadingSearch(true)
    } else setLoadingSearch(false)

    const jobSearchTimeout = setTimeout(() => {
      applySearchFilter()
    }, 2000)
    return () => {
      clearTimeout(jobSearchTimeout)
    }
  }, [search])

  return (
    <div className="min-h-screen p-4 sm:p-6 md:p-10">
      {loading ? (
        <RippleLoader />
      ) : (
        <div>
          <ErrorBoundary FallbackComponent={ErrorComponent}>
            <JobApplicantsTable
              data={singleJobData}
              fetchAllApplicants={fetchSingleJobApplicants}
              fetchCreditData={() =>
                dispatch(creditAction.fetchUserCredits(user.user_id))
              }
              jobId={jobId}
              isProcessingJob={isProcessingJob}
              page={page}
              limit={limit}
              totalPage={totalPage}
              status={status}
              search={search}
              setStatus={setStatus}
              setSearch={setSearch}
              setPage={setPage}
              applicantStatus={applicantStatus}
            />
          </ErrorBoundary>
        </div>
      )}
    </div>
  )
}

export default SingleJobPage
