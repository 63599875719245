import React from 'react'
import { AiOutlineGithub } from 'react-icons/ai'

const Loader = () => {
  return (
    <div className="flex flex-col justify-center items-center w-full h-screen overflow-hidden ">
      <div className="mb-6 sm:mb-10 text-7xl grid place-items-center ">
        <AiOutlineGithub />
      </div>

      <div className="scroller w-full h-full mt-6 sm:mt-10 max-h-40 text-2xl sm:text-4xl   ">
        <div className="scroller__div flex flex-col">
          <div className="w-full h-40 p-10 text-center">Fetching User...</div>
          <div className="w-full h-40 p-10 text-center">
            {' '}
            Fetching Repository...
          </div>
          <div className="w-full h-40 p-10 text-center">
            Fetching Commits...
          </div>
          <div className="w-full h-40 p-10 text-center">
            Fetching Languages...
          </div>
        </div>
      </div>
    </div>
  )
}

export default Loader
