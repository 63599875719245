import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import RippleLoader from '../loader/RippleLoader'
import SingleNotification from './SingleNotification'

const NotificationWrapper = ({ userType, close, unreadNotif }) => {
  const [loading, setLoading] = useState(true)
  const [notifications, setNotifications] = useState([])
  const { user, githubUser, loggedIn } = useSelector((state) => state.auth)
  const { shortlistEndpoint } = EndPoints.dashboard
  const fetchNotification = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Stage/notification/get?${
          loggedIn
            ? `user_id=${user.user_id}`
            : `github_id=${githubUser.username}`
        }`
      )
      console.log(data)
      setNotifications(data)
      setLoading(false)
      let unread = []
      data.forEach((nots) => {
        if (nots.status === 'UN_READ') unread.push(nots.notification_id)
      })
      if (unread.length > 0) {
        sendReadRequest(unread)
      }
    } catch (err) {
      console.log(err.message)
    }
  }

  const sendReadRequest = async (notification_ids) => {
    try {
      await axiosInstance.put(
        `${shortlistEndpoint}/Dev/notification/unread/put`,
        {
          notification_ids: notification_ids.join()
        }
      )
    } catch (err) {
      console.log(err.message)
    }
  }
  useEffect(() => {
    fetchNotification()
  }, [])

  return (
    <div className="w-full h-96 overflow-y-scroll scrollbar-hidden flex flex-col  p-2  ">
      {loading ? (
        <RippleLoader />
      ) : (
        <Fragment>
          <div className="flex flex-col mb-4 p-2">
            <h1 className="text-2xl font-bold">Notifications</h1>
            {unreadNotif > 0 && (
              <p className="text-gray-500 text-xs font-medium">
                You have {unreadNotif} unread notifications
              </p>
            )}
          </div>

          {notifications.map((nots, idx) => (
            <SingleNotification close={close} key={idx} notification={nots} />
          ))}
        </Fragment>
      )}
    </div>
  )
}

export default NotificationWrapper
