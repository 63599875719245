import React from 'react'
import SignUp from '../../components/auth/SignUp'

const SignupPage = () => {
  return (
    <div className="flex w-screen min-h-screen overflow-hidden bg-gradient-to-l from-sky-900 to-sky-600  ">
      <div className="bg-gradient-to-l from-sky-900 to-sky-600 pb-20 w-[150vw] h-full rotate-[8deg] ">
        <div className="bg-gradient-to-l from-sky-900 to-sky-700 pb-20 h-full w-[150vw] -rotate-[3deg] ">
          <div className="flex -rotate-[5deg] -translate-x-4 justify-between items-center w-screen p-4 sm:p-10 md:px-20 h-full text-white bg-gradient-to-t from-sky-900 to-sky-800    ">
            <div className="grid place-items-center w-full max-w-screen-xl mx-auto h-full sm:p-4 ">
              <div className="w-full max-w-xl sm:min-w-[400px] h-full pt-10 ">
                <SignUp />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupPage
