import React, { useState } from 'react'
import { AiOutlineGithub } from 'react-icons/ai'
import { BiLoaderAlt } from 'react-icons/bi'
import { BsArrowRightShort } from 'react-icons/bs'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useCreditHook from '../../../../hooks/useCreditHook'
import creditAction from '../../../../redux/actions/creditAction'
import EndPoints from '../../../../services/api/api'
import axiosInstance from '../../../../services/axios/axios'
import { showToast } from '../../../../services/global/Toaster'
import ClickWrapper from '../../click-wrapper/ClickWrapper'
import NoCreditComponent from '../../credits/NoCreditComponent'
const SearchUserForm = ({ searchedUsers }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [loginInput, setLoginInput] = useState({
    username: '',
    email: ''
  })
  const [showTokenModal, setShowTokenModal] = useState(false)
  const { user } = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const { dashboardEndpoint } = EndPoints.dashboard
  const { searchUserEndpoint } = EndPoints.searchPage

  const { generateProfileEligibility } = useCreditHook()
  const dispatch = useDispatch()

  const fetchUserExist = async (username) => {
    setIsLoading(true)
    if (username.length === 0) {
      return
    }
    try {
      const { data } = await axiosInstance.post(
        `${dashboardEndpoint}/adduser`,
        {
          username
        }
      )
      // console.log(data);
      if (data.user) {
        setLoginInput({
          username: '',
          email: ''
        })
        await sendSearchData()
        navigate(`/dashboard/${username}`)
      } else if (!data.user) {
        showToast('error', "username doesn't exist")
      }

      setIsLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const sendSearchData = async () => {
    const searchingSearchedUser = searchedUsers.find(
      (user) => user.github_id === loginInput.username
    )
    if (searchingSearchedUser) return
    const postData = {
      user_id: user?.user_id,
      activity: 'SEARCH',
      search_id: loginInput.username
    }

    try {
      const { data } = await axiosInstance.post(
        `${searchUserEndpoint}/postactivity`,
        postData
      )
      console.log(JSON.parse(data.body))
      dispatch(creditAction.fetchUserCredits(user.user_id))
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!generateProfileEligibility) {
      setShowTokenModal(true)
      return
    }

    fetchUserExist(loginInput.username)
  }

  return (
    <div className="relative">
      <form
        onSubmit={handleSubmit}
        className="border-2 rounded-full p-2 border-gray-300 w-full max-w-xl bg-gray-200 bg-opacity-20 mb-4  "
      >
        <label className=" w-full flex justify-between gap-2 text-2xl items-center ">
          <div className="flex gap-2 w-full items-center ">
            <AiOutlineGithub />
            <input
              value={loginInput.username}
              onChange={(e) =>
                setLoginInput({ ...loginInput, username: e.target.value })
              }
              style={{ width: '100%' }}
              required
              type="text"
              name="username"
              placeholder="Enter Github-ID"
              className=" w-full outline-none  border-none bg-transparent focus:bg-transparent placeholder-gray-400 text-sm font-semibold"
            />
          </div>
          {generateProfileEligibility && (
            <button
              type="submit"
              className="bg-gray-600 text-white rounded-full font-bold text-3xl"
            >
              {isLoading ? (
                <div className="animate-spin">
                  <BiLoaderAlt />
                </div>
              ) : (
                <BsArrowRightShort />
              )}
            </button>
          )}
        </label>
      </form>
      {!generateProfileEligibility && (
        <p className="text-red-500 font-semibold text-sm ">
          Not Enough Credits
        </p>
      )}
      {showTokenModal && (
        <div className="fixed left-0 right-0 top-0 h-screen w-full z-10  bg-gray-300 bg-opacity-40 backdrop-filter backdrop-blur-sm grid place-items-center">
          <div className="w-full max-w-xl p-6 m-auto z-50">
            <ClickWrapper func={() => setShowTokenModal(false)}>
              <NoCreditComponent />
            </ClickWrapper>
          </div>
        </div>
      )}
    </div>
  )
}

export default SearchUserForm
