import React from 'react'
import ReactApexChart from 'react-apexcharts'

const ProjectCharts = ({ data }) => {
  const series = Object.values(data).map((val) => val.total_commits)

  const options = {
    chart: {
      type: 'donut'
      //foreColor: '#ffffff',
    },
    stroke: {
      width: 0
    },
    plotOptions: {
      pie: {
        startAngle: 0,
        endAngle: 360
      }
    },
    labels: Object.values(data).map((val) => val.repo_name),
    dataLabels: {
      enabled: true
    },
    fill: {
      type: 'gradient'
    },

    legend: {
      formatter: function (val, opts) {
        return (
          val.slice(0, 15) + '... - ' + opts.w.globals.series[opts.seriesIndex]
        )
      },
      position: 'right'
    },
    responsive: [
      {
        breakpoint: 556,
        options: {
          legend: {
            position: 'bottom'
          }
        }
      }
    ]
  }

  return (
    <div className="p-4 sm:p-6 flex-1">
      <div className="p-4 sm:p-6 bg-white shadow-custom rounded-xl ">
        <div className="flex justify-between items-center mb-4">
          <h3 className="font-semibold">Projects Commited</h3>
        </div>

        <div id="chart">
          <ReactApexChart
            options={options}
            series={series}
            type="donut"
            height={300}
            className="w-full h-full grid place-items-center"
          />
        </div>
      </div>
    </div>
  )
}

export default ProjectCharts
