export const contributorHeaderData = [
  {
    title: '#',
    width: '10%'
  },
  {
    title: 'Name',
    width: '20%'
  },
  {
    title: 'Languages',
    width: '30%'
  },
  {
    title: 'Number of Commits',
    width: '10%'
  },
  {
    title: 'No of Files Changes',
    width: '10%'
  },
  {
    title: 'Lines of Codes Changes',
    width: '10%'
  },
  {
    title: 'Last Commit',
    width: '10%'
  }
]
