import React, { useEffect, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import uuid from 'react-uuid'
import useCreditHook from '../../../hooks/useCreditHook'
import creditAction from '../../../redux/actions/creditAction'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { showToast } from '../../../services/global/Toaster'
import NoCreditComponent from '../credits/NoCreditComponent'
import CreateJobSkillTab from './CreateJobSkillTab'
import JobDescriptionTab from './JobDescriptionTab'

const CreateJobModal = ({ close, refetchData }) => {
  const [allSkills, setAllSkills] = useState([])
  const [allHardSkills, setAllHardSkills] = useState([])
  const { user } = useSelector((state) => state.auth)
  const [imageUploading, setImageUploading] = useState(false)
  const [imageFile, setImageFile] = useState(null)
  const [previewImage, setPreviewImage] = useState(user.profile_image ?? '')
  const [tnc, setTnc] = useState(false)
  const [fileName, setFileName] = useState('')
  const [tab, setTab] = useState(1)
  const dispatch = useDispatch()
  const { createJobEligibility } = useCreditHook()

  const [profileValue, setProfileValue] = useState({
    user_id: user.user_id,
    job_name: '',
    key_skills: [],
    status: user.type === 'AGENCY' ? 'PENDING' : 'OPEN',
    organization: user.type === 'AGENCY' ? '' : user?.name ?? '',
    job_email: '',
    years_of_experience: 0,
    description: '',
    job_type: '',
    weekly_hours: '',
    work_hours: '',
    pay_range: '',
    country: '',
    job_category: '',
    management: [],
    soft: [],
    technical: [],
    management_skills_weight: 20,
    soft_skills_weight: 30,
    technical_skills_weight: 50
  })

  const { getAllSkills } = EndPoints.createProfile
  const { postJob, uploadJobLogo } = EndPoints.createJob
  const fetchAllSkills = async () => {
    try {
      const { data } = await axiosInstance.get(`${getAllSkills}`)
      //console.log(data);
      setAllSkills(JSON.parse(data.body))
    } catch (err) {
      console.log(err.message)
    }
  }

  const fetchAllHardSkills = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${'https://b2jzh4pdxj.execute-api.ap-south-1.amazonaws.com/Dev/getSkillCloud?skill=all'}`
      )
      setAllHardSkills(data)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleCreateJob = async (e) => {
    e.preventDefault()

    const postDataValue = { ...profileValue }

    delete postDataValue.management
    delete postDataValue.soft
    delete postDataValue.technical

    const postData = {
      ...postDataValue,
      logo_name: fileName,
      key_skills: postDataValue.key_skills
        .map((skills) => skills.language_name)
        .join(','),
      required_management_skills: String(profileValue.management),
      required_soft_skills: String(profileValue.soft),
      required_technical_skills: String(profileValue.technical)
    }

    try {
      if (imageFile && fileName) {
        await uploadImage()
      }
      await axiosInstance.post(`${postJob}`, postData)
      showToast('success', 'Job created successfully')

      dispatch(creditAction.fetchUserCredits(user.user_id))
      await refetchData()
      close()
    } catch (err) {
      console.log(err.message)
      showToast('error', err.message)
    }
  }

  useEffect(() => {
    fetchAllSkills()
    fetchAllHardSkills()
  }, [])

  const fileChange = (e) => {
    if (!e.target.files[0]) {
      alert('please Select a valid file')
      return
    }
    const newFile = e.target.files[0]
    setImageFile(newFile)
    setFileName(`${user.name ?? ''}-${uuid()}`)
  }

  const uploadImage = async () => {
    setImageUploading(true)
    const options = {
      headers: {
        //Authorization: `Bearer ${user.token}`,
        'Content-Type': imageFile.type
      }
    }

    try {
      const { data } = await axiosInstance.post(`${uploadJobLogo}`, {
        filename: fileName,
        bucketname: 'githubcompanieslogo'
      })

      const uploadUrl = JSON.parse(data).data.URL
      console.log(uploadUrl)
      const res = await axiosInstance.put(uploadUrl, imageFile, options)
      console.log(res)
      setImageFile(undefined)
    } catch (err) {
      console.log(err.message)
    }
    setImageUploading(false)
  }

  const handleJobDescriptionChange = (value) => {
    setProfileValue({ ...profileValue, description: value })
  }

  useEffect(() => {
    if (!imageFile) {
      return
    }
    const objectUrl = URL.createObjectURL(imageFile)
    setPreviewImage(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [imageFile])

  if (!createJobEligibility) return <NoCreditComponent />

  return (
    <div className="p-3 sm:p-6 md:p-10 w-full  m-auto h-[95vh] bg-white overflow-y-scroll relative ">
      <button
        onClick={close}
        className="absolute top-6 right-6 text-xl font-bold"
      >
        <MdClose />
      </button>
      <div>
        {tab === 1 && (
          <JobDescriptionTab
            setTab={setTab}
            setProfileValue={setProfileValue}
            profileValue={profileValue}
            allSkills={allSkills}
            tnc={tnc}
            setTnc={setTnc}
            fileChange={fileChange}
            imageFile={imageFile}
            previewImage={previewImage}
            imageUploading={imageUploading}
            handleJobDescriptionChange={handleJobDescriptionChange}
          />
        )}
        {tab === 2 && (
          <CreateJobSkillTab
            setTab={setTab}
            setProfileValue={setProfileValue}
            profileValue={profileValue}
            onSubmit={handleCreateJob}
            allSkills={allHardSkills}
            tnc={tnc}
            setTnc={setTnc}
          />
        )}
      </div>
    </div>
  )
}

export default CreateJobModal
