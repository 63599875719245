import React, { Fragment, useEffect, useState } from 'react'
import { MdWorkOutline } from 'react-icons/md'
import Select from 'react-select'
import uuid from 'react-uuid'
import EndPoints from '../../../services/api/api'
import axiosInstance from '../../../services/axios/axios'
import { reactSelectStyleUtils } from '../../../services/components/react-select'
import { jobCategoryData } from '../../components/create-job/data'
import {
  EmploymentTypeData,
  jobTypeData,
  workTypeData
} from '../../components/job-page/filterData'
import SingleJobListingCard from '../../components/job-page/SingleJobListingCard'
import { leaderboardCountries } from '../../components/leaderboard/tableData'
import RippleLoader from '../../components/loader/RippleLoader'
import ListingPagination from '../../components/pagination/ListingPagination'

const AllJobPage = () => {
  const getAllJobsData = () => {
    const data = sessionStorage.getItem('ALL_JOB_DATA')
    if (data === null) {
      return null
    }
    return JSON.parse(data)
  }

  const [loading, setLoading] = useState(true)
  const { getAllJobs } = EndPoints.allJobsPage
  const [allJobsData, setAllJobsData] = useState([])
  const [page, setPage] = useState(
    getAllJobsData()?.page ? getAllJobsData()?.page : 1
  )
  const [limit, setLimit] = useState(
    getAllJobsData()?.limit ? getAllJobsData()?.limit : 20
  )
  const [totalPage, setTotalPage] = useState(1)
  const [totalJobs, setTotalJobs] = useState(0)
  const [selectedCountry, setSelectedCountry] = useState(
    getAllJobsData()?.selectedCountry ? getAllJobsData()?.selectedCountry : []
  )
  const [jobType, setJobtype] = useState(
    getAllJobsData()?.jobType ? getAllJobsData()?.jobType : []
  )
  const [organization, setOrganization] = useState(
    getAllJobsData()?.organization ? getAllJobsData()?.organization : []
  )
  const [workType, setWorkType] = useState(
    getAllJobsData()?.workType ? getAllJobsData()?.workType : []
  )

  const [jobTime, setJobTime] = useState(
    getAllJobsData()?.jobTime ? getAllJobsData()?.jobTime : []
  )
  const [jobCategory, setJobCategory] = useState(
    getAllJobsData()?.jobCategory ? getAllJobsData()?.jobCategory : []
  )

  const getSearchQuery = (
    country,
    jobTime,
    workType,
    organization,
    jobType,
    jobCategory
  ) => {
    //console.log(country, jobTime, workType, organization, jobType, jobCategory);
    const countryQuery =
      country === null ||
      country === '' ||
      country.length === 0 ||
      country === ['ALL']
        ? 'ALL'
        : `[${[country].map((country) => `"${country.value}"`)}]`

    const jobTimeQuery =
      jobTime === null ||
      jobTime === '' ||
      jobTime.length === 0 ||
      jobTime === ['ALL']
        ? 'ALL'
        : `[${jobTime.map((jobTime) => `"${jobTime.value}"`)}]`

    const workTypeQuery =
      workType === null ||
      workType === '' ||
      workType.length === 0 ||
      workType === ['ALL']
        ? 'ALL'
        : `[${workType.map((workType) => `"${workType.value}"`)}]`

    const organizationQuery =
      organization === null ||
      organization === '' ||
      organization.length === 0 ||
      organization === ['ALL']
        ? 'ALL'
        : `[${organization.map((organization) => `"${organization.value}"`)}]`

    const jobTypeQuery =
      jobType === null ||
      jobType === '' ||
      jobType.length === 0 ||
      jobType === ['ALL']
        ? 'ALL'
        : `[${jobType.map((jobType) => `"${jobType.value}"`)}]`

    const jobCategoryQuery =
      jobCategory === null ||
      jobCategory === '' ||
      jobCategory.length === 0 ||
      jobCategory === ['ALL']
        ? 'ALL'
        : `[${[jobCategory].map((jobCategory) => `"${jobCategory.value}"`)}]`

    return `${getAllJobs}?page=${page}&limit=${limit}&country=${countryQuery}&job_time=${jobTimeQuery}&work_type=${workTypeQuery}&organization=${organizationQuery}&job_type=${jobTypeQuery}&job_category=${jobCategoryQuery}`
  }

  const fetchAllJobs = async () => {
    //setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        getSearchQuery(
          selectedCountry,
          jobTime,
          workType,
          organization,
          jobType,
          jobCategory
        )
      )
      console.log(data)
      setAllJobsData(data.message)
      setTotalPage(data.total_pages)
      setTotalJobs(data.total_count)
      const storedData = {
        page,
        limit,
        selectedCountry,
        jobTime,
        workType,
        organization,
        jobType,
        jobCategory
      }
      sessionStorage.setItem('ALL_JOB_DATA', JSON.stringify(storedData))
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchAllJobs()
  }, [
    page,
    selectedCountry,
    jobTime,
    workType,
    organization,
    jobType,
    limit,
    jobCategory
  ])

  useEffect(() => {
    window.scrollTo({ behavior: 'smooth', top: '0px' })
  }, [page])
  return (
    <div className="min-h-screen w-full p-4 sm:p-6 md:p-10">
      <div className="w-full max-w-7xl m-auto">
        <div className="w-full bg-purple-400 rounded-xl bg-opacity-10 p-6 md:p-10 flex flex-col gap-6 items-center">
          <h1 className="text-3xl font-semibold">
            <span className="text-blue-500 mr-2">
              {loading ? 'Finding' : totalJobs}
            </span>
            Jobs Available Now
          </h1>
          <div className="flex flex-col md:flex-row justify-between md:items-end gap-4 bg-white w-full max-w-5xl m-auto rounded-md p-6 shadow-custom">
            <label className="flex flex-col gap-2 flex-1 text-xs md:text-sm ">
              <Select
                className="basic-single  bg-transparent text-sm"
                onChange={(val) => setJobCategory(val)}
                value={jobCategory}
                classNamePrefix="Category"
                isClearable={true}
                isSearchable={true}
                name="jobCategory"
                placeholder="Select Job Category"
                styles={reactSelectStyleUtils}
                options={jobCategoryData.map((category) => ({
                  value: category,
                  label: category
                }))}
              />
            </label>

            <label className="flex flex-col gap-2 flex-1 text-xs md:text-sm ">
              <Select
                className="basic-single  bg-transparent text-sm"
                onChange={(val) => setSelectedCountry(val)}
                value={selectedCountry}
                classNamePrefix="Country"
                isClearable={true}
                isSearchable={true}
                name="country"
                placeholder="Select Job Country"
                styles={reactSelectStyleUtils}
                options={leaderboardCountries.map((country) => ({
                  value: country,
                  label: country
                }))}
              />
            </label>

            <button
              onClick={fetchAllJobs}
              className="p-3 self-end py-2 border-2 border-blue-500 text-sm hover:bg-transparent hover:text-blue-500 transition-all duration-300 ease-in-out rounded-md w-40 bg-blue-500 text-white grid place-items-center"
            >
              Find Jobs
            </button>
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-3 gap-3  md:flex w-full md:gap-6 justify-center items-center">
            <label className="flex flex-col gap-2 sm:min-w-[200px] text-xs md:text-sm ">
              <Select
                className="basic-multi-select  bg-transparent text-sm"
                isMulti
                onChange={(val) => setJobtype(val)}
                value={jobType}
                classNamePrefix="Country"
                isClearable={true}
                isSearchable={true}
                name="country"
                placeholder=" Job Type"
                styles={reactSelectStyleUtils}
                options={jobTypeData}
              />
            </label>
            <label className="flex flex-col gap-2 sm:min-w-[200px]  text-xs md:text-sm ">
              <Select
                className="basic-multi-select  bg-transparent text-sm"
                isMulti
                onChange={(val) => setWorkType(val)}
                value={workType}
                classNamePrefix="Country"
                isClearable={true}
                isSearchable={true}
                name="country"
                placeholder="Work Type"
                styles={reactSelectStyleUtils}
                options={workTypeData}
              />
            </label>
            <label className="flex flex-col gap-2  sm:min-w-[200px] text-xs md:text-sm ">
              <Select
                className="basic-multi-select  bg-transparent text-sm"
                isMulti
                onChange={(val) => setJobTime(val)}
                value={jobTime}
                classNamePrefix="Country"
                isClearable={true}
                isSearchable={true}
                name="country"
                placeholder="Employment Type"
                styles={reactSelectStyleUtils}
                options={EmploymentTypeData}
              />
            </label>
          </div>
        </div>
        {loading ? (
          <RippleLoader />
        ) : (
          <Fragment>
            {allJobsData.length === 0 ? (
              <div className="w-full h-full py-20 grid place-items-center text-gray-400">
                <div className=" flex flex-col gap-4 items-center text-center ">
                  <MdWorkOutline className="text-6xl" />

                  <h5 className="max-w-md m-auto">
                    Based on your filters , we didn't seem to find any open jobs
                    .
                  </h5>
                </div>
              </div>
            ) : (
              <Fragment>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 my-10">
                  {allJobsData.map((job) => (
                    <SingleJobListingCard key={uuid()} data={job} />
                  ))}
                </div>
                <ListingPagination
                  page={page}
                  onPageChange={(page) => setPage(page)}
                  count={totalPage}
                />
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default AllJobPage
