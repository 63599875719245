import Tooltip from '@mui/material/Tooltip'
import React, { useState } from 'react'
import { GiHamburgerMenu } from 'react-icons/gi'
import { IoMdClose } from 'react-icons/io'
import { useSelector } from 'react-redux'
import uuid from 'react-uuid'
import ProfileComponent from '../auth/ProfileComponent'
import { recruiterNavData } from './NavData'
import SingleHeaderLink from './SingleHeaderLink'
import SingleResponsiveHeader from './SingleResponsiveHeader'
import NotificationComponent from '../notification/NotificationComponent'

const AuthenticatedNavbar = ({ unreadNotif }) => {
  const [showNavMenu, setShowNavMenu] = useState(false)
  const { user, loggedIn } = useSelector((state) => state.auth)
  const navData = recruiterNavData(user?.type)
  return (
    <div className="">
      <div className=" flex md:gap-3 items-center text-xs sm:text-sm">
        {showNavMenu && (
          <div className=" md:hidden flex flex-col border-b-2 border-gray-300 border-opacity-40  md:flex-row absolute top-full w-full left-0 z-30  bg-white  p-6 justify-center items-center text-xs sm:text-sm">
            {navData.map((navData) => (
              <SingleResponsiveHeader
                key={uuid()}
                menu={navData}
                setShowMenu={setShowNavMenu}
              />
            ))}
          </div>
        )}

        <div className=" hidden md:flex flex-wrap bg-white  md:gap-3 justify-end items-center md:ml-6 text-xs sm:text-sm">
          {navData.map((navData) => (
            <SingleHeaderLink key={uuid()} menu={navData} />
          ))}
        </div>
        <div className="flex gap-3 items-center">
          <Tooltip title="Menu">
            <button
              className="md:hidden text-xl rounded-full w-8 h-8   text-gray-800 grid place-items-center"
              onClick={() => setShowNavMenu(!showNavMenu)}
            >
              {!showNavMenu ? <GiHamburgerMenu /> : <IoMdClose />}
            </button>
          </Tooltip>
          <Tooltip title="Notification">
            <NotificationComponent
              unreadNotif={unreadNotif}
              userType={'recruiter'}
            />
          </Tooltip>
          <Tooltip title="Profile">
            <ProfileComponent userType={'recruiter'} />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}

export default AuthenticatedNavbar
