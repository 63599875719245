import React from 'react'
import { AiOutlineAntDesign, AiOutlineClockCircle } from 'react-icons/ai'
import { GiDesk, GiMoneyStack } from 'react-icons/gi'
import { IoMedalOutline } from 'react-icons/io5'
import { MdLocationPin, MdWorkOutline } from 'react-icons/md'
import ReactHtmlParser from 'react-html-parser'
import { Link, useNavigate } from 'react-router-dom'
import uuid from 'react-uuid'

const SingleJobListingCard = ({ data }) => {
  const {
    user_id,
    job_id,
    job_name,
    key_skills,
    status,
    organization,
    job_email,
    years_of_experience,
    description,
    create_date,
    company_logo,
    job_type,
    weekly_hours,
    work_hours,
    pay_range,
    updated_at,
    job_location,
    country
  } = data
  const navigate = useNavigate()
  return (
    <div className="rounded-md flex flex-col text-sm p-4 gap-1 shadow-custom bg-white  relative">
      <div className="flex flex-col gap-6 relative">
        <div className=" flex flex-col gap-3 border-b-2 border-gray-300 border-opacity-40 pt-2 pb-3 ">
          <div className="flex justify-between gap-6 items-start">
            <div className="flex flex-col gap-2">
              <div className="flex gap-3 ">
                {company_logo && (
                  <img
                    className="w-16 h-16 p-1 rounded-md border-2 border-gray-300 border-opacity-40 object-contain "
                    src={company_logo}
                    alt={''}
                  />
                )}

                <Link
                  to={`/${organization.split(' ').join('-')}/${job_id}/apply`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-xl font-semibold hover:text-blue-500 "
                >
                  {job_name}
                </Link>
              </div>
              <div className="text-blue-500 flex gap-2 items-center text-sm">
                {' '}
                <AiOutlineAntDesign />
                <p className="text-sm font-semibold ">{organization}</p>
              </div>
            </div>

            <Link
              className="bg-blue-500 min-w-[150px] rounded-full flex shadow-lg text-sm justify-center items-center font-semibold gap-2 p-[10px] disabled:bg-gray-400 px-4 text-white "
              to={`/${organization.split(' ').join('-')}/${job_id}/apply`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Apply Now
            </Link>
          </div>
          <div className=" flex   gap-4 items-center flex-wrap">
            <p className="text-sm flex  gap-2  ">
              <IoMedalOutline className="text-xl" />
              {years_of_experience} {years_of_experience > 1 ? 'years' : 'year'}{' '}
              of experience{' '}
            </p>

            {pay_range && (
              <p className="text-sm flex  gap-2 items-center ">
                <GiMoneyStack className="text-xl" />
                {pay_range}
              </p>
            )}
            {job_type && (
              <p className="text-sm flex  gap-1 items-center ">
                <MdWorkOutline className="text-xl" />
                {job_type}
              </p>
            )}
            {country && (
              <p className="text-sm flex  gap-1 capitalize items-center ">
                <MdLocationPin className="text-xl" />
                {country}
              </p>
            )}
            {work_hours && (
              <p className="text-sm flex  gap-1 items-center ">
                <GiDesk className="text-xl" />
                {work_hours}
              </p>
            )}
            {weekly_hours && (
              <p className="text-sm flex  gap-1 items-center ">
                <AiOutlineClockCircle className="text-xl" />
                {weekly_hours} hours
              </p>
            )}
          </div>
        </div>

        <div className="flex ">
          {key_skills && key_skills.length > 0 && (
            <div className="flex-1">
              <h2 className="font-semibold mb-2"> Skills required : </h2>

              <div>
                {key_skills && (
                  <div className="flex gap-2 items-center flex-wrap">
                    {key_skills.split(',').map((skills) => (
                      <p
                        key={uuid()}
                        className="bg-gray-100 p-2 px-3 text-blue-900 rounded-full shadow text-xs "
                      >
                        {' '}
                        {skills}
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="my-3 ">
        {description && (
          <>
            <p>
              <span className="font-semibold mb-1">Description</span>
            </p>
            <p className="">{ReactHtmlParser(description.slice(0, 200))}</p>

            <button
              onClick={() =>
                navigate(
                  `/${organization.split(' ').join('-')}/${job_id}/apply`
                )
              }
              className="text-blue-400 text-sm font-semibold"
            >
              ... See More
            </button>
          </>
        )}
      </div>

      <div className="flex justify-between items-end gap-4 w-full ">
        <p className="text-xs w-full text-right ">
          <span className="font-semibold">Posted on </span>
          {create_date
            ? create_date.split(' ')[0].split('-').reverse().join('/')
            : ''}
        </p>
      </div>
    </div>
  )
}

export default SingleJobListingCard
