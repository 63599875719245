export const projectCategory = [
  'Web Development',
  'Mobile App Development',
  'UI/UX Design',
  'Graphic Design',
  'Copywriting',
  'Content Writing',
  'Technical Writing',
  'Blog Writing',
  'Article Writing',
  'Editing and Proofreading',
  'Translation',
  'Social Media Management',
  'Social Media Marketing',
  'Email Marketing',
  'SEO Services',
  'SEM Services',
  'PPC Advertising',
  'Affiliate Marketing',
  'Video Production',
  'Animation',
  'Voiceover',
  'Virtual Assistant',
  'Data Entry',
  'Data Analytics',
  'Market Research',
  'Business Consulting',
  'Financial Planning',
  'Tax Preparation',
  'Legal Services',
  'Real Estate Services',
  'Interior Design',
  'Architecture',
  '3D Modeling',
  'CAD Services',
  'Mechanical Engineering',
  'Electrical Engineering',
  'Civil Engineering',
  'Chemical Engineering',
  'Industrial Engineering',
  'Aerospace Engineering',
  'Software Testing',
  'QA Testing',
  'Game Development',
  'Game Design',
  'Music Production',
  'Audio Editing',
  'Podcast Production',
  'Photography',
  'Videography',
  'Drone Photography',
  'Drone Videography',
  'Illustration',
  'Book Cover Design',
  'Book Formatting',
  'Ghostwriting',
  'Screenwriting',
  'Scriptwriting',
  'Voice Acting',
  'Virtual Reality Development',
  'Augmented Reality Development',
  'Blockchain Development',
  'Cryptocurrency Services',
  'IT Support',
  'Cybersecurity',
  'DevOps',
  'Cloud Computing',
  'Database Administration',
  'Network Administration',
  'Technical Support',
  'Chatbot Development',
  'Artificial Intelligence Development',
  'Machine Learning Development',
  'Natural Language Processing',
  'Robotic Process Automation',
  'Chat Support',
  'Customer Service',
  'Sales',
  'Lead Generation',
  'Appointment Setting',
  'Telemarketing',
  'Market Analysis',
  'Digital Art',
  'Drawing',
  'Painting',
  'Sculpture',
  'Jewelry Design',
  'Fashion Design',
  'Textile Design',
  'Knitwear Design',
  'Embroidery',
  'Quilting',
  'Woodworking',
  'Metalworking',
  'Glassworking',
  'Pottery',
  'Ceramics',
  'Soap Making',
  'Candle Making',
  'Herbalism',
  'Nutrition Coaching'
]

export const ProjectKeySkills = [
  'Programming Languages (e.g. Java, Python, Ruby, C++, etc.)',
  'Web Development (e.g. HTML, CSS, JavaScript, etc.)',
  'Mobile App Development (e.g. iOS, Android, React Native, etc.)',
  'Database Management (e.g. MySQL, Oracle, MongoDB, etc.)',
  'Cloud Computing (e.g. AWS, Azure, Google Cloud, etc.)',
  'Data Science and Analytics (e.g. R, SAS, SPSS, Tableau, etc.)',
  'DevOps (e.g. Docker, Kubernetes, Jenkins, etc.)',
  'Machine Learning and Artificial Intelligence (e.g. TensorFlow, Keras, PyTorch, etc.)',
  'Cybersecurity and Information Security (e.g. Penetration Testing, Ethical Hacking, etc.)',
  'Blockchain Development (e.g. Ethereum, Hyperledger, etc.)',
  'UI/UX Design (e.g. Adobe Photoshop, Sketch, etc.)',
  'Project Management (e.g. Agile, Scrum, Waterfall, etc.)',
  'Quality Assurance and Testing (e.g. Selenium, JMeter, etc.)',
  'Network Administration and Engineering (e.g. Cisco, Juniper, etc.)',
  'Virtualization and Containerization (e.g. VirtualBox, Vagrant, etc.)',
  'Linux Administration (e.g. Red Hat, Ubuntu, etc.)',
  'Windows Administration (e.g. Active Directory, PowerShell, etc.)',
  'Big Data and Hadoop (e.g. Hadoop, Spark, etc.)',
  'API Development (e.g. REST, SOAP, etc.)',
  'Frontend Development Frameworks (e.g. Angular, React, Vue.js, etc.)',
  'Backend Development Frameworks (e.g. Laravel, Django, Ruby on Rails, etc.)',
  'Full Stack Development',
  'Game Development (e.g. Unity, Unreal Engine, etc.)',
  'Robotics and Automation',
  'Embedded Systems Development',
  'Computer Vision and Image Processing',
  'Natural Language Processing',
  'Chatbot Development',
  'Voice Assistant Development (e.g. Alexa Skills, Google Assistant Actions, etc.)',
  'Data Warehousing and Business Intelligence (e.g. ETL, Data Warehousing, etc.)',
  'Cloud Infrastructure Administration (e.g. Azure Administration, AWS Administration, etc.)',
  'Data Migration and Integration',
  'Data Cleansing and Enrichment',
  'Data Visualization',
  'Data Mining and Extraction',
  'Machine Learning Operations (MLOps)',
  'Data Engineering',
  'Data Governance and Compliance',
  'Data Security and Privacy',
  'Data Strategy and Management',
  'Distributed Systems Development',
  'Microservices Development',
  'Container Orchestration',
  'Cloud-Native Development',
  'Cloud Storage Management',
  'Cloud Migration and Deployment',
  'Cloud Cost Optimization',
  'Cloud Monitoring and Management',
  'Serverless Computing',
  'ChatOps',
  'Agile Methodologies',
  'Continuous Integration and Continuous Deployment (CI/CD)',
  'Version Control Systems (e.g. Git, SVN, etc.)',
  'Automated Testing',
  'Code Review and Optimization',
  'Data-driven Decision Making',
  'Data Storytelling',
  'Data Journalism',
  'IoT Development',
  'Wearable Devices Development',
  '3D Printing',
  'Computer-Aided Design (CAD)',
  'Finite Element Analysis (FEA)',
  'Computational Fluid Dynamics (CFD)',
  'Simulation and Modeling',
  'Mathematical Optimization',
  'Neural Networks and Deep Learning',
  'Reinforcement Learning',
  'Computer Graphics and Visualization',
  'Human-Computer Interaction',
  'Augmented Reality (AR)'
]
