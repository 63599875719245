import EndPoints from '../../services/api/api'
import axiosInstance from '../../services/axios/axios'
import { creditState, userCreditsState } from '../reducers/creditReducer'
import { CREDITS_LOADING, UPDATE_CREDITS } from './actionTypes'

const parseCreditValues = (arr) => {
  return arr.reduce(
    (obj, item) => Object.assign(obj, { [item.parameter]: { ...item } }),
    {}
  )
}

const fetchUserCredits = (userID) => async (dispatch) => {
  const { searchUserEndpoint } = EndPoints.searchPage
  const { getLeaderBoard } = EndPoints.leaderboard
  dispatch({ type: CREDITS_LOADING, payload: { loading: true } })
  let userCredits = userCreditsState
  let creditValues = creditState
  try {
    const res = await Promise.all([
      axiosInstance.get(
        `${searchUserEndpoint}/getusercredits?user_id=${userID}`
      ),
      axiosInstance.get(
        `${getLeaderBoard}//systemValues/get?category=CREDIT_SYSTEM`
      )
    ])
    if (JSON.parse(res[0].data.body).message) {
      userCredits = JSON.parse(res[0].data.body).message
    }

    if (res[1].data.message) {
      creditValues = parseCreditValues(res[1].data.message)
    }
    dispatch({ type: UPDATE_CREDITS, payload: { userCredits, creditValues } })
    dispatch({ type: CREDITS_LOADING, payload: { loading: false } })
  } catch (err) {
    console.log(err.message)
    dispatch({ type: CREDITS_LOADING, payload: { loading: false } })
  }
}
const creditAction = {
  fetchUserCredits
}

export default creditAction
