import React from 'react'
import ReactApexChart from 'react-apexcharts'

const RatingProgress = ({ points, height }) => {
  let arr = []
  arr.push(points)
  const series = arr
  const options = {
    chart: {
      height: height ? height : 250,
      type: 'radialBar',
      offsetY: -10
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        dataLabels: {
          name: {
            fontSize: '16px',
            color: undefined,
            offsetY: 120
          },
          value: {
            offsetY: 0,
            fontSize: '16px',
            color: undefined,
            formatter: function (val) {
              return val + '%'
            }
          }
        }
      }
    },
    fill: {
      type: 'gradient',
      gradient: {
        shade: 'dark',
        shadeIntensity: 0.15,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [0, 50, 65, 91]
      }
    },
    stroke: {
      dashArray: 2
    },
    labels: ['']
  }
  return (
    <div id="chart">
      <ReactApexChart
        options={options}
        series={series}
        type="radialBar"
        height={height ? height : 200}
      />
    </div>
  )
}

export default RatingProgress
