import React, { Fragment } from 'react'
import LanguageBar from '../../radial-bar/LanguageBar'
import { AiOutlineStar } from 'react-icons/ai'
import { CgGitFork } from 'react-icons/cg'
import uuid from 'react-uuid'
import { includeComma } from '../../../../services/global/globalFunction'
import { Link } from 'react-router-dom'

const SingleRepo = ({ data, userData }) => {
  const { repo_name, stars, description, total_commits, lang, forks, size } =
    data
  return (
    <Link
      to={`/repository/${userData.github_id}/${repo_name}`}
      target="blank"
      className="bg-white rounded-md p-4 shadow-custom "
    >
      <div className="flex justify-between items-start">
        <h3 className="font-semibold mr-2 truncate">{repo_name}</h3>

        <div className="flex flex-col items-end">
          <h3>{total_commits === null ? '0' : total_commits} commits</h3>
          <p className="text-sm my-1">Size : {includeComma(size)}</p>
          <div className="flex gap-2">
            <h3 className="flex items-center text-sm">
              <span className="mr-1">
                <AiOutlineStar />
              </span>
              {stars}
            </h3>
            <h3 className="flex items-center text-sm">
              <span className="mr-1">
                <CgGitFork />
              </span>
              {forks}
            </h3>
          </div>
        </div>
      </div>
      {description !== null && description.length > 0 && (
        <div>
          <p className="text-sm font-semibold mb-2">About : </p>
          <p className="text-xs truncate max-h-10 ">
            {' '}
            {description === null ? '' : description}
          </p>
        </div>
      )}

      <div className="flex flex-col my-4">
        {lang.length > 0 && (
          <Fragment>
            {' '}
            <p className="text-sm font-semibold mb-2">Language Used : </p>
            {lang.slice(0, 3).map((language) => (
              <LanguageBar
                key={uuid()}
                value={language.language_percentage}
                language={language.language}
              />
            ))}
          </Fragment>
        )}
      </div>
    </Link>
  )
}

export default SingleRepo
