import React from 'react'
import { IoPeopleOutline } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import { CONTACT_PAGE } from '../../../navigation/routes'
import { pricingData } from '../../components/pricing/data'
import SinglePricingCard from '../../components/pricing/SinglePricingCard'
import IconButton from '../../ui-component/buttons/IconButton'

const PricingPage = () => {
  const navigate = useNavigate()
  return (
    <div className="w-full min-h-screen p-6 md:p-10 ">
      <div className="flex justify-between items-center py-2 gap-6">
        <h1 className="text-2xl sm:text-3xl md:text-5xl leading-snug font-bold max-w-2xl ">
          Your <span className="text-blue-500">Code</span> could be your{' '}
          <span className="text-orange-500">Resume</span>{' '}
        </h1>
        <IconButton
          toolTipTitle={'Contact Us'}
          text={'Contact Us'}
          icon={<IoPeopleOutline />}
          onClick={() => {
            navigate(CONTACT_PAGE)
          }}
          type="primary"
          outline
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 my-10">
        <div className="rounded-2xl flex-col gap-6 p-8 hidden lg:flex">
          <h2 className="text-3xl">Select the TechProfile Plan</h2>

          <div className="text-base leading-relaxed flex flex-col gap-1">
            <p>
              <strong>No Success Fee:</strong>
              There is no additional fee or commission.
            </p>
            <p>
              <strong>No Install Costs:</strong> There are no upfront
              installation costs or fees required.
            </p>
            <p>
              <strong>No Hidden Costs:</strong> There are no hidden or surprise
              costs that will be incurred.
            </p>
            <p>
              <strong>Monthly Fee Only:</strong> The only fee that will be
              charged is a recurring monthly fee.
            </p>
            <p>
              <strong>Cancel Anytime:</strong> The monthly fee can be cancelled
              at any time without any penalties or fees.
            </p>
          </div>

          <lottie-player
            src="https://assets7.lottiefiles.com/packages/lf20_ep1tn2ew.json"
            background="transparent"
            speed="1"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              margin: 'auto'
            }}
            loop
            autoplay
          ></lottie-player>
        </div>
        <SinglePricingCard
          data={pricingData.startup}
          type={pricingData.startup.title.toLowerCase()}
        />
        <SinglePricingCard
          data={pricingData.growth}
          type={pricingData.growth.title.toLowerCase()}
        />
      </div>
    </div>
  )
}

export default PricingPage
