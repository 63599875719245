import React, { Fragment, useEffect, useState } from 'react'
import axiosInstance from '../../../services/axios/axios'
import EndPoints from '../../../services/api/api'
import { useNavigate } from 'react-router-dom'
import { RECRUITER_DASHBOARD_PAGE } from '../../../navigation/routes'
import RippleLoader from '../loader/RippleLoader'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'

const SingleInterviewActionModal = ({ id, close }) => {
  const [interviewData, setInterviewData] = useState({})
  const [loading, setLoading] = useState(true)
  const [loadingAccept, setLoadingAccept] = useState(false)
  const { shortlistEndpoint } = EndPoints.dashboard
  const { loggedIn } = useSelector((state) => state.auth)
  const navigate = useNavigate()

  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(localizedFormat)

  const fetchSingleInterview = async () => {
    try {
      const { data } = await axiosInstance.get(
        `${shortlistEndpoint}/Dev/singleInterviewDetails/get?id=${id}`
      )
      console.log(data)
      if (data.status === 'ACCEPTED') {
        if (loggedIn) {
          navigate(RECRUITER_DASHBOARD_PAGE)
        }

        close()
        return
      }
      setInterviewData(data)
      setLoading(false)
    } catch (err) {
      console.log(err.message)
    }
  }

  const handleInterviewResponse = async (acceptedSlot) => {
    setLoadingAccept(true)
    try {
      await axiosInstance.put(`${shortlistEndpoint}/Dev/interviewDetails/put`, {
        id,
        accepted_slot: acceptedSlot,
        status: 'ACCEPTED'
      })
      setLoadingAccept(false)
      fetchSingleInterview()
    } catch (err) {
      console.log(err.message)
    }
  }

  useEffect(() => {
    fetchSingleInterview()
  }, [])

  return (
    <Fragment>
      {loading ? (
        <RippleLoader />
      ) : (
        <div className="p-3 flex flex-col bg-gray-100 rounded-xl text-xs ">
          <p className="flex gap-2 capitalize">
            <span className="font-semibold">Job :</span>
            Frontend Developer
          </p>
          <p className="flex gap-2 capitalize">
            <span className="font-semibold capitalize">Mode :</span>
            {interviewData?.type.toLowerCase()}
          </p>
          <p className="flex gap-2 ">
            <span className="font-semibold">Location :</span>
            {interviewData?.location}
          </p>
          {interviewData?.status === 'ACCEPTED' ? (
            <div className="flex gap-2 items-center justify-between pt-2">
              <p className="font-semibold  ">
                {dayjs(interviewData?.accepted_slot)
                  .tz(interviewData?.time_zone)
                  .format('LLL')}
              </p>
            </div>
          ) : (
            <Fragment>
              {interviewData?.interview1 && (
                <div className="flex gap-2 items-center justify-between pt-2">
                  <p className="font-semibold  ">
                    {dayjs(interviewData?.interview1)
                      .tz(interviewData?.time_zone)
                      .format('LLL')}
                  </p>
                  <button
                    onClick={() =>
                      handleInterviewResponse(interviewData?.interview1)
                    }
                    disabled={loadingAccept}
                    className="text-blue-500 font-semibold items-center"
                  >
                    Accept
                  </button>
                </div>
              )}
              {interviewData?.interview2 && (
                <div className="flex gap-2 items-center justify-between pt-2">
                  <p className="font-semibold  ">
                    {dayjs(interviewData?.interview2)
                      .tz(interviewData?.time_zone)
                      .format('LLL')}
                  </p>
                  <button
                    onClick={() =>
                      handleInterviewResponse(interviewData?.interview2)
                    }
                    disabled={loadingAccept}
                    className="text-blue-500 font-semibold items-center"
                  >
                    Accept
                  </button>
                </div>
              )}
              {interviewData?.interview3 && (
                <div className="flex gap-2 items-center justify-between pt-2">
                  <p className="font-semibold  ">
                    {dayjs(interviewData?.interview3)
                      .tz(interviewData?.time_zone)
                      .format('LLL')}
                  </p>
                  <button
                    onClick={() =>
                      handleInterviewResponse(interviewData?.interview3)
                    }
                    disabled={loadingAccept}
                    className="text-blue-500 font-semibold items-center"
                  >
                    Accept
                  </button>
                </div>
              )}
            </Fragment>
          )}
        </div>
      )}
    </Fragment>
  )
}

export default SingleInterviewActionModal
